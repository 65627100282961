import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Button,
} from "@mui/material";
import { BreadCrumb } from "primereact/breadcrumb";
import { useParams } from "react-router-dom";
import API from "../../api/ClientApi";

const OfferViewBuyer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [offerData, setOfferData] = useState(null);
  const items = [
    { label: "Offers", url: "/dashboard/buyer/open-offers" },
    { label: "Offer Detail" },
  ];
  const home = { label: "Home", url: "/dashboard/website-list" };

  useEffect(() => {
    setLoad(true);
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }

    const fetchData = async () => {
      try {
        const response = await API.get(
          `${process.env.REACT_APP_BACKEND_API}/api/open-offer/${id}`
        );
        setOfferData(response.data);
        setLoad(false);
      } catch (error) {
        // Handle error here
        setLoad(false);
      }
    };

    fetchData(); // Call the async function immediately
  }, [localStorage.getItem("role")]);

  return (
    <div className="buyer-form-main-wraper">
     <BreadCrumb model={items} home={home} />
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Offer</h2>
          </div>
        </div>
      </div>
      {/* <Accordion className="accordion-filter accordion-global">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-summery"
        >
          <div className="w-100 d-flex justify-content-between filter-header">
            <h2>HOW IT WORKS?</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <div className="accordion-detail-div ac-div">
            In this section you can see tasks grouped by multiple statuses.Each
            status is accompanied by a number representing the quantity of tasks
            within that status.As the task is in progress, it will transition
            between different statuses.You'll stay updated through emails and
            notifications. To view a description of each status, hover your
            mouse pointer over the corresponding status name.To access
            additional details about the task ‘click button with three dots’ and
            smoothly perform further actions.
          </div>
        </AccordionDetails>
      </Accordion> */}
      {load ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="mt-2 offer-detail-container">
            <div className="offer-heading-wrapper">
              <h4>{offerData?.offer_name}</h4>
            </div>
            <div className="offer-rest-detail-wrapper">
              <div className="accordion-row">
                <div className="d-flex flex-column align-items-start offer-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Service type:</h6>
                  {offerData?.service_type}
                </div>
                <div className="d-flex flex-column align-items-start offer-row-col">
                  <h6 className="order-article-faq-q ">Promoted Url:</h6>
                  {offerData?.promoted_url}
                </div>
                <div className="d-flex flex-column align-items-start offer-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Price type:</h6>
                  {`${offerData?.price !== "open price" ? "$" : ""} ${
                    offerData?.price
                  }`}
                </div>
                <div className="d-flex flex-column align-items-start offer-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Offer available:</h6>
                  All publishers
                </div>
                <div className="d-flex flex-column align-items-start offer-row-col offer-row-col-active">
                  {" "}
                  {offerData?.status_id === 13 ? (
                    <div className="d-flex px-0 align-items-center">
                      <div className="act-circle"></div>Active
                    </div>
                  ) : (
                    <div className="d-flex px-0 align-items-center">
                      <div className="act-circle-p"></div>Paused
                    </div>
                  )}
                  {/* <Button
                    aria-describedby={id}
                    variant="outlined"
                    onClick={() =>
                      navigate(`/dashboard/publisher/bid/offer/${id}`)
                    }
                    className="bid-offer-btn bid-offer-btn-height"
                  >
                    Bid
                  </Button> */}
                </div>
              </div>
            </div>
            <div className="offer-desc-wrapper">
              <h6>Brief description:</h6>
              <p>{offerData?.brief_requirements}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OfferViewBuyer;
