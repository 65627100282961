import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  Button,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { verifyUserEmail } from "../../../actions/userActions";
import logo from "../../../assets/images/logoMain.png";
import "../styles/verifyCard.css";
import Swal from "sweetalert2";

const VerifyCard = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { response, loading, error } = useSelector((state) => state.verify);
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  // Display SweetAlert when error occurs
  useEffect(() => {
    dispatch(verifyUserEmail(id));
    if (response) {
      navigate("/login");
    }
    if (error) {
      Swal.fire({
        title: "Error!",
        html: error.response.data.message,
        icon: "error",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
    }
  }, [response, error]);

  return (
    <div className="verify-card-main">
      <div className="signup-container">
        <div className="login-logo">
          <img src={logo} alt="weblinks-logo" />

          <div className="d-flex flex-column form-main-sec">
            <h2>Verifying...</h2>
            <LinearProgress className="progress-verify" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyCard;
