import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PerformerListMain from "./components/PerformerListMain";

const PerformerList = () => {
  const { user } = useSelector((state) => state.profile);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    if (localStorage.getItem("role") === "buyer" && user?.is_verified === 0) {
      navigate("/dashboard/website-list");
    }
  }, [localStorage.getItem("role")]);

  return (
    <div>
      <PerformerListMain />
    </div>
  );
};

export default PerformerList;
