import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleIcon from "../../../assets/images/google-icon.png";
import FacebookIcon from "../../../assets/images/faceboo-icon.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import logo from "../../../assets/images/logoMain.png";
import {
  clearErrors,
  loginUser,
  registerThirdParty,
  selectRole,
  userProfile,
} from "../../../actions/userActions";
import Swal from "sweetalert2";
import "../styles/loginMain.css";
import { userBalance } from "../../../actions/walletActions";
import {
  userCheckNotifications,
  userUnreadCountNotifications,
} from "../../../actions/notificationActions";
import { userModeration } from "../../../actions/moderationActions";

const LoginMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const facebookAppID = process.env.REACT_APP_FACEBOOK_APP_ID;
  const [showPassword, setShowPassword] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });

  const { isAuthenticated, loading, error } = useSelector(
    (state) => state.auth
  );

  // Google auth related-------------------------------------------------------------
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (response) => setUser(response),
    onError: (error) => {
      return error;
    },
  });

  useEffect(() => {
    dispatch(clearErrors());
    if (localStorage.getItem("access_token")) {
      navigate("/dashboard");
    }
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
          params: {
            access_token: user.access_token,
          },
        })
        .then((res) => {
          setProfile(res.data);
          dispatch(
            registerThirdParty({
              email: res.data.email,
              login_token: user.access_token,
              login_type: "google",
            })
          );
        })
        .catch((err) => {
          return err;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, localStorage.getItem("access_token")]);

  //----- Facebook Login----------------------------------------------
  const responseFacebook = (response) => {
    // Login failed
    if (response.status === "unknown") {
      Swal.fire({
        title: "Error!",
        html: "Login failed",
        icon: "error",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
      return false;
    }
    setUser(response);
    if (response.accessToken) {
      dispatch(
        registerThirdParty({
          email: "",
          login_token: response.access_token,
          login_type: "facebook",
        })
      );
    } else {
      return null;
    }
  };
  //------------------------------------------------------------------

  const handleChange = (e) => {
    dispatch(clearErrors());
    setLoginCredentials({
      ...loginCredentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const loginResult = await dispatch(
        loginUser(loginCredentials.email, loginCredentials.password)
      );

      if (loginResult.error) {
        // Handle the login error, e.g., display an error message
        return;
      }

      // Continue with other actions if login is successful
      await dispatch(selectRole({ select_role: "buyer" }));
      await dispatch(userProfile("buyer"));
      await dispatch(userBalance("buyer"));
      await dispatch(userUnreadCountNotifications());
      await dispatch(userCheckNotifications());
      await dispatch(userModeration());
    } catch (error) {
      // Handle any other errors that may occur during the dispatch of subsequent actions
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const renderFacebookButton = (renderProps) => {
    return (
      <IconButton
        onClick={renderProps.onClick}
        disabled={renderProps.isDisabled}
        className="social-btn signup-facebook-btn"
      >
        <img src={FacebookIcon} alt="Facebook" />
        Login with Facebook
      </IconButton>
    );
  };

  return (
    <div className="signup-main">
      <div className="signup-container">
        <div className="login-logo">
          <img src={logo} alt="weblinks-logo" />
        </div>

        <h2>LOGIN</h2>
        <form
          onSubmit={handleSubmit}
          className="w-100 d-flex flex-column align-items-end form-main-sec"
        >
          <TextField
            type="email"
            className="w-100 signup-email-field"
            placeholder="Email Address"
            name="email"
            variant="outlined"
            required
            value={loginCredentials.email}
            onChange={handleChange}
            InputProps={{
              classes: {
                root: "custom-input",
                input: "custom-input",
              },
            }}
            InputLabelProps={{
              classes: {
                root: "custom-label",
              },
            }}
          />
          <TextField
            className="w-100 signup-email-field"
            placeholder="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            required
            value={loginCredentials.password}
            onChange={handleChange}
            InputProps={{
              classes: {
                root: "custom-input",
                input: "custom-input",
              },
              endAdornment: (
                <InputAdornment position="end" className="input-icon-container">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    className="input-icon-button"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: "custom-label",
              },
            }}
          />
          <div className="w-100 invalid-email-txt">
            {error && (
              <p>
                {error?.response?.data?.error || error?.response?.data?.message}
              </p>
            )}
          </div>
          <Link to="/forget-password" className="d-flex forget-text">
            Forgot Password?
          </Link>
          <button type="submit" color="primary" className="w-100 login-new-btn">
            {loading ? (
              <CircularProgress className="text-white auth-circle-prog" />
            ) : (
              "LOGIN"
            )}
          </button>
        </form>
        <p className="signup-terms">
          By logging in, you agree to our
          <br /> <span>Terms and Conditions</span> and{" "}
          <span>Privacy Policy</span>
        </p>
        <h3>Or continue with Google</h3>
        <div className="w-100 signup-btn-container d-flex justify-content-center social-container">
          <IconButton
            className="social-btn signup-google-btn"
            onClick={() => login()}
            startIcon={<GoogleIcon />}
          >
            <img src={GoogleIcon} alt="Google" />
            Login with Google
          </IconButton>

          {/* <FacebookLogin
            className="w-100 fc-btn"
            appId={facebookAppID}
            autoLoad={false}
            // fields="name,email,picture"
            scope="public_profile"
            callback={responseFacebook}
            render={renderFacebookButton}
          /> */}
        </div>
        <h4>
          Don’t have an account? <Link to="/register">Signup</Link>
        </h4>
      </div>
    </div>
  );
};

export default LoginMain;
