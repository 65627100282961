import React, { useEffect, useState } from "react";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import AddFundsModal from "../../components/AddFundsModal/AddFundsModal";
import { Button, CircularProgress, Tab, Tabs } from "@mui/material";
import API from "../../api/ClientApi";
import "./styles/balanceBuyer.css";
import NoData from "../../components/NoData/NoData";
import { Paginator } from "primereact/paginator";

const BalanceBuyer = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.profile);
  const { wloading, balance } = useSelector((state) => state.balance);
  const [load, setLoad] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tData, setTData] = useState([]);
  const [typeTab, setTypeTab] = useState("all payments");
  const [tabs, setTabs] = useState([]);
  const [active, setActive] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(10);
  const items = [{ label: "Balance" }];
  const home = { label: "Home", url: "/dashboard/website-list" };

  const handleWebTabChange = async (e, tabIndex) => {
    setCurrentPage(1);
    setTypeTab(tabIndex);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  const convertDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const getTransactionRecord = async () => {
    setLoad(true);
    try {
      const data = await API.get(
        `/api/transaction-page-details?page=${currentPage}&transaction_type_name=${typeTab}&user_type=1`
      );
      const tabData = await API.get(
        `/api/tabs-for-transaction-page?user_type=1`
      );
      if (data) {
        setTData(data?.data?.data?.data);
        setCurrentPage(data?.data?.data?.current_page);
        setTotalPages(data?.data?.data?.last_page);
        setTotal(data?.data?.data?.total);
      }
      if (tabData) {
        setTabs(tabData?.data?.status_data);
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    const getTransactionRecord = async () => {
      setLoad(true);
      try {
        const data = await API.get(
          `/api/transaction-page-details?page=${currentPage}&transaction_type_name=${typeTab}&user_type=1`
        );
        const tabData = await API.get(
          `/api/tabs-for-transaction-page?user_type=1`
        );
        if (data) {
          setTData(data?.data?.data?.data);
          setCurrentPage(data?.data?.data?.current_page);
          setTotalPages(data?.data?.data?.last_page);
          setTotal(data?.data?.data?.total);
        }
        if (tabData) {
          setTabs(tabData?.data?.status_data);
        }
      } catch (error) {
      } finally {
        setLoad(false);
      }
    };

    getTransactionRecord();
  }, [typeTab, currentPage, localStorage.getItem("role")]);

  return (
    <div>
      <div className="buyer-form-main-wraper">
        <BreadCrumb model={items} home={home} />
        <div className="heading-info-wrap">
          <div className="row">
            <div className="col-md-12">
              <h2> Balance </h2>
            </div>
          </div>
        </div>
        <div className="cta-add-website-wrap cta-funds">
          <div className="row">
            <div className="col-md-9">
              <h2 className="new-aticle-heading"> Need more funds? </h2>
            </div>
            <div className="col-md-3">
              <div className="add-funds-wrap">
                {" "}
                <button className="add-funds-btn-wrap" onClick={openModal}>
                  {" "}
                  ADD FUNDS{" "}
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="add-balance-chart">
          <ul className="row">
            <li className="col-md-4">
              <div className="add-balance-box">
                {" "}
                <a
                  onClick={() => setActive(0)}
                  className={`balance-info-wrapers ${
                    active === 0 ? "active-box-wrap" : ""
                  }`}
                >
                  <h5>
                    {" "}
                    $
                    {wloading ? (
                      <CircularProgress className="text-white" size={24} />
                    ) : balance?.balance ? (
                      balance?.balance
                    ) : (
                      "0.00"
                    )}{" "}
                  </h5>
                  <p>
                    {" "}
                    Main balance: Funds that you have added through available
                    modes of payment.
                  </p>
                </a>{" "}
              </div>
            </li>
            <li className="col-md-4">
              <div className="add-balance-box">
                {" "}
                <a
                  onClick={() => setActive(1)}
                  className={`balance-info-wrapers ${
                    active === 1 ? "active-box-wrap" : ""
                  }`}
                >
                  <h5> ${balance?.reserved ? balance?.reserved : "0.00"} </h5>
                  <p>
                    {" "}
                    Reserved balance: Funds that are reserved as a task payment.{" "}
                  </p>
                </a>{" "}
              </div>
            </li>
            <li className="col-md-4">
              <div className="add-balance-box">
                {" "}
                <a
                  onClick={() => setActive(2)}
                  className={`balance-info-wrapers ${
                    active === 2 ? "active-box-wrap" : ""
                  }`}
                >
                  <h5> ${balance?.bonus ? balance?.bonus : "0.00"} </h5>
                  <p>
                    {" "}
                    Bonus balance: Extra funds that are added for special
                    activities.{" "}
                  </p>
                </a>{" "}
              </div>
            </li>
          </ul>
        </div>

        <div className="tabpanel-balance-wrap">
          <h5> Transaction type </h5>
          <div className="order-article-tabs-wrap">
            <div className="w-100 open-offer-tabs">
              <Tabs
                className="tabs-add-web"
                value={typeTab}
                onChange={handleWebTabChange}
                variant="scrollable"
              >
                {tabs &&
                  tabs?.map((res, id) => (
                    <Tab
                      className={`${
                        typeTab === res?.transaction_type_name
                          ? "tab-active"
                          : "account-list-tabs"
                      }`}
                      icon={
                        <div className="d-flex">
                          <span>{res?.name}</span>
                          <div className="count-circle">
                            {res?.count > 0 ? res?.count : 0}
                          </div>
                        </div>
                      }
                      value={res?.transaction_type_name}
                      key={id}
                    ></Tab>
                  ))}
              </Tabs>
            </div>
            {load ? (
              <div className="w-100 mt-2 d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="tab-content">
                  <div className="table-responsive offer-table">
                    <table width="100%" className="table">
                      <tr className="heading-article">
                        <td width="25%">Date</td>
                        <td width="25%">Transaction description</td>
                        <td width="25%">Transaction amount</td>
                        <td width="25%">Balance</td>
                      </tr>
                      {tData &&
                        tData?.map((res, id) => (
                          <tr key={id}>
                            <td height="70">{convertDate(res?.created_at)}</td>
                            <td>{res?.message}</td>
                            <td
                              className={
                                res.message.includes("deducted")
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {res.message.includes("deducted") ? "-" : "+"}${" "}
                              {res?.amount}
                            </td>
                            <td>$ {res?.balance}</td>
                          </tr>
                        ))}
                      {/* <tr>
                        <td className="no-data-text" height="80" colspan="4">
                          This list is empty. You have no transactions yet.
                        </td>
                      </tr> */}
                    </table>
                    {(tData === null || tData?.length === 0) && <NoData />}
                  </div>
                  <div>
                    {total > 10 && (
                      <Paginator
                        first={currentPage * rows - rows}
                        rows={rows}
                        totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
                        onPageChange={onPageChange}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <AddFundsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        user={user}
        transaction={getTransactionRecord}
      />
    </div>
  );
};

export default BalanceBuyer;
