import React, { useState } from "react";
import {
  Container,
  Box,
  Button,
  Grid,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "../../assets/images/logoMain.png";
import CustomerIcon from "../../assets/images/customer.png";
import "./navbar.css";

const Navbar = ({ sticky }) => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 1186px)");

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className={`navbar-main ${sticky}`}>
      <div
        className="container d-flex align-items-center justify-content-between"
        maxWidth="lg"
      >
        <Link to="/">
          <img className="navbar-logo" src={Logo} alt="weblinks-logo" />
        </Link>
        {isMobile ? (
          <>
            <IconButton
              className="burger-menu py-4"
              color="inherit"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
              <List>
                <ListItem component={Link} to="/">
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem component={Link} to="/">
                  <ListItemText primary="Buyer" />
                </ListItem>
                <ListItem component={Link} to="/">
                  <ListItemText primary="Seller" />
                </ListItem>
                <ListItem component={Link} to="/contact">
                  <ListItemText primary="Contact" />
                </ListItem>
                <ListItem>
                  <Button
                    className="login-button"
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      if (
                        localStorage.getItem("access_token") &&
                        localStorage.getItem("role")
                      ) {
                        if (localStorage.getItem("role") === "buyer") {
                          navigate("/dashboard/website-list");
                        } else {
                          navigate("/dashboard/platform");
                        }
                      } else {
                        navigate("/login");
                      }
                    }}
                  >
                    Login
                  </Button>
                </ListItem>
                <ListItem
                  className="d-flex cursor-pointer"
                  onClick={async () => {
                    if (
                      localStorage.getItem("access_token") &&
                      localStorage.getItem("role")
                    ) {
                      if (localStorage.getItem("role") === "buyer") {
                        navigate("/dashboard/website-list");
                      } else {
                        navigate("/dashboard/platform");
                      }
                    } else {
                      navigate("/register");
                    }
                  }}
                >
                  <ListItemText primary="SIGN UP" />
                </ListItem>
              </List>
            </Drawer>
          </>
        ) : (
          <Box className="d-flex align-items-center py-4">
            <Grid container spacing={6}>
              <Grid className="d-flex align-items-center nav-items" item>
                <Link to="/">Home</Link>
              </Grid>
              <Grid className="d-flex align-items-center nav-items" item>
                <Link to="/">Buyer</Link>
              </Grid>
              <Grid className="d-flex align-items-center nav-items" item>
                <Link to="/">Seller</Link>
              </Grid>
              <Grid className="d-flex align-items-center nav-items" item>
                <Link to="/contact">Contact</Link>
              </Grid>
              <Grid className="d-flex align-items-center nav-items" item>
                <Button
                  className="login-button"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/login")}
                >
                  Login
                </Button>
              </Grid>
              <Grid className="d-flex align-items-center nav-items" item>
                <img
                  src={CustomerIcon}
                  className="customer-icon"
                  alt="customer-icon-webyLinks"
                />
                <ListItemText
                  className="list-item-signup"
                  primary="Signup"
                  onClick={() => navigate("/register")}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    </div>
  );
};

export default Navbar;
