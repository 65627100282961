import React, { useState, useRef } from "react";
import validUrl from "valid-url";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../../assets/images/info-icon-new.png";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import {
  Button,
  useMediaQuery,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "../styles/moderation.css";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import API from "../../../api/ClientApi";
import { userProfile } from "../../../actions/userActions";
import { userModeration } from "../../../actions/moderationActions";
import { useSelector } from "react-redux";

const ModerationMain = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 767px)");
  const formRef = useRef(null);
  const [load, setLoad] = useState(false);
  const [uError, setUError] = useState(false);
  const [rError, setRError] = useState("");
  const [otherSecurityDeposit, setOtherSecurityDeposit] = useState(null);
  const [otherWithdrawlMethod, setOtherWithdrawlMethod] = useState(null);
  const [withdrawlDelay, setWithdrawlDelay] = useState(null);
  const [invalidUrlIndexes, setInvalidUrlIndexes] = useState([]);
  const [invalidRefIndexes, setInvalidRefIndexes] = useState([]);
  const [nameError, setNameError] = useState("");
  const [buyData, setBuyData] = useState({
    user_name: "",
    website_url: [""],
    reference_work: ["", "", ""],
    security_deposit: { name: "Not ready", code: 0 },
    withdraw_delay_days: { name: "30 days", code: 30 },
    message: "",
    no_of_post: null,
  });

  const security_deposit = [
    { name: "Not ready", code: 0 },
    { name: "$100", code: 100 },
    { name: "$500", code: 200 },
    { name: "$1000", code: 300 },
    { name: "Other", code: 1 },
  ];

  const withdrawl_delay = [
    { name: "30 days", code: 30 },
    { name: "60 days", code: 60 },
    { name: "Other", code: 1 },
  ];

  const [selectedWithdrawlMethods, setSelectedWithdrawlMethods] =
    useState(null);

  const withdrawl_methods = [
    { name: "PayPal", code: "paypal" },
    { name: "Crypto USDT TRC 20", code: "crypto" },
    { name: "Other", code: "other" },
  ];
  const [futureWithdrawlMethods, setFutureWithdrawlMethods] = useState([
    { name: "Transfer to Bank Account", code: "bank" },
  ]);

  const future_withdrawl_methods = [
    { name: "Transfer to Bank Account", code: "bank" },
    { name: "Payoneer", code: "payoneer" },
  ];

  // Footer temp. for multiselect
  const handleOkButtonClick = () => {
    // Close the multiselect dropdown
    if (multiSelectRef.current) {
      multiSelectRef.current.hide();
    }
  };

  const panelFooterTemplate = () => {
    return (
      <div className="mb-2 w-100 d-flex justify-content-center">
        <button
          className="active-offer-btn offer-accept-pub-btn"
          onClick={handleOkButtonClick}
        >
          OK
        </button>
      </div>
    );
  };

  const multiSelectRef = useRef(null);
  // Footer temp. for multiselect
  const handleOkWButtonClick = () => {
    // Close the multiselect dropdown
    if (multiWSelectRef.current) {
      multiWSelectRef.current.hide();
    }
  };

  const panelWFooterTemplate = () => {
    return (
      <div className="mb-2 w-100 d-flex justify-content-center">
        <button
          className="active-offer-btn offer-accept-pub-btn"
          onClick={handleOkWButtonClick}
        >
          OK
        </button>
      </div>
    );
  };

  const multiWSelectRef = useRef(null);

  // Error states
  const [numError, setNumError] = useState("");
  const [mError, setMError] = useState("");
  const [fError, setFError] = useState("");
  const [wError, setWError] = useState("");
  const [sError, setSError] = useState("");

  const isValidUrl = (url) => {
    // Regular expression to match a URL pattern with "https://"
    if (url === "") {
      return true;
    } else {
      return validUrl.isWebUri(url);
    }
  };

  //-----------------

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "no_of_post") {
      setNumError("");
    }
    if (e.target.name === "message") {
      setMError("");
    }
    if (e.target.name === "user_name") {
      setNameError("");
    }
    setBuyData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  // Url
  const addUrlInput = () => {
    setBuyData((prevData) => ({
      ...prevData,
      website_url: [...prevData.website_url, ""],
    }));
  };

  // Remove url
  const removeUrlInput = (index) => {
    setBuyData((prevData) => ({
      ...prevData,
      website_url: prevData.website_url.filter((_, i) => i !== index),
    }));
  };

  const handleUrlChange = (e, index) => {
    setUError(false);
    const newUrls = [...buyData.website_url];
    newUrls[index] = e.target.value;
    // Check if the index is in the invalidUrlIndexes array and remove it
    if (invalidUrlIndexes.includes(index)) {
      const updatedInvalidUrlIndexes = invalidUrlIndexes.filter(
        (i) => i !== index
      );
      setInvalidUrlIndexes(updatedInvalidUrlIndexes);
    }
    setBuyData((prevData) => ({
      ...prevData,
      website_url: newUrls,
    }));
  };
  //-------------------------------------------------
  // Ref
  const addRefInput = () => {
    setBuyData((prevData) => ({
      ...prevData,
      reference_work: [...prevData.reference_work, ""],
    }));
  };

  // Remove anchor
  const removeAnchorInput = (index) => {
    setBuyData((prevData) => ({
      ...prevData,
      reference_work: prevData.reference_work.filter((_, i) => i !== index),
    }));
  };

  const handleRefChange = (e, index) => {
    setRError("");
    const newUrls = [...buyData.reference_work];
    newUrls[index] = e.target.value;
    if (invalidRefIndexes.includes(index)) {
      const updatedInvalidRefIndexes = invalidRefIndexes.filter(
        (i) => i !== index
      );
      setInvalidRefIndexes(updatedInvalidRefIndexes);
    }
    setBuyData((prevData) => ({
      ...prevData,
      reference_work: newUrls,
    }));
  };
  //-------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      buyData?.no_of_post <= 0 ||
      (user?.name === null && buyData?.user_name === "") ||
      buyData?.website_url?.some((url) => url.trim() === "") ||
      !buyData?.website_url?.every((url) => isValidUrl(url)) ||
      buyData?.reference_work?.some((url) => url.trim() === "") ||
      !buyData?.reference_work?.every((url) => isValidUrl(url)) ||
      !buyData.reference_work ||
      buyData.reference_work.length < 3 ||
      buyData.reference_work.filter((url) => url.trim() !== "").length < 3 ||
      buyData?.message === "" ||
      !selectedWithdrawlMethods ||
      (selectedWithdrawlMethods?.some((method) => method.code === "other") &&
        !otherWithdrawlMethod) ||
      !futureWithdrawlMethods ||
      (buyData?.withdraw_delay_days?.code === 1 && !withdrawlDelay) ||
      (buyData?.security_deposit?.code === 1 && !otherSecurityDeposit)
    ) {
      if (buyData?.no_of_post <= 0) {
        setNumError("Please enter valid number of posts");
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }

      if (
        buyData?.website_url?.some((url, index) => {
          if (url === "" || !isValidUrl(url)) {
            if (index > 0 && url === "") {
              return false;
            } else {
              formRef.current.scrollIntoView({ behavior: "smooth" });
              setInvalidUrlIndexes((prevIndexes) => [...prevIndexes, index]); // Add the index to the array
            }
          }
        })
      ) {
        formRef.current.scrollIntoView({ behavior: "smooth" });
        setUError(true);
      }

      if (
        buyData?.reference_work?.some((url, index) => {
          if (url === "" || !isValidUrl(url)) {
            if (index > 0 && url === "") {
              return false;
            } else {
              formRef.current.scrollIntoView({ behavior: "smooth" });
              setInvalidRefIndexes((prevIndexes) => [...prevIndexes, index]); // Add the index to the array
              return true; // Return true for the condition to be satisfied
            }
          }
          return false; // Return false for the condition to be satisfied
        })
      ) {
        setRError(true); // Set the UError state to true
      }
      if (user?.name === null && buyData?.user_name === "") {
        setNameError("Please enter your name");
      }
      if (
        !buyData.reference_work ||
        buyData.reference_work.length < 3 ||
        buyData.reference_work.filter((url) => url.trim() !== "").length < 3
      ) {
        setRError("Please enter at least 3 valid URLs");
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }

      if (buyData?.message === "") {
        setMError("This field should not be empty");
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }

      if (!selectedWithdrawlMethods) {
        setFError("Please select withdrawl methods");
        formRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        if (
          selectedWithdrawlMethods?.some((method) => method.code === "other")
        ) {
          if (!otherWithdrawlMethod) {
            setFError("Field should not be empty");
            formRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }
      }

      if (buyData?.withdraw_delay_days?.code === 1) {
        if (!withdrawlDelay) {
          setWError("Field should not be empty");
          formRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }

      if (buyData?.security_deposit?.code === 1) {
        if (!otherSecurityDeposit) {
          setSError("Field should not be empty");
          formRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      try {
        setLoad(true);
        const modifiedData = {
          ...buyData,
          future_withdraw_method: JSON.stringify(
            futureWithdrawlMethods?.map((item) => item.name)
          ),
          withdraw_method: JSON.stringify(
            selectedWithdrawlMethods.map((item) =>
              item.code === "other" ? otherWithdrawlMethod : item.name
            )
          ),
          security_deposit:
            buyData?.security_deposit?.code === 1
              ? `$${otherSecurityDeposit}`
              : buyData?.security_deposit?.code,
          withdraw_delay_days: buyData?.withdraw_delay_days?.code,
          website_url: JSON.stringify(buyData?.website_url),
          reference_work: JSON.stringify(buyData?.reference_work),
        };
        const response = await API.post(
          "/api/add-moderation-form",
          modifiedData
        );
        await dispatch(userModeration());
        await dispatch(userProfile());
        setLoad(false);
        navigate("/dashboard/platform");
        Swal.fire({
          title: "",
          html: `<h4 class="success-moderation-h">Thank you for your time!</h4>
        <p>Your account has been sent to moderation. The process takes up to five business days. You will receive an email notification with the moderation results.</p>`,
          icon: "success", // No icon
          showConfirmButton: true, // Hide default "OK" button
          showCloseButton: true,
          confirmButtonText: "OK",
          customClass: {
            header: "swal-header", // Apply custom CSS to the header
            content: "swal-content", // Apply custom CSS to the content
            container: "swal-custom-container",
          },
        });
      } catch (error) {
        setLoad(false);
        return error;
      }
    }
  };

  const isCatMobile = useMediaQuery("(max-width: 1500px)");

  // Widthdrawl
  return (
    <div className="buyer-form-main-wraper">
      <div className="heading-info-wrap heading-order-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Moderation Form </h2>
            <p>
              {" "}
              Fill in the form with the maximum details and data to get higher
              chances of passing moderation more successfully and easily.
            </p>
          </div>
        </div>
      </div>
      <div className="buyer-form-wraper moderation-wrap">
        <form ref={formRef}>
          {(user?.name === null || user?.name === "") && (
            <div className="form-group">
              <div className="row">
                <div className="col-md-12 no-of-post-wrapper">
                  <div className="d-flex filed-form-new-wrap">
                    <label className="d-flex align-items-center">
                      {" "}
                      Name *{" "}
                      <Tooltip
                        title="Please enter your name so that you can be distinguished among other publishers"
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <input
                      type="text"
                      className={`${
                        nameError ? "err-outline" : "text-area-control"
                      }`}
                      placeholder="Name"
                      name="user_name"
                      value={buyData.user_name}
                      onChange={handleChange}
                    />
                  </div>
                  {nameError && <p className="mod-err">{nameError}</p>}
                </div>
              </div>
            </div>
          )}
          <div className="form-group">
            <div className="row">
              <div className="col-md-12 no-of-post-wrapper">
                <div className="d-flex filed-form-new-wrap">
                  <label className="d-flex align-items-center">
                    {" "}
                    Number of posts *{" "}
                    <Tooltip
                      title="If you choose, e.g., 5 guest posts, we will pick 5
                    unique sites based on the package metrics you set."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <input
                    type="number"
                    className={`${
                      numError ? "err-outline" : "text-area-control"
                    }`}
                    placeholder="0"
                    name="no_of_post"
                    value={buyData.no_of_post}
                    onChange={handleChange}
                  />
                </div>
                {numError && <p className="mod-err">{numError}</p>}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-12 url-wrapper-form ">
                <div className="d-flex filed-form-new-wrap url-wrapper-form">
                  <label className="d-flex align-items-center anch-label">
                    {" "}
                    Your website*{" "}
                    <Tooltip
                      title="Enter the URL that you have included in your content
                      above. It will be used to check the performed task for
                      compliance with your requirements."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <input
                    type="text"
                    className={`${
                      invalidUrlIndexes.includes(0)
                        ? "err-url url-text-main"
                        : "text-area-control"
                    }`}
                    placeholder="http(s)://"
                    value={buyData.website_url[0]} // Use the first element from the array
                    onChange={(e) => handleUrlChange(e, 0)} // Pass index 0
                  />
                  {invalidUrlIndexes.includes(0) && (
                    <p className="ref-n-err">A valid url is required</p>
                  )}
                </div>
                {buyData.website_url.slice(1).map((url, index) => (
                  <div className="form-group" key={index}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="filed-form-wrap additional-arc-fields">
                          <input
                            type="text"
                            className={`${
                              invalidUrlIndexes.includes(index + 1)
                                ? "err-url"
                                : "text-area-control"
                            }`}
                            placeholder="http(s)://"
                            value={url}
                            onChange={(e) => handleUrlChange(e, index + 1)} // Increment the index by 1
                          />
                          {invalidUrlIndexes.includes(index + 1) && (
                            <p style={{ color: "red" }}>
                              A valid url is required
                            </p>
                          )}
                        </div>
                        <Button
                          className="remove-btn-buy-form remove-array-btn"
                          type="button"
                          onClick={() => removeUrlInput(index + 1)}
                        >
                          <RemoveIcon />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
                <Button
                  className="add-btn-buy-form"
                  type="button"
                  onClick={addUrlInput}
                >
                  <AddIcon />
                </Button>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-12 url-wrapper-form">
                <div
                  className={`d-flex filed-form-new-wrap url-wrapper-form ${
                    invalidRefIndexes.includes(0) ? "ref-margin" : ""
                  }`}
                >
                  <label className="d-flex align-items-center anch-label">
                    {" "}
                    1st Reference of work*{" "}
                    <Tooltip
                      title="Share examples of your completed works that, according
                    to you, show your services in the best way possible.
                    (N.B. Your content should be up to 6 months old.)."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>

                  <input
                    type="text"
                    className={`${
                      invalidRefIndexes.includes(0) || rError
                        ? "err-url"
                        : "text-area-control"
                    }`}
                    placeholder="http(s)://"
                    value={buyData.reference_work[0]} // Use the first element from the array
                    onChange={(e) => handleRefChange(e, 0)} // Pass index 0
                  />
                  {invalidRefIndexes.includes(0) && (
                    <p className="ref-1-err">A valid url is required</p>
                  )}
                </div>

                <div
                  className={`d-flex filed-form-new-wrap url-wrapper-form ${
                    invalidRefIndexes.includes(1) ? "ref-margin" : ""
                  }`}
                >
                  <label> 2nd Reference*</label>
                  <input
                    type="text"
                    className={`${
                      invalidRefIndexes.includes(1) || rError
                        ? "err-url"
                        : "text-area-control"
                    }`}
                    placeholder="http(s)://"
                    value={buyData.reference_work[1]} // Use the first element from the array
                    onChange={(e) => handleRefChange(e, 1)} // Pass index 0
                  />
                  {invalidRefIndexes.includes(1) && (
                    <p className="ref-2-err">A valid url is required</p>
                  )}
                </div>

                <div
                  className={`d-flex filed-form-new-wrap url-wrapper-form ${
                    invalidRefIndexes.includes(2) ? "ref-margin" : ""
                  }`}
                >
                  <label> 3rd Reference*</label>
                  <input
                    type="text"
                    className={`${
                      invalidRefIndexes.includes(2) || rError
                        ? "err-url"
                        : "text-area-control"
                    }`}
                    placeholder="http(s)://"
                    value={buyData.reference_work[2]} // Use the first element from the array
                    onChange={(e) => handleRefChange(e, 2)} // Pass index 0
                  />
                  {invalidRefIndexes.includes(2) && (
                    <p className="ref-3-err">A valid url is required</p>
                  )}
                </div>

                {buyData.reference_work.slice(3).map((url, index) => (
                  <div className="form-group" key={index}>
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className={`d-flex filed-form-new-wrap url-wrapper-form ${
                            invalidRefIndexes.includes(index + 3)
                              ? "ref-margin"
                              : ""
                          }`}
                        >
                          <label> {`${4 + index}th Reference`}</label>
                          <input
                            type="text"
                            className={`${
                              invalidRefIndexes.includes(index + 3)
                                ? "err-url"
                                : "text-area-control"
                            }`}
                            placeholder="http(s)://"
                            value={url}
                            onChange={(e) => handleRefChange(e, index + 3)} // Increment the index by 1
                          />
                          {invalidRefIndexes.includes(index + 3) && (
                            <p className="ref-n-err">A valid url is required</p>
                          )}
                        </div>
                        <Button
                          className="remove-btn-ref-form"
                          type="button"
                          onClick={() => removeAnchorInput(index + 1)}
                        >
                          <RemoveIcon />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
                <Button
                  className="add-btn-buy-form"
                  type="button"
                  onClick={addRefInput}
                >
                  <AddIcon />
                </Button>
                {rError && (
                  <p className="moderation-err mod-ref-err">{rError}</p>
                )}
              </div>
            </div>
          </div>

          <div className="form-group cat-group">
            <div className="row">
              <div className={!isCatMobile ? "col-md-6" : "col-md-12"}>
                <div className="d-flex filed-form-new-wrap">
                  <label
                    className={`d-flex align-items-center ${
                      buyData?.security_deposit?.code === 1
                        ? "withdraw-margin"
                        : ""
                    }`}
                  >
                    {" "}
                    Security deposit *{" "}
                    <Tooltip
                      title="E.g. $100, $200, and so on. This sum serves as a
                    guarantee that you will reimburse a buyer if their guest
                    post is edited or deleted after their approval."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton
                        size="small"
                        className="info-icon"
                        pTooltip="Enter your username"
                        tooltipPosition="top"
                      >
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <div className="w-100 d-flex flex-column">
                    <Dropdown
                      name="security_deposit"
                      value={buyData?.security_deposit}
                      onChange={handleChange}
                      options={security_deposit}
                      optionLabel="name"
                      placeholder="Make security deposit"
                      className="prime-mu-select withdrawal-mu-select"
                    />
                    {buyData?.security_deposit?.code === 1 && (
                      <div className="d-flex flex-column filed-form-wrap">
                        <input
                          type="number"
                          className="text-area-control withdrawal-other-input"
                          placeholder="Write other here"
                          value={otherSecurityDeposit}
                          onChange={(e) => {
                            setSError("");
                            setOtherSecurityDeposit(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {sError && <p className="moderation-err">{sError}</p>}
                  </div>
                </div>
              </div>
              <div className={!isCatMobile ? "col-md-6" : "col-md-12"}>
                <div className="d-flex filed-form-new-wrap">
                  <label
                    className={`d-flex align-items-center with-label ${
                      buyData?.withdraw_delay_days?.code === 1
                        ? "withdraw-margin"
                        : ""
                    }`}
                  >
                    {" "}
                    NET withrawl?*{" "}
                    <Tooltip
                      title="NET is a delay in the withdrawal of money. For example,
                      I can expect to be paid once every 30 days, or once
                      every 60 days."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <div className="w-100 d-flex flex-column">
                    <Dropdown
                      name="withdraw_delay_days"
                      value={buyData?.withdraw_delay_days}
                      onChange={handleChange}
                      options={withdrawl_delay}
                      optionLabel="name"
                      placeholder="Withdrawl delay"
                      className="prime-mu-select withdrawal-mu-select"
                    />
                    {buyData?.withdraw_delay_days?.code === 1 && (
                      <div className="d-flex flex-column filed-form-wrap">
                        <input
                          type="number"
                          className="text-area-control withdrawal-other-input"
                          placeholder="Write other here"
                          value={withdrawlDelay}
                          onChange={(e) => {
                            setWError("");
                            setWithdrawlDelay(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {wError && <p className="moderation-err">{wError}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div
                className={`withdrawl-wrapper ${
                  !isCatMobile ? "col-md-6" : "col-md-12"
                }`}
              >
                <div className="d-flex filed-form-new-wrap">
                  <label
                    className={`d-flex align-items-center ${
                      selectedWithdrawlMethods?.some(
                        (method) => method.code === "other"
                      )
                        ? "withdraw-margin"
                        : ""
                    }`}
                  >
                    {" "}
                    Withdrawal methods*{" "}
                    <Tooltip
                      title="You can choose a withdrawal method or specify the one you
                    want in other."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <div className="w-100 d-flex flex-column">
                    <MultiSelect
                      value={selectedWithdrawlMethods}
                      onChange={(e) => {
                        setFError("");
                        setSelectedWithdrawlMethods(e.value);
                      }}
                      options={withdrawl_methods}
                      optionLabel="name"
                      filter
                      placeholder="Select withdrawl methods"
                      className={`prime-mu-select withdrawal-mu-select ${
                        fError ? "withdrawal-mu-outline" : ""
                      }`}
                      panelFooterTemplate={panelWFooterTemplate}
                      ref={multiWSelectRef}
                    />
                    {selectedWithdrawlMethods?.some(
                      (method) => method.code === "other"
                    ) && (
                      <div className="d-flex flex-column filed-form-wrap">
                        <input
                          type="text"
                          className="text-area-control withdrawal-other-input"
                          placeholder="Write other here"
                          value={otherWithdrawlMethod}
                          onChange={(e) => {
                            setFError("");
                            setOtherWithdrawlMethod(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {fError && <p className="moderation-err">{fError}</p>}
                  </div>
                </div>
              </div>

              <div className={!isCatMobile ? "col-md-6" : "col-md-12"}>
                <div className="d-flex filed-form-new-wrap">
                  <label className="d-flex align-items-center with-label">
                    {" "}
                    Future withdrawal methods*{" "}
                    <Tooltip
                      title="You can choose a withdrawal future method."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <div className="w-100 d-flex flex-column">
                    <MultiSelect
                      value={futureWithdrawlMethods}
                      onChange={(e) => {
                        setFutureWithdrawlMethods(e.value);
                      }}
                      options={future_withdrawl_methods}
                      optionLabel="name"
                      filter
                      placeholder="Select future withdrawl methods"
                      className="prime-mu-select withdrawal-mu-select"
                      panelFooterTemplate={panelFooterTemplate}
                      ref={multiSelectRef}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex filed-form-area-wrap msg-wrapper">
                  <label className="t-suggest-area">
                    {" "}
                    Your message*{" "}
                    <Tooltip
                      title="Type additional information that will help us
                    successfully moderate your profile here."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>

                  <textarea
                    multiline
                    rows={4}
                    variant="outlined"
                    className={`${
                      mError
                        ? "err-outline requirement-text-area"
                        : "text-area-control requirement-text-area"
                    }`}
                    fullWidth
                    name="message"
                    placeholder="Type here"
                    value={buyData.message}
                    onChange={handleChange}
                  />
                  {mError && <p className="your-message-err">{mError}</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="btn-form-buyer">
            <Button
              className="moderation-btn-form"
              type="submit"
              onClick={handleSubmit}
            >
              {" "}
              {load ? <CircularProgress className="text-white" /> : "SEND"}{" "}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModerationMain;
