import { applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  authReducers,
  forgotPasswordReducer,
  userRoleReducer,
  userPhoneReducer,
  userOTPReducer,
  verifyUserReducer,
  userProfileReducer,
  userUpdateReducer,
  userProfilePageReducer,
} from "./reducers/userReducers";
import { walletReducers } from "./reducers/walletReducers";
import {
  notificationCheckReducers,
  notificationCountReducers,
  notificationReducers,
  notificationUnreadCountReducers,
} from "./reducers/NotificationReducers";
import { moderationReducers } from "./reducers/ModerationReducers";

// const reducer = combineReducers({});
const reducer = {
  auth: authReducers,
  forgotPassword: forgotPasswordReducer,
  role: userRoleReducer,
  phone: userPhoneReducer,
  otp: userOTPReducer,
  verify: verifyUserReducer,
  profile: userProfileReducer,
  update: userUpdateReducer,
  balance: walletReducers,
  notification: notificationReducers,
  notificationCount: notificationCountReducers,
  unreadCount: notificationUnreadCountReducers,
  notificationCheck: notificationCheckReducers,
  is_moderation: moderationReducers,
  profile_page: userProfilePageReducer,
};

let initialState = {};
const middleware = [thunk];

const store = configureStore(
  {
    reducer: reducer,
  },
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
