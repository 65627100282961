import React, { useEffect } from "react";
import AccountSettings from "./components/AccountSettings";
import { useNavigate } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { userProfile } from "../../actions/userActions";
import { useDispatch } from "react-redux";
import "./styles/profileManager.css";

const ProfileManagerPublisher = (props) => {
  const navigate = useNavigate();
  const items = [{ label: "My profile" }];
  const home = { label: "Home", url: "/dashboard/platform" };
  // const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("role") === "buyer" || props?.publish===0) {
      navigate("/dashboard/website-list");
    }
    // dispatch(userProfile());
  }, [localStorage.getItem("role")]);

  return (
    <div className="profile-manager-main">
      <BreadCrumb model={items} home={home} />
      <AccountSettings />
    </div>
  );
};

export default ProfileManagerPublisher;
