import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";

const NavbarWrapper = () => {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const routesWithoutNavbar = ["/home", "/contact", "/asssfff"];

  const exceptionRoutes = ["/api/verify-email", "/reset-password"];

  const isNavbarVisible = () => {
    return routesWithoutNavbar.includes(location.pathname);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const shouldShowNavbar = isNavbarVisible();
      if (scrollY > 0 && shouldShowNavbar) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  return (
    <div className="position-relative App">
      {isNavbarVisible() && <Navbar sticky={isSticky ? "sticky" : ""} />}
    </div>
  );
};

export default NavbarWrapper;
