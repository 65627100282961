import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import API from "../../api/ClientApi";
import { BreadCrumb } from "primereact/breadcrumb";
import NoData from "../../components/NoData/NoData";
import "./style/taks.css";
import { useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { OverlayPanel } from "primereact/overlaypanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import AcceptTaskModal from "./AcceptTaskModal";
import RejectTaskModal from "./RejectTaskModal";
import InvitationAcceptModal from "./InvitationAcceptModal";
import InvitationRejectModal from "./InvitationRejectModal";

const TasksPublisher = (props) => {
  const navigate = useNavigate();
  const op = useRef(null);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [load, setLoad] = useState(false);
  const [typeTab, setTypeTab] = useState(24);
  const [tabs, setTabs] = useState([]);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(10);
  const [acceptModal, setAcceptModal] = useState(false);
  const [inviteAcceptModal, setInviteAcceptModal] = useState(false);
  const [inviteRejectModal, setInviteRejectModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [eid, setEId] = useState(null);
  const items = [{ label: "Tasks" }];
  const home = { label: "Home", url: "/dashboard/platform" };

  const getList = async () => {
    try {
      const response = await API.get(
        `/api/orders?status_id=${typeTab}&page=${currentPage}&user_type=2`
      );
      const responseData = response.data;

      setOrders(responseData.data);
      setCurrentPage(responseData.current_page);
      setTotalPages(responseData.last_page);
      setTotal(responseData.total);

      setLoad(false);
    } catch (error) {
      setLoad(false);
    }
  };

  const getTabs = async () => {
    try {
      const data = await API.get("/api/get-status-by-buyer-order?user_type=2");
      if (data) {
        setTabs(data?.data?.status_data);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "buyer" || props.publish === 0) {
      navigate("/dashboard/website-list");
    }
    setLoad(true);
    const getListTabs = async () => {
      try {
        const data = await API.get(
          `/api/get-status-by-buyer-order?user_type=2`
        );
        if (data) {
          setTabs(data?.data?.status_data);
        }
      } catch (error) {
        return error;
      }
    };

    const fetchData = async () => {
      try {
        const response = await API.get(
          `/api/orders?status_id=${typeTab}&page=${currentPage}&user_type=2`
        );
        const responseData = response.data;
        setOrders(responseData.data);
        setCurrentPage(responseData.current_page);
        setTotalPages(responseData.last_page);
        setTotal(responseData.total);
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    };
    getListTabs();
    fetchData();
  }, [typeTab, currentPage, localStorage.getItem("role")]);

  const handleWebTabChange = (e, tabIndex) => {
    setCurrentPage(1);
    setTypeTab(tabIndex);
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  const handleAcceptClose = () => {
    setAcceptModal(false);
  };

  const handleRejectClose = () => {
    setRejectModal(false);
  };

  const handleInviteAcceptClose = () => {
    setInviteAcceptModal(false);
  };

  const handleInviteRejectClose = () => {
    setInviteRejectModal(false);
  };

  return (
    <div className="buyer-form-main-wraper">
      <BreadCrumb model={items} home={home} />
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Tasks </h2>
          </div>
        </div>
      </div>
      <Accordion className="accordion-filter accordion-global">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-summery"
        >
          <div className="w-100 d-flex justify-content-between filter-header">
            <h2>HOW IT WORKS?</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <div className="accordion-detail-div ac-div">
            In this section you can see tasks grouped by multiple statuses.Each
            status is accompanied by a number representing the quantity of tasks
            within that status.As the task is in progress, it will transition
            between different statuses.You'll stay updated through emails and
            notifications. To view a description of each status, hover your
            mouse pointer over the corresponding status name.To access
            additional details about the task ‘click button with three dots’ and
            smoothly perform further actions.
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="order-article-tabs-wrap">
        <div className="w-100 open-offer-tabs">
          <Tabs
            className="tabs-add-web"
            value={typeTab}
            onChange={handleWebTabChange}
            variant="scrollable"
          >
            {tabs &&
              tabs?.map((res, id) => (
                <Tab
                  className={`${
                    typeTab === res?.id ? "tab-active" : "account-list-tabs"
                  }`}
                  icon={
                    <div className="d-flex">
                      <span>{res?.name}</span>
                      <div className="count-circle">{res?.count}</div>
                    </div>
                  }
                  value={res?.id}
                  key={id}
                ></Tab>
              ))}
          </Tabs>
        </div>
        {load ? (
          <div className="w-100 mt-2 d-flex justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table width="100%" className="table">
                <tr className="heading-article">
                  <td width="10%">Task ID</td>
                  <td width="13%">Url</td>
                  <td width="17%">Content type</td>
                  <td width="14%">Content creator</td>
                  <td width="8%">Price</td>
                  <td width="14%">Status</td>
                  <td width="10%">Created at</td>
                  <td width="15%">Actions</td>
                </tr>
                {orders &&
                  orders?.map((res, id) => (
                    <tr key={id}>
                      <td height="70">
                        <span>#{id + 1}</span>
                      </td>
                      <td>
                        {res?.wl_custom_assigned_publishers === null ? (
                          <span
                            className="link-table"
                            onClick={() => window.open(res?.wl_products?.url)}
                          >
                            {res?.wl_products?.url &&
                            res?.wl_products?.url.length > 30
                              ? res?.wl_products?.url.slice(0, 30) + "..."
                              : res?.wl_products?.url}
                          </span>
                        ) : (
                          <span
                            className="link-table"
                            // onClick={() => window.open(res?.wl_products?.url)}
                          >
                            N/A
                          </span>
                        )}
                      </td>
                      <td>
                        {res?.service_type_id === 1
                          ? "Link insertion"
                          : res?.service_type_id === 2
                          ? res?.article_wordCount_id !== null
                            ? "Article by webylinks"
                            : "Article by buyer"
                          : ""}
                      </td>
                      <td>
                        {res?.wl_custom_assigned_publishers === null
                          ? res?.content_creator_type
                          : "Webylinks"}
                      </td>
                      <td>
                        $
                        {res?.wl_custom_assigned_publishers === null
                          ? res.total_amount
                          : res?.wl_custom_assigned_publishers
                              ?.customized_price}
                      </td>
                      {/* <td>
                <a href="#"> Continue </a>
              </td> */}
                      <td>
                        {res?.wl_custom_assigned_publishers === null
                          ? res?.wl_status?.status_name
                          : res?.wl_custom_assigned_publishers?.status === 0
                          ? "In Process"
                          : res?.wl_custom_assigned_publishers?.status === 1
                          ? "Accepted"
                          : res?.wl_custom_assigned_publishers?.status === 2
                          ? "Completed"
                          : res?.wl_custom_assigned_publishers?.status === 3
                          ? "Improvement"
                          : res?.wl_custom_assigned_publishers?.status === 4
                          ? "In Progress"
                          : res?.wl_custom_assigned_publishers?.status === 6
                          ? "Pending approval"
                          : "Rejected"}
                      </td>
                      <td>{new Date(res?.created_at).toLocaleDateString()}</td>
                      <td>
                        <div className="d-flex px-0">
                          <button
                            onClick={() => {
                              navigate(`/dashboard/publisher/task/${res?.id}`);
                            }}
                            className="active-offer-btn offer-accept-pub-btn"
                          >
                            {" "}
                            View{" "}
                          </button>
                          {res?.status_id !== 29 &&
                            res?.status_id !== 26 &&
                            res?.status_id !== 25 &&
                            res?.status_id !== 30 &&
                            res?.status_id !== 41 &&
                            !(
                              res?.wl_custom_assigned_publishers &&
                              res?.wl_custom_assigned_publishers?.status === 1
                            ) && (
                              <>
                                <Button
                                  aria-describedby={id}
                                  variant="outlined"
                                  onClick={async (e) => {
                                    setEId(res?.id);
                                    // setStatus(res?.status_id);
                                    op.current.toggle(e);
                                  }}
                                  className="verticon-button-offer"
                                >
                                  <IconButton className="more-option-card">
                                    <MoreHorizIcon />
                                  </IconButton>
                                </Button>
                                <OverlayPanel ref={op}>
                                  <List component="nav">
                                    {res?.status_id !== 25 && (
                                      <ListItem
                                        button
                                        onClick={() => {
                                          if (
                                            res?.wl_custom_assigned_publishers !==
                                            null
                                          ) {
                                            setInviteAcceptModal(true);
                                          } else {
                                            setAcceptModal(true);
                                          }
                                        }}
                                        className="p-card-menu-btn"
                                      >
                                        <ListItemIcon>
                                          <FontAwesomeIcon
                                            icon={faCircleCheck}
                                            size="lg"
                                            style={{ color: "#ffc813" }}
                                          />{" "}
                                        </ListItemIcon>
                                        <ListItemText primary="Accept" />
                                      </ListItem>
                                    )}
                                    {(res?.status_id === 24 ||
                                      res?.wl_custom_assigned_publishers !==
                                        null) && (
                                      <ListItem
                                        button
                                        onClick={() => {
                                          if (
                                            res?.wl_custom_assigned_publishers !==
                                            null
                                          ) {
                                            setInviteRejectModal(true);
                                          } else {
                                            setRejectModal(true);
                                          }
                                        }}
                                        className="p-card-menu-btn"
                                      >
                                        <ListItemIcon>
                                          <FontAwesomeIcon
                                            icon={faCircleXmark}
                                            size="lg"
                                            style={{ color: "#ffc813" }}
                                          />{" "}
                                        </ListItemIcon>
                                        <ListItemText primary="Cancel" />
                                      </ListItem>
                                    )}
                                  </List>
                                </OverlayPanel>
                              </>
                            )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
              {orders.length === 0 && <NoData />}
            </div>
            <div>
              {total > 10 && (
                <Paginator
                  first={currentPage * rows - rows}
                  rows={rows}
                  totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
      <AcceptTaskModal
        id={eid}
        getList={getList}
        getTabs={getTabs}
        setType={setTypeTab}
        acceptModal={acceptModal}
        handleAcceptClose={handleAcceptClose}
      />
      <RejectTaskModal
        id={eid}
        getList={getList}
        getTabs={getTabs}
        setType={setTypeTab}
        rejectModal={rejectModal}
        handleRejectClose={handleRejectClose}
      />
      <InvitationAcceptModal
        id={eid}
        getList={getList}
        getTabs={getTabs}
        setType={setTypeTab}
        inviteAcceptModal={inviteAcceptModal}
        handleInviteAcceptClose={handleInviteAcceptClose}
      />
      <InvitationRejectModal
        id={eid}
        getList={getList}
        getTabs={getTabs}
        setType={setTypeTab}
        inviteRejectModal={inviteRejectModal}
        handleInviteRejectClose={handleInviteRejectClose}
      />
    </div>
  );
};

export default TasksPublisher;
