// Login request
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// Register request
export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

// Load user
export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";

// Load user profile page
export const LOAD_USER_PROFILE_REQUEST = "LOAD_USER_PROFILE_REQUEST";
export const LOAD_USER_PROFILE_SUCCESS = "LOAD_USER_PROFILE_SUCCESS";
export const LOAD_USER_PROFILE_FAIL = "LOAD_USER_PROFILE_FAIL";

// Verify user
export const VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";

// User Role
export const USER_ROLE_SELECT_SUCCESS = "USER_ROLE_SELECT_SUCCESS";
export const USER_ROLE_SELECT_REQUEST = "USER_ROLE_SELECT_REQUEST";
export const USER_ROLE_SELECT_FAIL = "USER_ROLE_SELECT_FAIL";

// User Phone
export const USER_PHONE_SELECT_SUCCESS = "USER_PHONE_SELECT_SUCCESS";
export const USER_PHONE_SELECT_REQUEST = "USER_PHONE_SELECT_REQUEST";
export const USER_PHONE_SELECT_FAIL = "USER_PHONE_SELECT_FAIL";

// OTP VERIFICATION
export const USER_OTP_SELECT_SUCCESS = "USER_OTP_SELECT_SUCCESS";
export const USER_OTP_SELECT_REQUEST = "USER_OTP_SELECT_REQUEST";
export const USER_OTP_SELECT_FAIL = "USER_OTP_SELECT_FAIL";

// UPDATE PROFILE
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

// Logout user
export const LOGOUT_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOGOUT_USER_FAIL = "LOAD_USER_FAIL";

// Update password
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

// Forgot password
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

// New password
export const NEW_PASSWORD_REQUEST = "NEW_PASSWORD_REQUEST";
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS";
export const NEW_PASSWORD_FAIL = "NEW_PASSWORD_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
