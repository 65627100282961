import React, { useState } from "react";
import API from "../../../api/ClientApi";
import DeleteIcon from "../../../assets/images/ssss.svg";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import "../styles/deldecModal.css";
import Swal from "sweetalert2";

const DeleteModal = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);

  const handleDelete = async () => {
    setLoad(true);
    await API.delete(`/api/publisher/${props.data?.id}`)
      .then(async (response) => {
        setLoad(false);
        // Handle success or failure
        // Close the confirmation modal
        if (response?.data?.status === 200) {
          localStorage.setItem("selectedSubRoute", "/dashboard/platform");
          await props.handleModalClose();
          Swal.fire({
            title: "Success!",
            html: "Website deleted successfully",
            icon: "success",
            timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
            showConfirmButton: false, // Hide the "OK" button
            allowOutsideClick: false, // Prevent closing by clicking outside
          });
          await props.getTabs();
          await props.getList();
          // navigate("/dashboard/platform")
        }
        // window.location.reload();
      })
      .catch((error) => {
        setLoad(false);
        // Handle error
      });
  };

  return (
    <Modal
      open={props.showConfirmationModal}
      onClose={props.handleModalClose}
      aria-labelledby="Delete Website"
    >
      <Box className="modal-container">
        <img src={DeleteIcon} alt="Delete Icon" />
        <Typography variant="h6" id="modal-title" gutterBottom>
          Delete Website
        </Typography>
        <Typography className="detail-modal-text">
          Are you sure you want to delete this website?
        </Typography>

        <hr />
        <div className="buttons-container">
          {" "}
          {/* Added a container for the buttons */}
          <Button
            onClick={props.handleModalClose}
            className="cancel-btn-modal"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="delete-button"
            onClick={handleDelete}
            disabled={load} // Disable the button when loading
          >
            {load ? (
              <CircularProgress className="text-white circle-delete" />
            ) : (
              "Delete"
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
