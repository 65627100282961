import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, CircularProgress } from "@mui/material";
import logo from "../../../assets/images/logoMain.png";
import API from "../../../api/ClientApi";
import Swal from "sweetalert2";

const EmailSubmitStep = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoad(true);
    const config = {
      headers: {
        "Content-type": "multipart/form-data", // Since we now submiting images as well
      },
    };
    const data = await API.post(
      "/api/forget-password",
      { email:email },
      config
    );
    if(data){
      setLoad(false);
      Swal.fire({
        title: "Success!",
        html: data.data.message,
        icon: "success",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
    }
  };

  return (
    <div className="verify-card-main">
      <div className="signup-container">
      <div className="login-logo">
          <img src={logo} alt="weblinks-logo" />
        </div>

      <form
        onSubmit={handleSubmit}
        className="d-flex flex-column form-main-sec"
      >
        <h2>Forgot Password</h2>
        <p className="signup-terms">
          Please enter your email address to <br/>reset your password
        </p>
        <TextField
          type="email"
          placeholder="Email Address"
          variant="outlined"
          name="email"
          className="w-100 mt-2"
          required
          value={email}
          onChange={handleChange}
          InputProps={{
            classes: {
              root: "custom-input",
              input: "custom-input",
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-100 signup-btn"
        >
          {load ? <CircularProgress className="text-white"/> : "SUBMIT"}
        </Button>
      </form>
      <div className="bt-login">
        <p onClick={()=>navigate("/login")}><span>Back to login</span></p>
      </div>
      </div>
    </div>
  );
};

export default EmailSubmitStep;
