import {
  NOTIFICATION_FAIL,
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_COUNT_REQUEST,
  NOTIFICATION_COUNT_SUCCESS,
  NOTIFICATION_COUNT_FAIL,
  NOTIFICATION_UNREAD_COUNT_FAIL,
  NOTIFICATION_UNREAD_COUNT_SUCCESS,
  NOTIFICATION_UNREAD_COUNT_REQUEST,
  NOTIFICATION_CHECK_FAIL,
  NOTIFICATION_CHECK_REQUEST,
  NOTIFICATION_CHECK_SUCCESS,
} from "../constants/NotificationConstants";

// Login, Register and Load user (Auth)
export const notificationReducers = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_REQUEST:
      return {
        nloading: true,
      };

    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        nloading: false,
        notification_data: action.payload,
      };

    case NOTIFICATION_FAIL:
      return {
        ...state,
        nloading: false,
        notification_data: 0,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const notificationCountReducers = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_COUNT_REQUEST:
      return {
        ncloading: true,
      };

    case NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        ncloading: false,
        notification_count: action.payload,
      };

    case NOTIFICATION_COUNT_FAIL:
      return {
        ...state,
        ncloading: false,
        notification_count: 0,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const notificationUnreadCountReducers = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_UNREAD_COUNT_REQUEST:
      return {
        ncloading: true,
      };

    case NOTIFICATION_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        ncloading: false,
        notification_unread_count: action.payload,
      };

    case NOTIFICATION_UNREAD_COUNT_FAIL:
      return {
        ...state,
        ncloading: false,
        notification_unread_count: 0,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const notificationCheckReducers = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_CHECK_REQUEST:
      return {
        ncloading: true,
      };

    case NOTIFICATION_CHECK_SUCCESS:
      return {
        ...state,
        ncloading: false,
        notification_check: action.payload,
      };

    case NOTIFICATION_CHECK_FAIL:
      return {
        ...state,
        ncloading: false,
        notification_check: 0,
        error: action.payload,
      };

    default:
      return state;
  }
};
