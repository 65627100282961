import React, { useState } from "react";
import { Button, TextField, CircularProgress } from "@mui/material";

const ContactInfo = (props) => {
  const [empty_contact_error, setEmptyContactError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      (props.profile.name === "" || props.profile.name === null) &&
      (props.profile.whatsapp_number === "" ||
        props.profile.whatsapp_number === null) &&
      (props.profile.phone_number === "" || props.profile.phone_number === null)
    ) {
      setEmptyContactError(true);
    } else {
      props.handleSubmit(e);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="billing-info-main info-main-acc">
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container">
            <div className="w-100">
              <label>Name</label>
            </div>
            <TextField
              className={`w-100 signup-email-field ${
                empty_contact_error ? "empty-account-fields" : ""
              }`}
              placeholder="Name"
              name="name"
              type="text"
              variant="outlined"
              value={props.profile.name}
              onChange={(event) => {
                setEmptyContactError(false);
                props.handleChange(event);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
          <div className="info-billing-container second-input">
            <div className="w-100">
              <label>Whatsapp number</label>
            </div>
            <TextField
              className={`w-100 signup-email-field ${
                empty_contact_error ? "empty-account-fields" : ""
              }`}
              name="whatsapp_number"
              placeholder="Whatsapp Number"
              type="string"
              variant="outlined"
              value={props.profile.whatsapp_number}
              onChange={(event) => {
                setEmptyContactError(false);
                props.handleChange(event);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container">
            <div className="w-100">
              <label>Phone number</label>
            </div>
            <TextField
              className={`w-100 signup-email-field ${
                empty_contact_error ? "empty-account-fields" : ""
              }`}
              placeholder="Phone_number"
              name="phone_number"
              type="string"
              variant="outlined"
              value={props.profile.phone_number}
              onChange={(event) => {
                setEmptyContactError(false);
                props.handleChange(event);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
        </div>
        {empty_contact_error && (
          <div className="mt-2 w-100 d-flex justify-content-end">
            <p className="text-danger">Please fill atleast one field</p>
          </div>
        )}
        <div className="w-100 d-flex align-items-right justify-content-end account-setting-update">
          <button type="sybmit" className="billing-btn">
            {props.loading ? (
              <CircularProgress className="text-black" />
            ) : (
              "UPDATE"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactInfo;
