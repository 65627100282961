import React, { useState } from "react";
import API from "../../api/ClientApi";
import DeleteIcon from "../../assets/images/ssss.svg";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Dashboard/styles/deldecModal.css";
import Swal from "sweetalert2";

const AcceptTaskModal = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);

  const handleAccept = async (e) => {
    e.preventDefault();
    setLoad(true);
    await API.post(
      `/api/update-order-status-by-publisher/${
        props.id
      }?status_id=25&user_type=${
        localStorage.getItem("role") === "buyer" ? 1 : 2
      }`
    )
      .then(async (response) => {
        setLoad(false);
        // Handle success or failure
        // Close the confirmation modal
        await props.handleAcceptClose();
        Swal.fire({
          title: "Success!",
          html: "Task accepted successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
        await props.getTabs();
        await props.getList();
        props.setType(25);
        // navigate("/dashboard/platform")

        // window.location.reload();
      })
      .catch((error) => {
        setLoad(false);
        // Handle error
      });
  };

  return (
    <Modal
      open={props.acceptModal}
      onClose={props.handleAcceptClose}
      aria-labelledby="Accept Task"
    >
      <Box className="modal-container">
        <FontAwesomeIcon
          icon={faCircleCheck}
          size="xl"
          className="font-style"
        />
        <Typography variant="h6" id="modal-title" gutterBottom>
          Accept Task
        </Typography>
        <Typography className="detail-modal-text">
          Are you sure you want to accept this task?
        </Typography>

        <hr />
        <div className="buttons-container">
          {" "}
          {/* Added a container for the buttons */}
          <Button
            onClick={props.handleAcceptClose}
            className="cancel-btn-modal"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="delete-button"
            onClick={handleAccept}
            disabled={load} // Disable the button when loading
          >
            {load ? (
              <CircularProgress className="text-white circle-delete" />
            ) : (
              "Accept"
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AcceptTaskModal;
