import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Info from "../../assets/images/info-icon.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDropzone } from "react-dropzone";
import "./styles/taskView.css";
import API from "../../api/ClientApi";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import InfoIcon from "../../assets/images/infoIcon.png";
import validUrl from "valid-url";
import Swal from "sweetalert2";
import { userBalance } from "../../actions/walletActions";
import TaskRejectModal from "./components/TaskRejectModal";
import TaskAcceptModal from "./components/TaskAcceptModal";
import { BreadCrumb } from "primereact/breadcrumb";
import TaskCompleteModal from "./components/TaskCompleteModal";
import InvitationAcceptModal from "../Tasks/InvitationAcceptModal";
import InvitationRejectModal from "../Tasks/InvitationRejectModal";

const TaskPubView = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setUploadedFile(acceptedFiles[0]);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "*/*",
  });
  const [websiteData, setWebsiteData] = useState(null);
  const [wload, setWload] = useState(false);
  const [placementUrl, setPlacementUrl] = useState("");
  const [pError, setPError] = useState(false);
  const [improvements, setImprovements] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [inviteAcceptModal, setInviteAcceptModal] = useState(false);
  const [inviteRejectModal, setInviteRejectModal] = useState(false);
  const items = [
    { label: "Tasks", url: "/dashboard/publisher/tasks" },
    { label: "Task Detail" },
  ];
  const home = { label: "Home", url: "/dashboard/platform" };

  const fetchData = async () => {
    setPError(false);
    setWload(true);
    try {
      const response = await API.get(`/api/orders/${id}/edit`); // Replace with your actual API endpoint
      setWebsiteData(response.data);
      setWload(false);
    } catch (error) {
      setWload(false);
      return error;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "buyer" || props.publish === 0) {
      navigate("/dashboard/website-list");
    }
    const fetchData = async () => {
      setWload(true);
      try {
        const response = await API.get(`/api/orders/${id}/edit`); // Replace with your actual API endpoint
        setWebsiteData(response.data);
        setPlacementUrl(response?.data?.task_placement_url);
        setImprovements(response?.data?.improvement_comment);
        setWload(false);
      } catch (error) {
        setWload(false);
        return error;
      }
    };

    fetchData();
  }, [localStorage.getItem("role"), id]);

  const isValidUrl = (url) => {
    // Regular expression to match a URL pattern with "https://"
    if (url === "") {
      return true;
    } else {
      return validUrl.isWebUri(url);
    }
  };

  const handleRejectClose = () => {
    setRejectModal(false);
  };

  const handleAcceptClose = () => {
    setAcceptModal(false);
  };

  const handleCompleteClose = () => {
    setCompleteModal(false);
  };

  const handleInviteAcceptClose = () => {
    setInviteAcceptModal(false);
  };

  const handleInviteRejectClose = () => {
    setInviteRejectModal(false);
  };

  const handleComplete = () => {
    if (placementUrl === "" || !isValidUrl(placementUrl)) {
      setPError(true);
    } else {
      setCompleteModal(true);
    }
  };

  return (
    <div className="buyer-form-main-wraper">
      <BreadCrumb model={items} home={home} />
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-7">
            <h2> Task </h2>
          </div>
        </div>
      </div>

      {websiteData?.status_id === 24 && (
        <div className="mt-2 list-website-info">
          <span>
            {" "}
            <img src={InfoIcon} alt="" />{" "}
          </span>
          <strong>
            {" "}
            This task is pending your acceptence. You can accept or reject this
            task.
          </strong>
        </div>
      )}
      {websiteData?.status_id === 25 && (
        <div className="mt-2 list-website-info">
          <span>
            {" "}
            <img src={InfoIcon} alt="" />{" "}
          </span>
          <strong>
            {" "}
            You have accepted the task. You can now add placement Url and
            complete this task.
          </strong>
        </div>
      )}
      {websiteData?.status_id === 26 && (
        <div className="mt-2 list-website-info">
          <span>
            {" "}
            <img src={InfoIcon} alt="" />{" "}
          </span>
          <strong>
            {" "}
            You have completed the task. Please wait for buyer's approval.
          </strong>
        </div>
      )}
      {websiteData?.status_id === 41 && (
        <div className="mt-2 list-website-info">
          <span>
            {" "}
            <img src={InfoIcon} alt="" />{" "}
          </span>
          <strong>
            {" "}
            Buyer has asked for improvement. Please update the placement URL for
            improved task.
          </strong>
        </div>
      )}
      {wload ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="mt-2 offer-detail-container">
            {/* <div className="offer-heading-wrapper">
              <h4>{websiteData?.offer_name}</h4>
            </div> */}
            <div className="offer-rest-detail-wrapper">
              <div className="accordion-row accordion-row-task">
                {" "}
                {/* {websiteData?.wl_products && (
                  <div className="d-flex flex-column align-items-start offer-d-row-col">
                    {" "}
                    <h6 className="order-article-faq-q ">Name:</h6>
                    {websiteData?.wl_products?.user?.wl_users_header?.name}
                  </div>
                )} */}
                {websiteData?.wl_products && (
                  <div className="d-flex flex-column align-items-start offer-d-row-col">
                    {" "}
                    <h6 className="order-article-faq-q ">Publisher's Url:</h6>
                    <a
                      href={websiteData?.wl_products?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="break-word"
                    >
                      {websiteData?.wl_products?.url}
                    </a>
                  </div>
                )}
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Task placement URL:</h6>
                  {websiteData?.task_placement_url ? (
                    <a
                      href={websiteData?.task_placement_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="break-word"
                    >
                      {websiteData?.task_placement_url}
                    </a>
                  ) : (
                    "This is where the publisher will add the url of page with the performed task."
                  )}
                  {/* {websiteData?.service_type} */}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Promoted Url:</h6>
                  {websiteData?.url &&
                    JSON.parse(websiteData?.url).length > 0 &&
                    JSON.parse(websiteData?.url).map((res, id) => (
                      <a
                        href={res}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={id}
                      >
                        {res}
                      </a>
                    ))}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Anchor text:</h6>
                  {websiteData?.anchor_text &&
                  JSON.parse(websiteData?.anchor_text).length > 0
                    ? JSON.parse(websiteData?.anchor_text).map((res, id) => (
                        <p key={id}>{res}</p>
                      ))
                    : "N/A"}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Service type:</h6>
                  {websiteData?.service_type_id === 1
                    ? "Link insertion"
                    : "Guest post"}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Content creator:</h6>
                  Webylinks
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Price:</h6>$
                  {websiteData?.wl_custom_assigned_publishers === null
                    ? websiteData?.total_amount
                    : websiteData?.wl_custom_assigned_publishers
                        ?.customized_price}
                  {/* {websiteData?.service_type} */}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  <h6 className="order-article-faq-q ">
                    Special requirements:
                  </h6>
                  {websiteData?.special_requirements === null
                    ? "N/A"
                    : websiteData?.special_requirements}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q">Content type:</h6>
                  {websiteData?.service_type_id === 1
                    ? "Link insertion"
                    : websiteData?.service_type_id === 2
                    ? websiteData?.article_wordCount_id !== null
                      ? "Article by webylinks"
                      : "Article by buyer"
                    : ""}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Task status:</h6>
                  {websiteData?.status_id === 24 &&
                  websiteData?.order_type === "product"
                    ? "Your Acceptance"
                    : websiteData?.status_id === 24 &&
                      websiteData?.order_type === "custom"
                    ? "In Process"
                    : websiteData?.status_id === 23
                    ? "Draft"
                    : websiteData?.status_id === 25 &&
                      websiteData?.order_type === "product"
                    ? "In Progress"
                    : websiteData?.wl_custom_assigned_publishers?.status ===
                        4 && websiteData?.order_type === "custom"
                    ? "In Progress"
                    : websiteData?.wl_custom_assigned_publishers?.status ===
                        1 && websiteData?.order_type === "custom"
                    ? "Pending Approval"
                    : websiteData?.wl_custom_assigned_publishers?.status ===
                        6 && websiteData?.order_type === "custom"
                    ? "Pending Approval"
                    : websiteData?.status_id === 26
                    ? "Pending Acceptence"
                    : websiteData?.status_id === 25 &&
                      websiteData?.order_type === "custom" &&
                      websiteData?.wl_custom_assigned_publishers?.status === 3
                    ? "Improvement"
                    : websiteData?.status_id === 25 &&
                      websiteData?.order_type === "custom" &&
                      websiteData?.wl_custom_assigned_publishers?.status === 5
                    ? "Rejected"
                    : websiteData?.status_id === 41
                    ? "Improvement"
                    : websiteData?.status_id === 27
                    ? "Invitation"
                    : websiteData?.status_id === 29
                    ? "Completed"
                    : websiteData?.status_id === 30
                    ? "Rejected"
                    : websiteData?.status_id === 40
                    ? "Pending Approval"
                    : "N/A"}
                </div>
              </div>
            </div>
            {websiteData?.wl_products && websiteData?.service_type_id === 1 && (
              <div className="offer-desc-wrapper">
                <Editor
                  apiKey="rw57q63adwugi7s974bxs2bwaqna6dd3u8himamgjfsf8ax2"
                  initialValue={websiteData?.content}
                  init={{
                    height: 400,
                    menubar: false,
                    selector: "textarea",
                    toolbar: "",
                    content_style:
                      "body { font-family: 'Arial', sans-serif; font-size: 14px }",
                  }}
                  disabled
                />
              </div>
            )}
            {(websiteData?.status_id === 41 ||
              websiteData?.status_id === 29) && (
              <div className="w-100 d-flex flex-column improvement-wrap">
                <label>Improvements</label>
                <textarea
                  className={`text-area-control`}
                  rows="6"
                  name="improvements"
                  placeholder="Please specify any improvements you want to suggest in this task"
                  value={improvements}
                  disabled={
                    websiteData?.status_id === 41 ||
                    websiteData?.status_id === 29
                  }
                ></textarea>
              </div>
            )}
            {websiteData?.wl_custom_assigned_publishers?.status === 3 &&
              websiteData.order_type === "custom" &&
              websiteData?.status_id === 25 &&
              websiteData?.wl_custom_order_admin_action && (
                <div className="w-100 d-flex flex-column improvement-wrap">
                  <label>Improvements</label>
                  <textarea
                    className={`text-area-control`}
                    rows="6"
                    name="improvements"
                    placeholder="Please specify any improvements you want to suggest in this task"
                    value={
                      websiteData?.wl_custom_order_admin_action
                        ?.improvement_remarks
                    }
                    disabled
                  ></textarea>
                </div>
              )}
            {websiteData?.wl_custom_assigned_publishers?.status === 5 &&
              websiteData.order_type === "custom" && (
                <div className="w-100 d-flex flex-column improvement-wrap">
                  <label>Cancellation Remarks</label>
                  <textarea
                    className={`text-area-control`}
                    rows="6"
                    name="improvements"
                    placeholder="Please specify any improvements you want to suggest in this task"
                    value={
                      websiteData?.wl_custom_order_admin_action
                        ?.cancellation_reason
                    }
                    disabled
                  ></textarea>
                </div>
              )}
            {(websiteData?.status_id === 25 ||
              websiteData?.status_id === 41) && (
              <>
                <div className="w-100 d-flex flex-column improvement-wrap">
                  <label>Placement Url*</label>
                  <input
                    type="text"
                    name="taskPlacementURL"
                    placeholder="https://"
                    value={placementUrl}
                    className={`text-area-control ${pError ? "p-url-err" : ""}`}
                    disabled={
                      websiteData?.status_id === 24 ||
                      (websiteData?.wl_custom_assigned_publishers?.status ===
                        6 &&
                        websiteData?.order_type === "custom") ||
                      (websiteData?.wl_custom_assigned_publishers?.status ===
                        5 &&
                        websiteData?.order_type === "custom")
                    }
                    onChange={(e) => {
                      setPError(false);
                      setPlacementUrl(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="w-100 d-flex flex-column improvement-wrap">
                  <label>Task files*</label>
                  {uploadedFile ? (
                    <div className="upload-file-xls">
                      <p>{uploadedFile.name}</p>
                      <CloseIcon
                        className="cls-icon"
                        onClick={() => setUploadedFile(null)}
                      />
                    </div>
                  ) : (
                    <div {...getRootProps()} className="add-upload-file">
                      <input disabled {...getInputProps()} />
                      <p>Drag and drop files here, or click to select files.</p>
                    </div>
                  )}
                </div> */}
              </>
            )}
            {pError && (
              <div className="mt-2 d-flex w-100 justify-content-end">
                <p style={{ color: "red" }}>
                  Please enter a valid placement url
                </p>
              </div>
            )}
            {websiteData?.status_id !== 29 &&
              websiteData?.status_id !== 26 &&
              websiteData?.status_id !== 30 &&
              !(
                websiteData?.wl_custom_assigned_publishers?.status === 1 &&
                websiteData?.order_type === "custom"
              ) && (
                <div className="mt-4 w-100 d-flex justify-content-end task-btns-wrapper">
                  {(websiteData?.wl_custom_assigned_publishers?.status === 4 &&
                    websiteData.order_type === "custom" &&
                    websiteData?.status_id === 25) ||
                  (websiteData?.wl_custom_assigned_publishers?.status === 3 &&
                    websiteData.order_type === "custom" &&
                    websiteData?.status_id === 25) ||
                  (websiteData?.order_type === "product" &&
                    websiteData?.status_id === 25) ||
                  websiteData?.status_id === 41 ? (
                    <button
                      className="mx-2 active-offer-btn offer-accept-pub-btn"
                      onClick={() => {
                        handleComplete();
                      }}
                    >
                      {" "}
                      Complete{" "}
                    </button>
                  ) : (
                    (!(
                      websiteData?.status_id === 25 &&
                      websiteData.order_type === "custom"
                    ) ||
                      websiteData?.status_id !== 25) && (
                      <button
                        className="mx-2 active-offer-btn offer-accept-pub-btn"
                        onClick={() => {
                          if (
                            websiteData?.wl_custom_assigned_publishers !== null
                          ) {
                            setInviteAcceptModal(true);
                          } else {
                            setAcceptModal(true);
                          }
                        }}
                      >
                        {" "}
                        Accept{" "}
                      </button>
                    )
                  )}
                  {websiteData?.status_id === 24 && (
                    <button
                      className="active-offer-btn offer-accept-pub-btn"
                      onClick={() => {
                        if (
                          websiteData?.wl_custom_assigned_publishers !== null
                        ) {
                          setInviteRejectModal(true);
                        } else {
                          setRejectModal(true);
                        }
                      }}
                    >
                      {" "}
                      Cancel{" "}
                    </button>
                  )}
                </div>
              )}
          </div>
        </>
      )}
      <TaskCompleteModal
        id={websiteData?.id}
        getTask={fetchData}
        completeModal={completeModal}
        handleCompleteClose={handleCompleteClose}
        placementUrl={placementUrl}
        uploadedFile={uploadedFile}
      />
      <TaskRejectModal
        id={websiteData?.id}
        rejectModal={rejectModal}
        getTask={fetchData}
        handleRejectClose={handleRejectClose}
      />
      <TaskAcceptModal
        id={websiteData?.id}
        acceptModal={acceptModal}
        getTask={fetchData}
        handleAcceptClose={handleAcceptClose}
      />
      <InvitationAcceptModal
        id={websiteData?.id}
        getList={fetchData}
        getTabs={() => {}}
        setType={() => {}}
        inviteAcceptModal={inviteAcceptModal}
        handleInviteAcceptClose={handleInviteAcceptClose}
      />
      <InvitationRejectModal
        id={websiteData?.id}
        getList={fetchData}
        getTabs={() => {}}
        setType={() => {}}
        inviteRejectModal={inviteRejectModal}
        handleInviteRejectClose={handleInviteRejectClose}
      />
    </div>
  );
};

export default TaskPubView;
