import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import API from "../../api/ClientApi";
import { BreadCrumb } from "primereact/breadcrumb";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/NoData/NoData";
import Trash from "../../assets/images/trash.svg";
import { Paginator } from "primereact/paginator";

const Lists = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const items = [{ label: "Lists" }];
  const [rows, setRows] = useState(10);
  const home = { label: "Home", url: "/dashboard/website-list" };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    const getList = async () => {
      setLoad(true);
      try {
        const data = await API.get(
          `${process.env.REACT_APP_BACKEND_API}/api/customize-task-order?page=${currentPage}&user_type=1`
        );
        if (data) {
          setLoad(false);
          setOrders(data.data.data);
          setCurrentPage(data?.data?.current_page);
          setTotalPages(data?.data?.last_page);
          setTotal(data?.data?.total);
        }
      } catch (error) {
        setLoad(false);
        return error;
      }
    };

    getList();
  }, [currentPage, localStorage.getItem("role")]);

  return (
    <div className="buyer-form-main-wraper">
      <BreadCrumb model={items} home={home} />
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Customized Orders </h2>
          </div>
        </div>
      </div>{" "}
      <Accordion className="accordion-filter accordion-global">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-summery"
        >
          <div className="w-100 d-flex justify-content-between filter-header">
            <h2>HOW IT WORKS?</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <div className="accordion-detail-div ac-div">
            From the section below you can see your content orders categorized
            according to various statuses.Adjacent to each order you can see a
            number representing the quantity of orders under that status.When
            the order is further processed it move from one status to
            another.Confirmation will be sent through emails and
            notifications.The three dots button will guide you to get more
            information about the order.
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="cta-add-website-wrap">
        <div className="row">
          <div className="col-md-8">
            <h2 className="new-aticle-heading"> Want to customize an order? </h2>
          </div>
          <div className="col-md-4">
            <div className="add-website-wrap task-list-head-btn">
              {" "}
              <Button
                onClick={() => {
                  navigate("/dashboard/da-traffic");
                }}
                className="website-add-banner-btn custom-btn"
              >
                Customized order
              </Button>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="order-article-tabs-wrap">
        {load ? (
          <div className="w-100 mt-2 d-flex justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table width="100%" className="table">
                <tr className="heading-article">
                  <td width="16.5%">Order ID</td>
                  <td width="16.5%">Priority</td>
                  <td width="17.5%">Url</td>
                  <td width="16.5%">Word Count</td>
                  <td width="16.5%">Price</td>
                  <td width="16.5%">Actions</td>
                </tr>
                {orders &&
                  orders?.map((res, id) => (
                    <tr>
                      <td height="70">
                        <span>#{id + 1}</span>
                      </td>
                      <td>{res?.website_priorities}</td>
                      <td>
                        <span
                          className="link-table px-0"
                          onClick={() => window.open(JSON.parse(res?.url)[0])}
                        >
                          {JSON.parse(res?.url)[0]}
                        </span>
                      </td>
                      <td>{res?.words}</td>
                      <td>${res?.total_amount}</td>

                      <td>
                        <div className="d-flex px-0">
                          <button
                            className="active-offer-btn offer-accept-pub-btn"
                            onClick={() => {
                              navigate(`/dashboard/customized-order/${res?.id}`);
                            }}
                          >
                            {" "}
                            View{" "}
                          </button>

                          <button className="active-offer-btn offer-reject-btn">
                            Cancel
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
              {orders?.length === 0 && <NoData />}
            </div>
            <div>
              {total > 10 && (
                <Paginator
                  first={currentPage * rows - rows}
                  rows={rows}
                  totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Lists;
