import React, { useState } from "react";
import API from "../../../api/ClientApi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ActivateIcon from "../../../assets/images/activite.svg"

import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

const ActivateModal = (props) => {
  const [load, setLoad] = useState(false);

  const handleActive = () => {
    setLoad(true);
    API.patch(`/api/status-change-by-publisher/${props.data?.id}?is_active=1`)
      .then((response) => {
        setLoad(false);
        // Handle success or failure
        // Close the confirmation modal
        localStorage.setItem("selectedSubRoute", "/dashboard/platform");
        Swal.fire({
          title: "Success!",
          html: "Website activated successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
        props.getList();
        props.handleActModalClose();
      })
      .catch((error) => {
        setLoad(false);
        // Handle error
      });
  };

  return (
    <Modal
      open={props.showActConfirmationModal}
      onClose={props.handleActModalClose}
      aria-labelledby="Activate Website"
    >
      <Box className="modal-container">
        <img src={ActivateIcon} alt="Delete Icon" />

        <Typography variant="h6" id="modal-title" gutterBottom>
          Activate Website
        </Typography>
        <Typography className="detail-modal-text">
          Are you sure you want to activate this website?
        </Typography>
        <hr />
        <div className="buttons-container">
          <Button
            onClick={props.handleActModalClose}
            className="cancel-btn-modal"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="delete-button"
            onClick={handleActive}
          >
            {load ? (
              <CircularProgress className="text-white circle-delete" />
            ) : (
              "Activate"
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ActivateModal;
