import React, { useState } from "react";
import { useSelector } from "react-redux";
import ProfileWebList from "./components/ProfileWebList";

const ProfileListingMain = () => {
  const { user, loading } = useSelector((state) => state.profile);
  const initialRole =
    localStorage.getItem("role") ||
    (user?.user?.type_id === 1 ? "buyer" : "publisher");
  const [role, setRole] = useState(initialRole);
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <div>
      <ProfileWebList
        isDarkMode={isDarkMode}
        id={user?.user?.type_id}
        setRole={setRole}
        hash={user?.hash === null}
      />
    </div>
  );
};

export default ProfileListingMain;
