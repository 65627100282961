import {
  WALLET_FAIL,
  WALLET_REQUEST,
  WALLET_SUCCESS,
} from "../constants/walletConstants";

// Login, Register and Load user (Auth)
export const walletReducers = (state = { balance: 0 }, action) => {
  switch (action.type) {
    case WALLET_REQUEST:
      return {
        wloading: true,
      };

    case WALLET_SUCCESS:
      return {
        ...state,
        wloading: false,
        balance: action.payload
      };

    case WALLET_FAIL:
      return {
        ...state,
        wloading: false,
        balance: 0,
        error: action.payload,
      };

    default:
      return state;
  }
};
