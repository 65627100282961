import API from "../api/ClientApi";
import {
  WALLET_FAIL,
  WALLET_REQUEST,
  WALLET_SUCCESS,
} from "../constants/walletConstants";

// Get balance
export const userBalance = (role) => async (dispatch) => {
  try {
    dispatch({ type: WALLET_REQUEST });

    const { data } = await API.get(
      `/api/user-wallet?user_type=${
        role === "buyer" ? 1 : 2
      }`
    );
    dispatch({ type: WALLET_SUCCESS, payload: data?.balance });
  } catch (error) {
    dispatch({
      type: WALLET_FAIL,
      payload: error.response.data.message,
    });
  }
};
