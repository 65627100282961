import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  DialogContent,
  IconButton,
  Popover,
  CircularProgress,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import "../styles/buyerDashboard.css";
import { Badge } from "primereact/badge";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useSelector } from "react-redux/es/hooks/useSelector";
import InfoIcon from "../../../assets/images/infoIcon.png";
import PublisherInfoCard from "./PublisherInfoCard";
import API from "../../../api/ClientApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NoData from "../../../components/NoData/NoData";
import { Paginator } from "primereact/paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import Icon from "../../../assets/DashboardIcons/info.svg";
import Flag from "../../../assets/DashboardIcons/flag.jpg";
import Clock from "../../../assets/DashboardIcons/clock.svg";
import Gear from "../../../assets/DashboardIcons/gear.svg";
import Graph from "../../../assets/DashboardIcons/graph.jpg";
import Limg from "../../../assets/DashboardIcons/link.svg";
import Plus from "../../../assets/DashboardIcons/plus.svg";
import Search from "../../../assets/DashboardIcons/search.svg";
import AddUrlFunds from "./AddUrlFunds";
import Swal from "sweetalert2";
import CountryFlag from "react-country-flag";
import Avatar from "../../../assets/images/user-solid.svg";
import VIcon from "../../../assets/images/verifyIcon.png";

const BuyerDashboard = (props) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.profile);
  const { balance, wloading } = useSelector((state) => state.balance);
  const isMobileX = useMediaQuery("(max-width: 400px)");
  const isMobileL = useMediaQuery("(max-width: 1550px)");
  const isMobileM = useMediaQuery("(max-width: 1350px)");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const da = searchParams.get("da");

  const [showAdditionalFilters, setShowAdditionalFilters] = useState(
    sessionStorage.getItem("filters") ? true : false
  );
  const toggleAdditionalFilters = () => {
    setShowAdditionalFilters(!showAdditionalFilters);
  };
  const [dates, setDates] = useState(
    sessionStorage.getItem("dates")
      ? sessionStorage
          .getItem("dates")
          .split(",")
          .map((dateString) => new Date(dateString))
      : []
  );
  const [websites, setWebsites] = useState(null);
  const [webload, setWebLoad] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [minimumPriceError, setMinimumPriceError] = useState("");
  const [maximumPriceError, setMaximumPriceError] = useState("");
  const [minimumDaError, setMinimumDaError] = useState("");
  const [maximumDaError, setMaximumDaError] = useState("");
  const [minimumDrError, setMinimumDrError] = useState("");
  const [maximumDrError, setMaximumDrError] = useState("");
  const [minimumScError, setMinimumScError] = useState("");
  const [maximumScError, setMaximumScError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(10);
  const [searchUrl, setSearchUrl] = useState("");
  const [allWeb, setAllWeb] = useState(true);
  const [category, setCategory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lang, setLang] = useState("All");
  const [cat, setCat] = useState("All");
  const [drr, setDr] = useState({ da: null, dr: null, traffic: null });
  const [filterOptions, setFilterOptions] = useState(
    sessionStorage.getItem("filters")
      ? JSON.parse(sessionStorage.getItem("filters"))
      : {
          minimum_price: null,
          maximum_price: null,
          minimum_da: null,
          maximum_da: null,
          minimum_dr: null,
          maximum_dr: null,
          minimum_spam_score: null,
          maximum_spam_score: null,
          monthly_traffic: null,
          service_type: { name: "All", code: "All" },
          language: { name: "All", code: "All" },
          category_id: { name: "All", code: "All" },
          disclosure_type: { name: "All", code: "All" },
          link_type: { name: "All", code: "All" },
          organic_traffic: null,
          marked_sponsored_by: { name: "All", code: "All" },
          publishers_tat: { name: "All", code: "All" },
          publishers_rating: { name: "All", code: "All" },
          avg_lifetime_of_links: { name: "More than 70%", code: 70 },
          task_with_Initial: { name: "More than 70%", code: 70 },
          completion_rate: { name: "More than 70%", code: 70 },
        }
  );

  const [page, setPage] = useState({ name: "30 per page", code: 30 });
  const [sortBy, setSortBy] = useState({
    name: "From the greatest DA",
    code: "highest_da",
  });

  const languages = [
    { name: "All", code: "All", countryCode: "" }, // You can assign an empty string for "All"
    { name: "English", code: "English", countryCode: "US" },
    { name: "Urdu", code: "Urdu", countryCode: "PK" },
    { name: "Chinese", code: "Chinese", countryCode: "CN" },
    { name: "French", code: "French", countryCode: "FR" },
    { name: "German", code: "German", countryCode: "DE" },
    { name: "Hindi", code: "Hindi", countryCode: "IN" },
    { name: "Spanish", code: "Spanish", countryCode: "ES" },
    { name: "Arabic", code: "Arabic", countryCode: "SA" },
    { name: "Bulgarian", code: "Bulgarian", countryCode: "BG" },
    { name: "Dutch", code: "Dutch", countryCode: "NL" },
    { name: "Greek", code: "Greek", countryCode: "GR" },
    { name: "Hrvatski", code: "Hrvatski", countryCode: "HR" },
    { name: "Indonesian", code: "Indonesian", countryCode: "ID" },
    { name: "Italian", code: "Italian", countryCode: "IT" },
    { name: "Japanese", code: "Japanese", countryCode: "JP" },
    { name: "Korean", code: "Korean", countryCode: "KR" },
    { name: "Norwegian", code: "Norwegian", countryCode: "NO" },
    { name: "Other", code: "Other", countryCode: "" }, // You can assign an empty string for "Other"
    { name: "Polish", code: "Polish", countryCode: "PL" },
    { name: "Portuguese", code: "Portuguese", countryCode: "PT" },
    { name: "Romanian", code: "Romanian", countryCode: "RO" },
    { name: "Russian", code: "Russian", countryCode: "RU" },
    { name: "Spanish", code: "Spanish", countryCode: "ES" },
    { name: "Swedish", code: "Swedish", countryCode: "SE" },
    { name: "Turkish", code: "Turkish", countryCode: "TR" },
    { name: "Ukranian", code: "Ukranian", countryCode: "UA" },
  ];

  const services = [
    { name: "All", code: "All" },
    { name: "Link insertion", code: "link-insertion-amount" },
    { name: "Guest post", code: "guest-post" },
  ];

  const disclosure = [
    { name: "All", code: "All" },
    { name: "Yes", code: 1 },
    { name: "No", code: 0 },
  ];

  const link = [
    { name: "All", code: "All" },
    { name: "DoFollow", code: 1 },
    { name: "NoFollow", code: 0 },
  ];

  const publisherTAT = [
    { name: "All", code: "All" },
    { name: "Up to 1 day", code: 1 },
    { name: "Up to 2 days", code: 2 },
    { name: "Up to 3 days", code: 3 },
    { name: "Up to 4 days", code: 4 },
    { name: "Up to 5 days", code: 5 },
    { name: "Up to 6 days", code: 6 },
    { name: "Up to 7 days", code: 7 },
    { name: "Up to 8 days", code: 8 },
    { name: "Up to 9 days", code: 9 },
    { name: "More then 10 days", code: 10 },
  ];

  const publisherRating = [
    { name: "All", code: "All" },
    { name: "1 star", code: 1 },
    { name: "2 stars", code: 2 },
    { name: "3 stars", code: 3 },
    { name: "4 stars", code: 4 },
    { name: "5 stars", code: 5 },
  ];

  const averageLinkLifetime = [
    { name: "More than 70%", code: 70 },
    { name: "More than 80%", code: 80 },
    { name: "More than 90%", code: 90 },
  ];

  const pages = [
    { name: "30 per page", code: 30 },
    { name: "50 per page", code: 50 },
    { name: "100 per page", code: 100 },
  ];

  const sort = [
    { name: "From the greatest price", code: "highest_price" },
    { name: "From the lowest price", code: "lowest_price" },
    { name: "From the greatest DA", code: "highest_da" },
    { name: "From the lowest DA", code: "lowest_da" },
    { name: "From the greatest DR", code: "highest_dr" },
    { name: "From the lowest DR", code: "lowest_dr" },
    { name: "From the greatest Organic traffic", code: "highest_traffic" },
    { name: "From the lowest Organic traffic", code: "lowest_traffic" },
  ];

  const clear = async (e) => {
    e.preventDefault();
    setMaximumPriceError("");
    setMinimumPriceError("");
    setMaximumDaError("");
    setMinimumDaError("");
    setMaximumDrError("");
    setMinimumDrError("");
    setMaximumScError("");
    setMinimumScError("");
    setDates([]);
    if (
      filterOptions.minimum_price !== null ||
      filterOptions?.maximum_price !== null ||
      filterOptions.minimum_da !== null ||
      filterOptions?.maximum_da !== null ||
      filterOptions.minimum_dr !== null ||
      filterOptions?.maximum_dr !== null ||
      filterOptions.minimum_spam_score !== null ||
      filterOptions?.maximum_spam_score !== null ||
      filterOptions?.service_type !== "All" ||
      filterOptions?.language !== "All" ||
      filterOptions?.category_id !== "All" ||
      filterOptions?.disclosure_type !== "All" ||
      filterOptions?.link_type !== 0 ||
      filterOptions?.monthly_traffic !== null ||
      filterOptions?.organic_traffic !== null
    ) {
      const newFilterOptions = {
        minimum_price: null,
        maximum_price: null,
        minimum_da: null,
        maximum_da: null,
        minimum_dr: null,
        maximum_dr: null,
        minimum_spam_score: null,
        maximum_spam_score: null,
        monthly_traffic: null,
        service_type: { name: "All", code: "All" },
        language: { name: "All", code: "All" },
        category_id: { name: "All", code: "All" },
        disclosure_type: { name: "All", code: "All" },
        link_type: { name: "All", code: "All" },
        organic_traffic: null,
        marked_sponsored_by: { name: "All", code: "All" },
        publishers_tat: { name: "All", code: "All" },
        publishers_rating: { name: "All", code: "All" },
        avg_lifetime_of_links: { name: "More than 70%", code: 70 },
        task_with_Initial: { name: "More than 70%", code: 70 },
        completion_rate: { name: "More than 70%", code: 70 },
      };
      setDates(null);
      setFilterOptions(newFilterOptions);
      await handleClear(e, newFilterOptions);

      // Explicitly clear the TextField values
      document.getElementsByName("minimum_price")[0].value = null;
      document.getElementsByName("maximum_price")[0].value = null;
      document.getElementsByName("minimum_da")[0].value = null;
      document.getElementsByName("maximum_da")[0].value = null;
      document.getElementsByName("minimum_dr")[0].value = null;
      document.getElementsByName("maximum_dr")[0].value = null;
      document.getElementsByName("minimum_spam_score")[0].value = null;
      document.getElementsByName("maximum_spam_score")[0].value = null;
      document.getElementsByName("monthly_traffic")[0].value = null;
      document.getElementsByName("organic_traffic")[0].value = null;
    }
  };

  // Checking da range validation
  const daError = () => {
    if (
      filterOptions?.minimum_da !== null &&
      filterOptions?.maximum_da !== null
    ) {
      if (filterOptions?.minimum_da > 100) {
        setMinimumDaError("Max da is 100");
        return true;
      }
      if (filterOptions?.maximum_da > 0 && filterOptions?.minimum_da > 0) {
        if (filterOptions?.maximum_da < filterOptions?.minimum_da) {
          setMaximumDaError(
            `Max. da should be greater then ${filterOptions?.minimum_da}`
          );
          return true;
        } else {
          return false;
        }
      } else {
        if (filterOptions?.minimum_da < 1) {
          setMinimumDaError("Min. da should be greater then 0");
          return true;
        }
        if (filterOptions?.maximum_da > 100) {
          setMaximumDaError("Max. da should be less then 100");
          return true;
        }
      }
    }
    if (
      filterOptions?.minimum_da === null &&
      filterOptions?.maximum_da === null
    ) {
      return false;
    }
    if (
      filterOptions?.minimum_da !== null &&
      filterOptions?.maximum_da === null
    ) {
      if (filterOptions?.minimum_da > 100) {
        setMinimumDaError("Max da is 100");
        return true;
      }
      if (filterOptions?.minimum_da < 1) {
        setMinimumDaError("Min. da should be greater then 0");
        return true;
      } else {
        return false;
      }
    }
    if (
      filterOptions?.minimum_da === null &&
      filterOptions?.maximum_da !== null
    ) {
      if (filterOptions?.maximum_da < 1 || filterOptions?.maximum_da > 100) {
        return true;
      } else {
        return false;
      }
    }
  };

  // Checking dr range validation
  const drError = () => {
    if (
      filterOptions?.minimum_dr !== null &&
      filterOptions?.maximum_dr !== null
    ) {
      if (filterOptions?.minimum_dr > 100) {
        setMinimumDrError("Max dr is 100");
        return true;
      }
      if (filterOptions?.maximum_dr > 0 && filterOptions?.minimum_dr > 0) {
        if (filterOptions?.maximum_dr < filterOptions?.minimum_dr) {
          setMaximumDrError(
            `Max. dr should be greater then ${filterOptions?.minimum_dr}`
          );
          return true;
        } else {
          return false;
        }
      } else {
        if (filterOptions?.minimum_dr < 1) {
          setMinimumDrError("Min. dr should be greater then 0");
          return true;
        }
        if (filterOptions?.maximum_dr > 100) {
          setMaximumDrError("Max. dr should be less then 100");
          return true;
        }
      }
    }
    if (
      filterOptions?.minimum_dr === null &&
      filterOptions?.maximum_dr === null
    ) {
      return false;
    }
    if (
      filterOptions?.minimum_dr !== null &&
      filterOptions?.maximum_dr === null
    ) {
      if (filterOptions?.minimum_dr > 100) {
        setMinimumDrError("Max dr is 100");
        return true;
      }
      if (filterOptions?.minimum_dr < 1) {
        setMinimumDrError("Min. dr should be greater then 0");
        return true;
      } else {
        return false;
      }
    }
    if (
      filterOptions?.minimum_dr === null &&
      filterOptions?.maximum_dr !== null
    ) {
      if (filterOptions?.maximum_dr < 1 || filterOptions?.maximum_dr > 100) {
        return true;
      } else {
        return false;
      }
    }
  };

  // Checking spam score range validation
  const spamError = () => {
    if (
      filterOptions?.minimum_spam_score !== null &&
      filterOptions?.maximum_spam_score !== null
    ) {
      if (filterOptions?.minimum_spam_score > 100) {
        setMinimumScError("Max spam score is 100");
        return true;
      }
      if (
        filterOptions?.maximum_spam_score > 0 &&
        filterOptions?.minimum_spam_score > 0
      ) {
        if (
          filterOptions?.maximum_spam_score < filterOptions?.minimum_spam_score
        ) {
          setMaximumScError(
            `Max. spam score should be greater then ${filterOptions?.minimum_spam_score}`
          );
          return true;
        } else {
          return false;
        }
      } else {
        if (filterOptions?.minimum_spam_score < 1) {
          setMinimumScError("Min. spam score should be greater then 0");
          return true;
        }
        if (filterOptions?.maximum_spam_score > 100) {
          setMaximumScError("Max. spam score should be less then 100");
          return true;
        }
      }
    }
    if (
      filterOptions?.minimum_spam_score === null &&
      filterOptions?.maximum_spam_score === null
    ) {
      return false;
    }
    if (
      filterOptions?.minimum_spam_score !== null &&
      filterOptions?.maximum_spam_score === null
    ) {
      if (filterOptions?.minimum_spam_score > 100) {
        setMinimumScError("Max spam score is 100");
        return true;
      }
      if (filterOptions?.minimum_spam_score < 1) {
        setMinimumScError("Min. spam score should be greater then 0");
        return true;
      } else {
        return false;
      }
    }
    if (
      filterOptions?.minimum_spam_score === null &&
      filterOptions?.maximum_spam_score !== null
    ) {
      if (
        filterOptions?.maximum_spam_score < 1 ||
        filterOptions?.maximum_spam_score > 100
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const priceError = () => {
    if (
      filterOptions?.minimum_price !== null &&
      filterOptions?.maximum_price !== null
    ) {
      if (filterOptions?.minimum_price > 10000) {
        setMinimumPriceError("Max price is 10000");
        return true;
      }
      if (
        filterOptions?.maximum_price > 0 &&
        filterOptions?.minimum_price > 0
      ) {
        if (filterOptions?.maximum_price < filterOptions?.minimum_price) {
          setMaximumPriceError(
            `Max. price should be greater then ${filterOptions?.minimum_price}`
          );
          return true;
        } else {
          return false;
        }
      } else {
        if (filterOptions?.minimum_price < 15) {
          setMinimumPriceError("Min. price should be greater then 15");
          return true;
        }
        if (filterOptions?.maximum_price > 10000) {
          setMaximumPriceError("Max. price should be less then 10000");
          return true;
        }
      }
    }
    if (
      filterOptions?.minimum_price === null &&
      filterOptions?.maximum_price === null
    ) {
      return false;
    }
    if (
      filterOptions?.minimum_price !== null &&
      filterOptions?.maximum_price === null
    ) {
      if (filterOptions?.minimum_price > 10000) {
        setMinimumPriceError("Max price is 100");
        return true;
      }
      if (filterOptions?.minimum_price < 15) {
        setMinimumPriceError("Min. price should be greater then 15");
        return true;
      } else {
        return false;
      }
    }
    if (
      filterOptions?.minimum_price === null &&
      filterOptions?.maximum_price !== null
    ) {
      if (
        filterOptions?.maximum_price < 15 ||
        filterOptions?.maximum_price > 10000
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleClear = async (e, newFilterOptions) => {
    setWebLoad(true);
    e.preventDefault();
    sessionStorage.removeItem("filters");
    sessionStorage.removeItem("dates");
    setCat("All");
    setLang("All");
    setSearchUrl("");
    try {
      const data = await API.get(
        `/api/filters?website=${searchUrl}&minimum_price=${
          newFilterOptions.minimum_price ? newFilterOptions.minimum_price : ""
        }&maximum_price=${
          newFilterOptions.maximum_price ? newFilterOptions.maximum_price : ""
        }&minimum_da=${
          newFilterOptions.minimum_da ? newFilterOptions.minimum_da : ""
        }&maximum_da=${
          newFilterOptions.maximum_da ? newFilterOptions.maximum_da : ""
        }&minimum_dr=${
          newFilterOptions.minimum_dr ? newFilterOptions.minimum_dr : ""
        }&maximum_dr=${
          newFilterOptions.maximum_dr ? newFilterOptions.maximum_dr : ""
        }&minimum_spam_score=${
          newFilterOptions.minimum_spam_score
            ? newFilterOptions.minimum_spam_score
            : ""
        }&maximum_spam_score=${
          newFilterOptions.maximum_spam_score
            ? newFilterOptions.maximum_spam_score
            : ""
        }&monthly_traffic=${
          newFilterOptions.monthly_traffic
            ? newFilterOptions.monthly_traffic
            : ""
        }&service_type=${newFilterOptions.service_type.code}&language=${
          newFilterOptions.language.code
        }&category=${newFilterOptions.category_id.code}&disclosure_type=${
          newFilterOptions.disclosure_type.code
        }&link_type=${
          newFilterOptions.link_type.code
        }&minimum_created_date=&maximum_created_date=&organic_traffic=${
          newFilterOptions.organic_traffic
            ? newFilterOptions.organic_traffic
            : ""
        }&publishers_tat=${
          newFilterOptions.publishers_tat.code
        }&category_id=${cat}&lang_code=${lang}&sorting=${
          sortBy.code
        }&per_page=${page.code}&page=${currentPage}`
      );
      setWebsites(data.data.data);
      setCurrentPage(data.data.current_page);
      setTotalPages(data.data.last_page);
      setTotal(data.data.total);
      setWebLoad(false);
      setAllWeb(true);
    } catch (error) {
      setWebLoad(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    sessionStorage.setItem("filters", JSON.stringify(filterOptions));
    const pErr = priceError();
    const daErr = daError();
    const drErr = drError();
    const soError = spamError();
    if (!pErr && !daErr && !drErr && !soError) {
      setWebLoad(true);
      try {
        setSearchUrl("");
        setCat("All");
        setLang("All");
        const data = await API.get(
          `/api/filters?website=${searchUrl}&minimum_price=${
            filterOptions.minimum_price ? filterOptions.minimum_price : ""
          }&maximum_price=${
            filterOptions.maximum_price ? filterOptions.maximum_price : ""
          }&minimum_da=${
            filterOptions.minimum_da ? filterOptions.minimum_da : ""
          }&maximum_da=${
            filterOptions.maximum_da ? filterOptions.maximum_da : ""
          }&minimum_dr=${
            filterOptions.minimum_dr ? filterOptions.minimum_dr : ""
          }&maximum_dr=${
            filterOptions.maximum_dr ? filterOptions.maximum_dr : ""
          }&minimum_spam_score=${
            filterOptions.minimum_spam_score
              ? filterOptions.minimum_spam_score
              : ""
          }&maximum_spam_score=${
            filterOptions.maximum_spam_score
              ? filterOptions.maximum_spam_score
              : ""
          }&monthly_traffic=${
            filterOptions.monthly_traffic ? filterOptions.monthly_traffic : ""
          }&service_type=${filterOptions.service_type.code}&language=${
            filterOptions.language.code
          }&category=${filterOptions.category_id.code}&disclosure_type=${
            filterOptions.disclosure_type.code
          }&link_type=${filterOptions.link_type.code}&minimum_created_date=${
            dates?.length > 0 ? formatDate(new Date(dates[0])) : ""
          }&maximum_created_date=${
            dates?.length > 0 && dates[1] !== null
              ? formatDate(new Date(dates[1]))
              : ""
          }&organic_traffic=${
            filterOptions.organic_traffic ? filterOptions.organic_traffic : ""
          }&publishers_tat=${
            filterOptions.publishers_tat.code
          }&category_id=${cat}&lang_code=${lang}&sorting=${
            sortBy.code
          }&per_page=${page.code}&page=${currentPage}`
        );
        setWebsites(data.data.data);
        setCurrentPage(data.data.current_page);
        setTotalPages(data.data.last_page);
        setTotal(data.data.total);
        if (
          filterOptions?.minimum_price === null &&
          filterOptions?.maximum_price === null &&
          filterOptions?.minimum_da === null &&
          filterOptions?.maximum_da === null &&
          filterOptions?.minimum_dr === null &&
          filterOptions?.maximum_dr === null &&
          filterOptions?.minimum_spam_score === null &&
          filterOptions?.maximum_spam_score === null &&
          filterOptions?.service_type.name === "All" &&
          filterOptions?.service_type.code === "All" &&
          filterOptions?.language.name === "All" &&
          filterOptions?.language.code === "All" &&
          filterOptions?.category_id.name === "All" &&
          filterOptions?.category_id.code === "All" &&
          filterOptions?.disclosure_type.name === "All" &&
          filterOptions?.disclosure_type.code === "All" &&
          filterOptions?.link_type.name === "All" &&
          filterOptions?.link_type.code === "All" &&
          filterOptions?.organic_traffic.name === "All" &&
          filterOptions?.organic_traffic.code === "All" &&
          filterOptions?.marked_sponsored_by.name === "All" &&
          filterOptions?.marked_sponsored_by.code === "All" &&
          filterOptions?.publishers_tat.name === "All" &&
          filterOptions?.publishers_tat.code === "All" &&
          filterOptions?.publishers_rating.name === "All" &&
          filterOptions?.publishers_rating.code === "All" &&
          filterOptions?.avg_lifetime_of_links.name === "More than 70%" &&
          filterOptions?.avg_lifetime_of_links.code === 70 &&
          filterOptions?.completion_rate.name === "More than 70%" &&
          filterOptions?.completion_rate.code === 70 &&
          filterOptions?.task_with_Initial.name === "More than 70%" &&
          filterOptions?.task_with_Initial.code === 70
        ) {
          setAllWeb(true);
        } else {
          setAllWeb(false);
        }
        setWebLoad(false);
      } catch (error) {
        setWebLoad(false);
      }
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "minimum_price") {
      setMinimumPriceError("");
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else if (name === "maximum_price") {
      setMaximumPriceError("");
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else if (name === "minimum_da") {
      setMinimumDaError("");
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else if (name === "maximum_da") {
      setMaximumDaError("");
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else if (name === "minimum_dr") {
      setMinimumDaError("");
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else if (name === "maximum_dr") {
      setMaximumDaError("");
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else if (name === "minimum_spam_score") {
      setMinimumScError("");
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else if (name === "maximum_spam_score") {
      setMaximumScError("");
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else if (name === "monthly_traffic") {
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
    } else if (name === "organic_traffic") {
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
    } else {
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [e.target.name]: e.target.value,
      }));
    }
  };
  const open = Boolean(anchorEl);
  const smallScreenXS = useMediaQuery("(max-width:700px)");

  useEffect(() => {
    setWebLoad(true);
    if (da) {
      sessionStorage.setItem(
        "filters",
        JSON.stringify({ ...filterOptions, minimum_da: da === "40" ? "50" : da })
      );
    }
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    const getList = async () => {
      try {
        const data = await API.get(
          `/api/filters?website=${searchUrl}&minimum_price=${
            filterOptions.minimum_price ? filterOptions.minimum_price : ""
          }&maximum_price=${
            filterOptions.maximum_price ? filterOptions.maximum_price : ""
          }&minimum_da=${
            filterOptions.minimum_da ? filterOptions.minimum_da : ""
          }&maximum_da=${
            filterOptions.maximum_da ? filterOptions.maximum_da : ""
          }&minimum_dr=${
            filterOptions.minimum_dr ? filterOptions.minimum_dr : ""
          }&maximum_dr=${
            filterOptions.maximum_dr ? filterOptions.maximum_dr : ""
          }&minimum_spam_score=${
            filterOptions.minimum_spam_score
              ? filterOptions.minimum_spam_score
              : ""
          }&maximum_spam_score=${
            filterOptions.maximum_spam_score
              ? filterOptions.maximum_spam_score
              : ""
          }&monthly_traffic=${
            filterOptions.monthly_traffic ? filterOptions.monthly_traffic : ""
          }&service_type=${filterOptions.service_type.code}&language=${
            filterOptions.language.code
          }&category=${filterOptions.category_id.code}&disclosure_type=${
            filterOptions.disclosure_type.code
          }&link_type=${filterOptions.link_type.code}&minimum_created_date=${
            filterOptions.minimum_created_date
              ? filterOptions.minimum_created_date
              : ""
          }&maximum_created_date=${
            filterOptions.maximum_created_date
              ? filterOptions.maximum_created_date
              : ""
          }&organic_traffic=${
            filterOptions.organic_traffic ? filterOptions.organic_traffic : ""
          }&publishers_tat=${
            filterOptions.publishers_tat.code
          }&category_id=${cat}&lang_code=${lang}&sorting=${
            sortBy.code
          }&per_page=${page.code}&page=${currentPage}`
        );
        const dataCat = await API.get("/api/get-product-category");
        const modifiedArray = dataCat?.data?.data?.productcategory?.map(
          (item) => ({
            code: item.id,
            name: item.title,
          })
        );
        modifiedArray.unshift({ name: "All", code: "All" });
        setCategory(modifiedArray);
        if (data) {
          setWebLoad(false);
          setWebsites(data.data.data);
          setCurrentPage(data.data.current_page);
          setTotalPages(data.data.last_page);
          setTotal(data.data.total);
        }
      } catch (error) {
        setWebLoad(false);
      }
    };

    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localStorage.getItem("role"),
    page,
    sortBy,
    currentPage,
    cat,
    searchUrl,
    lang,
    // drr,
  ]);

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const truncateText = (text, maxLength) => {
    return text?.length <= maxLength ? text : text?.slice(0, maxLength) + "...";
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  // Date conversion
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };
  return (
    <div>
      {/* New Section */}
      <div className="d-flex align-items-center text-center page-info">
        <FontAwesomeIcon icon={faStar} size="lg" style={{ color: "#0e810c" }} />{" "}
        <p className="px-2">
          Here you can view only the websites that have successfully
          demonstrated a probability of 70 percent or higher in task performance
          on these platforms.
        </p>
      </div>
      <div className="new-filter-listing-wraper">
        <h3> Search For Verified Publishers </h3>
        <form className="row">
          <div className="col-md-3">
            <div
              className={`form-group ${
                minimumPriceError || maximumPriceError ? "form-grp-red" : ""
              }`}
            >
              {" "}
              <a href="#">
                <Tooltip
                  title="Maximum and minimum price you are ready to pay for task"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> Price Range </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="15"
                name="minimum_price"
                value={filterOptions.minimum_price}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="10000"
                name="maximum_price"
                value={filterOptions.maximum_price}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              {minimumPriceError && (
                <div className="error-message">{minimumPriceError}</div>
              )}
              {!minimumPriceError && maximumPriceError && (
                <div className="error-message">{maximumPriceError}</div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={`form-group ${
                minimumDaError || maximumDaError ? "form-grp-red" : ""
              }`}
            >
              {" "}
              <a href="#">
                <Tooltip
                  title="DA, which stands for 'Domain Authority,' is a metric commonly used in search engine optimization (SEO) and digital marketing to assess the overall authority, credibility, and potential ranking strength of a specific domain or website."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> DA RANGE </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="1"
                name="minimum_da"
                value={filterOptions.minimum_da}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="100"
                name="maximum_da"
                value={filterOptions.maximum_da}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              {minimumDaError && (
                <div className="error-message">{minimumDaError}</div>
              )}
              {!minimumDaError && maximumDaError && (
                <div className="error-message">{maximumDaError}</div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={`form-group ${
                minimumDrError || maximumDrError ? "form-grp-red" : ""
              }`}
            >
              {" "}
              <a href="#">
                <Tooltip
                  title="DR, which stands for 'Domain Rating,' is a metric commonly used in search engine optimization (SEO) and digital marketing to assess the authority and backlink profile of a specific domain or website."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> DR RANGE </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="1"
                name="minimum_dr"
                value={filterOptions.minimum_dr}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="100"
                name="maximum_dr"
                value={filterOptions.maximum_dr}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              {minimumDrError && (
                <div className="error-message">{minimumDrError}</div>
              )}
              {!minimumDrError && maximumDrError && (
                <div className="error-message">{maximumDrError}</div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={`form-group ${
                minimumScError || maximumScError ? "form-grp-red" : ""
              }`}
            >
              {" "}
              <a href="#">
                <Tooltip
                  title="Spam Score is a metric used in the field of digital marketing and search engine optimization (SEO) to assess the likelihood that a particular website or web page contains spammy or low-quality content."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> SPAM SCORE </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="1"
                name="minimum_spam_score"
                value={filterOptions.minimum_spam_score}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="100"
                name="maximum_spam_score"
                value={filterOptions.maximum_spam_score}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              {minimumScError && (
                <div className="error-message">{minimumScError}</div>
              )}
              {!minimumScError && maximumScError && (
                <div className="error-message">{maximumScError}</div>
              )}
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Maximum and minimum price you are ready to pay for task"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> MONTHLY TRAFFIC </label>
              <input
                type="number"
                className="form-control"
                placeholder="From"
                name="monthly_traffic"
                value={filterOptions.monthly_traffic}
                onChange={(e) => handleFilterChange(e)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Ahref Organic Traffic, often referred to simply as 'organic traffic,' is a key metric in website analytics that measures the volume of visitors a website receives from search engines like Google, Bing, or Yahoo."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> Ahref Organic TRAFFIC </label>
              <input
                type="number"
                className="form-control"
                placeholder="From"
                name="organic_traffic"
                value={filterOptions.organic_traffic}
                onChange={(e) => handleFilterChange(e)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Choose the most relevant categories for the website you want to promote"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> Category </label>
              <Dropdown
                name="category_id"
                value={filterOptions?.category_id}
                onChange={async (e) => {
                  e.preventDefault();
                  handleFilterChange(e);
                }}
                options={category}
                optionLabel="name"
                placeholder="Select a category"
                filter
                className="w-100 filter-custom-dropdown"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Choose between Dofollow links (the ones that can be found by search engines) and Nofollow (the ones that are protected from search engine crawlers)"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> LINKS </label>
              <Dropdown
                name="link_type"
                value={filterOptions?.link_type}
                onChange={async (e) => {
                  e.preventDefault();
                  handleFilterChange(e);
                }}
                options={link}
                optionLabel="name"
                placeholder="Select a link"
                className="w-100 filter-custom-dropdown"
              />
            </div>
          </div>
          {showAdditionalFilters && (
            <>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="hose whether you want to choose a publisher who will post your content or will create then post your content"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Service Type </label>
                  <Dropdown
                    name="service_type"
                    value={filterOptions?.service_type}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={services}
                    optionLabel="name"
                    placeholder="Select a service"
                    filter
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="Language of a website"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Language </label>
                  <Dropdown
                    name="language"
                    value={filterOptions?.language}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={languages}
                    optionLabel="name"
                    placeholder="Select a language"
                    filter
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              {/* <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The designation 'Sponsored by' is an indication that a specific backlink on a website has been paid for by an advertiser or sponsor."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Marked "Sponsored by" </label>
                  <Dropdown
                    name="disclosure_type"
                    value={filterOptions?.disclosure_type}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={disclosure}
                    optionLabel="name"
                    placeholder="Select a disclosure"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div> */}
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The 'Average Lifetime of Links' is a crucial metric in the world of website backlinks. It represents the typical duration for which backlinks, placed on a publisher's website, remain active and functional."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Average lifetime of links </label>
                  <Dropdown
                    name="average_liftime_of_links"
                    value={filterOptions?.average_liftime_of_links}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={averageLinkLifetime}
                    optionLabel="name"
                    placeholder="Select a lifetime"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The 'Publisher's TAT' (Turnaround Time) is a crucial factor in the realm of website backlinks. It refers to the time taken by publishers or website owners to complete specific tasks related to backlinks."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Publisher's TAT </label>
                  <Dropdown
                    name="publishers_tat"
                    value={filterOptions?.publishers_tat}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={publisherTAT}
                    optionLabel="name"
                    placeholder="Select a disclosure"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="This refers to a task associated with an initial website domain and a specified price."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Task with initial domain and price </label>
                  <Dropdown
                    name="task_with_Initial"
                    value={filterOptions?.task_with_Initial}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={averageLinkLifetime}
                    optionLabel="name"
                    placeholder="Select a lifetime"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="Date when site appeared on webylinks"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Added to webylinks </label>
                  <Calendar
                    className="w-100 calender-filter"
                    value={dates}
                    onChange={(e) => {
                      sessionStorage.setItem("dates", e.value);
                      setDates(e.value);
                    }}
                    selectionMode="range"
                    readOnlyInput
                    placeholder="mm/dd/yyyy - mm/dd/yyyy"
                  />
                </div>
              </div>
            </>
          )}
        </form>
        {showAdditionalFilters && (
          <div className="d-flex justify-content-end search-filter-btn">
            <button className="btn-info-count" onClick={clear}>
              Clear
            </button>
            <button className="apply-filters-button" onClick={handleSubmit}>
              Apply Filters
            </button>
          </div>
        )}
        {!showAdditionalFilters && (
          <div className="d-flex justify-content-center load-more-filters">
            <button onClick={toggleAdditionalFilters}>
              {" "}
              LOAD MORE ADVANCE FILTERS <img src={Plus} alt="plus" />{" "}
            </button>
          </div>
        )}
      </div>
      <div
        className={`d-flex search-results-head ${
          smallScreenXS
            ? "flex-column justify-content-start"
            : "justify-content-between"
        } filter-header`}
      >
        {allWeb &&
        cat === "All" &&
        lang === "All" &&
        searchUrl === "" &&
        !sessionStorage.getItem("filters") ? (
          <h2>
            {`All Websites`}
            <span>({total})</span>
          </h2>
        ) : (
          <h2>
            Found: <span>{websites?.length > 0 ? total : 0} </span>
            {`Website${websites?.length < 2 ? "" : "s"}`}
          </h2>
        )}
        <div className="d-flex sort-container">
          <div className="d-flex mx-2 justify-content-center align-items-center sorting-menu">
            <label className="mx-2">Show</label>{" "}
            <Dropdown
              name="page"
              value={page}
              onChange={async (e) => {
                e.preventDefault();
                setCurrentPage(1);
                setPage({ name: e.value.name, code: e.value.code });
              }}
              options={pages}
              optionLabel="name"
              placeholder="Select page number"
              className="role-select-options-page"
            />
          </div>
          <div className="d-flex justify-content-center align-items-center sorting-menu">
            <label className="mx-2">Sort by</label>{" "}
            <Dropdown
              name="sortBy"
              value={sortBy}
              onChange={async (e) => {
                e.preventDefault();
                setSortBy({ name: e.value.name, code: e.value.code });
              }}
              options={sort}
              optionLabel="name"
              placeholder="Select sort type"
              className="role-select-options-page"
            />
          </div>
        </div>
      </div>
      <div className="website-listed-main-wrap">
        <div className="table-responsive">
          <table width="100%" border="0">
            <tr height="50" className="fields-table-wrap">
              <td width={isMobileL ? "6%" : "12%"}>
                <div className="form-main-table">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="URL"
                    value={searchUrl}
                    onChange={(e) => setSearchUrl(e.target.value)}
                  />
                  <button disabled>
                    <img src={Search} alt="search" />
                  </button>
                </div>
              </td>
              <td width={isMobileM ? "6%" : "8%"}>
                <div className="form-main-table">
                  <select
                    className="form-control"
                    value={cat}
                    onChange={async (e) => {
                      await setCat(e.target.value);
                    }}
                  >
                    <option value="All">Categories</option>
                    {category?.slice(1).map((res, id) => (
                      <option key={id} value={res.code}>
                        {res.name}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
              <td width="6%">
                <div className="form-main-table">
                  <select
                    className="form-control"
                    value={drr?.da}
                    onChange={(e) =>
                      setSortBy({ name: e.target.value, code: e.target.value })
                    }
                  >
                    <option value="All">DA</option>
                    <option value="lowest_da">Low to High</option>
                    <option value="highest_da">Hight to Low</option>
                  </select>
                </div>
              </td>
              <td width="6%">
                <div className="form-main-table">
                  <select
                    className="form-control"
                    value={drr?.dr}
                    onChange={(e) =>
                      setSortBy({ name: e.target.value, code: e.target.value })
                    }
                  >
                    <option value="All">DR</option>
                    <option value="lowest_dr">Low to High</option>
                    <option value="highest_dr">Hight to Low</option>
                  </select>
                </div>
              </td>
              <td width="6%">
                <div className="form-main-table">
                  <select
                    className="form-control"
                    value={lang}
                    onChange={(e) => setLang(e.target.value)}
                  >
                    <option value="All">Language</option>
                    {languages?.map((res, id) => (
                      <option key={id} value={res.code}>
                        {res.name}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
              <td width={isMobileL ? "18%" : isMobileM ? "16%" : "8%"}>
                <div className="form-main-table">
                  <select
                    className="form-control"
                    value={drr?.traffic}
                    onChange={(e) =>
                      setSortBy({ name: e.target.value, code: e.target.value })
                    }
                  >
                    <option value="All">Traffic</option>
                    <option value="lowest_monthly_traffic">Low to High</option>
                    <option value="highest_monthly_traffic">
                      Hight to Low
                    </option>
                  </select>
                </div>
              </td>
            </tr>
            {!webload &&
              websites !== null &&
              Array.isArray(websites) &&
              websites?.length > 0 &&
              websites?.map((res, id) => (
                <tr key={id} className={`${id % 2 === 0 ? "" : "light-grey"}`}>
                  <td>
                    <div className="d-flex align-items-center">
                      {currentPage === 1 &&
                      (balance?.is_paid === 0 || balance === 0) &&
                      id > 4 ? (
                        <h6
                          className="web-list-url"
                          onClick={() => {
                            Swal.fire({
                              title: "Add Funds",
                              html: `
            <p style="font-size: 13px;">Add $25 to access our list. The amount will be credited to your wallet for future use</p>
          `,
                              confirmButtonText: "Add Funds",
                              showCancelButton: true,
                              cancelButtonText: "Cancel",
                              customClass: {
                                container: "custom-swal",
                                title: "modal-title",
                                content: "modal-content",
                                confirmButton: "modal-confirm-button",
                                cancelButton: "modal-cancel-button-swal",
                                actions: "modal-actions",
                              },
                              buttonsStyling: false,
                              allowOutsideClick: false,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                openModal();
                              }
                            });
                          }}
                        >
                          URL is hidden
                        </h6>
                      ) : currentPage > 1 &&
                        (balance?.is_paid === 0 || balance === 0) ? (
                        <h6
                          className="web-list-url"
                          onClick={() => {
                            Swal.fire({
                              title: "Add Funds",
                              html: `
            <p style="font-size: 13px;">Add $25 to access our list. The amount will be credited to your wallet for future use</p>
          `,
                              confirmButtonText: "Add Funds",
                              showCancelButton: true,
                              cancelButtonText: "Cancel",
                              customClass: {
                                container: "custom-swal",
                                title: "modal-title",
                                content: "modal-content",
                                confirmButton: "modal-confirm-button",
                                cancelButton: "modal-cancel-button-swal",
                                actions: "modal-actions",
                              },
                              buttonsStyling: false,
                              allowOutsideClick: false,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                openModal();
                              }
                            });
                          }}
                        >
                          URL is hidden
                        </h6>
                      ) : (
                        <h6
                          className="web-list-url"
                          onClick={() => window.open("https://" + res?.url)}
                        >
                          {truncateText(res?.url, isMobileM ? 15 : 20)}
                        </h6>
                      )}
                      <Tooltip
                        title="Publisher is verified"
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img src={VIcon} alt="verify-icon" />
                          {res?.Confirmation_status === "Owner" && (
                            <img
                              src={Avatar}
                              className="owner-img"
                              alt="owner"
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                      {/* <p>{res?.Confirmation_status}</p> */}
                      <Badge
                        value={res?.Confirmation_status}
                        severity="warning"
                      ></Badge>
                    </div>
                    {/* <div className="my-2 contributer-not-owner"> */}
                    {/* </div> */}
                    <span>
                      {" "}
                      <img src={Limg} alt="link" />
                      Max {res?.number_of_links}{" "}
                      <strong>
                        {res?.link_type === 0
                          ? "NoFollow links"
                          : "DoFollow links"}
                      </strong>{" "}
                    </span>{" "}
                    <span>
                      {" "}
                      <img src={Gear} alt="gear" />
                      Turnaround Time:{" "}
                      <strong>
                        {res?.tat} day{res?.tat > 1 ? "s" : ""}
                      </strong>{" "}
                    </span>{" "}
                    <span>
                      {" "}
                      <img src={Clock} alt="clock" />
                      Spam Score <strong>{res?.spam_score}</strong>
                    </span>
                  </td>
                  <td>
                    <div className="category-list-wrap">
                      {res?.categories?.map((res, id) => (
                        <span className="mx-1" key={id}>
                          {res?.title}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td>
                    <div className="dr-tex-wrap">
                      {" "}
                      <span>
                        {" "}
                        DA <strong>{res?.da}</strong>
                      </span>{" "}
                    </div>
                  </td>
                  <td>
                    <div className="dr-tex-wrap">
                      {" "}
                      <span>
                        {" "}
                        DR <strong>{res?.dr}</strong>
                      </span>{" "}
                    </div>
                  </td>
                  <td>
                    <div className="language-wrap">
                      <CountryFlag
                        countryCode={
                          languages.find((lang) => lang.code === res?.language)
                            ?.countryCode
                        } // Use the language code from your data
                        style={{ width: "25px" }}
                        svg
                      />
                      <span> {res?.language}</span>
                    </div>
                  </td>
                  <td>
                    <div className="btn-mounth-track">
                      <h5>
                        {" "}
                        Monthly Traffic{" "}
                        <span>
                          {" "}
                          <img src={Graph} alt="graph" />{" "}
                          {res?.monthly_traffic && res.monthly_traffic > 1000
                            ? res?.monthly_traffic
                            : `< 1000`}{" "}
                        </span>{" "}
                      </h5>
                      {user?.user?.wl_users_header_id !==
                        res?.user?.wl_users_header_id && (
                        <div
                          className="button-price-wrap"
                          onClick={async (e) => {
                            e.preventDefault();

                            if (user?.is_verified === 0) {
                              Swal.fire({
                                icon: "info",
                                text: "Please verify your account to perform this task",
                                allowOutsideClick: false,
                              });
                            } else {
                              if (
                                (currentPage === 1 &&
                                  (balance?.is_paid === 0 || balance === 0) &&
                                  id <= 4) ||
                                balance?.is_paid === 1
                              ) {
                                localStorage.setItem("show", true);
                              } else {
                                localStorage.setItem("show", false);
                              }

                              localStorage.setItem(
                                "selectedSubRoute",
                                `/dashboard/performer-list/${res?.id}`
                              );
                              navigate(`/dashboard/buy-form/${res?.id}`);
                            }
                          }}
                        >
                          {" "}
                          <a> Buy Post </a>{" "}
                          <strong>
                            {" "}
                            $
                            {res?.service_type
                              ?.split(",")
                              .map((item) => item.trim())[0] === "1"
                              ? res?.linkInsertion_amount
                              : res?.service_type
                                  ?.split(",")
                                  .map((item) => item.trim())[0] === "2"
                              ? res?.guestPost_amount
                              : 0}{" "}
                          </strong>{" "}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </table>
          {webload && (
            <div className="w=100 d-flex justify-content-center align-items-center">
              <CircularProgress
                style={{
                  width: "40px",
                  height: "40px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              />
            </div>
          )}
          {!webload && (websites === null || websites?.length === 0) && (
            <NoData listing={true} />
          )}
          <div>
            {total > page.code && (
              <Paginator
                first={currentPage * rows - rows}
                rows={rows}
                totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
                onPageChange={onPageChange}
              />
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <AddUrlFunds
          isOpen={isModalOpen}
          onClose={closeModal}
          user={props.profile}
          transaction={() => {
            return;
          }}
        />
      )}
    </div>
  );
};

export default BuyerDashboard;
