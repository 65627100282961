import React, { useEffect, useState, useRef } from "react";
import {
  useMediaQuery,
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../../assets/images/info-icon-new.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Editor } from "@tinymce/tinymce-react";
import { MultiSelect } from "primereact/multiselect";
import { useDispatch, useSelector } from "react-redux";
import { BreadCrumb } from "primereact/breadcrumb";
import API from "../../../api/ClientApi";
import Swal from "sweetalert2";
import "../styles/orderArticle.css";
import Plus from "../../../assets/images/plus.svg";
import { userBalance } from "../../../actions/walletActions";
import AddFundsModal from "../../../components/AddFundsModal/AddFundsModal";

const OrderArticle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.profile);
  const formRef = useRef(null);
  const form2Ref = useRef(null);
  const form3Ref = useRef(null);
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [load, setLoad] = useState(false);
  const [catError, setCatError] = useState(false);
  const [cError, setCError] = useState(false);
  const [kError, setKError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wordActive, setWordActive] = useState(0);
  const items = [
    { label: "Content purchase", url: "/dashboard/buyer/content" },
    { label: "Order Article" },
  ];
  const home = { label: "Home", url: "/dashboard/website-list" };
  const [buyData, setBuyData] = useState({
    contents: "",
    total_amount: 0,
    category_id: [1],
    language: { name: "English", code: "English" },
    suggestion: "",
    keywords: "",
    content_goal: "",
    target_audience: "",
    sample_content: "",
  });

  const [selectedCategories, setSelectedCategories] = useState([]);
  const category = [
    { name: "Bussiness", code: 1 },
    { name: "Traveling", code: 3 },
    { name: "Sports", code: 2 },
  ];
  const fresh = () => {
    setBuyData((prevData) => ({
      ...prevData,
      contents: "",
      total_amount: 0,
      category_id: 0,
      language: "english",
      suggestion: "",
      keywords: "",
      content_goal: "",
      target_audience: "",
      sample_content: "",
    }));
    setWordActive(0);
  };

  const handleWords = (id) => {
    setWordActive(id);
  };

  const handleChange = (e) => {
    setKError("");
    e.preventDefault();
    setBuyData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  // Footer temp. for multiselect
  const handleOkButtonClick = () => {
    // Close the multiselect dropdown
    if (multiSelectRef.current) {
      multiSelectRef.current.hide();
    }
  };

  const panelFooterTemplate = () => {
    return (
      <div className="mb-2 w-100 d-flex justify-content-center">
        <button
          className="active-offer-btn offer-accept-pub-btn"
          onClick={handleOkButtonClick}
        >
          OK
        </button>
      </div>
    );
  };

  const multiSelectRef = useRef(null);

  // Editor
  const handleEditorChange = (content, editor) => {
    setCError(false);
    setBuyData((prevData) => ({ ...prevData, contents: content }));
  };

  // Submitting
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedCategories?.length === 0) {
      if (form3Ref.current) {
        form3Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      setCatError(true);
    }

    if (buyData?.keywords === "") {
      formRef.current.scrollIntoView({ behavior: "smooth" });
      setKError(true);
    }

    if (buyData?.contents === "") {
      form2Ref?.current?.scrollIntoView({ behavior: "smooth" });
      setCError(true);
    }

    // Proceed with submission only if validation conditions are met
    if (
      buyData?.keywords !== "" &&
      buyData?.contents !== "" &&
      selectedCategories?.length !== 0
    ) {
      try {
        setLoad(true);

        const config = {
          headers: {
            "Content-type": "multipart/form-data", // Since we now submit images as well
          },
        };

        const modifiedData = {
          ...buyData,
          order_type: "Article",
          words:
            wordActive === 0
              ? "100"
              : wordActive === 1
              ? "1000"
              : wordActive === 2
              ? "1500"
              : "2000",
          total_amount: setPrice(),
          category_id:
            selectedCategories === null
              ? [1]
              : selectedCategories.map((category) => category.code),
        };

        const payload = {
          ...modifiedData,
          category_id: `[${modifiedData.category_id.join(", ")}]`,
        };

        // Make the POST request using Axios
        const response = await API.post(
          `${process.env.REACT_APP_BACKEND_API}/api/article-order?user_type=1`,
          payload,
          config
        );

        // Handle the response if needed
        fresh();
        navigate("/dashboard/buyer/content");
        Swal.fire({
          title: "Success!",
          html: "Article ordered successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
        dispatch(userBalance(localStorage.getItem("role")));
      } catch (error) {
        // Handle errors
        Swal.fire({
          title: "Add Funds",
          html: `
            <p style="font-size: 13px;">You have insufficient balance to perform this action, please add funds</p>
          `,
          confirmButtonText: "Add Funds",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          customClass: {
            title: "modal-title",
            content: "modal-content",
            confirmButton: "modal-confirm-button",
            cancelButton: "modal-cancel-button-swal",
            actions: "modal-actions",
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            openModal();
          }
        });
      } finally {
        setLoad(false);
      }
    }
  };

  const setPrice = () => {
    return (
      1 *
      (wordActive === 0
        ? 25
        : wordActive === 1
        ? 50
        : wordActive === 2
        ? 75
        : 100)
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
  }, [localStorage.getItem("role")]);

  const isCatMobile = useMediaQuery("(max-width: 1000px)");

  return (
    <div className="buyer-form-main-wraper">
      <BreadCrumb model={items} home={home} />
      <div className="heading-info-wrap heading-order-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Order Article </h2>
            <p>
              {" "}
              Benefit from the expertise of over 15 premium copywriters at
              Webylink, who will craft exceptional and unique article customized
              according to your needs.
            </p>
          </div>
        </div>
      </div>
      <Accordion className="accordion-filter accordion-global">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-summery"
        >
          <div className="w-100 d-flex justify-content-between filter-header">
            <h2>HOW IT WORKS?</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <div className="accordion-row">
            <div className="d-flex flex-column align-items-start accordion-row-col">
              <h6 className="order-article-faq-q">
                In what languages is the content available?
              </h6>
              For now , the articles available at Webylink is in English only
            </div>
            <div className="d-flex flex-column align-items-start accordion-row-col">
              <h6 className="order-article-faq-q">
                By whom the content is written?
              </h6>
              Professional writers are hired to write your article.After that it
              is proofread by moderators/experts.
            </div>
            <div className="d-flex flex-column align-items-start accordion-row-col">
              <h6 className="order-article-faq-q">
                What is the expected time frame for completing my article?
              </h6>
              The standard time frame for article completion and delivery is
              within 5 working days.
            </div>
            <div className="d-flex flex-column align-items-start accordion-row-col">
              <h6 className="order-article-faq-q">
                Can I request for changes in an articles?
              </h6>
              Certainly, you have the option to do so. We provide one
              complimentary revision request for each article. Please be aware
              that your request must be made within 3 days from the date of
              article is ordered.
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="buyer-form-wraper">
        <h4> * Fields are required </h4>
        <form>
          <div className="single-word-group">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center single-select-word">
                  <label className="d-flex align-items-center">
                    {" "}
                    Word Count*{" "}
                    <Tooltip
                      title="Set your desired length for the article. Keep in mind
                        that it will directly impact the final price"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>

                  <ul className="word-count-list wc-list">
                    <li
                      className={`word-select ${
                        wordActive === 0 ? "single-select-active-0" : ""
                      }`}
                      onClick={() => handleWords(0)}
                    >
                      {" "}
                      500 words{" "}
                    </li>
                    <li
                      className={`word-select ${
                        wordActive === 1 ? "single-select-active-1" : ""
                      }`}
                      onClick={() => handleWords(1)}
                    >
                      {" "}
                      1000 words{" "}
                    </li>
                    <li
                      className={`word-select ${
                        wordActive === 2 ? "single-select-active-2" : ""
                      }`}
                      onClick={() => handleWords(2)}
                    >
                      {" "}
                      1500 words{" "}
                    </li>
                    <li
                      className={`word-select ${
                        wordActive === 3 ? "single-select-active-3" : ""
                      }`}
                      onClick={() => handleWords(3)}
                    >
                      {" "}
                      2000 words{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group cat-group cat-group-article">
            <div className="row">
              <div
                className={`cat-wrapper ${
                  !isCatMobile ? "col-md-6" : "col-md-12"
                } ${catError ? "cat-err-wrapper" : ""}`}
              >
                <div className="d-flex filed-form-new-wrap" ref={form3Ref}>
                  <label className="d-flex align-items-center">
                    {" "}
                    Category *{" "}
                    <Tooltip
                      title="In which business field do you require an article? We
                        will contact a copywriter with the most professional
                        expertise in that field to compose your article."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton
                        size="small"
                        className="info-icon"
                        pTooltip="Enter your username"
                        tooltipPosition="top"
                      >
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>

                  <MultiSelect
                    value={selectedCategories}
                    onChange={(e) => {
                      setCatError(false);
                      setSelectedCategories(e.value);
                    }}
                    options={category}
                    optionLabel="name"
                    filter
                    placeholder="Select categories"
                    className={`multi-article-cat-select ${
                      catError ? "err-outline-select" : "prime-mu-select"
                    }`}
                    panelFooterTemplate={panelFooterTemplate}
                    ref={multiSelectRef}
                  />
                </div>
                {catError && (
                  <p className="cat-error">Please select a category</p>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex filed-form-area-wrap">
                  <label className="t-suggest-area">
                    {" "}
                    Title suggestion{" "}
                    <Tooltip
                      title="You can write a headline or allow our copywriters to
                        create one for you, following to the best practices for
                        attention-grabbing titles."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <textarea
                    multiline
                    rows={4}
                    variant="outlined"
                    className="text-area-control requirement-text-area"
                    fullWidth
                    name="suggestion"
                    placeholder="Provide title suggestion if you have any"
                    value={buyData.suggestion}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-12 keyword-wrapper">
                <div className="d-flex filed-form-new-wrap" ref={formRef}>
                  <label className="d-flex align-items-center keyword-label">
                    {" "}
                    Keywords*{" "}
                    <Tooltip
                      title="You can write a headline or allow our copywriters to
                        create one for you, following to the best practices for
                        attention-grabbing titles."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <input
                    type="text"
                    className={`${
                      kError ? "err-outline" : "text-area-control"
                    }`}
                    fullWidth
                    name="keywords"
                    placeholder="Separate each keyword with comma"
                    value={buyData.keywords}
                    onChange={handleChange}
                  />
                </div>
                {kError && <p className="key-error">The field required</p>}
              </div>
            </div>
          </div>
          <div className="form-group editor-container">
            <div className="row">
              <div className="col-md-12 c-wrapper">
                <div
                  className={`d-flex filed-form-wrap ${cError ? "err-editor" : ""}`}
                  ref={formRef}
                >
                  <label className="content-label">
                    {" "}
                    Brief requirements *{" "}
                    <Tooltip
                      title="Place your content like an article in this section.If the
                  publisher has not indicated, otherwise you can insert upto 3
                  links (URLs and corresponding anchor text) within your
                  content. Kindly enter the identical URLs and anchor text in
                  the ‘URL’ and ‘Anchor text’ fields below,as they will be
                  utilized to check performance of your tasks to your
                  specifications."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <Editor
                    apiKey="rw57q63adwugi7s974bxs2bwaqna6dd3u8himamgjfsf8ax2"
                    initialValue=""
                    init={{
                      height: 300,
                      menubar: false,
                      selector: "textarea",
                      plugins:
                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                      toolbar:
                        "undo redo | blocks | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent",
                      content_style:
                        "body { font-family: 'Arial', sans-serif; font-size: 14px }",
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </div>
                {cError && <p className="c-error">Content cannot be blank</p>}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex filed-form-new-wrap">
                  <label className="d-flex align-items-center">
                    {" "}
                    Content goal{" "}
                    <Tooltip
                      title="Define the objective/goal, whether it's educating readers,
                      enhancing brand awareness, or another goal, and allow our
                      copywriters to accomplish it through the persuasive use of
                      language."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <input
                    type="text"
                    className="text-area-control"
                    fullWidth
                    name="content_goal"
                    value={buyData.content_goal}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex filed-form-new-wrap">
                  <label className="d-flex align-items-center">
                    {" "}
                    Target Audience{" "}
                    <Tooltip
                      title="Specify the target audience you aim to connect with,
                  helping our copywriters in organizing the article more
                  effectively with the readers' expectations."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>

                  <input
                    type="text"
                    className="text-area-control"
                    fullWidth
                    name="target_audience"
                    value={buyData.target_audience}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex filed-form-new-wrap">
                  <label className="d-flex align-items-center">
                    {" "}
                    Sample Content{" "}
                    <Tooltip
                      title="Give list of examples so that copywriter must understand
                    that what you have in mind."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>

                  <input
                    type="text"
                    className="text-area-control"
                    fullWidth
                    name="sample_content"
                    placeholder="Separate each link to a sample piece of content with a comma"
                    value={buyData.sample_content}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <button className="d-flex price-info-wrap">
            {" "}
            <p>
              Task price<span className="price-info-price">${setPrice()}</span>
            </p>
          </button>
          <div className="btn-form-buyer">
            <Button
              className="send-btn-form send-btn-article"
              type="submit"
              onClick={handleSubmit}
            >
              {" "}
              {load ? (
                <CircularProgress className="text-white" />
              ) : (
                "Order Article"
              )}{" "}
            </Button>
          </div>
        </form>
      </div>
      <AddFundsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        user={user}
        transaction={() => {
          return;
        }}
      />
    </div>
  );
};

export default OrderArticle;
