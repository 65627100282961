import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import Home from "./pages/Home/Home";
import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import NavbarWrapper from "./components/NavbarWrapper";
import VerifyEmail from "./pages/verifyEmail/VerifyEmail";
import EmailSubmitStep from "./pages/ForgotPassword/components/EmailSubmitStep";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import { userProfile } from "./actions/userActions";
import MyWebsites from "./pages/MyWebsites/MyWebsites";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./app.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { UserbackProvider } from "@userback/react";
import Contact from "./pages/Contact/Contact";
import NotFound from "./pages/NotFound/NotFound";
import TawkTo from "./components/TawkTo";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

function App() {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if(localStorage.getItem("access_token")){
  //   dispatch(userProfile());
  //   }
  // },[])

  //   <UserbackProvider token="41640|84541|lKNA4yIknQvZPzWZf8abTI0Nj">
  //   <Dashboard />
  // </UserbackProvider>

  return (
    <BrowserRouter>
      <NavbarWrapper />
      <Elements stripe={stripePromise}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/contact" element={<Contact />} exact />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/api/verify-email/:id" element={<VerifyEmail />} />
          <Route path="/forget-password" element={<EmailSubmitStep />} />
          <Route
            path="/api/reset-password/:hash"
            element={<ForgotPassword />}
          />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </Elements>
    </BrowserRouter>
  );
}

export default App;
