import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { DateTime } from "luxon";
import { Dropdown } from "primereact/dropdown";

const DaySelect = (props) => {
  const minDate = new Date(); // Set to today's date

  return (
    <div className="w-100 d-flex flex-column">
      {" "}
      {props?.active === 0 ? (
        <>
          <Calendar
            value={props.selectedDate}
            onChange={(e) => {
              if (props.selectedTimezone === null) {
                props.handleTimezoneChange(DateTime.local().zoneName);
              }
              props.setSelectedDate(e.value);
            }}
            minDate={minDate}
            readOnlyInput
            dateFormat="yy-mm-dd"
            inline
            showWeek
          />
          {props.selectedDate !== null && (
            <div className="mt-4 w-100 d-flex justify-content-between consultation-wrapper">
              <div className="w-100 consultaion-selection-wrapper">
                <select
                  value={props.selectedTimezone}
                  onChange={(e) => props.handleTimezoneChange(e.target.value)}
                  className="consultation-dropdown"
                >
                  {props.timezones.map((tz) => (
                    <option key={tz.value} value={tz.value}>
                      {tz.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-100 consultaion-selection-wrapper">
                <select
                  value={props.time}
                  onChange={(e) => {
                    props.setTime(e.target.value);
                  }}
                  className="consultation-dropdown"
                >
                  {props.times.map((t) => (
                    <option key={t.value} value={t.value}>
                      {t.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="row">
          <div className="col-md-5">
            <h4 className="mb-3">Schedule fit for you</h4>
            <ul className="mb-3 terms-condition-list">
              <li className="mb-3">30 min</li>
              <li className="mb-3">
                Web conferencing details provided upon confirmation
              </li>
              <li className="mb-3">
                {`${props.time} to ${props.endTime(props.time, 30)},`}
                {props.dateC}
              </li>
              <li className="mb-3">Timezone: {props.selectedTimezone}</li>
            </ul>
          </div>
          <div className="col-md-7">
            <div className="w-100 d-flex flex-column">
              <input
                className={`consultation-text ${
                  props.nError ? "consultation-red-outline" : ""
                }`}
                name="name"
                type="text"
                placeholder="Name*"
                value={props?.name}
                onChange={(e) => {
                  props.setNError(false);
                  props?.setName(e.target.value);
                }}
              />
              {props.nError && (
                <p className="mt-1" style={{ color: "red" }}>
                  Please enter name
                </p>
              )}
              <input
                className={`mt-3 consultation-text ${
                  props.eError ? "consultation-red-outline" : ""
                }`}
                name="email"
                type="email"
                placeholder="Email*"
                value={props?.email}
                onChange={(e) => {
                  props.setEError(false);
                  props?.setEmail(e.target.value);
                }}
              />
              {props.eError && (
                <p className="mt-1" style={{ color: "red" }}>
                  Please enter a valid email
                </p>
              )}
              <textarea
                rows={4}
                name="guests"
                className="mt-3 consultation-area"
                placeholder="Guests"
                value={props.guestsInput}
                onChange={props.handleGuestsChange}
              ></textarea>
              <textarea
                rows={5}
                name="details"
                className="mt-3 consultation-area"
                placeholder="Add any additional detail that will be useful in our meeting"
                value={props.details}
                onChange={(e) => props.setDetails(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DaySelect;
