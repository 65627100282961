import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  CircularProgress,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import Info from "../../../assets/images/info-icon.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "../../../assets/images/info-icon-new.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "../styles/buyerForm.css";
import API from "../../../api/ClientApi";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import validUrl from "valid-url";
import AddFundsModal from "../../../components/AddFundsModal/AddFundsModal";
import Swal from "sweetalert2";
import { userBalance } from "../../../actions/walletActions";
import SummeryModal from "./SummeryModal";
import DropzoneComponent from "react-dropzone-component";
import "react-dropzone-component/styles/filepicker.css"; // Import React-Dropzone CSS
import "dropzone/dist/min/dropzone.min.css"; // Import Dropzone.js CSS

const BuyerForm = () => {
  const dropzoneRef = useRef();
  const { id } = useParams();
  const { user } = useSelector((state) => state.profile);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [size, setSize] = useState(0);
  const [webUrl, setWebUrl] = useState("");
  const { balance } = useSelector((state) => state.balance);
  const formRef = useRef(null);
  const form2Ref = useRef(null);
  const form4Ref = useRef(null);
  const form5Ref = useRef(null);
  const form6Ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cError, setCError] = useState(false);
  const [dload, setDload] = useState(false);
  const [uError, setUError] = useState(false);
  const [websiteData, setWebsiteData] = useState(null);
  const [wload, setWload] = useState(false);
  const [wordActive, setWordActive] = useState(1);
  const [words, setWords] = useState([]);
  const [wordId, setWordId] = useState(false);
  const [load, setLoad] = useState(false);
  const [active, setActive] = useState(0);
  const [serviceActive, setServiceActive] = useState(0);
  const [publisherActive, setPublisherActive] = useState(0);
  const [disable, setDisable] = useState(false);
  const [typeDisable, setTypeDisable] = useState(false);
  const [catError, setCatError] = useState(false);
  const [tError, setTError] = useState(false);
  const [kError, setKError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invalidUrlIndexes, setInvalidUrlIndexes] = useState([]);
  const [invalidAnchorIndexes, setInvalidAnchorIndexes] = useState([]);
  const [serviceArray, setServiceArray] = useState([]);
  const [wordAmount, setWordAmount] = useState(0);
  const [openSummery, setOpenSummery] = useState(false);
  const [filerr, setFileErr] = useState("");
  const [buyData, setBuyData] = useState({
    product_id: id,
    content: "",
    url: [""],
    anchor_text: [""],
    special_requirements: "",
    total_amount: 0,
    category_id: 0,
    language: { name: "English", code: "English" },
    suggestion: "",
    keywords: "",
    order_type: "Product",
    article_wordCount_id: "100",
  });
  const [selectedCategories, setSelectedCategories] = useState([]);

  const fresh = () => {
    setBuyData((prevData) => ({
      ...prevData,
      content: "",
      url: [""],
      anchor_text: [""],
      special_requirements: "",
      total_amount: 0,
      category_id: 0,
      language: "english",
      suggestion: "",
      keywords: "",
      order_type: "Product",
    }));
    setWordActive(0);
  };

  const handleSummeryClose = async () => {
    setOpenSummery(false);
  };

  // Editor
  const handleEditorChange = (content, editor) => {
    setCError(false);
    setBuyData((prevData) => ({ ...prevData, content: content }));
  };

  // Url
  const addUrlInput = () => {
    if (buyData.url.length < 3) {
      setBuyData((prevData) => ({
        ...prevData,
        url: [...prevData.url, ""],
      }));
    }
  };

  // Remove url
  const removeUrlInput = (index) => {
    setBuyData((prevData) => ({
      ...prevData,
      url: prevData.url.filter((_, i) => i !== index),
    }));
  };

  const handleUrlChange = (e, index) => {
    setUError(false);
    const newUrls = [...buyData.url];
    newUrls[index] = e.target.value;
    // Check if the index is in the invalidUrlIndexes array and remove it
    if (invalidUrlIndexes.includes(index)) {
      const updatedInvalidUrlIndexes = invalidUrlIndexes.filter(
        (i) => i !== index
      );
      setInvalidUrlIndexes(updatedInvalidUrlIndexes);
    }
    setBuyData((prevData) => ({
      ...prevData,
      url: newUrls,
    }));
  };
  //-------------------------------------------------

  // Anchor text
  const addAnchorInput = () => {
    if (buyData.anchor_text.length < parseInt(websiteData?.number_of_links)) {
      setBuyData((prevData) => ({
        ...prevData,
        anchor_text: [...prevData.anchor_text, ""],
      }));
    }
    if (buyData.url.length < parseInt(websiteData?.number_of_links)) {
      setBuyData((prevData) => ({
        ...prevData,
        url: [...prevData.url, ""],
      }));
    }
  };

  // Remove anchor
  const removeAnchorInput = (index) => {
    setBuyData((prevData) => ({
      ...prevData,
      anchor_text: prevData.anchor_text.filter((_, i) => i !== index),
    }));
    setBuyData((prevData) => ({
      ...prevData,
      url: prevData.url.filter((_, i) => i !== index),
    }));
  };

  const handleAnchorChange = (e, index) => {
    const newAnchors = [...buyData.anchor_text];
    newAnchors[index] = e.target.value;
    if (invalidAnchorIndexes.includes(index)) {
      const updatedInvalidUrlIndexes = invalidAnchorIndexes.filter(
        (i) => i !== index
      );
      setInvalidAnchorIndexes(updatedInvalidUrlIndexes);
    }
    if (invalidUrlIndexes.includes(index)) {
      const updatedInvalidUrlIndexes = invalidUrlIndexes.filter(
        (i) => i !== index
      );
      setInvalidUrlIndexes(updatedInvalidUrlIndexes);
    }
    setBuyData((prevData) => ({
      ...prevData,
      anchor_text: newAnchors,
    }));
  };

  //-------------------------------------------------------

  const handleSaveDraft = async (e) => {
    e.preventDefault();
    let formData = [];

    selectedFiles.forEach((file, index) => {
      formData.push(file?.file);
    });
    if (serviceActive === 0 && publisherActive === 1) {
      if (buyData?.keywords === "") {
        form5Ref.current.scrollIntoView({ behavior: "smooth" });
        setKError(true);
      }
      if (selectedCategories?.length === 0) {
        form4Ref.current.scrollIntoView({ behavior: "smooth" });
        setCatError(true);
      }
    }

    // Check content and URL validation
    if (
      (buyData?.content === "" || buyData?.content === null) &&
      serviceActive === 2 &&
      active === 0
    ) {
      formRef?.current?.scrollIntoView({ behavior: "smooth" });
      setCError(true);
    }

    // if (publisherActive === 1 && buyData?.keyword === "") {
    //   setKError(true);
    // }

    if (
      buyData?.url?.some((url, index) => {
        if (index === 0) {
          if (url === "" && buyData?.anchor_text[index] === "") {
            setInvalidAnchorIndexes((prevIndexes) => [...prevIndexes, index]);
          }
        }
        if (url === "" || !isValidUrl(url)) {
          if (url === "" && buyData?.anchor_text[index] === "") {
            return false;
          } else {
            setInvalidUrlIndexes((prevIndexes) => [...prevIndexes, index]); // Add the index to the array
          }
        }
        if (
          url !== "" &&
          isValidUrl(url) &&
          buyData?.anchor_text[index] === ""
        ) {
          setInvalidAnchorIndexes((prevIndexes) => [...prevIndexes, index]);
        }
      })
    ) {
      setUError(true);
    }
    const contentBool =
      (buyData?.content === "" || buyData?.content === null) &&
      serviceActive === 1;
    const contentBool2 =
      buyData?.content !== "" &&
      buyData?.content !== null &&
      serviceActive !== 1;
    const contentBool3 =
      (buyData?.content === null || buyData?.content === "") && active === 1;
    const anchorBool = buyData?.url?.some((url, index) => {
      if (index === 0) {
        if (url === "" && buyData?.anchor_text[index] === "") {
          return true;
        }
      }
      if (url !== "" && isValidUrl(url) && buyData?.anchor_text[index] === "") {
        return true;
      }
      if (url === "" || !isValidUrl(url)) {
        if (url === "" && buyData?.anchor_text[index] === "") {
          return false;
        } else {
          return true;
        }
      }
    });
    // Proceed with submission only if validation conditions are met
    if (
      (contentBool || contentBool2 || contentBool3) &&
      !(
        serviceActive === 0 &&
        publisherActive === 1 &&
        buyData?.keywords === ""
      ) &&
      !(
        serviceActive === 0 &&
        publisherActive === 1 &&
        selectedCategories?.length === 0
      ) &&
      !anchorBool
    ) {
      try {
        setDload(true);
        const config = {
          headers: {
            "Content-type": "multipart/form-data", // Since we now submit images as well
          },
        };
        const filter = buyData.url.filter((url) => url !== "");
        const filterAnchor = buyData.anchor_text.filter(
          (anchor) => anchor !== ""
        );

        const modifiedData = {
          ...buyData,
          anchor_text: JSON.stringify(filterAnchor),
          url: JSON.stringify(filter),
          content_creator_type: "Webylinks",
          content_type: serviceActive > 0 ? active : 3,
          total_amount: setPrice(),
          service_type_id: serviceActive,
          category_id: websiteData.categories.map((category) =>
            parseInt(category.id, 10)
          ),
          language: buyData?.language?.name,
          custom_package_word_count_id:
            serviceActive === 0 && publisherActive === 1 ? wordId : null,
          suggestion: buyData?.suggestion === null ? "" : buyData?.suggestion,
          article_wordCount_id:
            words?.length > 0 && active === 1 ? wordActive : null,
          attachments: selectedFiles,
        };

        const payload = {
          ...modifiedData,
          category_id: `[${modifiedData.category_id.join(", ")}]`,
          save_as_draft: "Draft",
        };

        // Make the POST request using Axios
        const response = await API.post(
          `${process.env.REACT_APP_BACKEND_API}/api/orders?user_type=1`,
          payload,
          config
        );

        fresh();
        // Handle the response if needed
        localStorage.setItem("selectedSubRoute", "/dashboard/buyer/tasks");
        navigate("/dashboard/buyer/tasks");
        Swal.fire({
          title: "Success!",
          html: "Task added to draft successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
      } catch (error) {
        // Handle errors
        Swal.fire({
          title: "Add Funds",
          html: `
            <p style="font-size: 13px;">You have insufficient balance to perform this action, please add funds</p>
          `,
          confirmButtonText: "Add Funds",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          customClass: {
            container: "custom-swal",
            title: "modal-title",
            content: "modal-content",
            confirmButton: "modal-confirm-button",
            cancelButton: "modal-cancel-button-swal",
            actions: "modal-actions",
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if (localStorage.getItem("role") === "buyer") {
              navigate("/dashboard/balance"); // Navigate to the add funds page
            } else {
              navigate("/dashboard/publisher/balance");
            }
          }
        });
      } finally {
        setDload(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // let formData = [];

    // selectedFiles.forEach((file, index) => {
    //   formData.push(file?.file);
    // });
    if (serviceActive === 0 && publisherActive === 1) {
      if (buyData?.keywords === "") {
        form5Ref.current.scrollIntoView({ behavior: "smooth" });
        setKError(true);
      }
      if (selectedCategories?.length === 0) {
        form4Ref.current.scrollIntoView({ behavior: "smooth" });
        setCatError(true);
      }
    }

    // Check content and URL validation
    if (
      (buyData?.content === "" || buyData?.content === null) &&
      serviceActive === 2 &&
      active === 0
    ) {
      formRef?.current?.scrollIntoView({ behavior: "smooth" });
      setCError(true);
    }

    // if (publisherActive === 1 && buyData?.keyword === "") {
    //   setKError(true);
    // }

    if (
      buyData?.url?.some((url, index) => {
        if (index === 0) {
          if (url === "" && buyData?.anchor_text[index] === "") {
            setInvalidAnchorIndexes((prevIndexes) => [...prevIndexes, index]);
          }
        }
        if (url === "" || !isValidUrl(url)) {
          if (url === "" && buyData?.anchor_text[index] === "") {
            return false;
          } else {
            setInvalidUrlIndexes((prevIndexes) => [...prevIndexes, index]); // Add the index to the array
          }
        }
        if (
          url !== "" &&
          isValidUrl(url) &&
          buyData?.anchor_text[index] === ""
        ) {
          setInvalidAnchorIndexes((prevIndexes) => [...prevIndexes, index]);
        }
      })
    ) {
      setUError(true);
    }
    const contentBool =
      (buyData?.content === "" || buyData?.content === null) &&
      serviceActive === 1;
    const contentBool2 =
      buyData?.content !== "" &&
      buyData?.content !== null &&
      serviceActive !== 1;
    const contentBool3 =
      (buyData?.content === null || buyData?.content === "") && active === 1;
    const anchorBool = buyData?.url?.some((url, index) => {
      if (index === 0) {
        if (url === "" && buyData?.anchor_text[index] === "") {
          return true;
        }
      }
      if (url !== "" && isValidUrl(url) && buyData?.anchor_text[index] === "") {
        return true;
      }
      if (url === "" || !isValidUrl(url)) {
        if (url === "" && buyData?.anchor_text[index] === "") {
          return false;
        } else {
          return true;
        }
      }
    });

    // Proceed with submission only if validation conditions are met
    if (
      (contentBool || contentBool2 || contentBool3) &&
      !(
        serviceActive === 0 &&
        publisherActive === 1 &&
        buyData?.keywords === ""
      ) &&
      !(
        serviceActive === 0 &&
        publisherActive === 1 &&
        selectedCategories?.length === 0
      ) &&
      !anchorBool
    ) {
      setOpenSummery(true);
    }
  };

  const handlePlaceOrder = async () => {
    try {
      setLoad(true);
      const config = {
        headers: {
          "Content-type": "multipart/form-data", // Since we now submit images as well
        },
      };
      let formData = [];

      selectedFiles.forEach((file, index) => {
        formData.push(file?.file);
      });
      const filter = buyData.url.filter((url) => url !== "");
      const filterAnchor = buyData.anchor_text.filter(
        (anchor) => anchor !== ""
      );
      const modifiedData = {
        ...buyData,
        anchor_text: JSON.stringify(filterAnchor),
        url: JSON.stringify(filter),
        content_creator_type: "Webylinks",
        content_type: serviceActive > 0 ? active : 3,
        total_amount: setPrice(),
        service_type_id: serviceActive,
        category_id: websiteData.categories.map((category) =>
          parseInt(category.id, 10)
        ),
        language: buyData?.language?.name,
        custom_package_word_count_id:
          serviceActive === 0 && publisherActive === 1 ? wordId : null,
        suggestion: buyData?.suggestion === null ? "" : buyData?.suggestion,
        article_wordCount_id:
          words?.length > 0 && active === 1 ? wordActive : null,
        attachments: selectedFiles,
      };

      const payload = {
        ...modifiedData,
        category_id: `[${modifiedData.category_id.join(", ")}]`,
        save_as_draft: "Order",
      };
      // // Make the POST request using Axios
      const response = await API.post(
        `${process.env.REACT_APP_BACKEND_API}/api/orders?user_type=1`,
        payload,
        config
      );

      fresh();
      // Handle the response if needed
      localStorage.setItem("selectedSubRoute", "/dashboard/buyer/tasks");
      navigate("/dashboard/buyer/tasks");
      Swal.fire({
        title: "Success!",
        html: "Task added successfully",
        icon: "success",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
      dispatch(userBalance(localStorage.getItem("role")));
    } catch (error) {
      // Handle errors
      setOpenSummery(false);
      Swal.fire({
        title: "Add Funds",
        html: `
          <p style="font-size: 13px;">You have insufficient balance to perform this action, please add $${setPrice()} to your funds</p>
        `,
        confirmButtonText: "Add Funds",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        customClass: {
          container: "custom-swal",
          title: "modal-title",
          content: "modal-content",
          confirmButton: "modal-confirm-button",
          cancelButton: "modal-cancel-button-swal",
          actions: "modal-actions",
        },
        buttonsStyling: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          openModal();
          // if (localStorage.getItem("role") === "buyer") {
          //   navigate("/dashboard/balance"); // Navigate to the add funds page
          // } else {
          //   navigate("/dashboard/publisher/balance");
          // }
        }
      });
    } finally {
      setOpenSummery(false);
      setLoad(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "keyword") {
      setKError(false);
    }
    setBuyData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleService = (id) => {
    setActive(0);
    setServiceActive(id);
    if (id === 1) {
      setBuyData((prevData) => ({ ...prevData, content: "" }));
    }
  };

  const handlePublisher = (id) => {
    setPublisherActive(id);
  };

  const handleContentTypeClick = (id) => {
    setActive(id);
    if (id === 1) {
      setBuyData((prevData) => ({ ...prevData, content: "" }));
    }
    if (id === 0) {
      setBuyData((prevData) => ({
        ...prevData,
        total_amount: websiteData?.mini_post_price,
      }));
    }
    if (id === 1) {
      setBuyData((prevData) => ({
        ...prevData,
        total_amount: websiteData?.article_price,
      }));
    }
    if (id === 2) {
      setBuyData((prevData) => ({
        ...prevData,
        total_amount: websiteData?.long_article,
      }));
    }
  };

  const handleWords = (id, amount) => {
    setWordActive(id);
    setWordId(id);
    setWordAmount(amount);
  };

  // Return price from words array based on id
  const getPriceById = (idToFind) => {
    for (const word of words) {
      if (word.id === idToFind) {
        return word.price;
      }
    }
    // If the ID is not found, you can return a default value or handle the case as needed.
    return null; // For example, returning null if the ID is not found.
  };

  const setPrice = () => {
    if (serviceActive === 1) {
      return websiteData?.linkInsertion_amount;
    } else if (serviceActive === 2) {
      if (active === 0) {
        return websiteData?.guestPost_amount;
      } else if (active === 1) {
        return websiteData?.guestPost_amount + getPriceById(wordActive);
      }
    }
  };

  const isValidUrl = (url) => {
    // Regular expression to match a URL pattern with "https://"
    if (url === "") {
      return true;
    } else {
      return validUrl.isWebUri(url);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // For file dropzone
  var componentConfig = { postUrl: "no-url" };
  var djsConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    maxFilesize: 10,
  };
  var totalSize = 0; // Variable to keep track of total size

  var eventHandlers = {
    addedfile: async (file) => {
      // Check if the file type is accepted
      // const acceptedFiles = djsConfig.acceptedFiles.split(", ");
      // const fileType = file.type;
      // if (!acceptedFiles.includes(fileType)) {
      //   // The file type is not accepted, so remove the file from Dropzone
      //   setFileErr("Invalid file type");
      //   dropzoneRef.current.dropzone.removeFile(file);
      //   return;
      // }

      // Check if the total file size exceeds the maximum allowed size
      totalSize += file.size / (1024 * 1024); // Add the size of the new file
      if (totalSize > djsConfig.maxFilesize) {
        setFileErr("Total file size should not be more then 10MB");
        dropzoneRef.current.dropzone.removeFile(file);
        totalSize -= file.size / (1024 * 1024); // Subtract the size of the removed file
        return;
      }
      // Check if a file with the same name already exists in selectedFiles
      const similarFile = selectedFiles.find(
        (selectedFile) => selectedFile.name === file.name
      );

      if (similarFile) {
        // File with the same name already exists, so remove the newly added file from Dropzone
        setFileErr("File with similar name already exists");
        dropzoneRef.current.dropzone.removeFile(file);
        totalSize -= file.size / (1024 * 1024); // Subtract the size of the removed file
      } else {
        // No file with the same name found, so add the file to your array
        setFileErr("");
        // Update the state using setSelectedFiles
        selectedFiles.push(file);
        setSize((prevSize) => prevSize + 1);
      }
    },
    removedfile: (file) => {
      // Remove the file from your array
      const index = selectedFiles.indexOf(file);
      if (index > -1) {
        setFileErr("");
        selectedFiles.splice(index, 1);
        totalSize -= file.size / (1024 * 1024); // Subtract the size of the removed file
        setSize((prevSize) => prevSize - 1);
      }
    },
  };
  //====================================

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    if (localStorage.getItem("role") === "buyer" && user?.is_verified === 0) {
      navigate("/dashboard/website-list");
    }
    const fetchData = async () => {
      setWload(true);
      try {
        const response = await API.get(`/api/publisher/${id}`); // Replace with your actual API endpoint
        const responsePackage = await API.get("/api/custom-order-field-count");
        setWords(response?.data?.publication_article_prices);
        setWordId(response?.data?.publication_article_prices[0]?.id);
        setWordActive(response?.data?.publication_article_prices[0]?.id);
        setWebsiteData(response.data);
        const contentPlacementPrice =
          response?.data?.content_placement_price || 0;
        const miniPostPrice = response?.data?.mini_post_price || 0;
        const articlePrice = response?.data?.article_price || 0;
        const longArticle = response?.data?.long_article || 0;
        const articleBuyerPrice = response?.data?.article_written_by_buyer || 0;
        const articlePublisherPrice =
          response?.data?.article_written_by_publisher || 0;
        const articleWebylinksPrice =
          response?.data?.article_written_by_webylinks || 0;
        const checkService = response?.data?.service_type
          ?.split(",")
          .map((item) => item.trim());
        setServiceArray(
          response?.data?.service_type?.split(",").map((item) => item.trim())
        );
        setServiceActive(parseInt(checkService[0]));
        if (response?.data?.service_type === 1) {
          setActive(0);
        }
        if (response?.data?.service_type === 2) {
          let active = 0;
          if (articleBuyerPrice === 0) {
            if (articlePublisherPrice === 0) {
              active = 2; // Long Article (State 3)
              setBuyData((prevData) => ({
                ...prevData,
                total_amount: articleWebylinksPrice,
              }));
            } else {
              active = 1; // Article (State 2)
              setBuyData((prevData) => ({
                ...prevData,
                total_amount: articlePublisherPrice,
              }));
            }
          } else {
            active = 0; // Content Placement (State 0)
            setBuyData((prevData) => ({
              ...prevData,
              total_amount: articleBuyerPrice,
            }));
          }
          setActive(active);
        }
        if (response?.data?.service_type === 3) {
          let active = 0;
          if (miniPostPrice === 0) {
            if (articlePrice === 0) {
              active = 2; // Long Article (State 3)
              setBuyData((prevData) => ({
                ...prevData,
                total_amount: longArticle,
              }));
            } else {
              active = 1; // Article (State 2)
              setBuyData((prevData) => ({
                ...prevData,
                total_amount: articlePrice,
              }));
            }
          } else {
            active = 0; // Content Placement (State 0)
            setBuyData((prevData) => ({
              ...prevData,
              total_amount: miniPostPrice,
            }));
          }
          setActive(active);
        }
        // if (miniPostPrice === 0 && articlePrice === 0 && longArticle === 0) {
        //   setServiceActive(1);
        //   setPublisherActive(1);
        //   setTypeDisable(true);
        // }
        // if (response?.data?.content_placement_price === 0) {
        //   setServiceActive(0);
        //   setPublisherActive(0);
        //   setDisable(true);
        // }
        // let active = 0;

        // if (miniPostPrice === 0) {
        //   if (articlePrice === 0) {
        //     active = 2; // Long Article (State 3)
        //     setBuyData((prevData) => ({
        //       ...prevData,
        //       total_amount: longArticle,
        //     }));
        //   } else {
        //     active = 1; // Article (State 2)
        //     setBuyData((prevData) => ({
        //       ...prevData,
        //       total_amount: articlePrice,
        //     }));
        //   }
        // } else {
        //   active = 0; // Content Placement (State 0)
        //   setBuyData((prevData) => ({
        //     ...prevData,
        //     total_amount: miniPostPrice,
        //   }));
        // }
        setWebUrl(response?.data?.url);

        // // Set the determined active value
        // setActive(active);
        if (localStorage.getItem("resumeData")) {
          const data = JSON.parse(localStorage.getItem("resumeData"));
          setBuyData({
            ...buyData,
            content: data?.content,
            keywords: data?.keywords,
            special_requirements: data?.special_requirements,
            suggestion: data?.suggestion,
            language: { name: data?.language, code: data?.language },
            url: JSON.parse(data?.url),
            anchor_text: JSON.parse(data?.anchor_text),
          });
          if (
            data?.content_creator_type === "publisher" &&
            data?.content_type === "3"
          ) {
            setServiceActive(1);
          }
          if (miniPostPrice === 0 && articlePrice === 0 && longArticle === 0) {
            if (
              data?.content_creator_type === "publisher" &&
              data?.content_type !== "3"
            ) {
              setPublisherActive(1);
            }
          }
          if (miniPostPrice !== 0 || articlePrice !== 0 || longArticle !== 0) {
            setPublisherActive(
              data?.content_creator_type === "webylinks" ? 1 : 0
            );
          }
          setSelectedCategories(
            data?.category_id?.map((item) => ({
              code: item.id,
              name: item.title,
            }))
          );
          setWordActive(
            data?.words === "100"
              ? 0
              : data?.words === "1000"
              ? 1
              : data?.words === "1500"
              ? 2
              : 3
          );
        }
      } catch (error) {
        return error;
      } finally {
        setWload(false);
      }
    };

    fetchData();
  }, [localStorage.getItem("role"), id]);

  return (
    <div className="buyer-form-main-wraper">
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-7">
            <h2>
              {" "}
              Create a Task For{" "}
              {localStorage.getItem("show") === "true" ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open("https://" + webUrl)}
                >
                  {webUrl}
                </span>
              ) : (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    Swal.fire({
                      title: "Add Funds",
                      html: `
            <p style="font-size: 13px;">You have insufficient balance to perform this action, please add $25 to your funds</p>
          `,
                      confirmButtonText: "Add Funds",
                      showCancelButton: true,
                      cancelButtonText: "Cancel",
                      customClass: {
                        container: "custom-swal",
                        title: "modal-title",
                        content: "modal-content",
                        confirmButton: "modal-confirm-button",
                        cancelButton: "modal-cancel-button-swal",
                        actions: "modal-actions",
                      },
                      buttonsStyling: false,
                      allowOutsideClick: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        openModal();
                      }
                    });
                  }}
                >
                  Url is hidden
                </span>
              )}
            </h2>
          </div>
        </div>
      </div>
      <Accordion className="accordion-filter accordion-global">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-summery"
        >
          <div className="w-100 d-flex justify-content-between filter-header">
            <h2>FAQ?</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <div className="accordion-row">
            <div className="d-flex flex-column align-items-start accordion-row-col">
              <h6 className="order-article-faq-q">
                Why can’t I see publishers’ URLs?
              </h6>
              All the URLs are hidden for new users until you make your first
              top-up. After that, you can see all publishers’ URLs, and use the
              money you’ve added to your balance to order posts from them.
            </div>
            <div className="d-flex flex-column align-items-start accordion-row-col">
              <h6 className="order-article-faq-q">
                What will happen after I create a task?
              </h6>
              You’ll be able to find it in the “Tasks” section. If you’ve
              created a task but haven’t paid for it, you can find it in the
              “Draft” status. If the money for the task was reserved, find it in
              the “Publisher’s Acceptance” status.
            </div>
            <div className="d-flex flex-column align-items-start accordion-row-col">
              <h6 className="order-article-faq-q ">Can I edit my task?</h6>
              You can edit your task while it’s in the “Draft” status. Later,
              you can communicate with the publisher while they work on your
              task. After they submit the task for your approval, you are free
              to request changes if the task doesn’t fully meet the requirements
              you had provided.
            </div>

            <div className="d-flex flex-column align-items-start accordion-row-col">
              <h6 className="order-article-faq-q">
                Can I get my money back if I am not satisfied with the task
                performed?
              </h6>
              If your task is in the “In Progress” status for more than 5 days;
              or if it isn’t performed properly, and the publisher refuses to
              make any improvements to it, you can open a dispute. We’ll review
              it, and the money for the task will be returned to your balance.
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {wload ? (
        <div className="w-100 d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="buyer-form-wraper">
          <h4> * Fields are required </h4>
          <form>
            <div className="d-flex align-items-center single-word-group single-select-group">
              <label className="d-flex align-items-center">
                Service Type:
                <span>
                  <div>
                    <Tooltip
                      title="Select from one of the following service types"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </span>
              </label>

              <div className="row service-list-row">
                <div className="col-md-12">
                  <div className="single-select-word">
                    <ul className="word-count-list service-list-options">
                      {serviceArray.includes("1") && (
                        <li
                          className={`d-flex align-items-center ${
                            serviceActive === 1 ? "service-type-0" : "st-word"
                          }`}
                          onClick={() => handleService(1)}
                        >
                          {" "}
                          {serviceActive === 1 ? (
                            <div className="active-circle">
                              <div className="inner-active-circle" />
                            </div>
                          ) : (
                            <div className="in-active-circle" />
                          )}
                          Link insertion
                        </li>
                      )}
                      {serviceArray.includes("2") && (
                        <li
                          className={`d-flex align-items-center  ${
                            serviceActive === 2 ? "service-type-0" : "st-word"
                          }`}
                          onClick={() => handleService(2)}
                        >
                          {" "}
                          {serviceActive === 2 ? (
                            <div className="active-circle">
                              <div className="inner-active-circle" />
                            </div>
                          ) : (
                            <div className="in-active-circle" />
                          )}
                          Guest post
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                {/* {!isMobile && (
                <div className="col-md-4">
                  <div className="info-tool-tip tip-set">
                    {" "}
                    Select from one of the following service types{" "}
                  </div>
                </div>
              )} */}
              </div>
            </div>

            {serviceActive === 2 && (
              <div className="d-flex align-items-center single-word-group single-select-group">
                <label className="d-flex align-items-center">
                  Content Type:{" "}
                  <span>
                    {" "}
                    <div>
                      <Tooltip
                        title="Select one of the following content types"
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </span>
                </label>
                <div className="row service-list-row">
                  <div className="col-md-12">
                    <div className="single-select-word content-type-group">
                      <ul className="word-count-list service-list-options">
                        <li
                          onClick={() => handleContentTypeClick(0)}
                          className={`d-flex align-items-center ${
                            active === 0 ? "content-type-0" : "st-word"
                          }`}
                        >
                          {active === 0 ? (
                            <div className="active-circle">
                              <div className="inner-active-circle" />
                            </div>
                          ) : (
                            <div className="in-active-circle" />
                          )}{" "}
                          Article written by buyer{" "}
                        </li>

                        {words?.length > 0 && (
                          <li
                            onClick={() => handleContentTypeClick(1)}
                            className={`d-flex align-items-center ${
                              active === 1 ? "content-type-1" : "st-word"
                            }`}
                          >
                            {active === 1 ? (
                              <div className="active-circle">
                                <div className="inner-active-circle" />
                              </div>
                            ) : (
                              <div className="in-active-circle" />
                            )}{" "}
                            Article written by webylinks{" "}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {active === 1 && (
              <>
                <div className="mb-3 single-word-group">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex align-items-center single-select-word">
                        <label className="d-flex align-items-center">
                          {" "}
                          Word Count*{" "}
                          <Tooltip
                            title="Set your desired length for the article. Keep in mind
                        that it will directly impact the final price"
                            placement="top-start"
                            className="mui-tooltip"
                          >
                            <IconButton size="small" className="info-icon">
                              <img
                                src={InfoIcon}
                                alt="Info"
                                className="info-icon-image"
                              />
                            </IconButton>
                          </Tooltip>
                        </label>
                        <ul className="word-count-list wc-list">
                          {words?.map((res, id) => (
                            <li
                              key={id}
                              className={`word-select ${
                                wordActive === res?.id
                                  ? "single-select-active-0"
                                  : ""
                              }`}
                              onClick={() => handleWords(res?.id, res?.price)}
                            >
                              {" "}
                              {`${res?.word_count} words`}{" "}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {serviceActive === 2 && active === 0 && (
              <div className="form-group editor-container">
                <div className="row">
                  <div className="col-md-12 c-wrapper">
                    <div
                      className={`d-flex filed-form-wrap ${
                        cError ? "err-editor" : ""
                      }`}
                      ref={formRef}
                    >
                      <label className="content-label">
                        {" "}
                        Content *{" "}
                        <Tooltip
                          title="Place your content like an article in this section.If the
                    publisher has not indicated, otherwise you can insert upto 3
                    links (URLs and corresponding anchor text) within your
                    content. Kindly enter the identical URLs and anchor text in
                    the ‘URL’ and ‘Anchor text’ fields below,as they will be
                    utilized to check performance of your tasks to your
                    specifications."
                          placement="top-start"
                          className="mui-tooltip"
                        >
                          <IconButton size="small" className="info-icon">
                            <img
                              src={InfoIcon}
                              alt="Info"
                              className="info-icon-image"
                            />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <Editor
                        apiKey="rw57q63adwugi7s974bxs2bwaqna6dd3u8himamgjfsf8ax2"
                        initialValue={!localStorage.getItem("resumeData") && ""}
                        value={
                          localStorage.getItem("resumeData") && buyData?.content
                        }
                        init={{
                          height: 300,
                          menubar: false,
                          selector: "textarea",
                          plugins:
                            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                          toolbar:
                            "undo redo | blocks | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent",
                          content_style:
                            "body { font-family: 'Arial', sans-serif; font-size: 14px }",
                        }}
                        onEditorChange={handleEditorChange}
                      />
                    </div>
                    {cError && (
                      <p className="c-error">Content cannot be blank</p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="form-group">
              <div className="row">
                {/* ... (other columns and components) */}

                {/* Anchor text */}
                <div className="col-md-12 url-wrapper-form">
                  <div className="d-flex filed-form-new-wrap url-wrapper-form">
                    <label className="d-flex align-items-center anch-label">
                      {" "}
                      Anchor text{" "}
                      <Tooltip
                        title="Enter the anchor text that you have included in your content
                    above. It will be used to check the performed task for
                    compliance with your requirements. Maximum of 3 anchor text
                    can be added."
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>

                    <div className="w-100 d-flex justify-content-between anchor-field-wraper">
                      <div className="anchor-field second-field">
                        <input
                          type="text"
                          className={`${
                            invalidAnchorIndexes.includes(0)
                              ? "err-url url-text-main"
                              : "text-area-control"
                          }`}
                          placeholder="Provide your anchor text here"
                          value={buyData.anchor_text[0]}
                          onChange={(e) => handleAnchorChange(e, 0)}
                        />
                        {invalidAnchorIndexes.includes(0) && (
                          <p className="err-msg-url">
                            An Anchor text is required
                          </p>
                        )}
                      </div>
                      <div className="url-field second-field">
                        <input
                          type="text"
                          className={`${
                            invalidUrlIndexes.includes(0)
                              ? "err-url url-text-main"
                              : "text-area-control"
                          }`}
                          placeholder="Provide Url of your anchor text here"
                          value={buyData.url[0]}
                          onChange={(e) => handleUrlChange(e, 0)}
                        />
                        {invalidUrlIndexes.includes(0) && (
                          <p className="err-msg-url">A valid url is required</p>
                        )}
                      </div>
                    </div>
                  </div>
                  {buyData.anchor_text.slice(1).map((anchor, index) => (
                    <div className="form-group" key={index}>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className={`d-flex justify-content-between filed-form-wrap additional-arc-fields ${
                              invalidUrlIndexes.includes(index + 1)
                                ? "filed-wrap-2"
                                : ""
                            }`}
                          >
                            <div className="anchor-field second-field">
                              <input
                                type="text"
                                className={`${
                                  invalidAnchorIndexes.includes(index + 1)
                                    ? "err-url"
                                    : "text-area-control"
                                }`}
                                placeholder="Provide your anchor text here"
                                value={anchor}
                                onChange={(e) =>
                                  handleAnchorChange(e, index + 1)
                                }
                              />
                              {invalidAnchorIndexes.includes(index + 1) && (
                                <p className="err-msg-url">
                                  An Anchor text is required
                                </p>
                              )}
                            </div>
                            <div className="url-field second-field">
                              <input
                                type="text"
                                className={`${
                                  invalidUrlIndexes.includes(index + 1)
                                    ? "err-url"
                                    : "text-area-control"
                                }`}
                                placeholder="Provide Url for your anchor text here"
                                value={buyData?.url[index + 1]}
                                onChange={(e) => handleUrlChange(e, index + 1)}
                              />
                              {invalidUrlIndexes.includes(index + 1) && (
                                <p className="err-msg-url">
                                  A valid url is required
                                </p>
                              )}
                            </div>
                          </div>

                          <Button
                            className="remove-btn-buy-form"
                            type="button"
                            onClick={() => removeAnchorInput(index + 1)}
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                        {/* ... (info-tool-tip column) */}
                      </div>
                    </div>
                  ))}
                  <Button
                    className={`add-btn-buy-form ${
                      parseInt(websiteData?.number_of_links) < 2
                        ? "no-show-add-btn"
                        : ""
                    }`}
                    type="button"
                    onClick={addAnchorInput}
                  >
                    <AddIcon />
                  </Button>
                </div>
              </div>
            </div>
            {/* ... (other components and form structure) */}

            <div className="form-group sq">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex filed-form-area-wrap">
                    <label className="d-flex align-items-center sr-label">
                      {" "}
                      Special Requirements{" "}
                      <Tooltip
                        title="Detail any specific criteria you have in mind for the
                    article, such as its outline, format, preferred tone of
                    voice etc"
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <textarea
                      className="text-area-control requirement-text-area"
                      rows="6"
                      name="special_requirements"
                      placeholder="Please specify any other requirements you have here"
                      value={buyData.special_requirements}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="form-group">
              <div className="row">
                <div className="col-md-12 url-wrapper-form">
                  <div
                    className="d-flex filed-form-new-wrap url-wrapper-form"
                    ref={form6Ref}
                  >
                    <label className="d-flex align-items-center url-label">
                      {" "}
                      URL*{" "}
                      <Tooltip
                        title="Enter the URL that you have included in your content above.
                    It will be used to check the performed task for compliance
                    with your requirements. Maximum of 3 urls can be added."
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <input
                      type="text"
                      className={`${
                        invalidUrlIndexes.includes(0)
                          ? "err-url url-text-main"
                          : "text-area-control"
                      }`}
                      placeholder="http(s)://"
                      value={buyData.url[0]} // Use the first element from the array
                      onChange={(e) => handleUrlChange(e, 0)} // Pass index 0
                    />
                  </div>
                  {invalidUrlIndexes.includes(0) && (
                    <p className="err-msg">A valid url is required</p>
                  )}
                  {buyData.url.slice(1).map((url, index) => (
                    <div className="form-group" key={index}>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="filed-form-wrap additional-arc-fields"
                            ref={form6Ref}
                          >
                            <input
                              type="text"
                              className={`${
                                invalidUrlIndexes.includes(index + 1)
                                  ? "err-url"
                                  : "text-area-control"
                              }`}
                              placeholder="http(s)://"
                              value={url}
                              onChange={(e) => handleUrlChange(e, index + 1)} // Increment the index by 1
                            />
                            {invalidUrlIndexes.includes(index + 1) && (
                              <p style={{ color: "red" }}>
                                A valid url is required
                              </p>
                            )}
                          </div>
                          <Button
                            className="remove-btn-buy-form"
                            type="button"
                            onClick={() => removeUrlInput(index + 1)}
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <Button
                    className="add-btn-buy-form"
                    type="button"
                    onClick={addUrlInput}
                  >
                    <AddIcon />
                  </Button>
                </div>
              </div>
            </div> */}
            <div className="form-group">
              <div className="row">
                <div className="col-md-12 c-wrapper">
                  <div className={`d-flex filed-form-wrap`}>
                    <label className="content-label">
                      {" "}
                      Attachments{" "}
                      <Tooltip
                        title="Supplementary files or documents that accompany a primary piece of content or communication, serving to provide additional information, context, or resources. These files can be in various formats, such as documents, images, videos, or any digital media, and are designed to enhance the understanding and value of the main content. "
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <div className="w-100 d-flex flex-column">
                      <DropzoneComponent
                        ref={dropzoneRef}
                        className={`w-100 d-flex align-items-center justify-content-center dropzone-main ${
                          size > 0 && size < 8
                            ? "selected-file-one"
                            : size >= 8
                            ? "selected-file-two"
                            : size === 0
                            ? "selected-file-three"
                            : ""
                        } `}
                        config={componentConfig}
                        eventHandlers={eventHandlers}
                        djsConfig={djsConfig}
                      />
                      {filerr && (
                        <p className="mt-1" style={{ color: "red" }}>
                          {filerr}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button className="d-flex price-info-wrap" disabled>
              {" "}
              <p>
                Task price
                <span className="price-info-price">${setPrice()}</span>
              </p>
            </button>

            <div className="btn-form-buyer">
              <Button
                className="send-btn-form"
                type="submit"
                onClick={handleSubmit}
              >
                {" "}
                Place order
              </Button>
              <Button className="draft-btn" onClick={handleSaveDraft}>
                {" "}
                {dload ? (
                  <CircularProgress className="text-dark" />
                ) : (
                  "SAVE AS DRAFT"
                )}{" "}
              </Button>
            </div>
          </form>
        </div>
      )}
      {isModalOpen && (
        <AddFundsModal
          isOpen={isModalOpen}
          onClose={closeModal}
          user={user}
          amountTask={setPrice()}
          transaction={() => {
            return;
          }}
        />
      )}
      <SummeryModal
        data={buyData}
        service={serviceActive}
        active={active}
        openSummery={openSummery}
        handleSummeryClose={handleSummeryClose}
        handleSubmit={handlePlaceOrder}
        load={load}
      />
    </div>
  );
};

export default BuyerForm;
