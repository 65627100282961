import React, { useEffect } from "react";
import ModerationMain from "./components/ModerationMain";
import { useNavigate } from "react-router-dom";

const ModerationBuyer = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("role") === "publisher") {
  //     navigate("/dashboard/platform");
  //   }
  // }, [localStorage.getItem("role")]);
  return <ModerationMain />;
};

export default ModerationBuyer;
