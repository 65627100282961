import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import API from "../../../api/ClientApi";
import "../styles/updatePassword.css";
import logo from "../../../assets/images/logoMain.png";
import Swal from "sweetalert2";

const UpdatePassword = () => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {}, []);

  const handleChange = (e) => {
    setUserCredentials({
      ...userCredentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };

    const url = `/api/reset-forget-password/${hash}?password=${encodeURIComponent(
      userCredentials.password
    )}&password_confirmation=${encodeURIComponent(
      userCredentials.confirmPassword
    )}`;

    try {
      const response = await API.put(url, null, config);
      const responseData = response.data; // Extract the response data
      Swal.fire({
        title: "Success!",
        html: responseData.message,
        icon: "success",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
      navigate("/login");
    } catch (error) {
      // Handle error if needed
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleC = () => {
    setShowCPassword((prevShowCPassword) => !prevShowCPassword);
  };

  return (
    <div className="update-password-main">
      <div className="signup-container">
        <div className="login-logo">
          <img src={logo} alt="weblinks-logo" />
        </div>
        <form
          onSubmit={handleSubmit}
          className="w-100 d-flex flex-column form-main-sec"
        >
          <h2>Reset Password</h2>
          <p className="signup-terms">Please enter password to update it</p>
          <TextField
            className="w-100 signup-email-field"
            placeholder="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            required
            value={userCredentials.password}
            onChange={handleChange}
            InputProps={{
              classes: {
                root: "custom-input",
                input: "custom-input",
              },
              endAdornment: (
                <InputAdornment position="end" className="input-icon-container">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    className="input-icon-button"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: "custom-label",
              },
            }}
          />
          <TextField
            className="w-100 signup-email-field"
            placeholder="Confirm Password"
            name="confirmPassword"
            type={showCPassword ? "text" : "password"}
            variant="outlined"
            required
            value={userCredentials.confirmPassword}
            onChange={handleChange}
            InputProps={{
              classes: {
                root: "custom-input",
                input: "custom-input",
              },
              endAdornment: (
                <InputAdornment position="end" className="input-icon-container">
                  <IconButton
                    onClick={handleToggleC}
                    className="input-icon-button"
                  >
                    {showCPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: "custom-label",
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="w-100 signup-btn"
          >
            {loading ? <CircularProgress className="text-white auth-circle-prog" /> : "SUBMIT"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
