import React from "react";
import "./styles/bid.css";
import { BreadCrumb } from "primereact/breadcrumb";
import BidForm from "./components/BidForm";

const BidMain = () => {
  const items = [{ label: "Bid" }];
  const home = { label: "Home", url: "/dashboard/platform" };
  return (
    <>
      <BreadCrumb model={items} home={home} />
      <BidForm />
    </>
  );
};

export default BidMain;
