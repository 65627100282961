import React from "react";
import VerifyCard from "./component/VerifyCard";
import "./verifyEmail.css";

const VerifyEmail = () => {
  return (
    <div className="verifyemail-main">
      <VerifyCard />
    </div>
  );
};

export default VerifyEmail;
