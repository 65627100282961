import React, { useEffect } from "react";
import ModerationMain from "./components/ModerationMain";
import { useNavigate } from "react-router-dom";

const ModerationPublisher = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("role") === "buyer" || props.publish===0) {
      navigate("/dashboard/website-list");
    }
  }, [localStorage.getItem("role")]);
  return <ModerationMain />;
};

export default ModerationPublisher;
