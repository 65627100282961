import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { BreadCrumb } from "primereact/breadcrumb";
import InfoIcon from "../../assets/images/infoIcon.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./securityDeposit.css";

const SecurityDeposit = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(null);
  const [aError, setAError] = useState("");
  const [load, setLoad] = useState(false);
  const { balance } = useSelector((state) => state.balance);
  const items = [
    { label: "Balance", url: "/dashboard/publisher/balance" },
    { label: "Transferring funds to the security deposit" },
  ];
  const home = { label: "Home", url: "/dashboard/platform" };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (amount > balance?.balance) {
      setAError(`Your balance is too low ${parseInt(balance?.balance)}`);
    } else if (amount < 100) {
      setAError("Security deposit must not be less then 100");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "buyer") {
      navigate("/dashboard/website-list");
    }
  }, [localStorage.getItem("role")]);

  return (
    <div>
      <div className="buyer-form-main-wraper">
        <BreadCrumb model={items} home={home} />
        <div className="heading-info-wrap">
          <div className="row">
            <div className="col-md-12">
              <h2> Transferring funds to the security deposit </h2>
            </div>
          </div>
        </div>
        <Accordion className="accordion-filter accordion-global">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="filter-summery"
          >
            <div className="w-100 d-flex justify-content-between filter-header">
              <h2>HOW IT WORKS?</h2>
            </div>
          </AccordionSummary>
          <AccordionDetails className="accordion-detail">
            <div className="accordion-detail-div ac-div">
              You have the option to transfer a minimum of $100 from your
              publisher's earnings to a security deposit. This amount acts as a
              safeguard, ensuring that you can provide compensation to a buyer
              in case their guest post undergoes editing or deletion after
              receiving their approval.
            </div>
          </AccordionDetails>
        </Accordion>
        <div className="mt-3 list-website-info list-amount-info">
          <span>
            {" "}
            <img src={InfoIcon} alt="" />{" "}
          </span>
          <strong> Minimum trusted amount is $100</strong>
        </div>
        <div className="mt-3 billing-info-main">
          <div className="w-100">
            <label>Choose amount of security deposit*</label>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="w-100 d-flex justify-content-between multi-input-section">
              <div className="info-billing-container info-url">
                <TextField
                  className="w-100 signup-email-field"
                  placeholder="Amount"
                  name="amount"
                  type="number"
                  variant="outlined"
                  required
                  value={amount}
                  onChange={(e) => {
                    e.preventDefault();
                    setAError("");
                    setAmount(e.target.value);
                  }}
                  InputProps={{
                    classes: {
                      root: "custom-input-billing",
                      input: "custom-input-billing",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "custom-label",
                    },
                  }}
                />
              </div>
              <div className="info-billing-container second-input info-next">
                <Button type="submit" className="info-next-btn">
                  {load ? (
                    <CircularProgress
                      className="text-white"
                      style={{ color: "#ffffff !important" }}
                    />
                  ) : (
                    "Make a deposit"
                  )}
                </Button>
              </div>
            </div>
          </form>
          <div className="w-100">
            {aError && (
              <p className="" style={{ color: "red" }}>
                {aError}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityDeposit;
