import React from "react";
import { Dialog } from "primereact/dialog";
import { CircularProgress, TextField } from "@mui/material";
import "../styles/summery.css";

const SummeryModal = (props) => {
  const footerContent = (
    <div className="d-flex w-100 justify-content-end">
      <button
        onClick={props.handleSubmit}
        className="mb-2 active-offer-btn offer-accept-pub-btn sc-event-btn callback-btn"
      >
        {props.load ? (
          <CircularProgress className="text-white sc-event-progress" />
        ) : (
          "Confirm"
        )}
      </button>
    </div>
  );

  return (
    <Dialog
      header="Order Summery"
      visible={props.openSummery}
      style={{ minWidth: "800px" }}
      className="my-dialog"
      onHide={props.handleSummeryClose}
      dismissableMask={true}
      footer={footerContent}
    >
      <div className="w-100 d-flex justify-content-between multi-input-section">
        <div className="info-billing-container summery-info-wrapper">
          <div className="w-100">
            <label>SERVICE TYPE</label>
          </div>
          <input
            className="w-100 signup-email-field custom-input-summery"
            name="company_name"
            type="text"
            variant="outlined"
            disabled
            value={props?.service === 1 ? "Link insertion" : "Guest post"}
          />
        </div>
        <div className="info-billing-container second-input summery-info-wrapper">
          <div className="w-100">
            <label>CONTENT TYPE</label>
          </div>
          <input
            className="w-100 signup-email-field custom-input-summery"
            name="street_address"
            type="text"
            variant="outlined"
            disabled
            value={
              props?.service === 1
                ? "Link insertion"
                : props?.service === 2
                ? props?.active === 0
                  ? "Article by buyer"
                  : props?.active === 1
                  ? "Article by webylinks"
                  : props?.active === 2
                  ? "Article by webylinks"
                  : ""
                : ""
            }
          />
        </div>
      </div>
      {props?.service === 2 && props?.active === 0 && (
        <div className="mt-3 w-100 d-flex justify-content-between multi-input-section">
          <div className="w-100 summery-info-wrapper">
            <div className="w-100">
              <label>CONTENT</label>
            </div>
            <textarea
              className="w-100"
              value={
                (props?.data?.content &&
                  new DOMParser().parseFromString(
                    props.data.content,
                    "text/html"
                  ).documentElement.textContent) ||
                ""
              }
              rows={5}
              disabled
            />
          </div>
        </div>
      )}
      <div className="mt-3 w-100 d-flex justify-content-between multi-input-section">
        <div className="w-100 summery-info-wrapper">
          <div className="w-100 row ">
            <div className="col-md-6">
              <div className="w-100">
                <label>ANCHOR TEXT</label>
              </div>
              {props?.data?.anchor_text?.map(
                (anc, id) =>
                  anc !== "" && (
                    <div className="anc-url-txt" key={id}>
                      {anc}
                    </div>
                  )
              )}
            </div>
            <div className="col-md-6">
              <div className="w-100">
                <label>URL</label>
              </div>
              {props?.data?.url?.map(
                (anc, id) =>
                  anc !== "" && (
                    <div className="anc-url-txt" key={id}>
                      {anc}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 w-100 d-flex justify-content-between multi-input-section">
        <div className="w-100 summery-info-wrapper">
          <div className="w-100">
            <label>SPECIAL REQUIREMENTS</label>
          </div>
          <textarea
            className="w-100"
            value={
              props?.data?.special_requirements
                ? props?.data?.special_requirements
                : "N/A"
            }
            rows={3}
            disabled
          />
        </div>
      </div>
    </Dialog>
  );
};

export default SummeryModal;
