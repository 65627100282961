import React, { useState } from "react";
import API from "../../api/ClientApi";
import DeleteIcon from "../../assets/images/ssss.svg";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextareaAutosize, // Import TextareaAutosize
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Dashboard/styles/deldecModal.css";
import "./style/taskReview.css";
import Swal from "sweetalert2";
import Rating from "@mui/material/Rating";
import Filter from "bad-words"; // Import "bad-words"

const TaskReviewModal = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [comments, setComments] = useState("");
  const [rating, setRating] = useState(0);

  // Create a "Filter" instance from "bad-words"
  const filter = new Filter();

  const handleReview = async (e) => {
    e.preventDefault();
    setLoad(true);

    // Filter the comments to remove insensitive language
    const filteredComments = filter.clean(comments);
    const modifiedData = {
      user_type: 1,
      publisher_id: props?.publisherId,
      comments: filteredComments, // Use the filtered comments
      ratings: rating,
      order_id: props?.id,
    };

    await API.post(`/api/add-reviews`, modifiedData)
      .then(async (response) => {
        setLoad(false);
        // Handle success or failure
        // Close the confirmation modal
        await props.handleReviewClose();
        await props.getData();
        Swal.fire({
          title: "Success!",
          html: "Review submitted successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
        await props.getTabs();
        await props.getList();
        props.setType(25);
      })
      .catch((error) => {
        setLoad(false);
        // Handle error
      });
  };

  return (
    <Modal
      open={props.reviewModal}
      onClose={props.handleReviewClose}
      aria-labelledby="Accept Task"
    >
      <Box className="modal-container">
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <div className="w-100 d-flex justify-content-center review-star-container">
            <Rating
              name="half-rating"
              onChange={(e) => setRating(e.target.value)}
              cancel={false}
              className="custom-rating"
              defaultValue={rating}
              precision={0.1}
            />
          </div>

          {/* Add a Textarea with label "Your Feedback" */}
          <div className="d-flex flex-column justify-content-center feedback-textarea">
            {/* <label>Your Feedback</label> */}
            <textarea
              rows={8}
              value={comments}
              className="review-area"
              onChange={(e) => setComments(e.target.value)}
              placeholder="Feedback"
            />
          </div>
        </div>
        <div className="buttons-container">
          <Button
            onClick={props.handleReviewClose}
            className="cancel-btn-modal"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="delete-button"
            onClick={handleReview}
            disabled={load}
          >
            {load ? (
              <CircularProgress className="text-white circle-delete" />
            ) : (
              "Confirm"
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default TaskReviewModal;
