import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";

const BillingDetails = (props) => {
  const [empty_error, setEmptyError] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      (props.profile.company_name === "" ||
        props.profile.company_name === null) &&
      (props.profile.street_address === "" ||
        props.profile.street_address === null) &&
      (props.profile.city === "" || props.profile.city === null) &&
      (props.profile.vat_number === "" || props.profile.vat_number === null) &&
      (props.profile.state === "" || props.profile.state === null) &&
      (props.profile.postal_code === "" ||
        props.profile.postal_code === null) &&
      (props.profile.country === "" || props.profile.country === null)
    ) {
      setEmptyError(true);
    } else {
      props.handleSubmit(e);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="billing-info-main info-main-acc">
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container">
            <div className="w-100">
              <label>Company Name*</label>
            </div>
            <TextField
              className={`w-100 signup-email-field ${
                empty_error ? "empty-account-fields" : ""
              }`}
              name="company_name"
              type="text"
              variant="outlined"
              value={props.profile.company_name}
              onChange={(event) => {
                setEmptyError(false);
                props.handleChange(event);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
          <div className="info-billing-container second-input">
            <div className="w-100">
              <label>Street address*</label>
            </div>
            <TextField
              className={`w-100 signup-email-field ${
                empty_error ? "empty-account-fields" : ""
              }`}
              name="street_address"
              type="text"
              variant="outlined"
              value={props.profile.street_address}
              onChange={(event) => {
                setEmptyError(false);
                props.handleChange(event);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container second-input">
            <div className="w-100">
              <label>City/ town/ village*</label>
            </div>
            <TextField
              className={`w-100 signup-email-field ${
                empty_error ? "empty-account-fields" : ""
              }`}
              name="city"
              type="text"
              variant="outlined"
              value={props.profile.city}
              onChange={(event) => {
                setEmptyError(false);
                props.handleChange(event);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
          <div className="info-billing-container second-input">
            <div className="w-100">
              <label>VAT number</label>
            </div>
            <TextField
              className={`w-100 signup-email-field ${
                empty_error ? "empty-account-fields" : ""
              }`}
              name="vat_number"
              type="text"
              variant="outlined"
              value={props.profile.vat_number}
              onChange={(event) => {
                setEmptyError(false);
                props.handleChange(event);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container">
            <div className="w-100">
              <label>State/ Province/ Region*</label>
            </div>
            <TextField
              className={`w-100 signup-email-field ${
                empty_error ? "empty-account-fields" : ""
              }`}
              name="state"
              type="text"
              variant="outlined"
              value={props.profile.state}
              onChange={(event) => {
                setEmptyError(false);
                props.handleChange(event);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
          <div className="info-billing-container second-input">
            <div className="w-100">
              <label>Postal code*</label>
            </div>
            <TextField
              className={`w-100 signup-email-field ${
                empty_error ? "empty-account-fields" : ""
              }`}
              name="postal_code"
              type="text"
              variant="outlined"
              value={props.profile.postal_code}
              onChange={(event) => {
                setEmptyError(false);
                props.handleChange(event);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container">
            <div className="w-100">
              <label>Country*</label>
            </div>
            <TextField
              className={`w-100 signup-email-field ${
                empty_error ? "empty-account-fields" : ""
              }`}
              name="country"
              type="text"
              variant="outlined"
              value={props.profile.country}
              onChange={(event) => {
                setEmptyError(false);
                props.handleChange(event);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
        </div>
        {empty_error && (
          <div className="mt-2 w-100 d-flex justify-content-end">
            <p className="text-danger">Please fill atleast one field</p>
          </div>
        )}
        <div className="w-100 d-flex justify-content-end account-setting-update">
          <button type="submit" className="billing-btn">
            {props.loading ? (
              <CircularProgress className="text-black" />
            ) : (
              "SAVE DETAILS"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default BillingDetails;
