import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { Tab, Tabs, Box, CircularProgress, Button } from "@mui/material";
import Swal from "sweetalert2";
import API from "../../../api/ClientApi";
import { userUnreadCountNotifications } from "../../../actions/notificationActions";

const ConfirmOwnership = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [url, setUrl] = useState("");
  const items = [{ label: "Confirm ownership" }];
  const home = { label: "Home", url: "/dashboard/platform" };

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const handleDownloadClick = async () => {
    const filePath = `https://app.webylinks.com/webylinks/storage/app/${url?.web_url}`;

    try {
      const response = await fetch(filePath);
      const text = await response.text();

      // Create a Blob from the text content
      const blob = new Blob([text], { type: "text/plain" });

      // Create an anchor element
      const anchor = document.createElement("a");

      // Set the anchor's attributes
      anchor.href = URL.createObjectURL(blob);
      anchor.target = "_blank";
      anchor.download = `${url?.web_url}`;

      // Trigger a click event on the anchor
      anchor.click();
    } catch (error) {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const urls = `/api/confirm-ownership-by-publisher/${id}`;

    try {
      const response = await API.put(urls);
      Swal.fire({
        title: "Success!",
        html: "Ownership confirmed successfully",
        icon: "success",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
      localStorage.setItem("selectedSubRoute", "/dashboard/platform");
      navigate("/dashboard/platform");
      dispatch(userUnreadCountNotifications());
    } catch (error) {
      // Handle error if needed
      Swal.fire({
        title: "Error!",
        html: "Site not confirmed",
        icon: "error",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "buyer" || props.publish===0) {
      navigate("/dashboard/website-list");
    }
    const fetchData = async () => {
      setLoad(true);
      try {
        const response = await API.get(`/api/publisher/${id}`);
        setUrl(response?.data);
        setLoad(false);
      } catch (error) {
        setLoad(false);
        return error;
      }
    };
    fetchData();
  }, [localStorage.getItem("role")]);

  return (
    <div className="w-100 account-settings-main">
      <BreadCrumb model={items} home={home} />
      <div className="w-100 as-header">
        <h2>Confirm Ownership</h2>
      </div>

      <div className="progress-main-wrap">
        <div className="progress" style={{ height: "8px" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `100%` }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <div className="ownership-header">
        <h4>
          Please confirm your ownership of website by following the
          instructions.
        </h4>
        <p>You may skip this step now and get back to verification later.</p>
      </div>
      {load ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-100 ownership-wrapper">
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            variant="scrollable"
          >
            <Tab
              className={`${
                currentTabIndex === 0 ? "tab-active" : "account-list-tabs"
              }`}
              label="File Upload"
            />
            <Tab
              className={`${
                currentTabIndex === 1 ? "tab-active" : "account-list-tabs"
              }`}
              label="Post HTML Code"
            />
          </Tabs>

          {currentTabIndex === 0 && (
            <Box className="ownership-step-main">
              <div className="d-flex align-items-center w-100 ownership-file-upload-steps upload-steps-1">
                <p>1. Click</p>
                <button
                  className="ownership-download-btn"
                  onClick={handleDownloadClick}
                >
                  Download File
                </button>
              </div>
              <div className="w-100 ownership-file-upload-steps">
                <p>2. Add downloaded file to your website’s root directory</p>
              </div>
              <div className="w-100 ownership-file-upload-steps">
                3. After that the file should be accessible at{" "}
                <span>
                  <Link
                    to={`${
                      url?.url?.endsWith("/") ? url?.url : url?.url + "/"
                    }${url?.web_url}`}
                  >{`${url?.url?.endsWith("/") ? url?.url : url?.url + "/"}${
                    url?.web_url
                  }`}</Link>
                </span>
              </div>
              <div className="w-100 ownership-file-upload-steps">
                4. Click "Confirm"
              </div>
              <div className="w-100 ownership-file-upload-steps">
                5. Our system will check your website
              </div>
              <div className="w-100 ownership-file-upload-steps">
                6. Once the verification process is completed, you will be
                notified about its results.
              </div>
              <div className="w-100 d-flex justify-content-end ownership-btn-wrapper">
                <button
                  className="ownership-back-btn"
                  onClick={() => navigate("/dashboard/platform")}
                >
                  Back
                </button>
                <button
                  className="ownership-confirm-btn"
                  onClick={(e) => handleSubmit(e)}
                >
                  {" "}
                  {loading ? <CircularProgress className="text-black"/> : "Confirm"}
                </button>
              </div>
            </Box>
          )}
          {currentTabIndex === 1 && (
            <Box className="ownership-step-main">
              <div className="w-100 ownership-file-upload-steps">
                <p>
                  1. Include the following code in the source code on the main
                  page of your website:{" "}
                  {"<!-- ce7d79016f379c22e14fa4712f849666b8bb4141 -->"}
                </p>
              </div>
              <div className="w-100 ownership-file-upload-steps">
                2. Click "Confirm"
              </div>
              <div className="w-100 ownership-file-upload-steps">
                3. Our system will check your website
              </div>
              <div className="w-100 ownership-file-upload-steps">
                4. Once the verification process is completed, you will be
                notified about its results.
              </div>
              <div className="w-100 d-flex justify-content-end ownership-btn-wrapper">
                <button
                  className="ownership-back-btn"
                  onClick={() => navigate("/dashboard/platform")}
                >
                  Back
                </button>
                <button className="ownership-confirm-btn">Confirm</button>
              </div>
            </Box>
          )}
        </div>
      )}
    </div>
  );
};

export default ConfirmOwnership;
