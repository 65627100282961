import React from "react";
import { CircularProgress } from "@material-ui/core";
import { Dropdown } from "primereact/dropdown";

const NotificationSettings = (props) => {
  const noti_mail = [
    { name: "About every event", code: "yes" },
    { name: "Once a day", code: "no" },
  ];
  const noti_phone = [
    { name: "ON", code: "yes" },
    { name: "OFF", code: "no" },
  ];

  function getNotyByCode(code) {
    const foundCategory = noti_mail.find((item) => item.code === code);
    return foundCategory ? foundCategory.name : "Unknown Category";
  }

  function getNotyPhoneByCode(code) {
    const foundCategory = noti_phone.find((item) => item.code === code);
    return foundCategory ? foundCategory.name : "Unknown Category";
  }

  return (
    <div className="noty-info-main info-main-acc">
      <form onSubmit={props.handleSubmit}>
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container">
            <label for="exampleFormControlSelect1">
              Get notification via email
            </label>
            <Dropdown
              name="get_notifiction_via_email"
              value={{
                name: getNotyByCode(props.profile.get_notifiction_via_email),
                code: props.profile.get_notifiction_via_email,
              }}
              onChange={(event) => props.handleChange(event)}
              options={noti_mail}
              optionLabel="name"
              placeholder="Select email notification period"
              className="w-100 prime-mu-select"
            />
          </div>
          <div className="info-billing-container second-input">
            <label for="exampleFormControlSelect1">
              Receive text messages on my phone
            </label>
            <Dropdown
              name="receive_text_on_phone"
              value={{
                name: getNotyPhoneByCode(props.profile.receive_text_on_phone),
                code: props.profile.receive_text_on_phone,
              }}
              onChange={(event) => props.handleChange(event)}
              options={noti_phone}
              optionLabel="name"
              placeholder="Receive notification via phone"
              className="w-100 prime-mu-select"
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container info-check">
            <div className="f-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={
                  props.profile?.essentials_letters === "no" ? "yes" : "no"
                }
                checked={props.profile?.essentials_letters === "yes"}
                name="essentials_letters"
                onChange={(event) => props.handleChange(event)}
                id="flexCheckChecked"
              />
              <label
                className="form-check-label noty-labels"
                for="flexCheckChecked"
              >
                Essential letters
              </label>
            </div>
            <p className="checker-text">
              Letters about everything related to your tasks and orders at
              webylinks. We highly recommend you to stay subscribed to these
              letters as they reflect the gist of your cooperation with
              webylinks.
            </p>
            <div className="f-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={props.profile?.helpfull_letters === "no" ? "yes" : "no"}
                checked={props.profile?.helpfull_letters === "yes"}
                name="helpfull_letters"
                onChange={(event) => props.handleChange(event)}
                id="flexCheckChecked"
              />
              <label
                className="form-check-label noty-labels"
                for="flexCheckChecked"
              >
                Helpful letters
              </label>
            </div>
            <p className="checker-text">
              Letters about everything related to your tasks and orders at
              webylinks. We highly recommend you to stay subscribed to these
              letters as they reflect the gist of your cooperation with Adsy.
            </p>
            <div className="f-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={props.profile?.weekkly_updates === "no" ? "yes" : "no"}
                checked={props.profile?.weekkly_updates === "yes"}
                name="weekkly_updates"
                onChange={(event) => props.handleChange(event)}
                id="flexCheckChecked"
              />
              <label
                className="form-check-label noty-labels"
                for="flexCheckChecked"
              >
                Weekly updates
              </label>
            </div>
            <p className="checker-text">
              Letters about everything related to your tasks and orders at
              webylinks. We highly recommend you to stay subscribed to these
              letters as they reflect the gist of your cooperation with Adsy.
            </p>
          </div>
          <div className="whatsapp-container">
            <p> How I would like to receive the notifications on my phone:</p>
            <div className="f-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={
                  props.profile?.notification_type_whatsapp === "no"
                    ? "yes"
                    : "no"
                }
                checked={props.profile?.notification_type_whatsapp === "yes"}
                name="notification_type_whatsapp"
                onChange={(event) => props.handleChange(event)}
                id="flexCheckChecked"
              />
              <label
                className="form-check-label noty-labels"
                for="flexCheckChecked"
              >
                Whatsapp
              </label>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-end account-setting-update">
          <button type="submit" className="billing-btn">
            {props.loading ? (
              <CircularProgress className="text-dark" />
            ) : (
              "SAVE DETAILS"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NotificationSettings;
