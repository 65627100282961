import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfileMain from "./componsnts/ProfileMain";

const PublisherProfile = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (localStorage.getItem("role") === "buyer") {
  //     navigate("/dashboard/website-list");
  //   }
  // }, [localStorage.getItem("role")]);

  return <div className="profile-manager-main"><ProfileMain/></div>;
};

export default PublisherProfile;
