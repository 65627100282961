import React, { useEffect, useState, useRef } from "react";
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  IconButton,
  Toolbar,
  AppBar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
  useTheme,
  Box,
  Select,
  MenuItem,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import BuyerDashboard from "./components/BuyerDashboard";
import Logo from "../../assets/images/logoMain.png";
import ContactIcon from "../../assets/images/contactIcon.png";
import NotificationIcon from "../../assets/images/notificationIcon.png";
import AvatarIcon from "../../assets/images/avatarIcon.png";
import avatarBlueIcon from "../../assets/images/avatarBlue.png";
import balanceIcon from "../../assets/images/balance.png";
import contentPurchaseIcon from "../../assets/images/contentPurchase.png";
import listsIcon from "../../assets/images/lists.png";
import offers from "../../assets/images/offers.png";
import packageIcon from "../../assets/images/package.png";
import recommended from "../../assets/images/recommended.png";
import shield from "../../assets/images/shield.png";
import tasks from "../../assets/images/tasks.png";
import AddUser from "../../assets/images/add-user.png";
import GoodReview from "../../assets/images/good-review.png";
import Consult from "../../assets/images/consultation.png";
import WebIcon from "../../assets/images/website-icon.svg";
import WarningIcon from "../../assets/images/warningIcon.png";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import API from "../../api/ClientApi";
import Swal from "sweetalert2";
import "./styles/dashboard.css";
import ProfileManager from "../ProfileManager/ProfileManager";
import MyWebsites from "../MyWebsites/MyWebsites";
import { useSelector } from "react-redux";
import UpdateWebsite from "../MyWebsites/components/UpdateWebsite";
import { selectRole, userProfile } from "../../actions/userActions";
import BuyerForm from "./components/BuyerForm";
import OrderArticle from "./components/OrderArticle";
import CreateOpenOffer from "./components/CreateOpenOffer";
import ContentPurchase from "./components/ContentPurchase";
import OpenOffers from "../OpenOffers/OpenOffers";
import OpenOffersBuyer from "../OpenOffers/OpenOffersBuyer";
import TasksBuyer from "../Tasks/TasksBuyer";
import TasksPublisher from "../Tasks/TasksPublisher";
import DATrafficForm from "./components/DATrafficForm";
import BalanceBuyer from "../Balance/BalanceBuyer";
import TasksCreated from "./components/TasksCreated";
import ConfirmOwnership from "../MyWebsites/components/ConfirmOwnership";
import Lists from "../Lists/Lists";
import ProfileManagerPublisher from "../ProfileManager/ProfileManagerPublisher";
import BalancePublisher from "../Balance/BalancePublisher";
import AddWebsiteSteps from "../MyWebsites/components/AddWebsiteSteps";
import FullLoader from "../../components/FullLoader/FullLoader";
import OpenOfferForm from "../OpenOffers/components/OpenOfferForm";
import EditOpenOfferForm from "../OpenOffers/components/EditOpenOfferForm";
import { userBalance } from "../../actions/walletActions";
import { OverlayPanel } from "primereact/overlaypanel";
import UpdateTaks from "../Tasks/UpdateTaks";
import NotificationsPublisher from "../Notifications/NotificationsPublisher";
import NotificationsBuyer from "../Notifications/NotificationsBuyer";
import ModerationBuyer from "../Moderation/ModerationBuyer";
import ModerationPublisher from "../Moderation/ModerationPublisher";
import {
  userCheckNotifications,
  userUnreadCountNotifications,
} from "../../actions/notificationActions";
import BidMain from "../BidFrom/BidMain";
import OfferView from "../OfferView/OfferView";
import OfferViewBuyer from "../OfferView/OfferViewBuyer";
import TaskView from "../TaskView/TaskView";
import TaskPubView from "../TaskView/TaskPubView";
import SecurityDeposit from "../SecurityDeposit/SecurityDeposit";
import { userModeration } from "../../actions/moderationActions";
import PublisherProfile from "../PublisherProfile/PublisherProfile";
import ProfileListingMain from "../PublisherProfileList/ProfileListingMain";
import PerformerList from "../PerformerList/PerformerList";
import CustomizedOrderView from "../CustomizedOrderView/CustomizedOrderView";
import ArticleView from "../ArticleView/ArticleView";
import InviteModal from "./components/InviteModal";
import FeedbackModal from "./components/FeedbackModal";
import ContactModal from "./components/ContactModal";
import ConsultationModal from "../../components/ConsultationModal/ConsultationModal";
import NotFound from "../NotFound/NotFound";
import TawkTo from "../../components/TawkTo";
import "../../components/SignupSteps/signupSteps.css";

const Dashboard = () => {
  const op = useRef(null);
  const navigate = useNavigate();
  const [inviteModal, setInviteModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, loading } = useSelector((state) => state.profile);
  const { notification_unread_count } = useSelector(
    (state) => state.unreadCount
  );
  const { notification_check } = useSelector(
    (state) => state.notificationCheck
  );
  const { balance, wloading } = useSelector((state) => state.balance);
  const [open, setOpen] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const initialRole =
    localStorage.getItem("role") ||
    (user?.user?.type_id === 1 ? "buyer" : "publisher");
  const [role, setRole] = useState(initialRole);
  const [anchorEl, setAnchorEl] = useState(null);
  const [load, setLoad] = useState(false);
  const [wload, setWload] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [uProfile, setUProfile] = useState({});
  const openPop = Boolean(anchorEl);
  const id = openPop ? "notification-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInviteClose = () => {
    setInviteModal(false);
  };

  const handleFeedbackClose = () => {
    setFeedbackModal(false);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };
  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  const routesWithoutNavbar = [
    "/dashboard",
    "/dashboard/website-list",
    "/dashboard/profile",
    "/dashboard/platform",
    "/dashboard/publisher/open-offers",
    "/dashboard/buyer/open-offers",
    "/dashboard/publisher/tasks",
    "/dashboard/buyer/content",
    "/dashboard/buyer/tasks",
    "/add-websites",
    "/dashboard/order-article",
    "/create-open-offer",
    "/content-purchase",
    "/dashboard/balance",
    "/dashboard/list",
    "/dashboard/publisher/profile",
    "/dashboard/publisher/balance",
    "/dashboard/addWebsite",
    "/dashboard/open-offer-form",
    "/dashboard/buyer/notifications",
    "/dashboard/publisher/notifications",
    "/dashboard/publisher/moderation",
    "/dashboard/buyer/moderation",
    "/dashboard/balance/make-deposit",
  ];

  const exceptionRoutes = ["/api/verify-email", "/reset-password"];

  const is404 = () => {
    return (
      !routesWithoutNavbar.includes(location.pathname) &&
      !location.pathname.startsWith("/dashboard/update-website") &&
      !location.pathname.startsWith("/dashboard/buy-form") &&
      !location.pathname.startsWith("/dashboard/update-task-form") &&
      !location.pathname.startsWith("/dashboard/publisher/confirm") &&
      !location.pathname.startsWith("/dashboard/da-traffic") &&
      !location.pathname.startsWith("/dashboard/edit-offer") &&
      !location.pathname.startsWith("/dashboard/publisher/bid/offer") &&
      !location.pathname.startsWith("/api/reset-password") &&
      !location.pathname.startsWith("/dashboard/publisher/offer") &&
      !location.pathname.startsWith("/dashboard/task") &&
      !location.pathname.startsWith("/dashboard/publisher/task") &&
      !location.pathname.startsWith("/dashboard/publisher/moderation") &&
      !location.pathname.startsWith("/dashboard/publisher/profile/main") &&
      !location.pathname.startsWith(
        "/dashboard/publisher/profile-listing/main"
      ) &&
      !location.pathname.startsWith("/dashboard/performer-list") &&
      !location.pathname.startsWith("/dashboard/customized-order") &&
      !location.pathname.startsWith("/dashboard/article-view") &&
      !location.pathname.startsWith("/dashboard/invite") &&
      !location.pathname.startsWith("/dashboard/feedback") &&
      !location.pathname.startsWith("/dashboard/buyer/offer") &&
      !location.pathname.startsWith("/webylinks/storage/app/public/attachments")
    );
  };
  const sidebarLinks =
    role === "buyer"
      ? [
          {
            label: "Search for Websites",
            url: "/dashboard/website-list",
            active:
              location.pathname === "/dashboard/website-list" ? true : false,
          },
          // {
          //   label: "New Packages",
          //   url: "/dashboard/da-traffic",
          //   active:
          //     location.pathname === "/dashboard/da-traffic" ? true : false,
          // },
          // {
          //   label: "Open Offers",
          //   url: "/dashboard/buyer/open-offers",
          //   active:
          //     location.pathname === "/dashboard/buyer/open-offers"
          //       ? true
          //       : false,
          // },
          {
            label: "Tasks",
            url: "/dashboard/buyer/tasks",
            active:
              location.pathname === "/dashboard/buyer/tasks" ? true : false,
          },
          // {
          //   label: "Content Purchase",
          //   url: "/dashboard/buyer/content",
          //   active:
          //     location.pathname === "/dashboard/buyer/content" ? true : false,
          // },
          // {
          //   label: "Lists",
          //   url: "/dashboard/list",
          //   active: location.pathname === "/dashboard/list" ? true : false,
          // },
          // { label: "Recommended sites", url: "" },
          {
            label: "Balance",
            url: "/dashboard/balance",
            active: location.pathname === "/dashboard/balance" ? true : false,
          },
          {
            label: "Invite People",
            url: "/dashboard/invite",
            active: location.pathname === "/dashboard/invite" ? true : false,
          },
          {
            label: "Send Feedback",
            url: "/dashboard/feedback",
            active: location.pathname === "/dashboard/feedback" ? true : false,
          },
          {
            label: "Consultation",
            url: "/dashboard/feedback",
            active: location.pathname === "/dashboard/feedback" ? true : false,
          },
          // { label: "Earn $1000/-month", url: "" },
        ]
      : [
          // {
          //   label: "My platforms",
          //   url: "",
          //   subLists: [
          //     { label: "My Websites", url: "/platform" },
          //     { label: "My Social media", url: "" },
          //   ],
          // },
          {
            label: "My Platform",
            url: "/dashboard/platform",
            active: location.pathname === "/dashboard/platform" ? true : false,
          },
          {
            label: "Tasks",
            url: "/dashboard/publisher/tasks",
            active:
              location.pathname === "/dashboard/publisher/tasks" ? true : false,
          },
          // {
          //   label: "Open Offers",
          //   url: "/dashboard/publisher/open-offers",
          //   active:
          //     location.pathname === "/dashboard/publisher/open-offers"
          //       ? true
          //       : false,
          // },
          {
            label: "Balance",
            url: "/dashboard/publisher/balance",
            active:
              location.pathname === "/dashboard/publisher/balance"
                ? true
                : false,
          },
          {
            label: "Invite People",
            url: "/dashboard/invite",
            active: location.pathname === "/dashboard/invite" ? true : false,
          },
          {
            label: "Send Feedback",
            url: "/dashboard/feedback",
            active: location.pathname === "/dashboard/feedback" ? true : false,
          },
          {
            label: "Consultation",
            url: "/dashboard/feedback",
            active: location.pathname === "/dashboard/feedback" ? true : false,
          },
          // { label: "Referal program", url: "" },
        ];

  const getIconForLink = (label) => {
    switch (label) {
      case "Search for Websites":
        return <img src={avatarBlueIcon} alt="avfatar-blue-webylinks" />;
      case "New Packages":
        return <img src={packageIcon} alt="package-webylinks" />;
      case "Open Offers":
        return <img src={offers} alt="offers-webylinks" />;
      case "My Platform":
        return <img src={WebIcon} alt="offers-webylinks" />;
      case "Tasks":
        return <img src={tasks} alt="Tasks-webylinks" />;
      case "Content Purchase":
        return <img src={contentPurchaseIcon} alt="Content Purchase Icon" />;
      case "Lists":
        return <img src={listsIcon} alt="Lists Icon" />;
      case "Recommended sites":
        return <img src={recommended} alt="Recommended Sites Icon" />;
      case "Balance":
        return <img src={balanceIcon} alt="Balance Icon" />;
      case "Earn $1000/-month":
        return <img src={shield} alt="Earn Icon" />;
      case "Invite People":
        return <img src={AddUser} alt="invite" />;
      case "Send Feedback":
        return <img src={GoodReview} alt="feedback" />;
      case "Consultation":
        return <img src={Consult} alt="Consultation" />;
      default:
        return null;
    }
  };

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("lg"));
  const smallScreenXS = useMediaQuery("(max-width:820px)");
  const sidebarWidth = isSmallScreen ? 0 : 277;

  // ------------ Open and close drawer ---------------------------//

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  //---------------------------------------------------------------//

  // ------------ Opening and closing signup steps ----------------//

  const handleClose = () => {
    setOpen(false);
  };
  //-------------------------------------------------------------//

  const handleChange = async (e) => {
    // e.preventDefault();
    setWload(true);
    await dispatch(selectRole({ select_role: e.target.value }));
    await dispatch(userProfile(e.target.value));
    await dispatch(userUnreadCountNotifications());
    await dispatch(userBalance(e.target.value));
    await dispatch(userUnreadCountNotifications());
    await dispatch(userCheckNotifications());
    await dispatch(userModeration());
    setRole(e.target.value);
    localStorage.setItem("role", e.target.value);
    if (e.target.value === "buyer") {
      navigate("/dashboard/website-list");
    } else {
      navigate("/dashboard/platform");
    }
    setWload(false);
  };

  const handleAccordionToggle = (link) => {
    link.subLists && (link.open = !link.open);
  };

  const handleResend = async (e) => {
    setLoad(true);
    e.preventDefault();
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };
    const data = await API.post(
      "/api/email/resend",
      { is_reverify: 1 },
      config
    );
    if (data) {
      setLoad(false);
      Swal.fire({
        title: "Success!",
        html: data.data,
        icon: "success",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
    }
  };

  useEffect(() => {
    const fetchDataAndNavigate = async () => {
      setWload(true);
      await dispatch(
        userProfile(
          user?.can_publish === 0 ? "buyer" : localStorage.getItem("role")
        )
      );
      await dispatch(
        userBalance(
          user?.can_publish === 0 ? "buyer" : localStorage.getItem("role")
        )
      );
      await dispatch(userUnreadCountNotifications());
      await dispatch(userCheckNotifications());
      await dispatch(userModeration());
      setWload(false);
    };

    fetchDataAndNavigate();
  }, [dispatch]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
    } else if (!loading) {
      const storedUrl = localStorage.getItem("storedUrl");
      if (location.pathname === "/dashboard") {
        if (user?.user?.type_id === 1) {
          setRole("buyer");
          localStorage.setItem("role", "buyer");
          navigate("/dashboard/website-list");
        } else if (user?.user?.type_id === 2) {
          setRole("publisher");
          localStorage.setItem("role", "publisher");
          navigate("/dashboard/platform");
        }
      } else {
        localStorage.setItem("storedUrl", location.pathname);
        if (user?.user?.type_id === 1) {
          setRole("buyer");
          localStorage.setItem("role", "buyer");
        } else if (user?.user?.type_id === 2) {
          setRole("publisher");
          localStorage.setItem("role", "publisher");
        }
        navigate(storedUrl || location.pathname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.user?.type_id, localStorage.getItem("access_token")]);

  useEffect(() => {
    if (user?.user && location.pathname !== "/dashboard") {
      localStorage.setItem("storedUrl", location.pathname);
    }
  }, [user, location.pathname]);

  return is404() ? (
    <NotFound />
  ) : wload ? (
    <FullLoader />
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        {isSmallScreen ? (
          <Drawer
            variant="temporary"
            anchor="left"
            open={openDrawer}
            onClose={handleCloseDrawer}
            className="drawer-dashboard"
          >
            <List className="dashboard-drawer">
              <ListItem
                style={{ maxWidth: "250px", cursor: "pointer" }}
                onClick={() => {
                  localStorage?.removeItem("resumeData");
                  if (localStorage.getItem("role") === "buyer") {
                    navigate("/dashboard/website-list");
                  } else {
                    navigate("/dashboard/platform");
                  }
                }}
              >
                <img
                  src={Logo}
                  alt="Logo-webylinks"
                  style={{ width: "100%", height: "100%" }}
                />
              </ListItem>
              <hr />
              {sidebarLinks.map((link, index) => (
                <ListItem
                  className="image-circle-sidebar-container"
                  button
                  onClick={() => {
                    setContactOpen(false);
                    if (
                      link?.label !== "Send Feedback" &&
                      link?.label !== "Invite People" &&
                      link?.label !== "Consultation"
                    ) {
                      localStorage?.removeItem("resumeData");
                      localStorage.setItem("selectedSubRoute", link.url);
                      navigate(`${link.url}`);
                    } else {
                      if (link?.label === "Invite People") {
                        setInviteModal(true);
                      }
                      if (link?.label === "Send Feedback") {
                        setFeedbackModal(true);
                      }
                      if (link?.label === "Consultation") {
                        setVisible(true);
                      }
                    }
                    handleCloseDrawer();
                  }}
                  key={index}
                >
                  <ListItemIcon
                    className={`image-circle-sidebar ${
                      link?.active ? "image-circle-sidebar-active" : ""
                    }`}
                  >
                    {getIconForLink(link.label)}
                  </ListItemIcon>
                  <ListItemText primary={link.label} />
                </ListItem>
              ))}
            </List>
          </Drawer>
        ) : (
          <Drawer
            variant="permanent"
            anchor="left"
            className="drawer-dashboard"
          >
            <List className="dashboard-drawer">
              <ListItem
                style={{ maxWidth: sidebarWidth, cursor: "pointer" }}
                onClick={() => {
                  localStorage?.removeItem("resumeData");
                  if (localStorage.getItem("role") === "buyer") {
                    navigate("/dashboard/website-list");
                  } else {
                    navigate("/dashboard/platform");
                  }
                }}
              >
                <img
                  src={Logo}
                  alt="Logo-webylinks"
                  style={{ width: "100%", height: "100%" }}
                />
              </ListItem>
              <hr />
              {sidebarLinks.map((link, index) => (
                <React.Fragment key={index}>
                  {link.subLists ? (
                    <>
                      <Accordion
                        expanded={link.open}
                        className="publisher-list-sidebar"
                        onChange={() => handleAccordionToggle(link)}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <ListItemIcon className="image-circle-sidebar">
                            {getIconForLink(link.label)}
                          </ListItemIcon>
                          <ListItemText primary={link.label} />
                        </AccordionSummary>
                        <AccordionDetails>
                          <List component="div" disablePadding>
                            {link.subLists.map((sublink, subIndex) => (
                              <ListItem
                                key={subIndex}
                                button
                                onClick={() => navigate(`${sublink.url}`)}
                              >
                                <ListItemText primary={sublink.label} />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  ) : (
                    <ListItem
                      className="image-circle-sidebar-container"
                      button
                      onClick={() => {
                        setContactOpen(false);
                        if (
                          link?.label !== "Send Feedback" &&
                          link?.label !== "Invite People" &&
                          link?.label !== "Consultation"
                        ) {
                          localStorage?.removeItem("resumeData");
                          localStorage.setItem("selectedSubRoute", link.url);
                          navigate(`${link.url}`);
                        } else {
                          if (link?.label === "Invite People") {
                            setInviteModal(true);
                          }
                          if (link?.label === "Send Feedback") {
                            setFeedbackModal(true);
                          }
                          if (link?.label === "Consultation") {
                            setVisible(true);
                          }
                        }
                      }}
                      key={index}
                    >
                      <ListItemIcon
                        className={`image-circle-sidebar ${
                          link?.active ? "image-circle-sidebar-active" : ""
                        }`}
                      >
                        {getIconForLink(link.label)}
                      </ListItemIcon>
                      <ListItemText primary={link.label} />
                    </ListItem>
                  )}
                </React.Fragment>
              ))}
            </List>
          </Drawer>
        )}
        <div
          className={`menu-container main-content-area ${
            isSmallScreen ? "responsive-menu" : ""
          } ${isDarkMode ? "menu-container-dark" : "menu-container-light"}`}
        >
          {" "}
          {/* Added marginLeft */}
          <AppBar position="static" className="app-bar">
            <Toolbar className="p-0 d-flex justify-content-between">
              {isSmallScreen && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  className="burger-icon"
                  onClick={handleOpenDrawer}
                >
                  <MenuIcon />
                </IconButton>
              )}
              {!smallScreenXS && (
                <div
                  className="d-flex balance"
                  onClick={() => {
                    localStorage?.removeItem("resumeData");
                    if (localStorage.getItem("role") === "buyer") {
                      navigate("/dashboard/balance");
                    } else {
                      navigate("/dashboard/publisher/balance");
                    }
                  }}
                >
                  <p>
                    Balance:{" "}
                    {wloading ? (
                      <CircularProgress
                        style={{ width: "15px", height: "15px" }}
                      />
                    ) : (
                      <span>
                        ${balance?.balance ? balance?.balance : "0.00"}
                      </span>
                    )}
                  </p>
                  <div className="mx-4 verticalo-line" />
                  <p>
                    Reserved:{" "}
                    <span>
                      ${balance?.reserved ? balance?.reserved : "0.00"}
                    </span>
                  </p>
                  <div className="mx-4 verticalo-line" />
                  <p>
                    Bonus:{" "}
                    <span>${balance?.bonus ? balance?.bonus : "0.00"}</span>
                  </p>
                </div>
              )}
              <Box className="d-flex">
                <IconButton color="inherit" className="icon-header-btn">
                  <img
                    src={ContactIcon}
                    className="img-dashboard"
                    alt="contact-webylinks"
                    onClick={() => setContactOpen(true)}
                  />{" "}
                </IconButton>
                <div className="notify-container">
                  <IconButton
                    color="inherit"
                    className="icon-header-btn"
                    onClick={() => {
                      localStorage?.removeItem("resumeData");
                      if (localStorage.getItem("role") === "buyer") {
                        navigate("/dashboard/buyer/notifications");
                      } else {
                        navigate("/dashboard/publisher/notifications");
                      }
                    }}
                  >
                    <img
                      src={NotificationIcon}
                      className="img-dashboard"
                      alt="notification-webylinks"
                    />
                  </IconButton>
                  {notification_unread_count?.notificationUnReadCount > 0 && (
                    <span className="notification-count">
                      {notification_unread_count?.notificationUnReadCount}
                    </span>
                  )}
                </div>
                <IconButton
                  color="inherit"
                  // onClick={handleClick}
                  onClick={(e) => op.current.toggle(e)}
                  className="icon-header-btn"
                >
                  <img
                    src={AvatarIcon}
                    className="img-dashboard"
                    alt="avatar-webylinks"
                  />
                </IconButton>
                <OverlayPanel ref={op}>
                  <List className="account-setting-selector">
                    <ListItem
                      button
                      onClick={async () => {
                        localStorage?.removeItem("resumeData");
                        handlePopClose();
                        localStorage.setItem("account_tab", 0);
                        localStorage.setItem(
                          "selectedSubRoute",
                          role === "buyer"
                            ? "/dashboard/profile"
                            : "/dashboard/publisher/profile"
                        );
                        navigate(
                          role === "buyer"
                            ? "/dashboard/profile"
                            : "/dashboard/publisher/profile"
                        );
                        op.current.toggle(false);
                      }}
                      className="avatar-manu-btn"
                    >
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Account Settings" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        localStorage?.removeItem("resumeData");
                        localStorage.removeItem("access_token");
                        localStorage.removeItem("selectedSubRoute");
                        localStorage.removeItem("role");
                        localStorage.removeItem("url");
                        localStorage.removeItem("storedUrl");
                        localStorage.removeItem("setSubRoute");
                        op.current.toggle(false);
                        navigate("/login");
                      }}
                      className="avatar-manu-btn"
                    >
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </OverlayPanel>
                {user?.can_publish === 1 && (
                  <div className="d-flex">
                    <Select
                      name="role"
                      value={role}
                      variant="standard"
                      disableUnderline
                      className="role-select-options"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 150,
                            // position: "fixed",
                          },
                        },
                        variant: "menu",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      onChange={(e) => handleChange(e)}
                    >
                      <MenuItem value="buyer">Buyer</MenuItem>
                      <MenuItem value="publisher">Publisher</MenuItem>
                    </Select>
                  </div>
                )}
              </Box>
            </Toolbar>
          </AppBar>
          {user?.is_verified <= 0 && user?.login_3rdparty_type === null ? (
            <div className="d-flex align-items-center text-center verification-warning-dashboard">
              <img src={WarningIcon} alt="warning" />
              <p className="px-2">
                Please{" "}
                <span onClick={(e) => handleResend(e)}>Confirm Your Email</span>
                {load ? (
                  <span>
                    <CircularProgress className="mx-2 text-black" />
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>
          ) : (
            ""
          )}
          {user?.name === null ? (
            <div className="d-flex align-items-center text-center profile-warning-dashboard">
              <img src={WarningIcon} alt="warning" />
              <p className="px-2">
                Please{" "}
                <span
                  onClick={() => {
                    localStorage.setItem("account_tab", 1);
                    if (localStorage.getItem("role") === "publisher") {
                      navigate("/dashboard/publisher/profile");
                    } else {
                      navigate("/dashboard/profile");
                    }
                  }}
                >
                  fill in your name
                </span>{" "}
                to let buyers distinguish you as a task performer and send you
                new tasks in the future if they like working with you
              </p>
            </div>
          ) : (
            ""
          )}
          <InviteModal
            inviteModal={inviteModal}
            handleInviteClose={handleInviteClose}
          />
          <FeedbackModal
            feedbackModal={feedbackModal}
            handleFeedbackClose={handleFeedbackClose}
          />
          <ContactModal
            contactOpen={contactOpen}
            setContactOpen={setContactOpen}
          />
          <ConsultationModal visible={visible} setVisible={setVisible} />
          <Routes>
            <Route
              path="website-list"
              element={
                <BuyerDashboard
                  isDarkMode={isDarkMode}
                  id={user?.user?.type_id}
                  setRole={setRole}
                  hash={user?.hash === null}
                />
              }
            />
            <Route path="profile" element={<ProfileManager />} exact />
            <Route
              path="publisher/profile"
              element={<ProfileManagerPublisher publish={user?.can_publish} />}
              exact
            />
            <Route
              path="platform"
              element={<MyWebsites publish={user?.can_publish} />}
              exact
            />
            <Route
              path="update-website/:id"
              element={<UpdateWebsite />}
              exact
            />
            <Route path="buy-form/:id" element={<BuyerForm />} exact />
            <Route path="update-task-form/:id" element={<UpdateTaks />} exact />
            <Route path="order-article" element={<OrderArticle />} exact />
            <Route
              path="create-open-offer"
              element={<CreateOpenOffer />}
              exact
            />
            <Route
              path="content-purchase"
              element={<ContentPurchase />}
              exact
            />
            <Route path="da-traffic" element={<DATrafficForm />} exact />
            {/* <Route
              path="/buyer/open-offers"
              element={<OpenOffersBuyer />}
              exact
            />
            <Route
              path="/publisher/open-offers"
              element={<OpenOffers />}
              exact
            /> */}
            <Route path="/buyer/content" element={<ContentPurchase />} exact />
            <Route
              path="/publisher/tasks"
              element={<TasksPublisher publish={user?.can_publish} />}
              exact
            />
            <Route
              path="/publisher/confirm/:id"
              element={<ConfirmOwnership publish={user?.can_publish} />}
              exact
            />
            <Route path="/buyer/tasks" element={<TasksBuyer />} exact />
            <Route path="/balance" element={<BalanceBuyer />} exact />
            <Route
              path="/publisher/balance"
              element={<BalancePublisher publish={user?.can_publish} />}
              exact
            />
            <Route
              path="/publisher/notifications"
              element={<NotificationsPublisher publish={user?.can_publish} />}
              exact
            />
            <Route
              path="/buyer/notifications"
              element={<NotificationsBuyer />}
              exact
            />
            <Route path="list" element={<Lists />} exact />
            <Route
              path="addWebsite"
              element={<AddWebsiteSteps publish={user?.can_publish} />}
              exact
            />
            <Route path="open-offer-form" element={<OpenOfferForm />} exact />
            <Route
              path="edit-offer/:id"
              element={<EditOpenOfferForm />}
              exact
            />
            {/* <Route
              path="/buyer/moderation"
              element={<ModerationBuyer />}
              exact
            /> */}
            <Route
              path="/publisher/moderation/:id"
              element={<ModerationPublisher publish={user?.can_publish} />}
              exact
            />
            <Route
              path="/publisher/bid/offer/:id"
              element={<BidMain />}
              exact
            />
            <Route path="/publisher/offer/:id" element={<OfferView />} exact />
            <Route path="/buyer/offer/:id" element={<OfferViewBuyer />} exact />
            <Route path="/task/:id" element={<TaskView />} exact />
            <Route
              path="/publisher/task/:id"
              element={<TaskPubView publish={user?.can_publish} />}
              exact
            />
            <Route
              path="/balance/make-deposit"
              element={<SecurityDeposit />}
              exact
            />
            <Route
              path="/publisher/moderation-profile"
              element={<ModerationBuyer publish={user?.can_publish} />}
              exact
            />
            <Route
              path="/publisher/profile/main/:id"
              element={<PublisherProfile publish={user?.can_publish} />}
              exact
            />
            <Route
              path="/publisher/profile-listing/main/:id"
              element={<ProfileListingMain publish={user?.can_publish} />}
              exact
            />
            <Route
              path="/performer-list/:id"
              element={<PerformerList />}
              exact
            />
            <Route
              path="/customized-order/:id"
              element={<CustomizedOrderView />}
              exact
            />
            <Route path="/article-view/:id" element={<ArticleView />} exact />
          </Routes>
        </div>
      </div>
      <TawkTo />
    </ThemeProvider>
  );
};

export default Dashboard;
