import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Box, Typography, Tab, Tabs } from "@mui/material";
import { TabMenu } from "primereact/tabmenu";
import { useGoogleLogin } from "@react-oauth/google";
import AccountMain from "./AccountSettingComponents/AccountMain";
import ContactInfo from "./ContactInfo";
import BillingDetails from "./BillingDetails";
import NotificationSettings from "./NotificationSettings";
import { registerSocialMedia } from "../../../actions/userActions";
import { updateUser, userProfile } from "../../../actions/userActions";
import API from "../../../api/ClientApi";
import Swal from "sweetalert2";
import AddressDetails from "./AddressDetails";

const AccountSettings = () => {
  const { user } = useSelector((state) => state.profile);
  const { moderation_check } = useSelector((state) => state.is_moderation);
  const dispatch = useDispatch();
  const { isUpdated, error } = useSelector((state) => state.update);
  const [loading, setLoading] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(
    parseInt(localStorage?.getItem("account_tab"))
  );
  const [moderated, setModerated] = useState(0);
  const [profileData, setProfileData] = useState({
    name: "",
    email: user?.email,
    paypal_email: "",
    password: "",
    usd_trc20_address: "",
    skype_id: "",
    phone_number: "",
    whatsapp_number: "",
    get_notifiction_via_email: "no", // 1. About every event, 2. Once a day
    essentails_letters: "no", // true or false
    helpfull_letters: "no", // true or false
    weekkly_updates: "no", // true or false
    notification_type_whatsapp: "no", // true or false
    receieve_text_on_phone: "no", // ON OR OFF
    company_name: user?.wl_users_billing_details?.company_name,
    street_address: user?.wl_users_billing_details?.street_address,
    city: user?.wl_users_billing_details?.city,
    state: user?.wl_users_billing_details?.state,
    apt: "",
    postal_code: user?.wl_users_billing_details?.postal_code,
    country: user?.wl_users_billing_details?.country,
    vat_number: user?.wl_users_billing_details?.vat_number,
    name_on_card: "",
    cc_number: "",
    cvv: "",
    expiry_date: "",
  });
  // 20 fields

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Dispatch the updateUser action
      const response = await dispatch(updateUser(profileData));
      // After updateUser action is completed, fetch the updated profile data
      if (response) {
        await dispatch(userProfile(localStorage.getItem("role")));

        // Show a success message

        Swal.fire({
          title: "Success!",
          html: "Profile updated successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (
      name === "get_notifiction_via_email" ||
      name === "receive_text_on_phone"
    ) {
      setProfileData((prevProfileData) => ({
        ...prevProfileData,
        [name]: value.code,
      }));
    } else {
      setProfileData((prevProfileData) => ({
        ...prevProfileData,
        [name]: value,
      }));
    }
  };

  // Goggle login
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      if (codeResponse) {
        axios
          .get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${codeResponse.access_token}`,
                Accept: "application/json",
              },
            }
          )
          .then(async (res) => {
            dispatch(
              registerSocialMedia({
                email: res.data.email,
                login_token: codeResponse.access_token,
                login_type: "google",
              })
            );
            const config = {
              headers: {
                "Content-type": "multipart/form-data",
              },
            };
            await API.post(
              "/api/google-linked-accounts",
              {
                login_token: codeResponse.access_token,
                login_type: "google",
                is_google_connected: 1,
              },
              config
            );
            dispatch(userProfile(localStorage.getItem("role")));
          })
          .catch((err) => {
            return err;
          });
      }
    },
    onError: (error) =>
      Swal.fire({
        title: "Error!",
        html: error,
        icon: "error",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      }),
  });

  useEffect(() => {
    if (user) {
      // Update profileData when user data is available
      setProfileData((prevProfileData) => ({
        ...prevProfileData,
        name: user?.name || "",
        email: user?.email || "",
        paypal_email: user?.wl_users_detail?.paypal_email || "",
        usd_trc20_address: user?.wl_users_detail?.usd_trc20_address || "",
        get_notifiction_via_email:
          user?.wl_users_detail?.get_notifiction_via_email || "no",
        essentials_letters: user?.wl_users_detail?.essentials_letters || "no",
        helpfull_letters: user?.wl_users_detail?.helpfull_letters || "no",
        weekkly_updates: user?.wl_users_detail?.weekkly_updates || "no",
        receieve_text_on_phone:
          user?.wl_users_detail?.receieve_text_on_phone || "no",
        notification_type_whatsapp:
          user?.wl_users_detail?.notification_type_whatsapp || "no",
        receive_text_on_phone:
          user?.wl_users_detail?.receive_text_on_phone || "no",
        company_name: user?.wl_users_billing_details?.company_name || "",
        street_address: user?.wl_users_billing_details?.street_address || "",
        city: user?.wl_users_billing_details?.city || "",
        state: user?.wl_users_billing_details?.state || "",
        postal_code: user?.wl_users_billing_details?.postal_code || "",
        country: user?.wl_users_billing_details?.country || "",
        vat_number: user?.wl_users_billing_details?.vat_number || "",
        whatsapp_number: user?.wl_users_detail?.whatsapp_number || "",
        phone_number: user?.wl_users_detail?.phone_number || "",
        // ... update other fields similarly
      }));
    }
  }, []);

  const items = [
    { label: "ACCOUNT SETTINGS", id: 0 },
    { label: "CONTACT INFO", id: 1 },
    { label: "NOTIFICATION SETTINGS", id: 2 },
    { label: "ADDRESS DETAILS", id: 3 },
    // { label: "BILLING DETAILS", id: 4 },
  ];

  const handleWebTabChange = (e, tabIndex) => {
    localStorage.setItem("account_tab", tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  return (
    <div className="w-100 account-settings-main">
      <div className="w-100 as-header">
        <h2>My Profile</h2>
      </div>
      <div className="w-100">
        <div className="w-100 open-offer-tabs">
          <Tabs
            className="tabs-add-web"
            value={currentTabIndex}
            onChange={handleWebTabChange}
            variant="scrollable"
          >
            {items &&
              items?.map((res, id) => (
                <Tab
                  className={`${
                    currentTabIndex === res?.id
                      ? "tab-active"
                      : "account-list-tabs"
                  }`}
                  icon={
                    <div className="d-flex">
                      <span>{res?.label}</span>
                    </div>
                  }
                  value={res?.id}
                  key={id}
                ></Tab>
              ))}
          </Tabs>
        </div>
        {/* TAB 1 Contents */}
        {currentTabIndex === 0 && (
          <Box>
            <AccountMain
              user={user}
              profile={profileData}
              setProfile={setProfileData}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              authLogin={login}
              loading={loading}
              moderated={moderation_check?.is_moderated}
            />
          </Box>
        )}

        {/* TAB 2 Contents */}
        {currentTabIndex === 1 && (
          <Box>
            <ContactInfo
              user={user}
              profile={profileData}
              setProfile={setProfileData}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              loading={loading}
            />
          </Box>
        )}

        {/* TAB 3 Contents */}
        {currentTabIndex === 2 && (
          <Box>
            <NotificationSettings
              user={user}
              profile={profileData}
              setProfile={setProfileData}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              loading={loading}
            />
          </Box>
        )}

        {/* TAB 4 Contents */}
        {currentTabIndex === 3 && (
          <Box>
            <BillingDetails
              profile={profileData}
              setProfile={setProfileData}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              loading={loading}
            />
          </Box>
        )}

        {/* {currentTabIndex === 4 && (
          <Box>
            <AddressDetails/>
          </Box>
        )} */}
      </div>
    </div>
  );
};

export default AccountSettings;
