import API from "../api/ClientApi";
import {
  MODERATION_FAIL,
  MODERATION_REQUEST,
  MODERATION_SUCCESS,
} from "../constants/ModerationConstant";

// Get notificationcheck
export const userModeration = () => async (dispatch) => {
  try {
    dispatch({ type: MODERATION_REQUEST });

    const { data } = await API.get("/api/get-moderated-status");
    dispatch({ type: MODERATION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MODERATION_FAIL,
      payload: error,
    });
  }
};
