import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../api/ClientApi";
import {
  useMediaQuery,
  Grid,
  TextField,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";
import { Calendar } from "primereact/calendar";
import InfoIcon from "../../../assets/images/infoIcon.png";
import NoData from "../../../components/NoData/NoData";
import { Paginator } from "primereact/paginator";
import PerformerListCard from "./PerformerListCard";
import PerformerUrlCard from "./PerformerUrlCard";
import Icon from "../../../assets/DashboardIcons/info.svg";
import Flag from "../../../assets/DashboardIcons/flag.jpg";
import Clock from "../../../assets/DashboardIcons/clock.svg";
import Gear from "../../../assets/DashboardIcons/gear.svg";
import Graph from "../../../assets/DashboardIcons/graph.jpg";
import Limg from "../../../assets/DashboardIcons/link.svg";
import Plus from "../../../assets/DashboardIcons/plus.svg";
import Search from "../../../assets/DashboardIcons/search.svg";
import { Swal } from "sweetalert2";
import Avatar from "../../../assets/images/user-solid.svg";

const PerformerListMain = (props) => {
  const op1 = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state) => state.profile);
  const { balance, wloading } = useSelector((state) => state.balance);
  const [dates, setDates] = useState(null);
  const [websites, setWebsites] = useState(null);
  const [website, setWebsite] = useState(null);
  const [webload, setWebLoad] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [minimumPriceError, setMinimumPriceError] = useState("");
  const [maximumPriceError, setMaximumPriceError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(10);
  const [category, setCategory] = useState([]);
  const items = [{ label: "Available performers" }];
  const home = { label: "Home", url: "/dashboard/website-list" };
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const toggleAdditionalFilters = () => {
    setShowAdditionalFilters(!showAdditionalFilters);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobileX = useMediaQuery("(max-width: 400px)");
  const isMobileL = useMediaQuery("(max-width: 1550px)");
  const isMobileM = useMediaQuery("(max-width: 1230px)");
  const isMobileML = useMediaQuery("(max-width: 1440px)");
  const isMobileLX = useMediaQuery("(max-width: 1700px)");
  const [filterOptions, setFilterOptions] = useState({
    minimum_price: null,
    maximum_price: null,
    service_type: { name: "All", code: "All" },
    language: { name: "All", code: "All" },
    category_id: { name: "All", code: "All" },
    disclosure_type: { name: "All", code: "All" },
    link_type: { name: "All", code: "All" },
    ahref_organic_traffic: { name: "All", code: "All" },
    marked_sponsored_by: { name: "All", code: "All" },
    publishers_tat: { name: "All", code: "All" },
    publishers_rating: { name: "All", code: "All" },
    security_deposit: { name: "All", code: "All" },
    average_liftime_of_links: { name: "More than 70%", code: 70 },
  });

  const [page, setPage] = useState({ name: "30 per page", code: 30 });
  const [sortBy, setSortBy] = useState({
    name: "From the lowest price",
    code: "lowest_price",
  });

  const languages = [
    { name: "All", code: "All" },
    { name: "English", code: "English" },
    { name: "Urdu", code: "Urdu" },
    { name: "Chinese", code: "Chinese" },
    { name: "French", code: "French" },
    { name: "German", code: "German" },
    { name: "Hindi", code: "Hindi" },
    { name: "Spanish", code: "Spanish" },
    { name: "Arabic", code: "Arabic" },
    { name: "Bulgarian", code: "Bulgarian" },
    { name: "Dutch", code: "Dutch" },
    { name: "French", code: "French" },
    { name: "Greek", code: "Greek" },
    { name: "Hrvatski", code: "Hrvatski" },
    { name: "Indonesian", code: "Indonesian" },
    { name: "Italian", code: "Italian" },
    { name: "Japanese", code: "Japanese" },
    { name: "Korean", code: "Korean" },
    { name: "Norwegian", code: "Norwegian" },
    { name: "Other", code: "Other" },
    { name: "Polish", code: "Polish" },
    { name: "Portuguese", code: "Portuguese" },
    { name: "Romanian", code: "Romanian" },
    { name: "Russian", code: "Russian" },
    { name: "Spanish", code: "Spanish" },
    { name: "Swedish", code: "Swedish" },
    { name: "Turkish", code: "Turkish" },
    { name: "Ukranian", code: "Ukranian" },
  ];

  const services = [
    { name: "All", code: "All" },
    { name: "Mini-post", code: "Mini-post" },
    { name: "Article", code: "Article" },
    { name: "Long article", code: "long-article" },
    { name: "Content placement price", code: "Content-placement-price" },
  ];

  const disclosure = [
    { name: "All", code: "All" },
    { name: "Yes", code: 1 },
    { name: "No", code: 0 },
  ];

  const link = [
    { name: "All", code: "All" },
    { name: "DoFollow", code: 1 },
    { name: "UnFollow", code: 0 },
  ];

  const publisherTAT = [
    { name: "All", code: "All" },
    { name: "Up to 1 day", code: 1 },
    { name: "Up to 2 days", code: 2 },
    { name: "Up to 3 days", code: 3 },
    { name: "Up to 4 days", code: 4 },
    { name: "Up to 5 days", code: 5 },
    { name: "Up to 6 days", code: 6 },
    { name: "Up to 7 days", code: 7 },
    { name: "Up to 8 days", code: 8 },
    { name: "Up to 9 days", code: 9 },
    { name: "More then 10 days", code: 10 },
  ];

  const publisherRating = [
    { name: "All", code: "All" },
    { name: "1 star", code: 1 },
    { name: "2 stars", code: 2 },
    { name: "3 stars", code: 3 },
    { name: "4 stars", code: 4 },
    { name: "5 stars", code: 5 },
  ];

  const averageLinkLifetime = [
    { name: "More than 70%", code: 70 },
    { name: "More than 80%", code: 80 },
    { name: "More than 90%", code: 90 },
  ];

  const pages = [
    { name: "30 per page", code: 30 },
    { name: "50 per page", code: 50 },
    { name: "100 per page", code: 100 },
  ];

  const sort = [
    { name: "From the lowest price", code: "lowest_price" },
    { name: "From the greatest price", code: "highest_price" },
  ];
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseDescription = () => {
    setAnchorEl(null);
    setCurrentDescription("");
  };

  const clear = async (e) => {
    e.preventDefault();
    setMaximumPriceError("");
    setMinimumPriceError("");
    if (
      filterOptions.minimum_price !== null ||
      filterOptions?.maximum_price !== null ||
      filterOptions?.service_type !== "All" ||
      filterOptions?.language !== "All" ||
      filterOptions?.category_id !== "All" ||
      filterOptions?.disclosure_type !== "All" ||
      filterOptions?.link_type !== 0
    ) {
      const newFilterOptions = {
        minimum_price: null,
        maximum_price: null,
        service_type: { name: "All", code: "All" },
        language: { name: "All", code: "All" },
        category_id: { name: "All", code: "All" },
        disclosure_type: { name: "All", code: "All" },
        link_type: { name: "All", code: "All" },
        ahref_organic_traffic: { name: "All", code: "All" },
        marked_sponsored_by: { name: "All", code: "All" },
        publishers_tat: { name: "All", code: "All" },
        publishers_rating: { name: "All", code: "All" },
        security_deposit: { name: "All", code: "All" },
        average_liftime_of_links: { name: "More than 70%", code: 70 },
      };
      setFilterOptions(newFilterOptions);
      await handleClear(e, newFilterOptions);

      // Explicitly clear the TextField values
      document.getElementsByName("minimum_price")[0].value = null;
      document.getElementsByName("maximum_price")[0].value = null;
    }
  };

  const handleClear = async (e, newFilterOptions) => {
    setWebLoad(true);
    e.preventDefault();
    try {
      const data = await API.get(
        `/api/searched-by-buyer-on-specific-url/${id}?minimum_price=${15}&maximum_price=${10000}&service_type=${
          newFilterOptions.service_type.code
        }&language=${newFilterOptions.language.code}&category_id=${
          newFilterOptions.category_id.code
        }&disclosure_type=${newFilterOptions.disclosure_type.code}&link_type=${
          newFilterOptions.link_type.code
        }&per_page=${page.code}&sorting=${sortBy.code}&page=${currentPage}`
      );
      setWebsites(data.data.data);
      setCurrentPage(data.data.current_page);
      setTotalPages(data.data.last_page);
      setTotal(data.data.total);
      setWebLoad(false);
    } catch (error) {
      setWebLoad(false);
      return error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (filterOptions.minimum_price < 15) {
      setMinimumPriceError("Min. price is 15");
    }
    if (filterOptions.maximum_price < filterOptions.minimum_price) {
      setMaximumPriceError(
        `Min. price is ${
          filterOptions.minimum_price < 15 ? 15 : filterOptions.minimum_price
        }`
      );
    }
    if (filterOptions.maximum_price > 10000) {
      setMaximumPriceError("Max. price is 10000");
    }
    if (
      filterOptions.minimum_price >= 15 &&
      filterOptions.maximum_price >= filterOptions.minimum_price &&
      filterOptions.maximum_price <= 10000
    ) {
      setWebLoad(true);
      try {
        const data = await API.get(
          `/api/searched-by-buyer-on-specific-url/${id}?minimum_price=${filterOptions.minimum_price}&maximum_price=${filterOptions.maximum_price}&service_type=${filterOptions.service_type.code}&language=${filterOptions.language.code}&category_id=${filterOptions.category_id.code}&disclosure_type=${filterOptions.disclosure_type.code}&link_type=${filterOptions.link_type.code}&per_page=${page.code}&sorting=${sortBy.code}&page=${currentPage}`
        );
        setWebsites(data.data.data);
        setCurrentPage(data.data.current_page);
        setTotalPages(data.data.last_page);
        setTotal(data.data.total);
        setWebLoad(false);
      } catch (error) {
        setWebLoad(false);
        return error;
      }
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "minimum_price") {
      setMinimumPriceError("");

      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else if (name === "maximum_price") {
      setMaximumPriceError("");
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else {
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [e.target.name]: e.target.value,
      }));
    }
  };
  const open = Boolean(anchorEl);
  const smallScreenXS = useMediaQuery("(max-width:700px)");

  useEffect(() => {
    setWebLoad(true);
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    const getList = async () => {
      try {
        const data = await API.get(
          `/api/searched-by-buyer-on-specific-url/${id}?minimum_price=${15}&maximum_price=${10000}&service_type=${
            filterOptions.service_type.code
          }&language=${filterOptions.language.code}&category_id=${
            filterOptions.category_id.code
          }&disclosure_type=${filterOptions.disclosure_type.code}&link_type=${
            filterOptions.link_type.code
          }&per_page=${page.code}&sorting=${sortBy.code}&page=${currentPage}`
        );
        const dataWeb = await API.get(`/api/publisher/${id}`);
        setWebsite(dataWeb?.data);
        const dataCat = await API.get("/api/get-product-category");
        const modifiedArray = dataCat?.data?.data?.productcategory?.map(
          (item) => ({
            code: item.id,
            name: item.title,
          })
        );
        modifiedArray.unshift({ name: "All", code: "All" });
        setCategory(modifiedArray);
        if (data) {
          setWebLoad(false);
          setWebsites(data.data.data);
          setCurrentPage(data.data.current_page);
          setTotalPages(data.data.last_page);
          setTotal(data.data.total);
        }
      } catch (error) {
        setWebLoad(false);
        return error;
      }
    };

    getList();
  }, [localStorage.getItem("role"), page, sortBy, currentPage]);

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  return (
    <div>
      <BreadCrumb model={items} home={home} />
      <div className="mt-2 heading-info-wrap">
        <div className="row">
          <div className="col-md-7">
            <h2> Available performers for your task </h2>
          </div>
        </div>
      </div>
      {website ? <PerformerUrlCard data={website} balance={balance} /> : ""}

      <div className="new-filter-listing-wraper">
        <h3> Search For Verified Publishers </h3>
        <form className="row">
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Maximum and minimum price you are ready to pay for task"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> Price Range </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="15"
                name="minimum_price"
                value={filterOptions.minimum_price}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="10000"
                name="maximum_price"
                value={filterOptions.maximum_price}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              {minimumPriceError && (
                <div className="error-message">{minimumPriceError}</div>
              )}
              {!minimumPriceError && maximumPriceError && (
                <div className="error-message">{maximumPriceError}</div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="DA, which stands for 'Domain Authority,' is a metric commonly used in search engine optimization (SEO) and digital marketing to assess the overall authority, credibility, and potential ranking strength of a specific domain or website."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> DA RANGE </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="15"
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="10000"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="DR, which stands for 'Domain Rating,' is a metric commonly used in search engine optimization (SEO) and digital marketing to assess the authority and backlink profile of a specific domain or website."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> DR RANGE </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="15"
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="10000"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Spam Score is a metric used in the field of digital marketing and search engine optimization (SEO) to assess the likelihood that a particular website or web page contains spammy or low-quality content."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> SPAM SCORE </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="15"
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="10000"
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Maximum and minimum price you are ready to pay for task"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> MONTHLY TRAFFIC </label>
              <input type="text" className="form-control" placeholder="From" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Ahref Organic Traffic, often referred to simply as 'organic traffic,' is a key metric in website analytics that measures the volume of visitors a website receives from search engines like Google, Bing, or Yahoo."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> Ahref Organic TRAFFIC </label>
              <input type="text" className="form-control" placeholder="From" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Choose the most relevant categories for the website you want to promote"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> Category </label>
              <Dropdown
                name="category_id"
                value={filterOptions?.category_id}
                onChange={async (e) => {
                  e.preventDefault();
                  handleFilterChange(e);
                }}
                options={category}
                optionLabel="name"
                placeholder="Select a category"
                filter
                className="w-100 filter-custom-dropdown"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Choose between Dofollow links (the ones that can be found by search engines) and Nofollow (the ones that are protected from search engine crawlers)"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> LINKS </label>
              <Dropdown
                name="link_type"
                value={filterOptions?.link_type}
                onChange={async (e) => {
                  e.preventDefault();
                  handleFilterChange(e);
                }}
                options={link}
                optionLabel="name"
                placeholder="Select a link"
                className="w-100 filter-custom-dropdown"
              />
            </div>
          </div>
          {showAdditionalFilters && (
            <>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="hose whether you want to choose a publisher who will post your content or will create then post your content"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Service Type </label>
                  <Dropdown
                    name="service_type"
                    value={filterOptions?.service_type}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={services}
                    optionLabel="name"
                    placeholder="Select a service"
                    filter
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="Language of a website"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Language </label>
                  <Dropdown
                    name="language"
                    value={filterOptions?.language}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={languages}
                    optionLabel="name"
                    placeholder="Select a language"
                    filter
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The designation 'Sponsored by' is an indication that a specific backlink on a website has been paid for by an advertiser or sponsor."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Marked "Sponsored by" </label>
                  <Dropdown
                    name="disclosure_type"
                    value={filterOptions?.disclosure_type}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={disclosure}
                    optionLabel="name"
                    placeholder="Select a disclosure"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The 'Average Lifetime of Links' is a crucial metric in the world of website backlinks. It represents the typical duration for which backlinks, placed on a publisher's website, remain active and functional."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Average lifetime of links </label>
                  <Dropdown
                    name="average_liftime_of_links"
                    value={filterOptions?.average_liftime_of_links}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={averageLinkLifetime}
                    optionLabel="name"
                    placeholder="Select a lifetime"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="It provides an evaluation of a publisher's overall performance and reputation"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Publisher's Rating </label>
                  <Dropdown
                    name="publishers_rating"
                    value={filterOptions?.publishers_rating}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={publisherRating}
                    optionLabel="name"
                    placeholder="Select a rating"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The 'Publisher's TAT' (Turnaround Time) is a crucial factor in the realm of website backlinks. It refers to the time taken by publishers or website owners to complete specific tasks related to backlinks."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Publisher's TAT </label>
                  <Dropdown
                    name="publishers_tat"
                    value={filterOptions?.publishers_tat}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={publisherTAT}
                    optionLabel="name"
                    placeholder="Select a disclosure"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The 'Publishers completion rate' is a metric that reflects the reliability and performance of publishers or website owners in completing backlink-related tasks."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Publisher's completion rate </label>
                  <Dropdown
                    name="average_liftime_of_links"
                    value={filterOptions?.average_liftime_of_links}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={averageLinkLifetime}
                    optionLabel="name"
                    placeholder="Select a lifetime"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="This refers to a task associated with an initial website domain and a specified price."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Task with initial domain and price </label>
                  <Dropdown
                    name="average_liftime_of_links"
                    value={filterOptions?.average_liftime_of_links}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={averageLinkLifetime}
                    optionLabel="name"
                    placeholder="Select a lifetime"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="Date when site appeared on webylinks"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Added to webylinks </label>
                  <Calendar
                    className="w-100 calender-filter"
                    value={dates}
                    onChange={(e) => setDates(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    minDate={new Date()}
                  />
                </div>
              </div>
            </>
          )}
        </form>
        {showAdditionalFilters && (
          <div className="d-flex justify-content-end search-filter-btn">
            <button className="btn-info-count" onClick={clear}>
              Clear
            </button>
            <button className="apply-filters-button" onClick={handleSubmit}>
              Apply Filters
            </button>
          </div>
        )}
        {!showAdditionalFilters && (
          <div className="d-flex justify-content-center load-more-filters">
            <button onClick={toggleAdditionalFilters}>
              {" "}
              LOAD MORE ADVANCE FILTERS <img src={Plus} alt="plus" />{" "}
            </button>
          </div>
        )}
      </div>
      <div
        className={`buyer-dashboard-filter-section search-results-wraper ${
          props.isDarkMode ? "filter-dark" : "filter-light"
        }`}
      >
        <div
          className={`d-flex search-results-head ${
            smallScreenXS
              ? "flex-column justify-content-start"
              : "justify-content-between"
          } filter-header`}
        >
          <h2>
            Search Results{" "}
            <span>({websites?.length > 0 ? websites?.length : 0})</span>
          </h2>
          <div className="d-flex sort-container">
            <div className="d-flex mx-2 justify-content-center align-items-center sorting-menu">
              <label className="mx-2">Show</label>{" "}
              <Dropdown
                name="page"
                value={page}
                onChange={async (e) => {
                  e.preventDefault();
                  setCurrentPage(1);
                  setPage({ name: e.value.name, code: e.value.code });
                }}
                options={pages}
                optionLabel="name"
                placeholder="Select page number"
                className="role-select-options-page"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center sorting-menu">
              <label className="mx-2">Sort by</label>{" "}
              <Dropdown
                name="sortBy"
                value={sortBy}
                onChange={async (e) => {
                  e.preventDefault();
                  setSortBy({ name: e.value.name, code: e.value.code });
                }}
                options={sort}
                optionLabel="name"
                placeholder="Select sort type"
                className="role-select-options-page"
              />
            </div>
          </div>
        </div>
        <div className="website-listed-main-wrap">
          <div className="table-responsive">
            <table width="100%" border="0">
              {webload ? (
                <div className="d-flex justify-content-center align-items-center">
                  <CircularProgress
                    style={{
                      width: "40px",
                      height: "40px",
                      marginBottom: "10px",
                    }}
                  />
                </div>
              ) : websites !== null ? (
                Array.isArray(websites) && websites.length > 0 ? (
                  websites.map((res, id) => (
                    <tr
                      key={id}
                      className={`${id % 2 === 0 ? "" : "light-grey"}`}
                    >
                      <td width={isMobileL ? "10%" : "12%"}>
                        <h6
                          onClick={() => {
                            if (props?.balance?.is_paid === 1) {
                              navigate(
                                `/dashboard/publisher/profile/main/${res?.user?.wl_users_header_id}`
                              );
                            } else {
                              openModal();
                            }
                          }}
                        >
                          {res?.user?.wl_users_header?.name}
                        </h6>
                        <div className="my-2 contributer-not-owner">
                          {res?.Confirmation_status === "Owner" && (
                            <img
                              src={Avatar}
                              className="owner-img"
                              alt="owner"
                            />
                          )}
                          <p>{res?.Confirmation_status}</p>
                        </div>
                        <span>
                          {" "}
                          <img src={Limg} alt="link" />
                          Max 03{" "}
                          <strong>
                            {res?.link_type === 1
                              ? "NoFollow links"
                              : "DoFollow links"}
                          </strong>{" "}
                        </span>{" "}
                        <span>
                          {" "}
                          <img src={Gear} alt="gear" />
                          Turnaround Time: <strong>1 day</strong>{" "}
                        </span>{" "}
                        <span>
                          {" "}
                          <img src={Clock} alt="clock" />
                          Completion rate <strong>77%</strong>
                        </span>
                      </td>

                      <td width={isMobileML ? "2%" : isMobileLX ? "6%" : "10%"}>
                        <div className="btn-mounth-track">
                          <span className="d-flex flex-column price-span-color">
                            {" "}
                            Placement{" "}
                            <strong>
                              {" "}
                              ${res?.content_placement_price}
                            </strong>{" "}
                          </span>
                        </div>
                      </td>
                      <td width={isMobileML ? "2%" : isMobileLX ? "6%" : "10%"}>
                        <div className="btn-mounth-track">
                          <span className="d-flex flex-column price-span-color">
                            {" "}
                            Mini post <strong>
                              {" "}
                              ${res?.mini_post_price}
                            </strong>{" "}
                          </span>
                        </div>
                      </td>
                      <td width={isMobileML ? "2%" : isMobileLX ? "6%" : "10%"}>
                        <div className="btn-mounth-track">
                          <span className="d-flex flex-column price-span-color">
                            {" "}
                            Article <strong> ${res?.article_price}</strong>{" "}
                          </span>
                        </div>
                      </td>
                      <td
                        width={isMobileML ? "3.5%" : isMobileLX ? "6%" : "10%"}
                      >
                        <div className="btn-mounth-track">
                          <span className="d-flex flex-column price-span-color">
                            {" "}
                            Long article <strong>
                              {" "}
                              ${res?.long_article}
                            </strong>{" "}
                          </span>
                        </div>
                      </td>
                      <td width={isMobileL ? "10%" : isMobileM ? "14%" : "10%"}>
                        {" "}
                        <div className="btn-mounth-track">
                          <div className="button-price-wrap">
                            {" "}
                            <a
                              onClick={() => {
                                if (props?.profile?.is_verified === 0) {
                                  Swal.fire({
                                    icon: "info",
                                    text: "Please verify your account to perform this task",
                                  });
                                } else {
                                  localStorage.setItem(
                                    "selectedSubRoute",
                                    `/dashboard/buy-form/${res.id}`
                                  );
                                  navigate(`/dashboard/buy-form/${res.id}`);
                                }
                              }}
                            >
                              {" "}
                              Buy Post{" "}
                            </a>{" "}
                            <strong
                              className="profile-pointer"
                              onClick={() => {
                                if (balance?.is_paid === 1) {
                                  navigate(
                                    `/dashboard/publisher/profile/main/${res?.user?.wl_users_header_id}`
                                  );
                                } else {
                                  openModal();
                                }
                              }}
                            >
                              {" "}
                              Profile{" "}
                            </strong>{" "}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoData />
                )
              ) : (
                <NoData />
              )}
            </table>
          </div>
        </div>

        <div>
          {total > page.code && (
            <Paginator
              first={currentPage * rows - rows}
              rows={rows}
              totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PerformerListMain;
