import React, { useRef, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  useMediaQuery,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Icon from "../../../assets/DashboardIcons/info.svg";
import Flag from "../../../assets/DashboardIcons/flag.jpg";
import Clock from "../../../assets/DashboardIcons/clock.svg";
import Gear from "../../../assets/DashboardIcons/gear.svg";
import Graph from "../../../assets/DashboardIcons/graph.jpg";
import Limg from "../../../assets/DashboardIcons/link.svg";
import Plus from "../../../assets/DashboardIcons/plus.svg";
import Search from "../../../assets/DashboardIcons/search.svg";
import CountryFlag from "react-country-flag";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { OverlayPanel } from "primereact/overlaypanel";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";
import DeleteModal from "../../Dashboard/components/DeleteModal";
import DeactivateModal from "../../Dashboard/components/DeactivateModal";
import ActivateModal from "../../Dashboard/components/ActivateModal";
import Avatar from "../../../assets/images/user-solid.svg";
import Swal from "sweetalert2";

const PlatformCard = (props) => {
  const navigate = useNavigate();
  const op2 = useRef();
  const isMobileX = useMediaQuery("(max-width: 400px)");
  const isMobileL = useMediaQuery("(max-width: 1550px)");
  const isMobileM = useMediaQuery("(max-width: 1230px)");
  const [showDecConfirmationModal, setShowDecConfirmationModal] =
    useState(false);
  const [showActConfirmationModal, setShowActConfirmationModal] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [webId, setWebId] = useState(null);

  const languages = [
    { name: "All", code: "All", countryCode: "" }, // You can assign an empty string for "All"
    { name: "English", code: "English", countryCode: "US" },
    { name: "Urdu", code: "Urdu", countryCode: "PK" },
    { name: "Chinese", code: "Chinese", countryCode: "CN" },
    { name: "French", code: "French", countryCode: "FR" },
    { name: "German", code: "German", countryCode: "DE" },
    { name: "Hindi", code: "Hindi", countryCode: "IN" },
    { name: "Spanish", code: "Spanish", countryCode: "ES" },
    { name: "Arabic", code: "Arabic", countryCode: "SA" },
    { name: "Bulgarian", code: "Bulgarian", countryCode: "BG" },
    { name: "Dutch", code: "Dutch", countryCode: "NL" },
    { name: "Greek", code: "Greek", countryCode: "GR" },
    { name: "Hrvatski", code: "Hrvatski", countryCode: "HR" },
    { name: "Indonesian", code: "Indonesian", countryCode: "ID" },
    { name: "Italian", code: "Italian", countryCode: "IT" },
    { name: "Japanese", code: "Japanese", countryCode: "JP" },
    { name: "Korean", code: "Korean", countryCode: "KR" },
    { name: "Norwegian", code: "Norwegian", countryCode: "NO" },
    { name: "Other", code: "Other", countryCode: "" }, // You can assign an empty string for "Other"
    { name: "Polish", code: "Polish", countryCode: "PL" },
    { name: "Portuguese", code: "Portuguese", countryCode: "PT" },
    { name: "Romanian", code: "Romanian", countryCode: "RO" },
    { name: "Russian", code: "Russian", countryCode: "RU" },
    { name: "Spanish", code: "Spanish", countryCode: "ES" },
    { name: "Swedish", code: "Swedish", countryCode: "SE" },
    { name: "Turkish", code: "Turkish", countryCode: "TR" },
    { name: "Ukranian", code: "Ukranian", countryCode: "UA" },
  ];

  const handleModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDecModalClose = () => {
    setShowDecConfirmationModal(false);
  };

  const handleActModalClose = () => {
    setShowActConfirmationModal(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActivateClick = async () => {
    handleClose();
    setShowActConfirmationModal(true);
  };

  const handleDeActivateClick = async () => {
    handleClose();
    setShowDecConfirmationModal(true);
  };

  const handleDeleteClick = () => {
    handleClose();
    setShowConfirmationModal(true);
  };

  const truncateText = (text, maxLength) => {
    return text?.length <= maxLength ? text : text?.slice(0, maxLength) + "...";
  };

  return (
    <>
      <tr
        key={props.id}
        className={`${props.id % 2 === 0 ? "" : "light-grey"}`}
      >
        <td>
          <h6
            className="web-list-url"
            onClick={() => window.open(props?.data?.url)}
          >
            {truncateText(props?.data?.url, isMobileX ? 20 : 20)}
          </h6>
          {props?.moderated === 0 ? (
            <div
              className="my-2 contributer-not-owner"
              onClick={() => {
                localStorage.setItem(
                  "selectedSubRoute",
                  `/dashboard/publisher/confirm/${props.data.id}`
                );
                Swal.fire({
                  title: "",
                  html: `<h4>The reasons why your websites might not be displayed in the inventory</h4>
                    <ol class="swal-modal-list">
                      <li>Your account has not passed moderation (in case your site does not have confirmed ownership).</li>
                      <li>You have not stated prices for your services. You can set the prices by going to My Platform. Click the Edit button in the sites row and write prices for your services.</li>
                    </ol>`,
                  icon: "", // No icon
                  showCancelButton: true,
                  showConfirmButton:
                    props?.data?.Confirmation_status === "Contributor", // Hide default "OK" button
                  showCloseButton: true,
                  confirmButtonText: "Confirm ownership",
                  cancelButtonText: "Pass moderation",
                  cancelButtonColor: "#3085d6",
                  cancelButtonClass: "custom-button", // Add custom CSS className to the close button
                  customClass: {
                    header: "swal-header", // Apply custom CSS to the header
                    content: "swal-content", // Apply custom CSS to the content
                    container: "swal-custom-container",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    // Handle the "Pass moderation" button click
                    navigate(`/dashboard/publisher/confirm/${props.data.id}`);
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // Handle the "Close" button click
                    navigate(`/dashboard/publisher/moderation-profile`);
                  }
                });
                // navigate(`/dashboard/publisher/confirm/${props.data.id}`);
              }}
            >
              <p>Not in inventory</p>
            </div>
          ) : (
            <div className="my-2 contributer-not-owner">
              {props?.data?.Confirmation_status === "Owner" ? (
                <>
                  <img src={Avatar} className="owner-img" alt="owner" />
                  <p>{props?.data?.Confirmation_status}</p>
                </>
              ) : (
                <p
                  onClick={() =>
                    navigate(`/dashboard/publisher/confirm/${props.data.id}`)
                  }
                >
                  Confirm ownership
                </p>
              )}
            </div>
          )}
          <span>
            {" "}
            <img src={Limg} alt="link" />
            Max 03{" "}
            <strong>
              {props?.data?.link_type === 1
                ? "NoFollow links"
                : "DoFollow links"}
            </strong>{" "}
          </span>{" "}
          <span>
            {" "}
            <img src={Gear} alt="gear" />
            Turnaround Time: <strong>1 day</strong>{" "}
          </span>{" "}
          <span>
            {" "}
            <img src={Clock} alt="clock" />
            Spam Score <strong>1%</strong>
          </span>
          <span>
            {props?.data?.is_active === 0 ? (
              <div className="d-flex align-items-center active-deactive-wrap a-d-wrap">
                <div className="circle-activeDeactive c-deactive"></div>
                <p>Deactivated</p>
              </div>
            ) : (
              <div className="d-flex align-items-center active-deactive-wrap a-d-wrap">
                <div className="circle-activeDeactive c-active"></div>
                <p>Active</p>
              </div>
            )}
          </span>
        </td>
        <td>
          <div className="category-list-wrap">
            {props?.data?.categories?.map((res, id) => (
              <span className="mx-1" key={id}>
                {res?.title}
              </span>
            ))}
          </div>
        </td>
        <td>
          <div className="dr-tex-wrap">
            {" "}
            <span>
              {" "}
              DA <strong>54</strong>
            </span>{" "}
          </div>
        </td>
        <td>
          <div className="dr-tex-wrap">
            {" "}
            <span>
              {" "}
              DR <strong>49</strong>
            </span>{" "}
          </div>
        </td>
        <td>
          <div className="language-wrap">
            <CountryFlag
              countryCode={
                languages.find((lang) => lang.code === props?.data?.language)
                  ?.countryCode
              } // Use the language code from your data
              style={{ width: "25px" }}
              svg
            />
            <span> {props?.data?.language}</span>
          </div>
        </td>
        <td>
          <div className="btn-mounth-track">
            <h5>
              {" "}
              Monthly Traffic{" "}
              <span>
                {" "}
                <img src={Graph} alt="graph" /> 18,924{" "}
              </span>{" "}
            </h5>
            <div className="button-price-wrap">
              {" "}
              <a
                onClick={() => {
                  localStorage.setItem(
                    "selectedSubRoute",
                    `/dashboard/update-website/${props?.data?.id}`
                  );
                  navigate(`/dashboard/update-website/${props?.data?.id}`);
                }}
              >
                {" "}
                Edit{" "}
              </a>{" "}
              <strong
                className="profile-pointer no-pad-more-icon"
                onClick={(e) => op2.current.toggle(e)}
              >
                {" "}
                <MoreHorizIcon className="more-icon-pub" />{" "}
              </strong>{" "}
              <OverlayPanel ref={op2}>
                <List component="nav">
                  <ListItem
                    button
                    onClick={() => {
                      navigate(
                        `/dashboard/publisher/profile/main/${props?.data?.user?.wl_users_header_id}`
                      );
                    }}
                    className="p-card-menu-btn"
                  >
                    <ListItemIcon>
                      <VisibilityIcon />
                    </ListItemIcon>
                    <ListItemText primary="View profile" />
                  </ListItem>
                  {props?.data?.is_active === 1 ? (
                    <ListItem
                      button
                      onClick={() => {
                        setWebId({
                          id: props?.data?.id,
                          url: props?.data?.url,
                        });
                        handleDeActivateClick();
                      }}
                      className="p-card-menu-btn"
                    >
                      <ListItemIcon>
                        <BlockIcon />
                      </ListItemIcon>
                      <ListItemText primary="Deactivate" />
                    </ListItem>
                  ) : (
                    <ListItem
                      button
                      onClick={() => {
                        setWebId({
                          id: props?.data?.id,
                          url: props?.data?.url,
                        });
                        handleActivateClick();
                      }}
                      className="p-card-menu-btn"
                    >
                      <ListItemIcon>
                        <BlockIcon />
                      </ListItemIcon>
                      <ListItemText primary="Activate" />
                    </ListItem>
                  )}
                  <ListItem
                    button
                    onClick={() => {
                      setWebId({
                        id: props?.data?.id,
                        url: props?.data?.url,
                      });
                      handleDeleteClick();
                    }}
                    className="p-card-menu-btn"
                  >
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                  </ListItem>
                </List>
              </OverlayPanel>
            </div>
          </div>
        </td>
      </tr>
      <DeleteModal
        showConfirmationModal={showConfirmationModal}
        handleModalClose={handleModalClose}
        handleDeleteClick={handleDeleteClick}
        getList={props.getList}
        getTabs={props.getTabs}
        data={webId}
      />
      <DeactivateModal
        showDecConfirmationModal={showDecConfirmationModal}
        handleDecModalClose={handleDecModalClose}
        handleDeActivateClick={handleDeActivateClick}
        getList={props.getList}
        data={webId}
      />
      <ActivateModal
        showActConfirmationModal={showActConfirmationModal}
        handleActModalClose={handleActModalClose}
        handleActivateClick={handleActivateClick}
        getList={props.getList}
        data={webId}
      />
    </>
  );
};

export default PlatformCard;
