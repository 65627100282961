import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  CircularProgress,
  Button,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { OverlayPanel } from "primereact/overlaypanel";
import { useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Dropdown } from "primereact/dropdown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import API from "../../../api/ClientApi";
import Swal from "sweetalert2";
import InfoIcon from "../../../assets/images/infoIcon.png";

const BidForm = () => {
  const { user } = useSelector((state) => state.profile);
  const op = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [bload, setBLoad] = useState(false);
  const [offerData, setOfferData] = useState(null);
  const [amount, setAmount] = useState(null);
  const [url, setUrl] = useState(null);
  const [urlErr, setUrlErr] = useState("");
  const [aErr, setAErr] = useState("");
  const [urls, setUrls] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!amount || amount <= 0 || !url || url === "") {
      if (!amount || amount <= 0) {
        setAErr("Biding amount should be greater then 0");
      }
      if (!url || url === "") {
        setUrlErr("Please select a website");
      }
    } else {
      try {
        setBLoad(true);
        const response = await API.post("/api/offer-bid-publisher", {
          wl_product_id: url.id,
          wl_open_offers_id: id,
          wl_user_id: user?.user?.id,
          total_amount: amount,
        });
        if (response) {
          setBLoad(false);
          setAmount(null);
          setUrl(null);
          // Clear the input field value
          document.getElementsByName("amount")[0].value = "";
          Swal.fire({
            title: "Success!",
            html: "Successfully made a bid",
            icon: "success",
            timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
            showConfirmButton: false, // Hide the "OK" button
            allowOutsideClick: false, // Prevent closing by clicking outside
          });
        }
      } catch (error) {
        setBLoad(false);
        return error;
      }
    }
  };

  useEffect(() => {
    setLoad(true);
    if (localStorage.getItem("role") === "buyer") {
      navigate("/dashboard/website-list");
    }
    const fetchUrls = async () => {
      try {
        const response = await API.get(
          `${process.env.REACT_APP_BACKEND_API}/api/get-publisher-urls`
        );
        setUrls(response.data.urls);
      } catch (error) {
        // Handle error here
        return error;
      }
    };
    const fetchData = async () => {
      try {
        const response = await API.get(
          `${process.env.REACT_APP_BACKEND_API}/api/open-offer/${id}`
        );
        setOfferData(response.data);
        setLoad(false);
      } catch (error) {
        // Handle error here
        setLoad(false);
      }
    };
    fetchUrls();
    fetchData(); // Call the async function immediately
  }, [localStorage.getItem("role")]);

  return (
    <div className="buyer-form-main-wraper">
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Offer</h2>
          </div>
        </div>
      </div>
      <Accordion className="accordion-filter accordion-global">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-summery"
        >
          <div className="w-100 d-flex justify-content-between filter-header">
            <h2>HOW IT WORKS?</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <div className="accordion-detail-div ac-div">
            In this section you can see tasks grouped by multiple statuses.Each
            status is accompanied by a number representing the quantity of tasks
            within that status.As the task is in progress, it will transition
            between different statuses.You'll stay updated through emails and
            notifications. To view a description of each status, hover your
            mouse pointer over the corresponding status name.To access
            additional details about the task ‘click button with three dots’ and
            smoothly perform further actions.
          </div>
        </AccordionDetails>
      </Accordion>
      {load ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="offer-detail-container">
            <div className="offer-heading-wrapper">
              <h4>{offerData?.offer_name}</h4>
            </div>
            <div className="offer-rest-detail-wrapper">
              <div className="accordion-row">
                <div className="d-flex flex-column align-items-start offer-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Service type:</h6>
                  {offerData?.service_type}
                </div>
                <div className="d-flex flex-column align-items-start offer-row-col">
                  <h6 className="order-article-faq-q ">Promoted Url:</h6>
                  {offerData?.promoted_url}
                </div>
                <div className="d-flex flex-column align-items-start offer-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Price type:</h6>
                  {`${offerData?.price !== "open price" ? "$" : ""} ${
                    offerData?.price
                  }`}
                </div>
                <div className="d-flex flex-column align-items-start offer-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Offer available:</h6>
                  All publishers
                </div>
                <div className="d-flex flex-column align-items-start offer-row-col offer-row-col-active">
                  {" "}
                  <div className="d-flex align-items-center">
                    <div className="act-circle"></div>Active
                  </div>
                  <Button
                    aria-describedby={id}
                    variant="outlined"
                    // onClick={(e) => op.current.toggle(e)}
                    className="verticon-button-offer verticon-bid-btn"
                  >
                    <IconButton className="more-option-card">
                      <MoreHorizIcon />
                    </IconButton>
                  </Button>
                  <OverlayPanel ref={op}>
                    <List component="nav">
                      <ListItem button>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                      </ListItem>

                      <ListItem button>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                      </ListItem>
                    </List>
                  </OverlayPanel>
                </div>
              </div>
            </div>
            <div className="offer-desc-wrapper">
              <h6>Brief description:</h6>
              <p>{offerData?.brief_requirements}</p>
            </div>
          </div>
          <div className="offer-bid-form-container">
            <div className="offer-heading-wrapper">
              <h4>Make a bid</h4>
              <div className="w-100 offer-bid-form">
                <form>
                  <div className="row">
                    <div className="col-sm-12 col-md-5 bid-form-fields">
                      <div className="d-flex flex-column filed-form-wrap offer-web-field-wrap">
                        <label> Your websites </label>
                        <Dropdown
                          name="url"
                          value={url}
                          onChange={(e) => {
                            setUrlErr("");
                            setUrl(e.target.value);
                          }}
                          options={urls}
                          optionLabel="url"
                          placeholder="Select a url"
                          filter
                          className="prime-mu-select"
                        />
                        {urlErr && <p className="bid-err">{urlErr}</p>}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 bid-form-fields bid-amount-field">
                      <label>Offer your price:</label>
                      <TextField
                        className="w-100 signup-email-field"
                        placeholder="Amount"
                        name="amount"
                        value={amount}
                        type="number"
                        variant="outlined"
                        required
                        // value={amount}
                        onChange={(e) => {
                          e.preventDefault();
                          setAErr("");
                          setAmount(e.target.value);
                        }}
                        InputProps={{
                          classes: {
                            root: "custom-input-billing",
                            input: "custom-input-billing",
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "custom-label",
                          },
                        }}
                      />
                      {aErr && <p className="bid-err">{aErr}</p>}
                    </div>
                    <div className="col-sm-12 col-md-2 d-flex align-items-center offer-btn-container">
                      <Button
                        className="w-100 send-btn-form offer-btn-bid"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {" "}
                        {bload ? (
                          <CircularProgress className="text-white" />
                        ) : (
                          "Make a Bid"
                        )}{" "}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BidForm;
