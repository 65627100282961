import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { DateTime } from "luxon";
import DaySelect from "./components/DaySelect";
import { CircularProgress } from "@mui/material";
import "./styles/consultation.css";
import API from "../../api/ClientApi";
import Swal from "sweetalert2";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConsultationModal = (props) => {
  const [active, setActive] = useState(0);
  const [load, setLoad] = useState(false);
  const [sload, setSLoad] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [details, setDetails] = useState("");
  const [nError, setNError] = useState(false);
  const [eError, setEError] = useState(false);
  const [guests, setGuests] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [time, setTime] = useState("9.00pm");
  const [guestsInput, setGuestsInput] = useState("");
  const times = [
    { label: "9:00pm", value: "9:00pm" },
    { label: "9:30pm", value: "9:30pm" },
    { label: "10:00pm", value: "10:00pm" },
    { label: "10:30pm", value: "10:30pm" },
    { label: "11:00pm", value: "11:00pm" },
    { label: "11:30pm", value: "11:30pm" },
    { label: "12:00pm", value: "12:00pm" },
    { label: "12:30pm", value: "12:30pm" },
    { label: "1:00pm", value: "1:00pm" },
    { label: "1:30pm", value: "1:30pm" },
  ];

  useEffect(() => {
    const fetchZones = async () => {
      setLoad(true);
      try {
        const timezones = Intl.supportedValuesOf("timeZone");

        const timezoneOptions = timezones.map((tz) => ({
          label: tz,
          value: tz,
        }));
        setTimezones(timezoneOptions);
        // Get the user's current time zone.
        const userTimeZone = DateTime.local().zoneName;
        setSelectedTimezone(userTimeZone);
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    };

    fetchZones();
  }, []);

  const handleClear = async () => {
    setName("");
    setEmail("");
    setDetails("");
    setSelectedTimezone(DateTime.local().zoneName);
    setGuests([]);
    setTime("9:00pm");
    setSelectedDate(null);
    setNError(false);
    setEError(false);
  };

  const handleTimezoneChange = async (selectedOption) => {
    setSelectedTimezone(selectedOption);
  };

  const updateEndTime = (startTimeString, durationMinutes) => {
    const timeParts = /^(\d+):(\d+)([ap]m)$/i.exec(startTimeString);

    if (timeParts) {
      let hours = parseInt(timeParts[1], 10);
      const minutes = parseInt(timeParts[2], 10);
      const ampm = timeParts[3].toLowerCase();

      // Adjust the hours based on AM or PM
      if (ampm === "pm" && hours < 12) {
        hours += 12;
      } else if (ampm === "am" && hours === 12) {
        hours = 0;
      }

      // Create a Date object and add the duration in minutes
      const startTime = new Date();
      startTime.setHours(hours);
      startTime.setMinutes(minutes + durationMinutes);

      // Calculate adjusted hours for display
      let endHours = startTime.getHours();
      const endMinutes = startTime.getMinutes();

      // Determine AM or PM for the end time
      const endAMPM = endHours >= 12 ? "pm" : "am";

      // Adjust hours to 12-hour format
      if (endHours > 12) {
        endHours -= 12;
      } else if (endHours === 0) {
        endHours = 12;
      }

      return `${endHours}:${endMinutes < 10 ? "0" : ""}${endMinutes}${endAMPM}`;
    } else {
      return "Invalid time format";
    }
  };

  const formatDateToDDMMYYYY = (dateString) => {
    const originalDate = new Date(dateString);
    const formattedDate = originalDate.toLocaleDateString("en-GB"); // "en-GB" represents the "dd/mm/yyyy" format
    return formattedDate;
  };

  const handleGuestsChange = (e) => {
    const inputValue = e.target.value;
    // Split the input by commas and line breaks
    const guestsArray = inputValue
      .split(/,|\n/)
      .map((email) => email.trim())
      .filter((email) => isValidEmail(email));

    setGuestsInput(inputValue);
    setGuests(guestsArray);
  };

  // Function to check if a given string is a valid email address
  const isValidEmail = (email) => {
    // You can use a regular expression to validate the email format
    // Here's a simple example, but you can use a more robust regex if needed
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const DateComponent = (inputDate) => {
    // Convert the input date to a JavaScript Date object
    const dateObject = new Date(inputDate);

    // Options for formatting the date
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };

    // Format the date
    const formattedDate = dateObject.toLocaleDateString(undefined, options);

    return formattedDate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name !== "" && email !== null && isValidEmail(email)) {
      setSLoad(true);
      try {
        const modifiedData = {
          name: name,
          email: email,
          details: details,
          consultation_date: formatDateToDDMMYYYY(selectedDate),
          timezone: selectedTimezone,
          start_time: time,
          end_time: updateEndTime(time, 30),
          guests: JSON.stringify(guests),
        };
        const config = {
          headers: {
            "Content-type": "multipart/form-data",
          },
        };
        await API.post(
          "/api/add-consultation?user_type=1",
          modifiedData,
          config
        );
        Swal.fire({
          title: "Success!",
          html: "Application submitted successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
        props.setVisible(false);
      } catch (error) {
      } finally {
        handleClear();
        setActive(0);
        setSLoad(false);
      }
    } else {
      if (name === "" || name === null) {
        setNError(true);
      }
      if (email === "" || email === null || !isValidEmail(email)) {
        setEError(true);
      }
    }
  };

  const footerContent = (
    <div className="d-flex w-100 justify-content-end">
      {active === 0 ? (
        <button
          onClick={() => setActive(1)}
          className={`active-offer-btn offer-accept-pub-btn ${
            selectedDate === null ? "disable-next-btn" : ""
          }`}
          disabled={selectedDate === null}
        >
          {sload ? <CircularProgress className="text-white" /> : "Next"}
        </button>
      ) : (
        <button
          onClick={(e) => handleSubmit(e)}
          className="active-offer-btn offer-accept-pub-btn sc-event-btn"
        >
          {sload ? (
            <CircularProgress className="text-white sc-event-progress" />
          ) : (
            "Schedule Event"
          )}
        </button>
      )}
    </div>
  );

  return (
    <Dialog
      header={
        active === 0 ? (
          "Select a Date & Time"
        ) : (
          <div
            className="back-consultation-circle"
            onClick={() => setActive(0)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        )
      }
      visible={props.visible}
      className="consultation-dialog"
      onHide={() => {
        handleClear();
        setActive(0);
        props.setVisible(false);
      }}
      onClose={() => {
        handleClear();
        setActive(0);
        props.setVisible(false);
      }}
      dismissableMask={true}
      footer={footerContent}
    >
      {load ? (
        <div className="w-100 d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <DaySelect
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          timezones={timezones}
          handleTimezoneChange={handleTimezoneChange}
          selectedTimezone={selectedTimezone}
          setSelectedTimezone={setSelectedTimezone}
          time={time}
          times={times}
          setTime={setTime}
          email={email}
          setEmail={setEmail}
          name={name}
          setName={setName}
          details={details}
          setDetails={setDetails}
          active={active}
          setActive={setActive}
          guestsInput={guestsInput}
          handleGuestsChange={handleGuestsChange}
          guests={guests}
          setGuests={setGuests}
          dateC={DateComponent(selectedDate)}
          endTime={updateEndTime}
          nError={nError}
          eError={eError}
          setNError={setNError}
          setEError={setEError}
        />
      )}
    </Dialog>
  );
};

export default ConsultationModal;
