import { MODERATION_FAIL, MODERATION_REQUEST, MODERATION_SUCCESS } from "../constants/ModerationConstant";

export const moderationReducers = (state = {}, action) => {
    switch (action.type) {
      case MODERATION_REQUEST:
        return {
          mloading: true,
        };
  
      case MODERATION_SUCCESS:
        return {
          ...state,
          mloading: false,
          moderation_check: action.payload,
        };
  
      case MODERATION_FAIL:
        return {
          ...state,
          mloading: false,
          moderation_check: 0,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };