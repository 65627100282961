import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { CircularProgress } from "@mui/material";
// import "./styles/consultation.css";
import "react-phone-input-2/lib/style.css";
import Phone from "../../../assets/images/phone.png";
import { PhoneNumberUtil } from "google-libphonenumber";
import PhoneInput from "react-phone-input-2";
import API from "../../../api/ClientApi";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import "../styles/contactDialog.css";

const ContactModal = (props) => {
  const [sload, setSLoad] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { user } = useSelector((state) => state.profile);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const handleOnChange = (value) => {
    const formattedPhoneNumber = `+${value}`;
    setPhoneNumber(formattedPhoneNumber);
    setIsValidPhoneNumber(true);
  };

  const handleSubmit = async () => {
    // Check if the phone number is valid before submitting the form
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      setSLoad(true);
      const parsedPhoneNumber = phoneUtil.parse(phoneNumber);
      if (phoneUtil.isValidNumber(parsedPhoneNumber)) {
        const config = {
          headers: {
            "Content-type": "multipart/form-data",
          },
        };
        const data = {
          phone_no: phoneNumber,
          name: user?.name,
          email: user?.email,
          user_type: 1,
        };
        await API.post("/api/contact-us-by-phone", data, config);
        props.setContactOpen(false);
        Swal.fire({
          title: "Success!",
          html: "Contact information submitted successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
      } else {
        setIsValidPhoneNumber(false);
      }
    } catch (e) {
      setIsValidPhoneNumber(false);
    } finally {
      setPhoneNumber("");
      setIsValidPhoneNumber(true);
      setSLoad(false);
    }
  };
  const footerContent = (
    <div className="d-flex w-100 justify-content-center">
      <button
        onClick={handleSubmit}
        className="mb-4 active-offer-btn offer-accept-pub-btn sc-event-btn callback-btn"
      >
        {sload ? (
          <CircularProgress className="text-white sc-event-progress" />
        ) : (
          "Call back"
        )}
      </button>
    </div>
  );
  return (
    <Dialog
      visible={props.contactOpen}
      className="contact-dialog"
      onHide={() => {
        setPhoneNumber("");
        setIsValidPhoneNumber(true);
        props.setContactOpen(false);
      }}
      onClose={() => {
        setPhoneNumber("");
        setIsValidPhoneNumber(true);
        props.setContactOpen(false);
      }}
      dismissableMask={true}
      footer={footerContent}
    >
      <div className="d-flex justify-content-center align-items-center step-three-img-container">
        <img src={Phone} alt="weblinks-phone" />
      </div>
      <h3 className="step-three-h3 contact-header">
        Do you want <br />
        <span>a callback?</span>
      </h3>
      <div className="w-100 d-flex justify-content-center step-three-phone">
        <PhoneInput
          country={"us"} // You can set a default country here
          value={phoneNumber}
          onChange={handleOnChange}
          className="phone-dropdown"
          inputClass="phone-input" // Optional: You can add a custom CSS class to the input field
        />
      </div>
      {!isValidPhoneNumber && (
        <p className="text-danger">Invalid phone number</p>
      )}
      {/* <p className="">
        We will contact you after successfull submission of phone number
      </p> */}
    </Dialog>
  );
};

export default ContactModal;
