import React, { useState } from "react";
import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import FacebookIcon from "../../../../assets/images/faceboo-icon.png";
import GoogleIcon from "../../../../assets/images/google-icon.png";
import { useNavigate } from "react-router-dom";
import PendingIcon from "@mui/icons-material/Pending";
import Swal from "sweetalert2";
import API from "../../../../api/ClientApi";

const AccountMain = (props) => {
  const navigate = useNavigate();
  const [code, setCode] = useState(false);
  // Verify code
  const codeVerification = () => {
    Swal.fire({
      html: `
      <h4 style="margin-top: 30px;">A verification code has been sent to your email. Please verify the code.</h4>
        <div class="w-100" id="password-modal">
          <input id="password-input" type="number" 
            style="width: 100%; border-radius: 6px; background-color: #f9f9f9; height: 50px; border: none; outline: none; margin-top: 30px; padding-left: 12px;">
          <div id="password-error-message" style="color: red;text-align: start; font-size:14px;"></div>
        </div>
      `,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: "Verify",
      confirmButtonStyle: "margin-bottom: 15px",
      customClass: {
        confirmButton: "confirm-verify-pass-btn", // Apply the CSS className to the "OK" button
      },
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          // Get the password value from the input field
          const passwordInput = document.getElementById("password-input");
          const code = passwordInput.value;
          const config = {
            headers: {
              "Content-type": "multipart/form-data", // Since we now submiting images as well
            },
          };
          const response = await API.post(
            "/api/verify-code",
            { code: code },
            config
          );
          if (response.data?.status === false) {
            // Display an error message and highlight the input field
            const passwordErrorMessage = document.getElementById(
              "password-error-message"
            );
            passwordErrorMessage.textContent = "Code is invalid";
            passwordInput.style.border = "1px solid red";
            passwordInput.focus();

            // Return false to prevent the modal from closing
            return false;
          } else {
            setCode(response.data.status);
            Swal.fire({
              title: "Success!",
              html: "Verification successfull. You can now update your email.",
              icon: "success",
            });
          }
        } catch (error) {
          // Return false if there's an error to prevent the modal from closing
          return { value: false };
        }
      },
    });
  };

  // Verify password
  const accountMainUpdate = (type) => {
    Swal.fire({
      html: `
      <h4 style="margin-top: 30px;">Please fill your webylinks account password</h4>
        <div class="w-100" id="password-modal">
          <input id="password-input" type="password" 
            style="width: 100%; border-radius: 6px; background-color: #f9f9f9; height: 50px; border: none; outline: none; margin-top: 30px; padding-left: 12px;">
          <div id="password-error-message" style="color: red;text-align: start; font-size:14px;"></div>
        </div>
      `,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: "OK",
      confirmButtonStyle: "margin-bottom: 15px",
      customClass: {
        confirmButton: "confirm-verify-pass-btn", // Apply the CSS className to the "OK" button
      },
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          // Get the password value from the input field
          const passwordInput = document.getElementById("password-input");
          const password = passwordInput.value;
          // Make an API request to verify the password
          const config = {
            headers: {
              "Content-type": "multipart/form-data",
            },
          };
          const response = await API.post(
            "/api/verify-password",
            { password },
            config
          );

          if (response.data?.status === false) {
            // Display an error message and highlight the input field
            const passwordErrorMessage = document.getElementById(
              "password-error-message"
            );
            passwordErrorMessage.textContent = "Password is incorrect";
            passwordInput.style.border = "1px solid red";
            passwordInput.focus();

            // Return false to prevent the modal from closing
            return false;
          } else {
            // Password is correct, continue with the Swal modal flow
            if (type === "password") {
              const config = {
                headers: {
                  "Content-type": "multipart/form-data", // Since we now submiting images as well
                },
              };
              const data = await API.post(
                "/api/forget-password",
                { email: props.profile.email },
                config
              );
              if (data) {
                Swal.fire({
                  title: "Success!",
                  html: data.data.message,
                  icon: "success",
                  timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
                  showConfirmButton: false, // Hide the "OK" button
                  allowOutsideClick: false, // Prevent closing by clicking outside
                });
              }
            }
            if (type === "email") {
              await API.get("/api/change-email");
              codeVerification();
            }
          }
        } catch (error) {
          // Return false if there's an error to prevent the modal from closing
          return { value: false };
        }
      },
    });
  };
  return (
    <div className="billing-info-main info-main-acc">
      {localStorage.getItem("role") === "publisher" &&
        props?.moderated === 0 && (
          <div className="w-100 d-flex justify-content-between align-items-center acc-settings-header">
            <div>
              <h4>Contributor Status</h4>
              <p>
                You can work only with sites where you can confirm your
                ownership. To work with sites as a contributor you need to
              </p>
            </div>
            <div className="moderation-btn-wrapper">
              <Button
                className="moderation-btn"
                onClick={() => {
                  navigate("/dashboard/publisher/moderation-profile");
                }}
              >
                PASS MODERATION
              </Button>
            </div>
          </div>
        )}
      {localStorage.getItem("role") === "publisher" &&
        props?.moderated === 2 && (
          <div className="w-100 d-flex justify-content-end align-items-center">
            <div className="moderation-btn-wrapper">
              <Button
                className={`connected-btn-mod`}
                startIcon={<PendingIcon />}
              >
                Pending moderation
              </Button>
            </div>
          </div>
        )}
      {localStorage.getItem("role") === "publisher" &&
        props?.moderated === 1 && (
          <div className="w-100 d-flex justify-content-end align-items-center">
            <div className="moderation-btn-wrapper">
              <Button className={`connected-btn-mod`} startIcon={<CheckIcon />}>
                Moderated
              </Button>
            </div>
          </div>
        )}
      <div className="w-100">
        <h3>Link Accounts</h3>
      </div>
      <div className="w-100 d-flex justify-content-between multi-input-section">
        {/* <div className="d-flex justify-content-between align-items-center social-container-acc">
          <div className="d-flex align-items-center">
            <img className="mx-3" src={FacebookIcon} alt="social-icon" />
            <p>Facebook account</p>
          </div>
          <Button className="social-acc-btn">LINK ACCOUNT</Button>
        </div> */}
        <div className="d-flex justify-content-between align-items-center social-container-acc second-input">
          <div className="d-flex align-items-center">
            <img className="mx-3" src={GoogleIcon} alt="social-icon" />
            <p className="">Google account</p>
          </div>
          <Button
            className={`social-acc-btn ${
              props.user?.is_google_connected === 1 ? "connected-btn" : ""
            }`}
            onClick={() => {
              if (props.user?.is_google_connected === 0) {
                props.authLogin();
              }
            }}
            startIcon={
              props.user?.is_google_connected === 1 ? <CheckIcon /> : ""
            }
          >
            {props.user?.is_google_connected === 1
              ? "CONNECTED"
              : "LINK ACCOUNT"}
          </Button>
        </div>
      </div>
      <div className="mb-2 w-100 d-flex justify-content-between multi-input-section">
        <div className="info-billing-container">
          <div className="w-100 d-flex justify-content-between align-items-end">
            <label>Account email*</label>
            {props?.user?.login_3rdparty_type === null && (
              <p className="verify-confirmation-text">
                {props?.user?.is_verified === 0 ? "Unverified" : "Verified"}
              </p>
            )}
          </div>
          <TextField
            className="w-100 signup-email-field"
            placeholder="info@youremail.com"
            name="email"
            type="email"
            variant="outlined"
            disabled={code === false}
            value={props.profile.email}
            onChange={(event) => props.handleChange(event)}
            InputProps={{
              classes: {
                root: "custom-input-billing",
                input: "custom-input-billing",
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => accountMainUpdate("email")}
                >
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: "custom-label",
              },
            }}
            autoComplete="off"
          />
        </div>
        {props?.user?.login_3rdparty_type === null && (
          <div className="info-billing-container">
            <div className="w-100">
              <label>Password*</label>
            </div>
            <TextField
              className="w-100 signup-email-field"
              name="password"
              type="password"
              variant="outlined"
              disabled
              value="123456"
              placeholder="Enter your password"
              onChange={(event) => props.handleChange(event)}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => accountMainUpdate("password")}
                  >
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
              autoComplete="off"
            />
          </div>
        )}
      </div>

      {code && (
        <div className="w-100 d-flex justify-content-end account-setting-update">
          <button
            className="billing-btn"
            onClick={async (event) => {
              await props.handleSubmit(event);
              setCode(false);
            }}
          >
            {props.loading ? (
              <CircularProgress className="text-black" />
            ) : (
              "UPDATE"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default AccountMain;
