import React, { useState } from "react";
import { Grid, Box, Tooltip, useMediaQuery } from "@mui/material";
import CountryFlag from "react-country-flag";
import Icon from "../../../assets/DashboardIcons/info.svg";
import Flag from "../../../assets/DashboardIcons/flag.jpg";
import Clock from "../../../assets/DashboardIcons/clock.svg";
import Gear from "../../../assets/DashboardIcons/gear.svg";
import Graph from "../../../assets/DashboardIcons/graph.jpg";
import Limg from "../../../assets/DashboardIcons/link.svg";
import Plus from "../../../assets/DashboardIcons/plus.svg";
import "../../Dashboard/styles/publisherCard.css";
import AddUrlFunds from "../../Dashboard/components/AddUrlFunds";

const PerformerUrlCard = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobileX = useMediaQuery("(max-width: 400px)");
  const languages = [
    { name: "All", code: "All", countryCode: "" }, // You can assign an empty string for "All"
    { name: "English", code: "English", countryCode: "US" },
    { name: "Urdu", code: "Urdu", countryCode: "PK" },
    { name: "Chinese", code: "Chinese", countryCode: "CN" },
    { name: "French", code: "French", countryCode: "FR" },
    { name: "German", code: "German", countryCode: "DE" },
    { name: "Hindi", code: "Hindi", countryCode: "IN" },
    { name: "Spanish", code: "Spanish", countryCode: "ES" },
    { name: "Arabic", code: "Arabic", countryCode: "SA" },
    { name: "Bulgarian", code: "Bulgarian", countryCode: "BG" },
    { name: "Dutch", code: "Dutch", countryCode: "NL" },
    { name: "Greek", code: "Greek", countryCode: "GR" },
    { name: "Hrvatski", code: "Hrvatski", countryCode: "HR" },
    { name: "Indonesian", code: "Indonesian", countryCode: "ID" },
    { name: "Italian", code: "Italian", countryCode: "IT" },
    { name: "Japanese", code: "Japanese", countryCode: "JP" },
    { name: "Korean", code: "Korean", countryCode: "KR" },
    { name: "Norwegian", code: "Norwegian", countryCode: "NO" },
    { name: "Other", code: "Other", countryCode: "" }, // You can assign an empty string for "Other"
    { name: "Polish", code: "Polish", countryCode: "PL" },
    { name: "Portuguese", code: "Portuguese", countryCode: "PT" },
    { name: "Romanian", code: "Romanian", countryCode: "RO" },
    { name: "Russian", code: "Russian", countryCode: "RU" },
    { name: "Spanish", code: "Spanish", countryCode: "ES" },
    { name: "Swedish", code: "Swedish", countryCode: "SE" },
    { name: "Turkish", code: "Turkish", countryCode: "TR" },
    { name: "Ukranian", code: "Ukranian", countryCode: "UA" },
  ];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const truncateText = (text, maxLength) => {
    return text?.length <= maxLength ? text : text?.slice(0, maxLength) + "...";
  };

  return (
    <div className="w-100 mt-3">
      <div className="website-listed-main-wrap">
        <div className="table-responsive">
          <table width="100%" border="0">
            <tr>
              <td width="16%">
                {props?.balance?.is_paid === 0 ? (
                  <h6 className="web-list-url" onClick={openModal}>
                    URL is hidden
                  </h6>
                ) : (
                  <h6
                    className="web-list-url"
                    onClick={() => window.open(props?.data?.url)}
                  >
                    {truncateText(props?.data?.url, isMobileX ? 20 : 30)}
                  </h6>
                )}

                <span>
                  {" "}
                  <img src={Clock} alt="clock" />
                  Spam Score <strong>1%</strong>
                </span>
              </td>
              {/* <td>
                <div className="category-list-wrap">
                  {res?.categories?.map((res, id) => (
                    <span className="mx-1" key={id}>
                      {res?.title}
                    </span>
                  ))}
                </div>
              </td> */}
              {/* <td width="14%">
                <div className="dr-tex-wrap">
                  {" "}
                  <span>
                    {" "}
                    Country <strong>United States</strong>
                  </span>{" "}
                </div>
              </td> */}
              <td width="14%">
                <div className="dr-tex-wrap">
                  {" "}
                  <span>
                    {" "}
                    DA <strong>54</strong>
                  </span>{" "}
                </div>
              </td>
              <td width="14%">
                <div className="dr-tex-wrap">
                  {" "}
                  <span>
                    {" "}
                    DR <strong>49</strong>
                  </span>{" "}
                </div>
              </td>
              <td width="22%">
                <div className="btn-mounth-track">
                  <h5>
                    {" "}
                    Organic Traffic{" "}
                    <span>
                      {" "}
                      <img src={Graph} alt="graph" /> 18,924{" "}
                    </span>{" "}
                  </h5>
                </div>
              </td>
              <td width="14%">
                <div className="language-wrap">
                  <CountryFlag
                    countryCode={
                      languages.find(
                        (lang) => lang.code === props?.data?.language
                      )?.countryCode
                    } // Use the language code from your data
                    style={{ width: "25px" }}
                    svg
                  />
                  <span> {props?.data?.language}</span>
                </div>
              </td>
              <td width="22%">
                <div className="btn-mounth-track">
                  <h5>
                    {" "}
                    Monthly Traffic{" "}
                    <span>
                      {" "}
                      <img src={Graph} alt="graph" /> 18,924{" "}
                    </span>{" "}
                  </h5>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <AddUrlFunds
        isOpen={isModalOpen}
        onClose={closeModal}
        user={props.profile}
        transaction={() => {
          return;
        }}
      />
    </div>
  );
};

export default PerformerUrlCard;
