import React from "react";
import "./noData.css";

const NoData = (props) => {
  return (
    <div
      className={`w-100 d-flex justify-content-center align-items-center ${
        props.listing ? "no-data-listing" : "no-data"
      }`}
    >
      <p>No results found.</p>
    </div>
  );
};

export default NoData;
