import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, CircularProgress, Button } from "@mui/material";
import NoData from "../../../components/NoData/NoData";
import { Paginator } from "primereact/paginator";
import { useSelector, useDispatch } from "react-redux";
import {
  userCountNotifications,
  userNotifications,
  userUnreadCountNotifications,
} from "../../../actions/notificationActions";
import axios from "axios";
import API from "../../../api/ClientApi";
import "../styles/notification_styles.css";

const NotificationsMain = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [nloading, setNloading] = useState(false);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [serviceActive, setServiceActive] = useState(0);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(10);
  const [readN, setRead] = useState(0);
  const [unread, setUnRead] = useState(0);
  const [disable, setDisable] = useState(false);
  const [tab, setTab] = useState(0);
  const [subtab, setSubTab] = useState("Order");
  // const { nloading, notification_data } = useSelector(
  //   (state) => state.notification
  // );
  const [notification_data, setNData] = useState(null);
  const { notification_count } = useSelector(
    (state) => state.notificationCount
  );

  const handleWebTabChange = (e) => {
    setCurrentPage(1);
    setTab(e);
    setSubTab("Order");
  };
  const handleSubWebTabChange = (e, tabIndex) => {
    setSubTab(tabIndex);
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();

    // Check if the date is today
    if (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    ) {
      // Format as time (HH:mm)
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    } else {
      // Format as MM yyyy
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const year = String(date.getFullYear());
      return `${month} ${year}`;
    }
  }

  // Markin read
  const read = async (id, notification, order_id) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        // You can add other headers here if needed
      };

      if (
        notification === "Order" ||
        notification === "Task Award" ||
        notification === "Task Invitation" ||
        notification === "Order Completed" ||
        notification === "Task Improvement"
      ) {
        if (localStorage.getItem("role") === "buyer") {
          navigate(`/dashboard/task/${order_id}`);
        } else {
          navigate(`/dashboard/publisher/task/${order_id}`);
        }
      }

      if (notification === "Open Offer") {
        if (localStorage.getItem("role") === "buyer") {
          navigate("/dashboard/buyer/open-offers");
        } else {
          navigate("/dashboard/publisher/open-offers");
        }
      }

      if (notification === "Ownership Claim") {
        navigate("/dashboard/platform");
      }

      await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/api/notification-update/${id}`,
        null, // Since you're not sending a request body, pass null as the second argument
        { headers } // Pass the headers object to Axios
      );

      dispatch(userUnreadCountNotifications());
    } catch (error) {
      return error;
    }
  };

  // Navigate on read
  const readNavigate = async (id, notification, order_id) => {
    if (notification === "Order") {
      if (localStorage.getItem("role") === "buyer") {
        navigate(`/dashboard/task/${order_id}`);
      } else {
        navigate(`/dashboard/publisher/task/${order_id}`);
      }
    }

    if (notification === "Open Offer") {
      if (localStorage.getItem("role") === "buyer") {
        navigate("/dashboard/buyer/open-offers");
      } else {
        navigate("/dashboard/publisher/open-offers");
      }
    }

    if (notification === "Ownership Claim") {
      navigate("/dashboard/platform");
    }
  };

  useEffect(() => {
    // dispatch(userNotifications(tab));
    setNloading(true);
    const fetchData = async () => {
      try {
        const response = await API.get(
          `/api/get-notifications?read=${tab}&notification_type=${subtab}&page=${currentPage}&user_type=${
            localStorage.getItem("role") === "buyer" ? 1 : 2
          }`
        );
        setNData(response.data);
        setRead(response?.data?.notificationReadCount);
        setUnRead(response?.data?.notificationUnReadCount);
        setCurrentPage(response?.data?.pagination?.notifications?.current_page);
        setTotalPages(response?.data?.pagination?.notifications?.last_page);
        setTotal(response?.data?.pagination?.notifications?.total);
        setNloading(false);
      } catch (error) {
        // Handle error here
        setNloading(false);
        return error;
      }
    };
    fetchData();
  }, [currentPage, tab, subtab]);

  return (
    <div className="buyer-form-main-wraper">
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Notifications </h2>
          </div>
        </div>
      </div>{" "}
      <div className="order-article-tabs-wrap">
        <div className="w-100 open-offer-tabs">
          <Tabs
            className="tabs-add-web"
            value={tab}
            onChange={() => handleWebTabChange(tab === 0 ? 1 : 0)}
            variant="scrollable"
          >
            <Tab
              className={`${tab === 0 ? "tab-active" : "account-list-tabs"}`}
              icon={
                <div className="d-flex">
                  <span>Unread</span>
                  <div className="count-circle">
                    {unread !== undefined ? unread : 0}
                  </div>
                </div>
              }
              value={0}
              key={1}
            ></Tab>
            <Tab
              className={`${tab === 1 ? "tab-active" : "account-list-tabs"}`}
              icon={
                <div className="d-flex">
                  <span>Read</span>
                  <div className="count-circle">
                    {readN !== undefined ? readN : 0}
                  </div>
                </div>
              }
              value={1}
              key={2}
            ></Tab>
          </Tabs>
        </div>
        {nloading ? (
          <div className="w-100 mt-2 d-flex justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="table-responsive offer-table">
              <table width="100%" className="table">
                <tr className="heading-article">
                  <td width="15%">ID</td>
                  <td width="30%">Title</td>
                  <td width="30%">Description</td>
                  <td width="25%">Time</td>
                </tr>
                {notification_data?.pagination?.notifications?.data &&
                  notification_data?.pagination?.notifications?.data?.map(
                    (res, id) => (
                      <tr
                        className="tr-notifications"
                        onClick={() => {
                          if (res?.read === 0) {
                            read(
                              res?.id,
                              res?.notification_type,
                              res?.order_id
                            );
                          } else {
                            readNavigate(
                              res?.id,
                              res?.notification_type,
                              res?.order_id
                            );
                          }
                        }}
                      >
                        <td height="70">
                          <span>#{id + 1}</span>
                        </td>
                        <td>{res?.notification_type}</td>
                        <td>{res?.order_description}</td>

                        <td>{formatTimestamp(res?.created_at)}</td>
                      </tr>
                    )
                  )}
              </table>
              {(notification_data === null ||
                notification_data?.pagination?.notifications?.data?.length ===
                  0 ||
                notification_data?.data === "Data not found") && <NoData />}
            </div>
            <div>
              {total > 10 && (
                <Paginator
                  first={currentPage * rows - rows}
                  rows={rows}
                  totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationsMain;
