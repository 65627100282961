import React from "react";
import UpdatePassword from "./components/UpdatePassword";

const ForgotPassword = () => {
  return (
    <div>
      <UpdatePassword />
    </div>
  );
};

export default ForgotPassword;
