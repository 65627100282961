import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
} from "@mui/material";
import "../styles/contentPurchase.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BreadCrumb } from "primereact/breadcrumb";
import Trash from "../../../assets/images/trash.svg";
import Plus from "../../../assets/images/plus.svg";
import API from "../../../api/ClientApi";
import NoData from "../../../components/NoData/NoData";
import { Paginator } from "primereact/paginator";

const ContentPurchase = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [load, setLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [typeTab, setTypeTab] = useState(17);
  const [tabs, setTabs] = useState([]);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(10);
  const items = [{ label: "Content purchase" }];
  const home = { label: "Home", url: "/dashboard/website-list" };

  const handleWebTabChange = (e, tabIndex) => {
    setTypeTab(tabIndex);
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    setLoad(true);
    const getListTabs = async () => {
      try {
        const data = await API.get(
          "/api/get-status-by-article-order?user_type=1"
        );
        if (data) {
          setTabs(data?.data?.status_data);
        }
      } catch (error) {
        return error;
      }
    };

    const getOrders = async () => {
      try {
        const response = await API.get(
          `/api/article-order?status_id=${typeTab}&page=${currentPage}&user_type=1`
        );
        setOrders(response?.data?.getArticleOrders?.data);
        setCurrentPage(response?.data?.getArticleOrders?.current_page);
        setTotalPages(response?.data?.getArticleOrders?.last_page);
        setTotal(response?.data?.getArticleOrders?.total);
        setLoad(false);
      } catch (error) {
        setLoad(false);
        return error;
      }
    };
    getListTabs();
    getOrders();
  }, [currentPage, localStorage.getItem("role"), typeTab]);

  return (
    <div className="buyer-form-main-wraper">
      <BreadCrumb model={items} home={home} />
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Content Purchase </h2>
          </div>
        </div>
      </div>{" "}
      <Accordion className="accordion-filter accordion-global">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-summery"
        >
          <div className="w-100 d-flex justify-content-between filter-header">
            <h2>HOW IT WORKS?</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <div className="accordion-detail-div ac-div">
            From the section below you can see your content orders categorized
            according to various statuses.Adjacent to each order you can see a
            number representing the quantity of orders under that status.When
            the order is further processed it move from one status to
            another.Confirmation will be sent through emails and
            notifications.The three dots button will guide you to get more
            information about the order.
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="cta-add-website-wrap">
        <div className="row">
          <div className="col-md-9">
            <h2 className="new-aticle-heading"> Want to order a new article? </h2>
          </div>
          <div className="col-md-3">
            <div className="add-website-wrap task-list-head-btn">
              {" "}
              <Button
                onClick={() => {
                  navigate("/dashboard/order-article");
                }}
                className="website-add-banner-btn"
              >
                Order Article
              </Button>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="order-article-tabs-wrap">
        <div className="w-100 open-offer-tabs">
          <Tabs
            className="tabs-add-web"
            value={typeTab}
            onChange={handleWebTabChange}
            variant="scrollable"
          >
            {tabs &&
              tabs?.map((res, id) => (
                <Tab
                  className={`${
                    typeTab === res?.id ? "tab-active" : "account-list-tabs"
                  }`}
                  icon={
                    <div className="d-flex">
                      <span>{res?.status_name}</span>
                      <div className="count-circle">
                        {res?.wl_order?.length > 0
                          ? res?.wl_order[0]?.article_count
                          : 0}
                      </div>
                    </div>
                  }
                  value={res?.id}
                  key={id}
                ></Tab>
              ))}
          </Tabs>
        </div>

        {load ? (
          <div className="w-100 mt-2 d-flex justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table width="100%" className="table">
                <tr className="heading-article">
                  <td width="16.5%">Order ID</td>
                  <td width="17.5%">Category</td>
                  <td width="16.5%">Keyword</td>
                  <td width="16.5%">Word Count</td>
                  <td width="16.5%">Price</td>
                  <td width="16.5%">Actions</td>
                </tr>
                {orders &&
                  orders?.map((res, id) => (
                    <tr>
                      <td height="70">
                        <span>#{id + 1}</span>
                      </td>
                      <td>
                        {res?.category_id === 1
                          ? "Business"
                          : res?.category_id === 2
                          ? "Sports"
                          : "Travel"}
                      </td>
                      <td>{res?.keywords}</td>
                      <td>{res?.words}</td>
                      <td>${res?.total_amount}</td>

                      <td>
                        <div className="d-flex px-0">
                          <button
                            className="active-offer-btn offer-accept-pub-btn"
                            onClick={() =>
                              navigate(`/dashboard/article-view/${res?.id}`)
                            }
                          >
                            {" "}
                            View{" "}
                          </button>

                          <button className="active-offer-btn offer-reject-btn">
                            Cancel
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
              {orders?.length === 0 && <NoData />}
            </div>
            <div>
              {total > 10 && (
                <Paginator
                  first={currentPage * rows - rows}
                  rows={rows}
                  totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContentPurchase;
