import React from "react";

const NotFound = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h2>404 - Not Found</h2>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
