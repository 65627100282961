import React from "react";
import { Button, CircularProgress, TextField } from "@mui/material";

const AddOneWebiste = (props) => {
  return (
    <div className="billing-info-main">
      <div className="w-100">
        <label>URL*</label>
      </div>
      <form onSubmit={props.handleSubmit}>
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container info-url">
            <TextField
              className="w-100 signup-email-field"
              placeholder="http(s)://"
              name="url"
              type="url"
              variant="outlined"
              required
              value={props.url}
              onChange={(e) => {
                e.preventDefault();
                props.setError("");
                props.setUrl(e.target.value);
              }}
              InputProps={{
                classes: {
                  root: "custom-input-billing",
                  input: "custom-input-billing",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
          <div className="info-billing-container second-input info-next">
            <Button type="submit" className="info-next-btn">
              {props.load ? (
                <CircularProgress
                  className="text-white"
                  style={{ color: "#ffffff !important" }}
                />
              ) : (
                "Next"
              )}
            </Button>
          </div>
        </div>
      </form>
      <div className="w-100">
        {props.error && (
          <p className="" style={{ color: "red" }}>
            {props.error}
          </p>
        )}
      </div>
    </div>
  );
};

export default AddOneWebiste;
