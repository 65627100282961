import React from "react";
import SignupMain from "./components/SignupMain";

const Signup = () => {
  return (
    <div>
      <SignupMain />
    </div>
  );
};

export default Signup;
