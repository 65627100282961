import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import API from "../../../api/ClientApi";
import Swal from "sweetalert2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Plus from "../../../assets/images/plus.svg";
import InfoIcon from "../../../assets/images/infoIcon.png";
import {
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import validUrl from "valid-url";
import { BreadCrumb } from "primereact/breadcrumb";

const EditOpenOfferForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const form2Ref = useRef(null);
  const form4Ref = useRef(null);
  const form5Ref = useRef(null);
  const [error, setError] = useState("");
  const [offerError, setOfferError] = useState("");
  const [urlError, setUrlError] = useState("");
  const [briefError, setBriefError] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const items = [
    { label: "Offers", url: "/dashboard/buyer/open-offers" },
    { label: "Edit offer" },
  ];
  const home = { label: "Home", url: "/dashboard/website-list" };
  const [data, setData] = useState({
    publishers: "all",
    price_type: 1,
    price: 0,
    service_type: 1,
    content_option: 1,
    offer_name: "",
    promoted_url: "",
    brief_requirements: "",
    activated: false,
  });

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    if (name === "offer_name") {
      setOfferError("");
    }
    if (name === "promoted_url") {
      setUrlError("");
    }
    if (name === "brief_requirements") {
      setBriefError("");
    }
    if (name === "price") {
      setError("");
    }
    if (type === "radio") {
      if (name === "price_typeApprox") {
        const newName = "price_type";
        setData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
      if (name === "price_typePub") {
        setError("");
        const newName = "price_type";
        setData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
      if (name === "service_typeA") {
        const newName = "service_type";
        setData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
      if (name === "service_typeB") {
        const newName = "service_type";
        setData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
      if (name === "content_typeA") {
        const newName = "content_option";
        setData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
      if (name === "content_typeB") {
        const newName = "content_option";
        setData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
      if (name === "content_typeC") {
        const newName = "content_option";
        setData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    if (data?.price_type === 1) {
      if (data?.price === 0 || data?.price === "" || data?.price === "0") {
        formRef.current.scrollIntoView({ behavior: "smooth" });
        setError("Enter a price greater then 0");
      } else if (data?.price.includes("-")) {
        formRef.current.scrollIntoView({ behavior: "smooth" });
        setError("Enter a price greater then 0");
      }
    }
    if (data?.brief_requirements === "") {
      form2Ref.current.scrollIntoView({ behavior: "smooth" });
      setBriefError("Please enter your requirements");
    }
    if (data?.offer_name === "") {
      form4Ref.current.scrollIntoView({ behavior: "smooth" });
      setOfferError("Please enter your offer name");
    }
    if (!isValidUrl(data?.promoted_url)) {
      form5Ref.current.scrollIntoView({ behavior: "smooth" });
      setUrlError("Please enter a valid url");
    }
    if (
      data?.offer_name &&
      data?.brief_requirements &&
      isValidUrl(data?.promoted_url)
    ) {
      if (
        (data?.price_type === 1 &&
          data?.price !== 0 &&
          data?.price !== "0" &&
          data?.price !== "" &&
          !data?.price.includes("-")) ||
        data?.price_type === 2
      ) {
        const modifiedData = {
          ...data,
          price: data?.price_type === 1 ? data?.price : "open price",
          service_type:
            data?.service_type === 1
              ? "Content placement"
              : "Content creation and placement",
          content_option:
            data?.content_option === 1
              ? "Mini-post"
              : data?.content_option === 2
              ? "Article"
              : "Long article",
        };

        try {
          setLoad(true);
          const config = {
            headers: {
              "Content-type": "multipart/form-data",
            },
          };
          const data = await API.put(
            `/api/open-offer/${id}?offer_name=${modifiedData?.offer_name}&promoted_url=${modifiedData?.promoted_url}&brief_requirements=${modifiedData?.brief_requirements}&price=${modifiedData?.price}&service_type=${modifiedData?.service_type}&content_option=${modifiedData?.content_option}`,

            config
          );
          if (data) {
            setLoad(false);
            Swal.fire({
              title: "Success!",
              html: "Offer updated successfully",
              icon: "success",
              timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
              showConfirmButton: false, // Hide the "OK" button
              allowOutsideClick: false, // Prevent closing by clicking outside
            });
            localStorage.setItem("setSubRoute", "/dashboard/buyer/open-offers");
            navigate("/dashboard/buyer/open-offers");
          }
        } catch (error) {
          setLoad(false);
          Swal.fire({
            title: "Error!",
            html: error.response.data.message,
            icon: "error",
            timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
            showConfirmButton: false, // Hide the "OK" button
            allowOutsideClick: false, // Prevent closing by clicking outside
          });
        }
      }
    }
  };

  const isValidUrl = (url) => {
    // Regular expression to match a URL pattern with "https://"
    return validUrl.isWebUri(url);
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    const getOfferDetail = async () => {
      setLoading(true);
      const response = await API.get(`/api/open-offer/${id}/edit`);
      setData((prevData) => ({
        ...prevData,
        brief_requirements: response?.data?.brief_requirements,
        promoted_url: response?.data?.promoted_url,
        offer_name: response?.data?.offer_name,
        price_type: response?.data?.price === "open price" ? 2 : 1,
        price:
          response?.data?.price !== "open price" ? response?.data?.price : 0,
        service_type:
          response?.data?.service_type === "Content placement" ? 1 : 2,
        content_option:
          response?.data?.content_option === "Mini-post"
            ? 1
            : response?.data?.content_option === "Article"
            ? 2
            : 3,
      }));
      setLoading(false);
    };

    getOfferDetail();
  }, [localStorage.getItem("role")]);

  return (
    <div className="buyer-form-main-wraper">
      <BreadCrumb model={items} home={home} />
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Offer </h2>
          </div>
        </div>
      </div>
      <Accordion className="accordion-filter accordion-global">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-summery"
        >
          <div className="w-100 d-flex justify-content-between filter-header">
            <h2>HOW IT WORKS?</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <div className="accordion-detail-div ac-div">
            The open offer enables you to consistently request sponsored posts
            for your website without encountering additional complications.Open
            offers works with a bidding scheme-publishers bid with their price
            suggestions with relevant websites, and you have the freedom to opt
            for the choice that provides the greatest worth for your investment.
            This is the section where you will create an open offer from
            scratch.Provide necessary details and pull it forward for bidding
            process.
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="list-website-info">
        <span>
          {" "}
          <img src={InfoIcon} alt="" />{" "}
        </span>
        <strong>
          {" "}
          This is the section where you will create an open offer from
          scratch.Provide necessary details and pull it forward for bidding
          process.
        </strong>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="create-offer-form-wrap">
          <div className="row">
            <div className="col-md-6">
              <div className="create-form-left">
                <div className="form-field-group">
                  <div className="form-field-label">
                    {" "}
                    <div className="detail-field-label">
                      {" "}
                      <span> Price type </span>
                      Set an approximate price you are ready to pay (allowing
                      some fluctuation), or choose to consider publishers’
                      prices.{" "}
                    </div>
                  </div>
                  <div className="form-check-inline" ref={formRef}>
                    <label className="customradio">
                      <span className="radiotextsty radiotxt2">
                        {" "}
                        Approximate price
                      </span>
                      <input
                        type="radio"
                        checked={data?.price_type === 1}
                        name="price_typeApprox"
                        value={1}
                        onChange={handleChange}
                      />
                      <span className="checkmark"></span>{" "}
                    </label>
                    <input
                      type="number"
                      className={`form-control dollar-numbers ${
                        error ? "doller-err" : ""
                      }`}
                      placeholder={data?.price}
                      name="price"
                      disabled={data?.price_type === 2}
                      onChange={handleChange}
                    />
                    <span>$</span>
                  </div>
                  {error && <p className="err-offers">{error}</p>}
                  <div className="form-check-inline">
                    <label className="customradio">
                      <span className="radiotextsty radiotxt2">
                        {" "}
                        Totally open for publishers' offers
                      </span>
                      <input
                        type="radio"
                        checked={data?.price_type === 2}
                        name="price_typePub"
                        value={2}
                        onChange={handleChange}
                      />
                      <span className="checkmark"></span>{" "}
                    </label>
                  </div>
                </div>

                <div className="form-field-group">
                  <div className="form-field-label">
                    {" "}
                    <div className="detail-field-label">
                      <span> Type of service</span> Choose the service type (as
                      always at Webylinks, you can choose between Content
                      placement or Content creation and placement){" "}
                    </div>
                  </div>
                  <div className="form-check-inline">
                    <label className="customradio">
                      <span className="radiotextsty radiotxt2">
                        {" "}
                        Content placement
                      </span>
                      <input
                        type="radio"
                        name="service_typeA"
                        checked={data?.service_type === 1}
                        value={1}
                        onChange={handleChange}
                      />
                      <span className="checkmark"></span>{" "}
                    </label>
                    <p className="text-detail-checkbox">
                      {" "}
                      Publishers place the content you provide{" "}
                    </p>
                  </div>
                  <div className="form-check-inline">
                    <label className="customradio">
                      <span className="radiotextsty radiotxt2">
                        {" "}
                        Content creation and placements
                      </span>
                      <input
                        type="radio"
                        name="service_typeB"
                        checked={data?.service_type === 2}
                        value={2}
                        onChange={handleChange}
                      />
                      <span className="checkmark"></span>{" "}
                    </label>
                    <p className="text-detail-checkbox">
                      {" "}
                      Publishers create content considering your requirements,
                      and then place it on their sites{" "}
                    </p>
                  </div>
                  {data?.service_type === 2 && (
                    <div className="d-flex flex-column service-type-sub">
                      <div className="form-check-inline">
                        <label className="customradio">
                          <span className="radiotextsty service-type-radio">
                            {" "}
                            <strong className="type-strong">
                              Mini-post
                            </strong>{" "}
                            up to 300 words, 1 hyperlink allowed
                          </span>
                          <input
                            type="radio"
                            name="content_typeA"
                            checked={
                              data?.service_type === 2 &&
                              data?.content_option === 1
                            }
                            value={1}
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>{" "}
                        </label>
                        {/* <p className="text-detail-checkbox">
                      {" "}
                      Publishers create content considering your requirements,
                      and then place it on their sites{" "}
                    </p> */}
                      </div>
                      <div className="form-check-inline">
                        <label className="customradio">
                          <span className="radiotextsty service-type-radio">
                            {" "}
                            <strong className="type-strong">
                              Article
                            </strong>{" "}
                            300-1000 words, 2 hyperlinks allowed
                          </span>
                          <input
                            type="radio"
                            name="content_typeB"
                            checked={
                              data?.service_type === 2 &&
                              data?.content_option === 2
                            }
                            value={2}
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>{" "}
                        </label>
                        {/* <p className="text-detail-checkbox">
                      {" "}
                      Publishers create content considering your requirements,
                      and then place it on their sites{" "}
                    </p> */}
                      </div>
                      <div className="form-check-inline">
                        <label className="customradio">
                          <span className="radiotextsty service-type-radio">
                            {" "}
                            <strong className="type-strong">Long</strong>{" "}
                            article more than 1000 words, 3 hyperlinks allowed
                          </span>
                          <input
                            type="radio"
                            name="content_typeC"
                            checked={
                              data?.service_type === 2 &&
                              data?.content_option === 3
                            }
                            value={3}
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>{" "}
                        </label>
                        {/* <p className="text-detail-checkbox">
                      {" "}
                      Publishers create content considering your requirements,
                      and then place it on their sites{" "}
                    </p> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="create-form-left">
                <div className="form-field-group">
                  <div className="form-field-label">
                    {" "}
                    <div className="detail-field-label offer-info-sec">
                      {" "}
                      <span> Offer name</span>Provide an offer name to make it
                      easy for you to find it later{" "}
                    </div>
                  </div>
                  <div className="form-group" ref={form4Ref}>
                    <input
                      type="text"
                      className={`form-control ${offerError ? "doller-err" : ""}`}
                      placeholder="Offer Name"
                      name="offer_name"
                      value={data?.offer_name}
                      onChange={handleChange}
                    />
                  </div>
                  {offerError && <p className="err-offers">{offerError}</p>}
                </div>
                <div className="form-field-group">
                  <div className="form-field-label">
                    {" "}
                    <div className="detail-field-label offer-info-sec">
                      {" "}
                      <span> Promoted URL</span> Provide URL of the site you
                      want to promote.{" "}
                    </div>
                  </div>
                  <div className="form-group" ref={form5Ref}>
                    <input
                      type="text"
                      className={`form-control ${urlError ? "doller-err" : ""}`}
                      placeholder="https://"
                      name="promoted_url"
                      value={data?.promoted_url}
                      onChange={handleChange}
                    />
                  </div>
                  {urlError && <p className="err-offers">{urlError}</p>}
                </div>

                <div className="form-field-group">
                  <div className="form-field-label">
                    {" "}
                    <div
                      className="detail-field-label offer-info-sec"
                      ref={form2Ref}
                    >
                      {" "}
                      <span> Brief requirements </span>
                      <strong>IMPORTANT:</strong> Don’t provide any content for
                      publishers at this stage to avoid theft or plagiarism.
                      You’ll be able to do it after you choose the publisher{" "}
                    </div>
                  </div>

                  <div className="textarea-group-wrap">
                    <textarea
                      className={`form-control txt-area-offer ${
                        briefError ? "doller-err" : ""
                      }`}
                      rows="5"
                      name="brief_requirements"
                      value={data?.brief_requirements}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  {briefError && <p className="err-offers">{briefError}</p>}
                </div>
              </div>
            </div>
          </div>

          <div className="w-100 d-flex justify-content-end open-offer-btn-wrapper">
            <Button className="open-offer-btn" onClick={handleSubmit}>
              {load ? <CircularProgress className="text-black"/> : "Update"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditOpenOfferForm;
