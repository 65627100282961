import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomizedView from "./components/CustomizedView";

const CustomizedOrderView = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
  }, [localStorage.getItem("role")]);

  return (
    <div>
      <CustomizedView />
    </div>
  );
};

export default CustomizedOrderView;
