import React, { useState } from "react";
import API from "../../../api/ClientApi";
import Swal from "sweetalert2";
import DeActivateIcon from "../../../assets/images/de-activite.svg";

import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

const DeactivateModal = (props) => {
  const [load, setLoad] = useState(false);
  const handleDelete = () => {
    setLoad(true);
    API.patch(`/api/status-change-by-publisher/${props.data?.id}?is_active=0`)
      .then((response) => {
        setLoad(false);
        // Handle success or failure
        // Close the confirmation modal
        localStorage.setItem("selectedSubRoute", "/dashboard/platform");
        Swal.fire({
          title: "Success!",
          html: "Website deactivated successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
        props.getList();
        props.handleModalClose();
      })
      .catch((error) => {
        setLoad(false);
        // Handle error
      });
  };

  return (
    <Modal
      open={props.showDecConfirmationModal}
      onClose={props.handleDecModalClose}
      aria-labelledby="Deactivate Website"
    >
      <Box className="modal-container">
        <img src={DeActivateIcon} alt="Delete Icon" />

        <Typography variant="h6" id="modal-title" gutterBottom>
          Deactivate Website
        </Typography>
        <Typography className="detail-modal-text">
          Are you sure you want to deactivate this website?
        </Typography>
        <hr />
        <div className="buttons-container">
          <Button
            onClick={props.handleDecModalClose}
            className="cancel-btn-modal"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="delete-button"
            onClick={handleDelete}
          >
            {load ? (
              <CircularProgress className="text-white circle-delete" />
            ) : (
              "Deactivate"
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeactivateModal;
