import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  DialogContent,
  IconButton,
  Popover,
  MenuItem,
  Select,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import InfoIcon from "../../../assets/images/infoIcon.png";
import PublisherInfoCard from "../../Dashboard/components/PublisherInfoCard";
import API from "../../../api/ClientApi";
import { useNavigate, useParams } from "react-router-dom";
import NoData from "../../../components/NoData/NoData";
import { Clear } from "@material-ui/icons";
import { Paginator } from "primereact/paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import PublisherWeblistCard from "../../Dashboard/components/PublisherWeblistCard";
import Icon from "../../../assets/DashboardIcons/info.svg";
import Flag from "../../../assets/DashboardIcons/flag.jpg";
import Clock from "../../../assets/DashboardIcons/clock.svg";
import Gear from "../../../assets/DashboardIcons/gear.svg";
import Graph from "../../../assets/DashboardIcons/graph.jpg";
import Limg from "../../../assets/DashboardIcons/link.svg";
import Plus from "../../../assets/DashboardIcons/plus.svg";
import Search from "../../../assets/DashboardIcons/search.svg";
import Swal from "sweetalert2";
import CountryFlag from "react-country-flag";

const ProfileWebList = (props) => {
  const navigate = useNavigate();
  const isMobileX = useMediaQuery("(max-width: 400px)");
  const isMobileL = useMediaQuery("(max-width: 1550px)");
  const isMobileM = useMediaQuery("(max-width: 1230px)");
  const [allWeb, setAllWeb] = useState(true);
  const { id } = useParams();
  const { user } = useSelector((state) => state.profile);
  const { balance, wloading } = useSelector((state) => state.balance);
  const [websites, setWebsites] = useState(null);
  const [dates, setDates] = useState(null);
  const [webload, setWebLoad] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [minimumPriceError, setMinimumPriceError] = useState("");
  const [maximumPriceError, setMaximumPriceError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(10);
  const [category, setCategory] = useState([]);
  const [lang, setLang] = useState("All");
  const [cat, setCat] = useState("All");
  const [searchUrl, setSearchUrl] = useState("");
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const toggleAdditionalFilters = () => {
    setShowAdditionalFilters(!showAdditionalFilters);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    minimum_price: null,
    maximum_price: null,
    service_type: { name: "All", code: "All" },
    language: { name: "All", code: "All" },
    category_id: { name: "All", code: "All" },
    disclosure_type: { name: "All", code: "All" },
    link_type: { name: "All", code: "All" },
    ahref_organic_traffic: { name: "All", code: "All" },
    marked_sponsored_by: { name: "All", code: "All" },
    publishers_tat: { name: "All", code: "All" },
    publishers_rating: { name: "All", code: "All" },
    security_deposit: { name: "All", code: "All" },
    average_liftime_of_links: { name: "More than 70%", code: 70 },
  });

  const [page, setPage] = useState({ name: "30 per page", code: 30 });
  const [sortBy, setSortBy] = useState({
    name: "From the lowest price",
    code: "lowest_price",
  });

  const languages = [
    { name: "All", code: "All", countryCode: "" }, // You can assign an empty string for "All"
    { name: "English", code: "English", countryCode: "US" },
    { name: "Urdu", code: "Urdu", countryCode: "PK" },
    { name: "Chinese", code: "Chinese", countryCode: "CN" },
    { name: "French", code: "French", countryCode: "FR" },
    { name: "German", code: "German", countryCode: "DE" },
    { name: "Hindi", code: "Hindi", countryCode: "IN" },
    { name: "Spanish", code: "Spanish", countryCode: "ES" },
    { name: "Arabic", code: "Arabic", countryCode: "SA" },
    { name: "Bulgarian", code: "Bulgarian", countryCode: "BG" },
    { name: "Dutch", code: "Dutch", countryCode: "NL" },
    { name: "Greek", code: "Greek", countryCode: "GR" },
    { name: "Hrvatski", code: "Hrvatski", countryCode: "HR" },
    { name: "Indonesian", code: "Indonesian", countryCode: "ID" },
    { name: "Italian", code: "Italian", countryCode: "IT" },
    { name: "Japanese", code: "Japanese", countryCode: "JP" },
    { name: "Korean", code: "Korean", countryCode: "KR" },
    { name: "Norwegian", code: "Norwegian", countryCode: "NO" },
    { name: "Other", code: "Other", countryCode: "" }, // You can assign an empty string for "Other"
    { name: "Polish", code: "Polish", countryCode: "PL" },
    { name: "Portuguese", code: "Portuguese", countryCode: "PT" },
    { name: "Romanian", code: "Romanian", countryCode: "RO" },
    { name: "Russian", code: "Russian", countryCode: "RU" },
    { name: "Spanish", code: "Spanish", countryCode: "ES" },
    { name: "Swedish", code: "Swedish", countryCode: "SE" },
    { name: "Turkish", code: "Turkish", countryCode: "TR" },
    { name: "Ukranian", code: "Ukranian", countryCode: "UA" },
  ];

  const services = [
    { name: "All", code: "All" },
    { name: "Mini-post", code: "Mini-post" },
    { name: "Article", code: "Article" },
    { name: "Long article", code: "long-article" },
    { name: "Content placement price", code: "Content-placement-price" },
  ];

  const disclosure = [
    { name: "All", code: "All" },
    { name: "Yes", code: 1 },
    { name: "No", code: 0 },
  ];

  const link = [
    { name: "All", code: "All" },
    { name: "DoFollow", code: 1 },
    { name: "UnFollow", code: 0 },
  ];

  const publisherTAT = [
    { name: "All", code: "All" },
    { name: "Up to 1 day", code: 1 },
    { name: "Up to 2 days", code: 2 },
    { name: "Up to 3 days", code: 3 },
    { name: "Up to 4 days", code: 4 },
    { name: "Up to 5 days", code: 5 },
    { name: "Up to 6 days", code: 6 },
    { name: "Up to 7 days", code: 7 },
    { name: "Up to 8 days", code: 8 },
    { name: "Up to 9 days", code: 9 },
    { name: "More then 10 days", code: 10 },
  ];

  const publisherRating = [
    { name: "All", code: "All" },
    { name: "1 star", code: 1 },
    { name: "2 stars", code: 2 },
    { name: "3 stars", code: 3 },
    { name: "4 stars", code: 4 },
    { name: "5 stars", code: 5 },
  ];

  const averageLinkLifetime = [
    { name: "More than 70%", code: 70 },
    { name: "More than 80%", code: 80 },
    { name: "More than 90%", code: 90 },
  ];

  const pages = [
    { name: "30 per page", code: 30 },
    { name: "50 per page", code: 50 },
    { name: "100 per page", code: 100 },
  ];

  const sort = [
    { name: "From the lowest price", code: "lowest_price" },
    { name: "From the greatest price", code: "highest_price" },
  ];

  const truncateText = (text, maxLength) => {
    return text?.length <= maxLength ? text : text?.slice(0, maxLength) + "...";
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseDescription = () => {
    setAnchorEl(null);
    setCurrentDescription("");
  };

  const clear = async (e) => {
    e.preventDefault();
    setMaximumPriceError("");
    setMinimumPriceError("");
    if (
      filterOptions.minimum_price !== null ||
      filterOptions?.maximum_price !== null ||
      filterOptions?.service_type !== "All" ||
      filterOptions?.language !== "All" ||
      filterOptions?.category_id !== "All" ||
      filterOptions?.disclosure_type !== "All" ||
      filterOptions?.link_type !== 0
    ) {
      const newFilterOptions = {
        minimum_price: null,
        maximum_price: null,
        service_type: { name: "All", code: "All" },
        language: { name: "All", code: "All" },
        category_id: { name: "All", code: "All" },
        disclosure_type: { name: "All", code: "All" },
        link_type: { name: "All", code: "All" },
        ahref_organic_traffic: { name: "All", code: "All" },
        marked_sponsored_by: { name: "All", code: "All" },
        publishers_tat: { name: "All", code: "All" },
        publishers_rating: { name: "All", code: "All" },
        security_deposit: { name: "All", code: "All" },
        average_liftime_of_links: { name: "More than 70%", code: 70 },
      };
      setFilterOptions(newFilterOptions);
      await handleClear(e, newFilterOptions);

      // Explicitly clear the TextField values
      document.getElementsByName("minimum_price")[0].value = null;
      document.getElementsByName("maximum_price")[0].value = null;
    }
  };

  const handleClear = async (e, newFilterOptions) => {
    setWebLoad(true);
    e.preventDefault();
    setCat("All");
    setLang("All");
    setSearchUrl("");
    try {
      const data = await API.get(
        `/api/searched-by-buyer?user_id=${id}&minimum_price=${15}&maximum_price=${10000}&service_type=${
          newFilterOptions.service_type.code
        }&language=${newFilterOptions.language.code}&category_id=${
          newFilterOptions.category_id.code
        }&disclosure_type=${newFilterOptions.disclosure_type.code}&link_type=${
          newFilterOptions.link_type.code
        }&per_page=${page.code}&sorting=${sortBy.code}&page=${currentPage}`
      );
      setWebsites(data.data.data);
      setCurrentPage(data.data.current_page);
      setTotalPages(data.data.last_page);
      setTotal(data.data.total);
      setWebLoad(false);
      setAllWeb(true);
    } catch (error) {
      setWebLoad(false);
      return error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (filterOptions.minimum_price < 15) {
      setMinimumPriceError("Min. price is 15");
    }
    if (filterOptions.maximum_price < filterOptions.minimum_price) {
      setMaximumPriceError(
        `Min. price is ${
          filterOptions.minimum_price < 15 ? 15 : filterOptions.minimum_price
        }`
      );
    }
    if (filterOptions.maximum_price > 10000) {
      setMaximumPriceError("Max. price is 10000");
    }
    if (
      filterOptions.minimum_price >= 15 &&
      filterOptions.maximum_price >= filterOptions.minimum_price &&
      filterOptions.maximum_price <= 10000
    ) {
      setWebLoad(true);
      try {
        setSearchUrl("");
        setCat("All");
        setLang("All");
        const data = await API.get(
          `/api/searched-by-buyer?user_id=${id}&minimum_price=${filterOptions.minimum_price}&maximum_price=${filterOptions.maximum_price}&service_type=${filterOptions.service_type.code}&language=${filterOptions.language.code}&category_id=${filterOptions.category_id.code}&disclosure_type=${filterOptions.disclosure_type.code}&link_type=${filterOptions.link_type.code}&per_page=${page.code}&sorting=${sortBy.code}&page=${currentPage}`
        );
        setWebsites(data.data.data);
        setCurrentPage(data.data.current_page);
        setTotalPages(data.data.last_page);
        setTotal(data.data.total);
        if (
          filterOptions?.minimum_price === null &&
          filterOptions?.maximum_price === null &&
          filterOptions?.service_type.name === "All" &&
          filterOptions?.service_type.code === "All" &&
          filterOptions?.language.name === "All" &&
          filterOptions?.language.code === "All" &&
          filterOptions?.category_id.name === "All" &&
          filterOptions?.category_id.code === "All" &&
          filterOptions?.disclosure_type.name === "All" &&
          filterOptions?.disclosure_type.code === "All" &&
          filterOptions?.link_type.name === "All" &&
          filterOptions?.link_type.code === "All" &&
          filterOptions?.ahref_organic_traffic.name === "All" &&
          filterOptions?.ahref_organic_traffic.code === "All" &&
          filterOptions?.marked_sponsored_by.name === "All" &&
          filterOptions?.marked_sponsored_by.code === "All" &&
          filterOptions?.publishers_tat.name === "All" &&
          filterOptions?.publishers_tat.code === "All" &&
          filterOptions?.publishers_rating.name === "All" &&
          filterOptions?.publishers_rating.code === "All" &&
          filterOptions?.security_deposit.name === "All" &&
          filterOptions?.security_deposit.code === "All" &&
          filterOptions?.average_liftime_of_links.name === "More than 70%" &&
          filterOptions?.average_liftime_of_links.code === 70
        ) {
          setAllWeb(true);
        } else {
          setAllWeb(false);
        }
        setWebLoad(false);
      } catch (error) {
        setWebLoad(false);
        return error;
      }
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "minimum_price") {
      setMinimumPriceError("");

      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else if (name === "maximum_price") {
      setMaximumPriceError("");
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: parseInt(value),
      }));
      // }
    } else {
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [e.target.name]: e.target.value,
      }));
    }
  };
  const open = Boolean(anchorEl);
  const smallScreenXS = useMediaQuery("(max-width:700px)");

  useEffect(() => {
    setWebLoad(true);
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    const getList = async () => {
      try {
        const data = await API.get(
          `/api/searched-by-buyer?user_id=${id}&website=${searchUrl}&minimum_price=${15}&maximum_price=${10000}&service_type=${
            filterOptions.service_type.code
          }&language=${lang}&category_id=${cat}&disclosure_type=${
            filterOptions.disclosure_type.code
          }&link_type=${filterOptions.link_type.code}&per_page=${
            page.code
          }&sorting=${sortBy.code}&page=${currentPage}`
        );
        const dataCat = await API.get("/api/get-product-category");
        const modifiedArray = dataCat?.data?.data?.productcategory?.map(
          (item) => ({
            code: item.id,
            name: item.title,
          })
        );
        modifiedArray.unshift({ name: "All", code: "All" });
        setCategory(modifiedArray);
        if (data) {
          setWebLoad(false);
          setWebsites(data.data.data);
          setCurrentPage(data.data.current_page);
          setTotalPages(data.data.last_page);
          setTotal(data.data.total);
        }
      } catch (error) {
        setWebLoad(false);
        return error;
      }
    };

    getList();
  }, [
    localStorage.getItem("role"),
    page,
    sortBy,
    currentPage,
    cat,
    searchUrl,
    lang,
  ]);

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  return (
    <div>
      {/* New Section */}
      <div className="d-flex align-items-center text-center page-info">
        <FontAwesomeIcon icon={faStar} size="lg" style={{ color: "#0e810c" }} />{" "}
        <p className="px-2">
          Here you can view only the websites that have successfully
          demonstrated a probability of 70 percent or higher in task performance
          on these platforms.
        </p>
      </div>

      {/* Filter Section */}
      <div className="new-filter-listing-wraper">
        <h3> Search For Websites </h3>
        <form className="row">
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Maximum and minimum price you are ready to pay for task"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> Price Range </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="15"
                name="minimum_price"
                value={filterOptions.minimum_price}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="10000"
                name="maximum_price"
                value={filterOptions.maximum_price}
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
              {minimumPriceError && (
                <div className="error-message">{minimumPriceError}</div>
              )}
              {!minimumPriceError && maximumPriceError && (
                <div className="error-message">{maximumPriceError}</div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="DA, which stands for 'Domain Authority,' is a metric commonly used in search engine optimization (SEO) and digital marketing to assess the overall authority, credibility, and potential ranking strength of a specific domain or website."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> DA RANGE </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="15"
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="10000"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="DR, which stands for 'Domain Rating,' is a metric commonly used in search engine optimization (SEO) and digital marketing to assess the authority and backlink profile of a specific domain or website."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> DR RANGE </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="15"
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="10000"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Spam Score is a metric used in the field of digital marketing and search engine optimization (SEO) to assess the likelihood that a particular website or web page contains spammy or low-quality content."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> SPAM SCORE </label>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="15"
              />
              <span>-</span>
              <input
                type="number"
                className="form-control mulit-fields-form"
                placeholder="10000"
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Maximum and minimum price you are ready to pay for task"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> MONTHLY TRAFFIC </label>
              <input type="text" className="form-control" placeholder="From" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Ahref Organic Traffic, often referred to simply as 'organic traffic,' is a key metric in website analytics that measures the volume of visitors a website receives from search engines like Google, Bing, or Yahoo."
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> Ahref Organic TRAFFIC </label>
              <input type="text" className="form-control" placeholder="From" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Choose the most relevant categories for the website you want to promote"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> Category </label>
              <Dropdown
                name="category_id"
                value={filterOptions?.category_id}
                onChange={async (e) => {
                  e.preventDefault();
                  handleFilterChange(e);
                }}
                options={category}
                optionLabel="name"
                placeholder="Select a category"
                filter
                className="w-100 filter-custom-dropdown"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              {" "}
              <a href="#">
                <Tooltip
                  title="Choose between Dofollow links (the ones that can be found by search engines) and Nofollow (the ones that are protected from search engine crawlers)"
                  placement="top-start"
                  className="mui-tooltip"
                >
                  <IconButton size="small" className="info-icon">
                    {" "}
                    <img src={Icon} alt="image" />{" "}
                  </IconButton>
                </Tooltip>
              </a>
              <label> LINKS </label>
              <Dropdown
                name="link_type"
                value={filterOptions?.link_type}
                onChange={async (e) => {
                  e.preventDefault();
                  handleFilterChange(e);
                }}
                options={link}
                optionLabel="name"
                placeholder="Select a link"
                className="w-100 filter-custom-dropdown"
              />
            </div>
          </div>
          {showAdditionalFilters && (
            <>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="hose whether you want to choose a publisher who will post your content or will create then post your content"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Service Type </label>
                  <Dropdown
                    name="service_type"
                    value={filterOptions?.service_type}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={services}
                    optionLabel="name"
                    placeholder="Select a service"
                    filter
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="Language of a website"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Language </label>
                  <Dropdown
                    name="language"
                    value={filterOptions?.language}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={languages}
                    optionLabel="name"
                    placeholder="Select a language"
                    filter
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The designation 'Sponsored by' is an indication that a specific backlink on a website has been paid for by an advertiser or sponsor."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Marked "Sponsored by" </label>
                  <Dropdown
                    name="disclosure_type"
                    value={filterOptions?.disclosure_type}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={disclosure}
                    optionLabel="name"
                    placeholder="Select a disclosure"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The 'Average Lifetime of Links' is a crucial metric in the world of website backlinks. It represents the typical duration for which backlinks, placed on a publisher's website, remain active and functional."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Average lifetime of links </label>
                  <Dropdown
                    name="average_liftime_of_links"
                    value={filterOptions?.average_liftime_of_links}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={averageLinkLifetime}
                    optionLabel="name"
                    placeholder="Select a lifetime"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="It provides an evaluation of a publisher's overall performance and reputation"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Publisher's Rating </label>
                  <Dropdown
                    name="publishers_rating"
                    value={filterOptions?.publishers_rating}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={publisherRating}
                    optionLabel="name"
                    placeholder="Select a rating"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The 'Publisher's TAT' (Turnaround Time) is a crucial factor in the realm of website backlinks. It refers to the time taken by publishers or website owners to complete specific tasks related to backlinks."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Publisher's TAT </label>
                  <Dropdown
                    name="publishers_tat"
                    value={filterOptions?.publishers_tat}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={publisherTAT}
                    optionLabel="name"
                    placeholder="Select a disclosure"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="The 'Publishers completion rate' is a metric that reflects the reliability and performance of publishers or website owners in completing backlink-related tasks."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Publisher's completion rate </label>
                  <Dropdown
                    name="average_liftime_of_links"
                    value={filterOptions?.average_liftime_of_links}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={averageLinkLifetime}
                    optionLabel="name"
                    placeholder="Select a lifetime"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="This refers to a task associated with an initial website domain and a specified price."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Task with initial domain and price </label>
                  <Dropdown
                    name="average_liftime_of_links"
                    value={filterOptions?.average_liftime_of_links}
                    onChange={async (e) => {
                      e.preventDefault();
                      handleFilterChange(e);
                    }}
                    options={averageLinkLifetime}
                    optionLabel="name"
                    placeholder="Select a lifetime"
                    className="w-100 filter-custom-dropdown"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  {" "}
                  <a href="#">
                    <Tooltip
                      title="Date when site appeared on webylinks"
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        {" "}
                        <img src={Icon} alt="image" />{" "}
                      </IconButton>
                    </Tooltip>
                  </a>
                  <label> Added to webylinks </label>
                  <Calendar
                    className="w-100 calender-filter"
                    value={dates}
                    onChange={(e) => setDates(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    minDate={new Date()}
                  />
                </div>
              </div>
            </>
          )}
        </form>
        {showAdditionalFilters && (
          <div className="d-flex justify-content-end search-filter-btn">
            <button className="btn-info-count" onClick={clear}>
              Clear
            </button>
            <button className="apply-filters-button" onClick={handleSubmit}>
              Apply Filters
            </button>
          </div>
        )}
        {!showAdditionalFilters && (
          <div className="d-flex justify-content-center load-more-filters">
            <button onClick={toggleAdditionalFilters}>
              {" "}
              LOAD MORE ADVANCE FILTERS <img src={Plus} alt="plus" />{" "}
            </button>
          </div>
        )}
      </div>
      {/* </AccordionDetails>
      </Accordion> */}
      <div
        className={`buyer-dashboard-filter-section search-results-wraper ${
          props.isDarkMode ? "filter-dark" : "filter-light"
        }`}
      >
        <div
          className={`d-flex search-results-head ${
            smallScreenXS
              ? "flex-column justify-content-start"
              : "justify-content-between"
          } filter-header`}
        >
          {allWeb && cat === "All" && lang === "All" && searchUrl === "" ? (
            <h2>
              {`All Websites`}
              <span>({websites?.length})</span>
            </h2>
          ) : (
            <h2>
              Found: <span>{websites?.length > 0 ? websites?.length : 0} </span>
              {`Website${websites?.length < 2 ? "" : "s"}`}
            </h2>
          )}
          <div className="d-flex sort-container">
            <div className="d-flex mx-2 justify-content-center align-items-center sorting-menu">
              <label className="mx-2">Show</label>{" "}
              <Dropdown
                name="page"
                value={page}
                onChange={async (e) => {
                  e.preventDefault();
                  setCurrentPage(1);
                  setPage({ name: e.value.name, code: e.value.code });
                }}
                options={pages}
                optionLabel="name"
                placeholder="Select page number"
                className="role-select-options-page"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center sorting-menu">
              <label className="mx-2">Sort by</label>{" "}
              <Dropdown
                name="sortBy"
                value={sortBy}
                onChange={async (e) => {
                  e.preventDefault();
                  setSortBy({ name: e.value.name, code: e.value.code });
                }}
                options={sort}
                optionLabel="name"
                placeholder="Select sort type"
                className="role-select-options-page"
              />
            </div>
          </div>
        </div>
        <div className="website-listed-main-wrap">
          <div className="table-responsive">
            <table width="100%" border="0">
              <tr height="50" className="fields-table-wrap">
                <td width={isMobileL ? "6%" : "12%"}>
                  <div className="form-main-table">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL"
                      value={searchUrl}
                      onChange={(e) => setSearchUrl(e.target.value)}
                    />
                    <button>
                      <img src={Search} alt="search" />
                    </button>
                  </div>
                </td>
                <td width={isMobileM ? "6%" : "8%"}>
                  <div className="form-main-table">
                    <select
                      className="form-control"
                      value={cat}
                      onChange={async (e) => {
                        await setCat(e.target.value);
                      }}
                    >
                      <option value="All">Categories</option>
                      {category?.map((res, id) => (
                        <option key={id} value={res.code}>
                          {res.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
                <td width="6%">
                  <div className="form-main-table">
                    <select className="form-control">
                      <option>DA</option>
                      <option>Low to High</option>
                      <option>Hight to Low</option>
                    </select>
                  </div>
                </td>
                <td width="6%">
                  <div className="form-main-table">
                    <select className="form-control">
                      <option>DR</option>
                      <option>Low to High</option>
                      <option>Hight to Low</option>
                    </select>
                  </div>
                </td>
                <td width="6%">
                  <div className="form-main-table">
                    <select
                      className="form-control"
                      value={lang}
                      onChange={(e) => setLang(e.target.value)}
                    >
                      <option value="All">Language</option>
                      {languages?.map((res, id) => (
                        <option key={id} value={res.code}>
                          {res.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
                <td width={isMobileL ? "14%" : isMobileM ? "16%" : "8%"}>
                  <div className="form-main-table">
                    <select className="form-control">
                      <option>Traffic</option>
                      <option>Low to High</option>
                      <option>Hight to Low</option>
                    </select>
                  </div>
                </td>
              </tr>
              {!webload &&
                websites !== null &&
                Array.isArray(websites) &&
                websites?.length > 0 &&
                websites?.map((res, id) => (
                  <tr
                    key={id}
                    className={`${id % 2 === 0 ? "" : "light-grey"}`}
                  >
                    <td>
                      {balance?.is_paid === 0 && id > 4 ? (
                        <h6 className="web-list-url" onClick={openModal}>
                          URL is hidden
                        </h6>
                      ) : (
                        <h6
                          className="web-list-url"
                          onClick={() => window.open(res?.url)}
                        >
                          {truncateText(res?.url, isMobileX ? 20 : 20)}
                        </h6>
                      )}
                      <span>
                        {" "}
                        <img src={Limg} alt="link" />
                        Max 03{" "}
                        <strong>
                          {res?.link_type === 1
                            ? "NoFollow links"
                            : "DoFollow links"}
                        </strong>{" "}
                      </span>{" "}
                      <span>
                        {" "}
                        <img src={Gear} alt="gear" />
                        Turnaround Time: <strong>1 day</strong>{" "}
                      </span>{" "}
                      <span>
                        {" "}
                        <img src={Clock} alt="clock" />
                        Spam Score <strong>1%</strong>
                      </span>
                    </td>
                    <td>
                      <div className="category-list-wrap">
                        {res?.categories?.map((res, id) => (
                          <span className="mx-1" key={id}>
                            {res?.title}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td>
                      <div className="dr-tex-wrap">
                        {" "}
                        <span>
                          {" "}
                          DA <strong>54</strong>
                        </span>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="dr-tex-wrap">
                        {" "}
                        <span>
                          {" "}
                          DR <strong>49</strong>
                        </span>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="language-wrap">
                        <CountryFlag
                          countryCode={
                            languages.find(
                              (lang) => lang.code === res?.language
                            )?.countryCode
                          } // Use the language code from your data
                          style={{ width: "25px" }}
                          svg
                        />
                        <span> {res?.language}</span>
                      </div>
                    </td>
                    <td>
                      <div className="btn-mounth-track">
                        <h5>
                          {" "}
                          Monthly Traffic{" "}
                          <span>
                            {" "}
                            <img src={Graph} alt="graph" /> 18,924{" "}
                          </span>{" "}
                        </h5>
                        <div className="button-price-wrap">
                          {" "}
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                user?.is_verified === 0 &&
                                user?.login_3rdparty_type === null
                              ) {
                                Swal.fire({
                                  icon: "info",
                                  text: "Please verify your account to perform this task",
                                  allowOutsideClick: false,
                                });
                              } else {
                                localStorage.setItem(
                                  "selectedSubRoute",
                                  `/dashboard/buy-form/${res?.id}`
                                );
                                navigate(`/dashboard/buy-form/${res?.id}`);
                              }
                            }}
                          >
                            {" "}
                            Buy Post{" "}
                          </a>{" "}
                          <strong>
                            {" "}
                            $
                            {res?.article_price > 0
                              ? res?.article_price
                              : res?.content_placement_price > 0
                              ? res?.content_placement_price
                              : res?.long_article > 0
                              ? res?.long_article
                              : res?.mini_post_price > 0
                              ? res?.mini_post_price
                              : 0}{" "}
                          </strong>{" "}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </table>
            {webload && (
              <div className="w=100 d-flex justify-content-center align-items-center">
                <CircularProgress
                  style={{
                    width: "40px",
                    height: "40px",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                />
              </div>
            )}
            {!webload && (websites === null || websites?.length === 0) && (
              <NoData listing={true} />
            )}
            <div>
              {total > page.code && (
                <Paginator
                  first={currentPage * rows - rows}
                  rows={rows}
                  totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Popover for displaying description */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseDescription}
        style={{ pointerEvents: "none", zIndex: "999 !important" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <DialogContent className="dialog-pop">
          {currentDescription}
        </DialogContent>
      </Popover>
    </div>
  );
};

export default ProfileWebList;
