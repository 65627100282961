import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import API from "../../../api/ClientApi";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import InfoIcon from "../../../assets/images/infoIcon.png";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress } from "@mui/material";

const AddMultiple = (props) => {
  const navigate = useNavigate();
  const [urlsArray, setUrlsArray] = useState([]);
  const [textVal, setTextVal] = useState("");
  const [load, setLoad] = useState(false);
  const [error, setError] = useState("");

  // Function to process the URLs and store them in the array
  const processUrls = () => {
    const textarea = document.getElementById("urlTextArea");
    const inputUrls = textarea.value.split(/,|\n/);

    // Trim, clean, and filter the URLs
    const validUrls = inputUrls
      .map((url) => url.trim())
      .filter((url) => url !== "");

    // Check if each URL is valid
    const isAllUrlsValid = validUrls.every((url) => isValidUrl(url));

    if (!isAllUrlsValid) {
      setError("Entered strings must be valid URLs");
      return false; // Return early if there are invalid URLs
    }

    // Update the state array with the valid URLs
    setUrlsArray(validUrls);
    return validUrls;
  };

  const isValidUrl = (url) => {
    // Regular expression to match a URL pattern with "https://"
    const urlPattern =
      /^(https:\/\/([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?)$/;

    return urlPattern.test(url);
  };

  // Xlsx drop zones
  const [uploadedFile, setUploadedFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setUploadedFile(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: [".csv", ".xls", ".xlsx"],
  });

  // Handle submit
  const handleSubmit = async () => {
    if (processUrls() === false) {
      setError("Please enter valid urls");
    } else {
      if (processUrls().length === 0 && !uploadedFile) {
        setError(
          "Please either upload excel file or add urls in text area in form"
        );
      } else {
        try {
          setLoad(true);

          const config = {
            headers: {
              "Content-type": "multipart/form-data", // Since we now submit images as well
            },
          };

          const modifiedData = {
            url: JSON.stringify(processUrls()),
            file: uploadedFile,
          };
          // Make the POST request using Axios
          const response = await API.post(
            `${process.env.REACT_APP_BACKEND_API}/api/add-websitelist-by-publisher`,
            modifiedData,
            config
          );

          // Handle the response if needed
          Swal.fire({
            title: "Success!",
            html: "Url lists added successfully",
            icon: "success",
            timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
            showConfirmButton: false, // Hide the "OK" button
            allowOutsideClick: false, // Prevent closing by clicking outside
          });
          localStorage.setItem("selectedSubRoute", "/dashboard/platform");
          navigate("/dashboard/platform");
          setLoad(false);
          // props.list();
        } catch (error) {
          // Handle errors
          setLoad(false);
          Swal.fire({
            title: "Error!",
            html: error?.response?.data?.message,
            icon: "error",
            timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
            showConfirmButton: false, // Hide the "OK" button
            allowOutsideClick: false, // Prevent closing by clicking outside
          });
        } finally {
          setLoad(false);
        }
      }
    }
  };

  const handleDownload = async() => {
    const data = await API.get("/api/get-template-download-url");
    // URL of the ZIP file
    const zipFileUrl =
      data?.data?.template_url;

    // Create an anchor element for the download
    const downloadLink = document.createElement("a");
    downloadLink.href = zipFileUrl;
    downloadLink.download = "List of sites template.zip";

    // Trigger the click event to initiate the download
    downloadLink.click();
  };

  return (
    <div className="billing-info-main">
      <div className="list-of-website-wrap">
        <div className="list-website-info list-website-info-one">
          <span>
            {" "}
            <img src={InfoIcon} alt="" />{" "}
          </span>
          <strong>
            {" "}
            You can add any number of sites. Note that we will send only 100 of
            them for moderation. Once those sites are checked and approved, they
            will be available to buyers. When we ensure that you complete the
            buyers’ tasks and can make publications on those sites, we will
            check more sites from your list and show them to buyers.{" "}
          </strong>
        </div>

        <div className="list-website-sections">
          <div className="row">
            <div className="col-md-6">
              <div className="left-list-wrap">
                <label>
                  {" "}
                  Copy and paste the URLs of the websites, and you can separate
                  them either with commas or by placing each URL on a new line.{" "}
                </label>

                <div className="list-text-area">
                  <textarea
                    id="urlTextArea"
                    className="form-control"
                    rows="5"
                    value={textVal}
                    onChange={(e) => {
                      e.preventDefault();
                      setError("");
                      setTextVal(e.target.value);
                    }}
                    disabled={uploadedFile !== null}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="right-list-wrap">
                <label> or upload them via file </label>

                <div className="list-website-info">
                  <span>
                    {" "}
                    <img src={InfoIcon} alt="" />{" "}
                  </span>
                  <strong>
                    {" "}
                    If uploading via file, set the prices right away to save
                    your time in the future. You can also use this option to
                    update prices for previously added sites.
                  </strong>
                </div>

                <div className="list-file-upload">
                  {textVal ? (
                    <div className="upload-file-xls">
                      <p>
                        Url is being added to text area, you cannot add files
                        while urls are being added.
                      </p>
                    </div>
                  ) : uploadedFile ? (
                    <div className="upload-file-xls">
                      <p>{uploadedFile.name}</p>
                      <CloseIcon
                        className="cls-icon"
                        onClick={() => setUploadedFile(null)}
                      />
                    </div>
                  ) : (
                    <div {...getRootProps()} className="add-upload-file">
                      <input disabled {...getInputProps()} />
                      <p>
                        Drag and drop CSV, XLS, or XLSX files here, or click to
                        select files.
                      </p>
                    </div>
                  )}

                  <p className="hint-file-wrap">
                    {" "}
                    Restrictions: no larger than 2MB, only сsv, xls, xlsx{" "}
                  </p>
                </div>

                <div className="d-flex align-items-baseline temlplate-upload-btn">
                  <button className="temlplate-btn" onClick={handleDownload}>
                    Template{" "}
                  </button>
                  <span> csv, xlsx </span>
                </div>

                <div className="right-files-info-detail">
                  <h4>
                    {" "}
                    In your file, set a price for at least 1 service type among
                    4 available ones for each site:{" "}
                  </h4>

                  <ul>
                    <li>
                      <h5> Content placement </h5>
                      <p>
                        {" "}
                        By choosing this service type you agree to place buyer’s
                        content on your website permanently.{" "}
                      </p>
                    </li>

                    <li>
                      <h5> Content creation and placement </h5>
                      <p>
                        {" "}
                        By choosing this service type you agree to write an
                        article according to buyer’s requirements and place it
                        on your website permanently.{" "}
                      </p>

                      <ul className="inner-list-right">
                        <li>
                          {" "}
                          <strong>Mini-post</strong> up to 300 words, 1
                          hyperlink allowed{" "}
                        </li>
                        <li>
                          {" "}
                          <strong>Article</strong> 300-1000 words, 2 hyperlinks
                          allowed{" "}
                        </li>
                        <li>
                          {" "}
                          <strong>Long</strong> article more than 1000 words, 3
                          hyperlinks allowed{" "}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {error && (
          <div className="w-100 d-flex justify-content-end">
            <p style={{ color: "red" }}>{error}</p>
          </div>
        )}
        <div className="w-100 d-flex justify-content-end list-of-websites-btn-wrap">
          <button
            className="submit-list-of-websites-btn"
            onClick={handleSubmit}
          >
            {load ? <CircularProgress /> : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMultiple;
