import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, IconButton, CircularProgress } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleIcon from "../../../assets/images/google-icon.png";
import FacebookIcon from "../../../assets/images/faceboo-icon.png";
import logo from "../../../assets/images/logoMain.png";
import "../styles/signupMain.css";
import {
  clearErrors,
  register,
  registerThirdParty,
  selectRole,
  userProfile,
} from "../../../actions/userActions";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { userBalance } from "../../../actions/walletActions";
import {
  userCheckNotifications,
  userUnreadCountNotifications,
} from "../../../actions/notificationActions";
import { userModeration } from "../../../actions/moderationActions";

const SignupMain = () => {
  const captchaRef = useRef(null);
  const navigate = useNavigate();
  const [cerror, setCError] = useState("");
  const facebookAppID = process.env.REACT_APP_FACEBOOK_APP_ID;
  const dispatch = useDispatch();
  // Google auth related-------------------------------------------------------------
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState({ email: "" });

  const { email } = profile;

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      if (codeResponse) {
        axios
          .get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${codeResponse.access_token}`,
                Accept: "application/json",
              },
            }
          )
          .then(async (res) => {
            setProfile({
              access_token: codeResponse.access_token,
              email: res.data.email,
              type: "gmail",
            });
            dispatch(
              registerThirdParty({
                email: res.data.email,
                login_token: codeResponse.access_token,
                login_type: "google",
              })
            );
          })
          .catch((err) => {
            return err;
          });
      }
    },
    onError: (error) =>
      Swal.fire({
        title: "Error!",
        html: error,
        icon: "error",
      }),
  });

  //----- Facebook Login----------------------------------------------
  const responseFacebook = (response) => {
    // Login failed
    if (response.status === "unknown") {
      Swal.fire({
        title: "Error!",
        html: "Signup failed",
        icon: "error",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
      return false;
    }
    setUser(response);
    if (response.accessToken) {
      const phoneNumber = response.phone.number;
      dispatch(
        registerThirdParty({
          email: "",
          login_token: response.access_token,
          login_type: "facebook",
        })
      );
    }
  };
  //---------------------------------------------------------

  const { loading, error } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(clearErrors());
    if (localStorage.getItem("access_token")) {
      navigate("/dashboard");
    }
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          setProfile({
            access_token: user.access_token,
            email: res.data.email,
            type: "gmail",
          });
          dispatch(
            registerThirdParty({
              email: res.data.email,
              login_token: user.access_token,
              login_type: "google",
            })
          );
          const config = {
            headers: {
              "Content-type": "multipart/form-data",
            },
          };
          await axios.post(
            "/api/google-linked-accounts",
            {
              login_token: user.access_token,
              login_type: "google",
              is_google_connected: 1,
            },
            config
          );
        })
        .catch((err) => {
          return err;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, localStorage.getItem("access_token")]);

  //------------------------------------------------------------------

  const handleChange = (e) => {
    dispatch(clearErrors());
    setProfile({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCError("");
    const token = captchaRef.current.getValue();
    if (token) {
      try {
        const registerUser = await dispatch(register({ email: email }));
        if (registerUser.error) {
          // Handle the login error, e.g., display an error message
          return;
        }
        captchaRef.current.reset();
        await dispatch(selectRole({ select_role: "buyer" }));
        await dispatch(userProfile("buyer"));
        await dispatch(userBalance("buyer"));
        await dispatch(userUnreadCountNotifications());
        await dispatch(userCheckNotifications());
        await dispatch(userModeration());
      } catch (error) {
      }
    } else {
      setCError("Please verify that you are not a robot");
    }
  };

  const renderFacebookButton = (renderProps) => {
    return (
      <IconButton
        onClick={renderProps.onClick}
        disabled={renderProps.isDisabled}
        className="social-btn signup-facebook-btn"
      >
        <img src={FacebookIcon} alt="Facebook" />
        Login with Facebook
      </IconButton>
    );
  };

  return (
    <div className="signup-main">
      <div className="signup-container">
        <div className="login-logo">
          <img src={logo} alt="weblinks-logo" />
        </div>

        <h2>SIGN UP</h2>
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-column form-main-sec"
        >
          <TextField
            type="email"
            className="w-100"
            placeholder="Email Address"
            variant="outlined"
            name="email"
            required
            value={email}
            onChange={handleChange}
            InputProps={{
              classes: {
                root: "custom-input",
                input: "custom-input",
              },
            }}
          />
          <div className="w-100 invalid-email-txt">
            {error && (
              <p>
                {error?.response?.data?.error || error?.response?.data?.message}
              </p>
            )}
          </div>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center contact-captcha-section">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_SITE}
              ref={captchaRef}
            />
            {cerror && <p>{cerror}</p>}
          </div>
          <button type="submit" color="primary" className="w-100 login-new-btn">
            {loading ? (
              <CircularProgress className="text-white auth-circle-prog" />
            ) : (
              "CREATE AN ACCOUNT"
            )}
          </button>
        </form>
        <p className="signup-terms">
          By signing up, you agree to our <br />
          <span>Terms and Conditions</span> and <span>Privacy Policy</span>
        </p>
        <h3>Or Signup with Google</h3>
        <div className="w-100 signup-btn-container d-flex justify-content-center social-container">
          <IconButton
            className="social-btn signup-google-btn"
            onClick={() => login()}
            startIcon={<GoogleIcon />}
          >
            <img src={GoogleIcon} alt="Google" />
            Signup with Google
          </IconButton>
          {/* <FacebookLogin
            className="fc-btn"
            appId={facebookAppID}
            autoLoad={false}
            // fields="name,email,picture"
            scope="public_profile"
            callback={responseFacebook}
            render={renderFacebookButton}
          /> */}
        </div>
        <h4>
          Already have an account? <Link to="/login">Login</Link>
        </h4>
      </div>
    </div>
  );
};

export default SignupMain;
