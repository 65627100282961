import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import API from "../../../api/ClientApi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../styles/myPaltform.css";
import Search from "../../../assets/DashboardIcons/search.svg";
import NoData from "../../../components/NoData/NoData";
import { Paginator } from "primereact/paginator";
import PlatformCard from "./PlatformCard";

const MyPlatforms = () => {
  const navigate = useNavigate();
  const { moderation_check } = useSelector((state) => state.is_moderation);
  const [tabs, setTabs] = useState([]);
  const [ids, setId] = useState(0);
  const [websites, setWebsites] = useState(null);
  const [searchUrl, setSearchUrl] = useState("");
  const [webId, setWebId] = useState(null);
  const [category, setCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(10);
  const [webload, setWebLoad] = useState(true);
  const [changed, setChanged] = useState(false);
  const [typeTab, setTypeTab] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const isMobileX = useMediaQuery("(max-width: 400px)");
  const isMobileL = useMediaQuery("(max-width: 1550px)");
  const isMobileM = useMediaQuery("(max-width: 1230px)");
  const [lang, setLang] = useState("All");
  const [cat, setCat] = useState("All");
  const [drr, setDr] = useState({ da: null, dr: null, traffic: null });

  const getListAfterDelete = async () => {
    setWebLoad(true);
    try {
      const data = await API.get(
        `/api/publisher?status_id=${typeTab}&page=${currentPage}&user_type=2`
      );
      if (data) {
        setWebLoad(false);
        setWebsites(data.data.products.data);
        setCurrentPage(data?.data?.products?.current_page);
        setTotalPages(data?.data?.products?.last_page);
        setTotal(data?.data?.products?.total);
      }
    } catch (error) {
      setWebLoad(false);
      return error;
    }
  };

  const handleWebTabChange = (e, tabIndex) => {
    setTypeTab(tabIndex);
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  const languages = [
    { name: "All", code: "All", countryCode: "" }, // You can assign an empty string for "All"
    { name: "English", code: "English", countryCode: "US" },
    { name: "Urdu", code: "Urdu", countryCode: "PK" },
    { name: "Chinese", code: "Chinese", countryCode: "CN" },
    { name: "French", code: "French", countryCode: "FR" },
    { name: "German", code: "German", countryCode: "DE" },
    { name: "Hindi", code: "Hindi", countryCode: "IN" },
    { name: "Spanish", code: "Spanish", countryCode: "ES" },
    { name: "Arabic", code: "Arabic", countryCode: "SA" },
    { name: "Bulgarian", code: "Bulgarian", countryCode: "BG" },
    { name: "Dutch", code: "Dutch", countryCode: "NL" },
    { name: "Greek", code: "Greek", countryCode: "GR" },
    { name: "Hrvatski", code: "Hrvatski", countryCode: "HR" },
    { name: "Indonesian", code: "Indonesian", countryCode: "ID" },
    { name: "Italian", code: "Italian", countryCode: "IT" },
    { name: "Japanese", code: "Japanese", countryCode: "JP" },
    { name: "Korean", code: "Korean", countryCode: "KR" },
    { name: "Norwegian", code: "Norwegian", countryCode: "NO" },
    { name: "Other", code: "Other", countryCode: "" }, // You can assign an empty string for "Other"
    { name: "Polish", code: "Polish", countryCode: "PL" },
    { name: "Portuguese", code: "Portuguese", countryCode: "PT" },
    { name: "Romanian", code: "Romanian", countryCode: "RO" },
    { name: "Russian", code: "Russian", countryCode: "RU" },
    { name: "Spanish", code: "Spanish", countryCode: "ES" },
    { name: "Swedish", code: "Swedish", countryCode: "SE" },
    { name: "Turkish", code: "Turkish", countryCode: "TR" },
    { name: "Ukranian", code: "Ukranian", countryCode: "UA" },
  ];
  // Handling DR filters
  const handleDr = async (da, dr, traffic) => {
    setDr({ ...drr, da: da, dr: dr, traffic: traffic });
  };

  const getListSearch = async (url) => {
    try {
      const data = await API.get(
        `/api/publisher?status_id=${parseInt(
          typeTab
        )}&url=${url}&page=${currentPage}&user_type=2`
      );
      if (data) {
        setWebLoad(false);
        setWebsites(data.data.products.data);
        setCurrentPage(data?.data?.products?.current_page);
        setTotalPages(data?.data?.products?.last_page);
        setTotal(data?.data?.products?.total);
      }
    } catch (error) {
      setWebLoad(false);
      return error;
    }
  };

  const getTabs = async () => {
    try {
      const data = await API.get("/api/get-status-by-publisher?user_type=2");
      if (data) {
        setTabs(data?.data?.status_data);
        setAllCount(data?.data?.total_product_count);
      }
    } catch (error) {
      setWebLoad(false);
      return error;
    }
  };

  useEffect(() => {
    // if(location){
    //   setActive(4)
    // }
    setWebLoad(true);
    const getListTabs = async () => {
      try {
        const data = await API.get("/api/get-status-by-publisher?user_type=2");
        if (data) {
          setTabs(data?.data?.status_data);
          setAllCount(data?.data?.total_product_count);
        }
      } catch (error) {
        setWebLoad(false);
        return error;
      }
    };
    const getList = async () => {
      try {
        const data = await API.get(
          `/api/publisher?status_id=${parseInt(
            typeTab
          )}&url=${searchUrl}&language=${lang}&page=${currentPage}&category_id=${cat}&da=${
            drr?.da
          }&dr=${drr?.dr}&traffic=${drr?.traffic}&user_type=2`
        );
        if (data) {
          setWebsites(data.data.products.data);
          setCurrentPage(data?.data?.products?.current_page);
          setTotalPages(data?.data?.products?.last_page);
          setTotal(data?.data?.products?.total);
        }
      } catch (error) {
        setWebLoad(false);
        return error;
      }
    };
    const getCategory = async () => {
      try {
        const data = await API.get("/api/get-product-category");
        if (data) {
          const modifiedArray = data?.data?.data?.productcategory?.map(
            (item) => ({
              code: item.id,
              name: item.title,
            })
          );
          modifiedArray.unshift({ name: "All", code: "All" });
          setCategory(modifiedArray);
          setWebLoad(false);
        }
      } catch (error) {
        setWebLoad(false);
        return error;
      }
    };
    getListTabs();
    getList();
    getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed, typeTab, currentPage, searchUrl, cat, lang, drr]);

  const truncateText = (text, maxLength) => {
    return text?.length <= maxLength ? text : text?.slice(0, maxLength) + "...";
  };

  return (
    <div className="w-100 account-settings-main">
      <div className="w-100 as-header">
        <h2>Add Website(s)</h2>
      </div>
      <div className="d-flex align-items-center justify-content-between text-center website-add-banner">
        <p>
          Want to monetize your site while placing or creating unique and
          relevant content?
        </p>
        <div className="d-flex justify-content-end add-form-header-wrapper">
          <Button
            onClick={() => {
              navigate("/dashboard/addWebsite");
            }}
            className="website-add-banner-btn"
          >
            Add Website
          </Button>
        </div>
      </div>

      <div className="w-100 terms-conditions-wraper">
        <div className="add-website-wraper">
          <div className="w-100">
            <Tabs
              className="tabs-add-web"
              variant="scrollable"
              value={typeTab}
              onChange={handleWebTabChange}
            >
              <Tab
                className={`${
                  typeTab === 0 ? "tab-active" : "account-list-tabs"
                }`}
                icon={
                  <div className="d-flex">
                    <span>All</span>
                    <div className="count-circle">{allCount}</div>
                  </div>
                }
                value={0}
              >
                {" "}
              </Tab>
              {tabs &&
                tabs?.map((res, id) => (
                  <Tab
                    className={`${
                      typeTab === res?.id ? "tab-active" : "account-list-tabs"
                    }`}
                    icon={
                      <div className="d-flex">
                        <span>{res?.status_name}</span>
                        <div className="count-circle">
                          {res?.wl_products?.length > 0
                            ? res?.wl_products[0]?.product_count
                            : 0}
                        </div>
                      </div>
                    }
                    value={res?.id}
                    key={id}
                  ></Tab>
                ))}
            </Tabs>
          </div>
          <Box
            className={`publisher-weblist-box ${
              !webload && websites?.length <= 0
                ? "publisher-weblist-box-margin"
                : ""
            }`}
          >
            <div className="website-listed-main-wrap">
              <div className="table-responsive">
                <table width="100%" border="0">
                  <tr height="50" className="fields-table-wrap">
                    <td width={isMobileL ? "6%" : "12%"}>
                      <div className="form-main-table">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="URL"
                          value={searchUrl}
                          onChange={(e) => setSearchUrl(e.target.value)}
                        />
                        <button>
                          <img src={Search} alt="search" />
                        </button>
                      </div>
                    </td>
                    <td width={isMobileM ? "6%" : "8%"}>
                      <div className="form-main-table">
                        <select
                          className="form-control"
                          value={cat}
                          onChange={async (e) => {
                            await setCat(e.target.value);
                          }}
                        >
                          <option value="All">Categories</option>
                          {category?.slice(1).map((res, id) => (
                            <option key={id} value={res.code}>
                              {res.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                    <td width="6%">
                      <div className="form-main-table">
                        <select
                          className="form-control"
                          value={drr?.da}
                          onChange={(e) => handleDr(e.target.value, null, null)}
                        >
                          <option value={null}>DA</option>
                          <option value="asc">Low to High</option>
                          <option value="dsc">Hight to Low</option>
                        </select>
                      </div>
                    </td>
                    <td width="6%">
                      <div className="form-main-table">
                        <select
                          className="form-control"
                          value={drr?.dr}
                          onChange={(e) => handleDr(null, e.target.value, null)}
                        >
                          <option value={null}>DR</option>
                          <option value="asc">Low to High</option>
                          <option value="dsc">Hight to Low</option>
                        </select>
                      </div>
                    </td>
                    <td width="6%">
                      <div className="form-main-table">
                        <select
                          className="form-control"
                          value={lang}
                          onChange={(e) => setLang(e.target.value)}
                        >
                          <option value="All">Language</option>
                          {languages?.map((res, id) => (
                            <option key={id} value={res.code}>
                              {res.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                    <td width={isMobileL ? "14%" : isMobileM ? "16%" : "8%"}>
                      <div className="form-main-table">
                        <select
                          className="form-control"
                          value={drr?.traffic}
                          onChange={(e) => handleDr(null, null, e.target.value)}
                        >
                          <option value={null}>Traffic</option>
                          <option value="asc">Low to High</option>
                          <option value="dsc">Hight to Low</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  {!webload &&
                    websites !== null &&
                    Array.isArray(websites) &&
                    websites?.length > 0 &&
                    websites?.map((res, id) => (
                      <PlatformCard
                        moderated={moderation_check?.is_moderated}
                        key={id}
                        id={id}
                        data={res}
                        getList={getListAfterDelete}
                        getTabs={getTabs}
                      />
                    ))}
                </table>
                {webload && (
                  <div className="w=100 d-flex justify-content-center align-items-center">
                    <CircularProgress
                      style={{
                        width: "40px",
                        height: "40px",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                )}
                {!webload && (websites === null || websites?.length === 0) && (
                  <NoData listing={true} />
                )}
                <div>
                  {total > 10 && (
                    <Paginator
                      first={currentPage * rows - rows}
                      rows={rows}
                      totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
                      onPageChange={onPageChange}
                      truncateText={truncateText}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* {webload ? (
              <div className="d-flex justify-content-center align-items-center circular-my-website">
                <CircularProgress />
              </div>
            ) : websites !== null ? (
              Array.isArray(websites) && websites.length > 0 ? (
                websites.map((res, id) => (
                  
                  <PublisherInfoCard
                    key={id}
                    moderated={moderation_check?.is_moderated}
                    data={res}
                    isDarkMode={false}
                    isPublisher={true}
                    getList={getListAfterDelete}
                    getTabs={getTabs}
                  />
                ))
              ) : (
                <NoData className="no-data-platform" />
              )
            ) : (
              <NoData className="no-data-platform" />
            )}
            <div>
              {total > 10 && (
                <Paginator
                  first={currentPage * rows - rows}
                  rows={rows}
                  totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
                  onPageChange={onPageChange}
                />
              )}
            </div> */}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default MyPlatforms;
