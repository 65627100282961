import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Info from "../../assets/images/info-icon.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./styles/taskView.css";
import API from "../../api/ClientApi";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import InfoIcon from "../../assets/images/infoIcon.png";
import { BreadCrumb } from "primereact/breadcrumb";
import Swal from "sweetalert2";
import { userBalance } from "../../actions/walletActions";
import TaskRejectModal from "./components/TaskRejectModal";
import TaskAcknowledgeModal from "./components/TaskAcknowledgeModal";
import TaskImprovementModal from "./components/TaskImprovementModal";
import TaskReviewModal from "../Tasks/TaskReviewModal";
const items = [
  { label: "Tasks", url: "/dashboard/buyer/tasks" },
  { label: "Task Detail" },
];
const home = { label: "Home", url: "/dashboard/website-list" };

const TaskView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dload, setDload] = useState(false);
  const [pload, setpLoad] = useState(false);
  const [websiteData, setWebsiteData] = useState(null);
  const [improvements, setImprovements] = useState("");
  const [admincomments, setAdminComments] = useState("");
  const [iError, setIError] = useState(false);
  const [wload, setWload] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [acknowledgeModal, setAcknowledgeModal] = useState(false);
  const [improvementModal, setImprovementModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [webId, setWebId] = useState(null);
  const [pubId, setPubId] = useState(null);

  const fetchData = async () => {
    setWload(true);
    try {
      const response = await API.get(`/api/orders/${id}/edit`); // Replace with your actual API endpoint
      setWebsiteData(response.data);
      setWload(false);
    } catch (error) {
      setWload(false);
      return error;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    const fetchData = async () => {
      setWload(true);
      try {
        const response = await API.get(`/api/orders/${id}/edit`); // Replace with your actual API endpoint
        setWebsiteData(response?.data);
        setAdminComments(response?.data?.admin_comments);
        setImprovements(response?.data?.improvement_comment);
        setPubId(response?.data?.wl_products?.user_id);
        setWebId(response?.data?.id);
        setWload(false);
      } catch (error) {
        setWload(false);
        return error;
      }
    };

    fetchData();
  }, [localStorage.getItem("role"), id]);

  const handleRejectClose = () => {
    setRejectModal(false);
  };

  const handleAcknowledgeClose = () => {
    setAcknowledgeModal(false);
  };

  const handleImprovementClose = () => {
    setImprovementModal(false);
  };

  const handleReviewClose = () => {
    setReviewModal(false);
  };

  const handleImprovement = () => {
    if (improvements === "" || improvements === null) {
      setIError(true);
    } else {
      setImprovementModal(true);
    }
  };

  const handleResume = async () => {
    try {
      setDload(true);
      const config = {
        headers: {
          "Content-type": "multipart/form-data", // Since we now submit images as well
        },
      };

      const modifiedData = {
        ...websiteData,
        anchor_text: websiteData?.anchor_text,
        url: websiteData?.url,
        content_creator_type: websiteData?.content_creator_type,
        content_type: websiteData?.content_type,
        total_amount: websiteData?.total_amount,
        category_id: JSON.stringify(
          websiteData?.wl_order_category?.map((item) => item.id)
        ),
        language: websiteData?.language,
        words: websiteData?.words,
        suggestion:
          websiteData?.suggestion === null ? "" : websiteData?.suggestion,
      };

      const payload = {
        ...modifiedData,
        save_as_draft: "Draft",
      };
      // Make the POST request using Axios
      const response = await API.post(
        `${process.env.REACT_APP_BACKEND_API}/api/orders?user_type=1`,
        payload,
        config
      );

      // Handle the response if needed
      localStorage.setItem(
        "selectedSubRoute",
        `/dashboard/performer-list/${websiteData?.product_id}`
      );
      navigate(`/dashboard/performer-list/${websiteData?.product_id}`);
      await API.post(`/api/permanent-rejected-order/${id}?user_type=1`);
      Swal.fire({
        title: "Success!",
        html: "Task added to draft successfully",
        icon: "success",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
    } catch (error) {
      // Handle errors
      Swal.fire({
        title: "Add Funds",
        html: `
            <p style="font-size: 13px;">You have insufficient balance to perform this action, please add funds</p>
          `,
        confirmButtonText: "Add Funds",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        customClass: {
          title: "modal-title",
          content: "modal-content",
          confirmButton: "modal-confirm-button",
          cancelButton: "modal-cancel-button-swal",
          actions: "modal-actions",
        },
        buttonsStyling: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (localStorage.getItem("role") === "buyer") {
            navigate("/dashboard/balance"); // Navigate to the add funds page
          } else {
            navigate("/dashboard/publisher/balance");
          }
        }
      });
    } finally {
      setDload(false);
    }
    const resumeData = {
      content: websiteData?.content,
      anchor_text: websiteData?.anchor_text,
      special_requirements: websiteData?.special_requirements,
      url: websiteData?.url,
      words: websiteData?.words,
      keywords: websiteData?.keywords,
      content_creator_type: websiteData?.content_creator_type,
      content_type: websiteData?.content_type,
      language: websiteData?.language,
      suggestion: websiteData?.suggestion,
      category_id: websiteData?.wl_order_category,
    };
    localStorage.setItem("resumeData", JSON.stringify(resumeData));
  };

  const permaReject = async () => {
    setpLoad(true);
    try {
      await API.post(`/api/permanent-rejected-order/${id}?user_type=1`);
      await fetchData();
      Swal.fire({
        title: "Success!",
        html: "Task rejected permanently",
        icon: "success",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
    } catch (error) {
      return error;
    } finally {
      setpLoad(false);
    }
  };

  return (
    <div className="buyer-form-main-wraper">
      <BreadCrumb model={items} home={home} />
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-7">
            <h2> Task </h2>
          </div>
        </div>
      </div>

      {websiteData?.status_id === 24 && (
        <div className="mt-2 list-website-info">
          <span>
            {" "}
            <img src={InfoIcon} alt="" />{" "}
          </span>
          <strong>
            {" "}
            This is pending publisher's acceptence. Unless it is accepted, you
            can reject it.
          </strong>
        </div>
      )}
      {websiteData?.status_id === 26 && (
        <div className="mt-2 list-website-info">
          <span>
            {" "}
            <img src={InfoIcon} alt="" />{" "}
          </span>
          <strong>
            {" "}
            Task has been completed by the publisher. You can view task link at
            Task Placement url.
          </strong>
        </div>
      )}
      {websiteData?.status_id === 30 && (
        <div className="mt-2 list-website-info">
          <span>
            {" "}
            <img src={InfoIcon} alt="" />{" "}
          </span>
          <strong> Task has been rejected by you or your publisher.</strong>
        </div>
      )}
      {wload ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="mt-2 offer-detail-container">
            <div className="offer-rest-detail-wrapper">
              {/* {websiteData?.status_id === 30 &&
                websiteData?.permanent_rejected === 0 && (
                  <div className="my-4 w-100 d-flex justify-content-between options-reject">
                    <p>Do you want to resume this task</p>
                    <div className="d-flex align-items-center justify-content-end task-btn-wrapper">
                      <button
                        className="mx-2 offer-resume-btn offer-accept-pub-btn"
                        onClick={handleResume}
                      >
                        {" "}
                        {dload ? (
                          <CircularProgress className="text-white yes-progress" />
                        ) : (
                          "Yes"
                        )}{" "}
                      </button>
                      <button
                        onClick={permaReject}
                        className="offer-resume-btn offer-accept-pub-btn improve-btn"
                        // onClick={handleImprovement}
                      >
                        {" "}
                        {pload ? (
                          <CircularProgress className="text-white yes-progress" />
                        ) : (
                          "No"
                        )}{" "}
                      </button>
                    </div>
                  </div>
                )} */}
              <div className="accordion-row accordion-row-task">
                {/* {websiteData?.wl_products && (
                  <div className="d-flex flex-column align-items-start offer-d-row-col">
                    {" "}
                    <h6 className="order-article-faq-q ">Name:</h6>
                    {websiteData?.wl_products?.user?.wl_users_header?.name}
                  </div>
                )} */}
                {websiteData?.wl_products && (
                  <div className="d-flex flex-column align-items-start offer-d-row-col">
                    {" "}
                    <h6 className="order-article-faq-q ">Publisher's Url:</h6>
                    <a
                      href={websiteData?.wl_products?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {websiteData?.wl_products?.url}
                    </a>
                  </div>
                )}
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Task placement URL:</h6>
                  {websiteData?.task_placement_url ? (
                    <a
                      href={websiteData?.task_placement_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {websiteData?.task_placement_url}
                    </a>
                  ) : (
                    "This is where the publisher will add the url of page with the performed task."
                  )}
                  {/* {websiteData?.service_type} */}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Promoted Url:</h6>
                  {websiteData?.url &&
                    JSON.parse(websiteData?.url).length > 0 &&
                    JSON.parse(websiteData?.url).map((res, id) => (
                      <a
                        href={res}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={id}
                      >
                        {res}
                      </a>
                    ))}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Anchor text:</h6>
                  {websiteData?.anchor_text &&
                  JSON.parse(websiteData?.anchor_text).length > 0
                    ? JSON.parse(websiteData?.anchor_text).map((res, id) => (
                        <p key={id}>{res}</p>
                      ))
                    : "N/A"}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Service type:</h6>
                  {websiteData?.service_type_id === 1
                    ? "Link insertion"
                    : "Guest post"}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Content creator:</h6>
                  Webylinks
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Price:</h6>$
                  {websiteData?.total_amount}
                  {/* {websiteData?.service_type} */}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  <h6 className="order-article-faq-q ">
                    Special requirements:
                  </h6>
                  {websiteData?.special_requirements === null
                    ? "N/A"
                    : websiteData?.special_requirements}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q">Content type:</h6>
                  {websiteData?.service_type_id === 1
                    ? "Link insertion"
                    : websiteData?.service_type_id === 2
                    ? websiteData?.article_wordCount_id !== null
                      ? "Article by webylinks"
                      : "Article by buyer"
                    : ""}
                </div>
                <div className="d-flex flex-column align-items-start offer-d-row-col">
                  {" "}
                  <h6 className="order-article-faq-q ">Task status:</h6>
                  {websiteData?.status_id === 24
                    ? "Pending Acceptance"
                    : websiteData?.status_id === 23
                    ? "Draft"
                    : websiteData?.status_id === 25
                    ? "In Progress"
                    : websiteData?.status_id === 26
                    ? "Your Approval"
                    : websiteData?.status_id === 41
                    ? "Improvement"
                    : websiteData?.status_id === 42
                    ? "Under Review"
                    : websiteData?.status_id === 29
                    ? "Completed"
                    : websiteData?.status_id === 30
                    ? "Rejected"
                    : websiteData?.status_id === 40
                    ? "Pending Approval"
                    : "N/A"}
                </div>
              </div>
            </div>
            {websiteData?.wl_products &&
              websiteData?.service_type_id !== 1 &&
              websiteData?.article_wordCount_id === null && (
                <div className="offer-desc-wrapper">
                  <Editor
                    apiKey="rw57q63adwugi7s974bxs2bwaqna6dd3u8himamgjfsf8ax2"
                    initialValue={websiteData?.content}
                    init={{
                      height: 400,
                      menubar: false,
                      selector: "textarea",
                      toolbar: "",
                      content_style:
                        "body { font-family: 'Arial', sans-serif; font-size: 14px }",
                    }}
                    disabled
                  />
                </div>
              )}
            {(websiteData?.status_id === 26 ||
              websiteData?.status_id === 41 ||
              (websiteData?.status_id === 29 && improvements !== null)) && (
              <div className="w-100 d-flex flex-column improvement-wrap">
                <label>Improvements</label>
                <textarea
                  className={`text-area-control ${iError ? "imp-err" : ""}`}
                  rows="6"
                  name="improvements"
                  placeholder="Please specify any improvements you want to suggest in this task"
                  value={improvements}
                  disabled={
                    websiteData?.status_id === 41 ||
                    websiteData?.status_id === 29
                  }
                  onChange={(e) => {
                    setIError(false);
                    setImprovements(e.target.value);
                  }}
                ></textarea>
              </div>
            )}
            {iError && (
              <div className="w-100 justify-content-end">
                <p style={{ color: "red" }}>
                  Please first specify the improvements
                </p>
              </div>
            )}
            {admincomments && (
              <div className="w-100 d-flex flex-column improvement-wrap">
                <label>Publisher remarks</label>
                <textarea
                  className={`text-area-control`}
                  rows="6"
                  name="admincomments"
                  placeholder="Admin remarks"
                  value={admincomments}
                  disabled={true}
                ></textarea>
              </div>
            )}
            {websiteData?.status_id !== 29 && websiteData?.status_id !== 30 && (
              <div className="mt-4 w-100 d-flex justify-content-end task-btns-wrapper">
                {websiteData?.status_id === 26 && (
                  <>
                    <button
                      className="mx-2 active-offer-btn offer-accept-pub-btn"
                      onClick={() => {
                        setAcknowledgeModal(true);
                      }}
                    >
                      {" "}
                      Acknowledge{" "}
                    </button>
                    <button
                      className="active-offer-btn offer-accept-pub-btn improve-btn"
                      onClick={handleImprovement}
                    >
                      {" "}
                      Improvement{" "}
                    </button>
                  </>
                )}
                {websiteData?.status_id === 24 && (
                  <button
                    className="active-offer-btn offer-accept-pub-btn"
                    onClick={() => {
                      setRejectModal(true);
                    }}
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                )}
              </div>
            )}
            {websiteData?.status_id === 29 &&
              websiteData?.is_reviewed === 0 &&
              websiteData?.wl_products && (
                <div className="mt-4 w-100 d-flex justify-content-end task-btns-wrapper">
                  <button
                    className="active-offer-btn offer-accept-pub-btn"
                    onClick={() => {
                      setReviewModal(true);
                    }}
                  >
                    {" "}
                    Review{" "}
                  </button>
                </div>
              )}
          </div>
        </>
      )}
      <TaskReviewModal
        reviewModal={reviewModal}
        handleReviewClose={handleReviewClose}
        publisherId={pubId}
        getData={fetchData}
        id={webId}
      />
      <TaskImprovementModal
        id={websiteData?.id}
        getTask={fetchData}
        improvements={improvements}
        improvementModal={improvementModal}
        handleImprovementClose={handleImprovementClose}
      />
      <TaskAcknowledgeModal
        id={websiteData?.id}
        getTask={fetchData}
        acknowledgeModal={acknowledgeModal}
        handleAcknowledgeClose={handleAcknowledgeClose}
      />
      <TaskRejectModal
        id={websiteData?.id}
        rejectModal={rejectModal}
        getTask={fetchData}
        handleRejectClose={handleRejectClose}
      />
    </div>
  );
};

export default TaskView;
