import React, { useEffect, useState } from "react";
// import { Rating } from "primereact/rating";
import "../styles/profileMain.css";
import InfoIcon from "../../../assets/images/info-icon-new.png";
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import ReviewContainer from "./ReviewContainer";
import API from "../../../api/ClientApi";
import { useParams } from "react-router-dom";
import NoData from "../../../components/NoData/NoData";
import Rating from "@mui/material/Rating";
import { BreadCrumb } from "primereact/breadcrumb";
const items = [
  { label: "Website list", url: "/dashboard/website-list" },
  { label: "Profile" },
];
const home = { label: "Home", url: "/dashboard/website-list" };

const ProfileMain = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(4.29);
  const [user, setUser] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      setLoad(true);
      try {
        const response = await API.get(`/api/selected-user-profile/${id}`);
        setUser(response?.data);
        setValue(response?.data?.user_details?.average_rating);
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    };
    getUser();
  }, []);

  return (
    <div className="w-100 account-settings-main">
      <BreadCrumb model={items} home={home} />
      <div className="w-100 as-header">
        <h2>Publisher Profile</h2>
      </div>
      {load ? (
        <div className="w-100 d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="profile-info-row">
            <div className="publisher-profile-info-section">
              <div className="content-profile-wrapper">
                <h3>
                  {user?.user_details?.name !== null
                    ? user?.user_details?.name
                    : "No name provided"}
                </h3>
                <div className="d-flex align-items-center">
                  <div className="active-user-circle" />
                  <p>
                    <span>Active</span> last active on 09 06 2023
                  </p>
                </div>
              </div>
            </div>
            <div className="publisher-profile-info-section">
              <div className="content-profile-wrapper">
                <p className="rating-text">Rating</p>
                <div className="d-flex review-star-wrapper">
                  <h3>
                    {
                      user?.user_details?.average_rating % 1 === 0
                        ? user?.user_details?.average_rating.toFixed(0) // No decimal places for whole numbers
                        : user?.user_details?.average_rating.toFixed(1) // One decimal place for non-whole numbers
                    }{" "}
                    out of 5
                  </h3>
                  <Rating
                    name="half-rating-read"
                    defaultValue={user?.user_details?.average_rating}
                    precision={0.1}
                    className="mx-2 mt-1"
                    readOnly
                  />
                  {/* <Rating
                    value={value}
                    onChange={(e) => setValue(e.value)}
                    cancel={false}
                    className="mx-2"
                    disabled
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="profile-info-row-2">
            <div className="publisher-profile-info-section-2">
              <div className="content-profile-wrapper-2">
                <p>
                  Average completion rate{" "}
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon p-text-secondary p-overlay-badge"
                    data-pr-tooltip="Average completion rate"
                    data-pr-position="top"
                    data-pr-at="left-60 top-10"
                    data-pr-my="left"
                    style={{ cursor: "pointer" }}
                  >
                    <IconButton size="small" className="info-icon">
                      <img
                        src={InfoIcon}
                        alt="Info"
                        className="info-icon-image"
                      />
                    </IconButton>
                  </i>
                </p>
                <div className="info-rate-div">77.77%</div>
              </div>
            </div>
            <div className="publisher-profile-info-section-2">
              <div className="content-profile-wrapper-2">
                <p>
                  Task with initial domain and price{" "}
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon p-text-secondary p-overlay-badge"
                    data-pr-tooltip="Task with initial domain and price"
                    data-pr-position="top"
                    data-pr-at="left-60 top-10"
                    data-pr-my="left"
                    style={{ cursor: "pointer" }}
                  >
                    <IconButton size="small" className="info-icon">
                      <img
                        src={InfoIcon}
                        alt="Info"
                        className="info-icon-image"
                      />
                    </IconButton>
                  </i>
                </p>
                <div className="info-rate-div">92.49%</div>
              </div>
            </div>
            <div className="publisher-profile-info-section-2">
              <div className="content-profile-wrapper-2">
                <p>
                  Average lifetime of links{" "}
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon p-text-secondary p-overlay-badge"
                    data-pr-tooltip="Average lifetime of links"
                    data-pr-position="top"
                    data-pr-at="left-60 top-10"
                    data-pr-my="left"
                    style={{ cursor: "pointer" }}
                  >
                    <IconButton size="small" className="info-icon">
                      <img
                        src={InfoIcon}
                        alt="Info"
                        className="info-icon-image"
                      />
                    </IconButton>
                  </i>
                </p>
                <div className="info-rate-div">100.00%</div>
              </div>
            </div>
          </div>
          <div className="profile-info-row-2">
            <button
              className="profile-pub-btn"
              onClick={() =>
                navigate(
                  `/dashboard/publisher/profile-listing/main/${user?.user_details?.id}`
                )
              }
            >
              View all sites
            </button>
            <button className="profile-pub-btn">Add to allow list</button>
            <button className="profile-pub-btn">Add to block list</button>
          </div>
          <div className="w-100 as-header">
            <h2>
              Buyer's review{" "}
              <span className="b-review-txt">
                ({user?.user_details?.ratings?.length})
              </span>
            </h2>
          </div>
          {user &&
            user?.user_details?.ratings?.map((res, id) => (
              <ReviewContainer user={res} />
            ))}
          {user && user?.user_details?.ratings?.length === 0 && <NoData />}
        </>
      )}
    </div>
  );
};

export default ProfileMain;
