import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import Trash from "../../assets/images/trash.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { BreadCrumb } from "primereact/breadcrumb";
import API from "../../api/ClientApi";
import Plus from "../../assets/images/plus.svg";
import "./style/taks.css";
import { useNavigate } from "react-router-dom";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import NoData from "../../components/NoData/NoData";
import { OverlayPanel } from "primereact/overlaypanel";
import { Paginator } from "primereact/paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RejectTaskModal from "./RejectTaskModal";
import AcknowledgeModal from "./AcknowledgeModal";

const TasksBuyer = () => {
  const navigate = useNavigate();
  const op = useRef(null);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [load, setLoad] = useState(false);
  const [typeTab, setTypeTab] = useState(24);
  const [tabs, setTabs] = useState([]);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(10);
  const [eid, setEId] = useState(null);
  const [rejectModal, setRejectModal] = useState(false);
  const [ackModal, setAckModal] = useState(false);
  const items = [{ label: "Tasks" }];
  const home = { label: "Home", url: "/dashboard/website-list" };

  const getList = async () => {
    try {
      const response = await API.get(
        `/api/orders?status_id=${typeTab}&page=${currentPage}&user_type=1`
      );
      const responseData = response.data;

      setOrders(responseData.data);
      setCurrentPage(responseData.current_page);
      setTotalPages(responseData.last_page);
      setTotal(responseData.total);

      setLoad(false);
    } catch (error) {
      setLoad(false);
    }
  };

  const getTabs = async () => {
    try {
      const data = await API.get("/api/get-status-by-buyer-order?user_type=1");
      if (data) {
        setTabs(data?.data?.status_data);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }

    setLoad(true);
    const getListTabs = async () => {
      try {
        const data = await API.get(
          `/api/get-status-by-buyer-order?&user_type=1`
        );
        setTabs(data?.data?.status_data);
      } catch (error) {
        return error;
      }
    };

    const fetchData = async () => {
      try {
        const response = await API.get(
          `/api/orders?status_id=${typeTab}&page=${currentPage}&user_type=1`
        );
        const responseData = response.data;
        setOrders(responseData.data);
        setCurrentPage(responseData.current_page);
        setTotalPages(responseData.last_page);
        setTotal(responseData.total);
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    };

    getListTabs();
    fetchData();
  }, [typeTab, currentPage, localStorage.getItem("role")]);

  const handleWebTabChange = (e, tabIndex) => {
    setCurrentPage(1);
    setTypeTab(tabIndex);
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };

  const handleRejectClose = () => {
    setRejectModal(false);
  };

  const handleAckClose = () => {
    setAckModal(false);
  };
  return (
    <div className="buyer-form-main-wraper">
      <BreadCrumb model={items} home={home} />
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Tasks </h2>
          </div>
        </div>
      </div>
      <Accordion className="accordion-filter accordion-global">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-summery"
        >
          <div className="w-100 d-flex justify-content-between filter-header">
            <h2>HOW IT WORKS?</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <div className="accordion-detail-div ac-div">
            In this section you can see tasks grouped by multiple statuses.Each
            status is accompanied by a number representing the quantity of tasks
            within that status.As the task is in progress, it will transition
            between different statuses.You'll stay updated through emails and
            notifications. To view a description of each status, hover your
            mouse pointer over the corresponding status name.To access
            additional details about the task ‘click button with three dots’ and
            smoothly perform further actions.
          </div>
        </AccordionDetails>
      </Accordion>

      <div className="cta-add-website-wrap">
        <div className="row">
          <div className="col-md-6">
            <h2 className="new-aticle-heading"> Want to place more tasks? </h2>
          </div>
          <div className="col-md-6">
            <div className="add-website-wrap">
              {" "}
              <Button
                onClick={() => {
                  navigate("/dashboard/website-list");
                }}
                className="tasks-banner-btn"
              >
                Search for publishers
              </Button>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="order-article-tabs-wrap">
        <div className="w-100 open-offer-tabs">
          <Tabs
            className="tabs-add-web"
            value={typeTab}
            onChange={handleWebTabChange}
            variant="scrollable"
          >
            {tabs &&
              tabs?.map((res, id) => (
                <Tab
                  className={`${
                    typeTab === res?.id ? "tab-active" : "account-list-tabs"
                  }`}
                  icon={
                    <div className="d-flex">
                      <span>{res?.name}</span>
                      <div className="count-circle">{res?.count}</div>
                    </div>
                  }
                  value={res?.id}
                  key={id}
                ></Tab>
              ))}
          </Tabs>
        </div>
        {load ? (
          <div className="w-100 mt-2 d-flex justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table width="100%" className="table">
                <tr className="heading-article">
                  <td width="11%">Task ID</td>
                  <td width="13%">Url</td>
                  <td width="17%">Content type</td>
                  <td width="14%">Price</td>
                  <td width="14%">Status</td>
                  <td width="14%">Created at</td>
                  <td width="15%">Actions</td>
                  {/* <td width="10%">Action</td> */}
                  {/* <td width="2%">&nbsp;</td> */}
                </tr>
                {orders &&
                  orders?.map((res, id) => (
                    <tr key={id}>
                      <td height="70">
                        <span>#{id + 1}</span>
                      </td>
                      <td>
                        {res?.wl_products !== null ? (
                          <span
                            className="link-table"
                            onClick={() => window.open(res?.wl_products?.url)}
                          >
                            {res?.wl_products?.url &&
                            res?.wl_products?.url.length > 30
                              ? res?.wl_products?.url.slice(0, 30) + "..."
                              : res?.wl_products?.url}
                          </span>
                        ) : (
                          <span
                            className=""
                            // onClick={() => window.open(res?.wl_products?.url)}
                          >
                            N/A
                          </span>
                        )}
                      </td>

                      <td>
                        {res?.service_type_id === 1
                          ? "Link insertion"
                          : res?.service_type_id === 2
                          ? res?.article_wordCount_id !== null
                            ? "Article by webylinks"
                            : "Article by buyer"
                          : ""}
                      </td>

                      <td>${res.total_amount}</td>
                      {/* <td>
                <a href="#"> Continue </a>
              </td> */}
                      <td>{res?.wl_status?.status_name}</td>
                      <td>{new Date(res?.created_at).toLocaleDateString()}</td>
                      <td>
                        {res?.wl_status?.status_name === "Draft" ? (
                          <button
                            className="active-offer-btn offer-accept-btn"
                            onClick={() =>
                              navigate(`/dashboard/update-task-form/${res?.id}`)
                            }
                          >
                            {" "}
                            Edit task{" "}
                          </button>
                        ) : (
                          <div className="d-flex px-0">
                            <button
                              className="active-offer-btn offer-accept-pub-btn"
                              onClick={() =>
                                navigate(`/dashboard/task/${res?.id}`)
                              }
                            >
                              {" "}
                              View{" "}
                            </button>

                            {res?.status_id !== 29 &&
                              res?.status_id !== 42 &&
                              res?.status_id !== 30 &&
                              res?.status_id !== 25 &&
                              res?.status_id !== 41 && (
                                <>
                                  <Button
                                    aria-describedby={id}
                                    variant="outlined"
                                    onClick={async (e) => {
                                      setEId(res?.id);
                                      // setStatus(res?.status_id);
                                      op.current.toggle(e);
                                    }}
                                    className="verticon-button-offer"
                                  >
                                    <IconButton className="more-option-card">
                                      <MoreHorizIcon />
                                    </IconButton>
                                  </Button>
                                  <OverlayPanel ref={op}>
                                    <List component="nav">
                                      {res?.status_id === 26 && (
                                        <ListItem
                                          button
                                          onClick={() => {
                                            setAckModal(true);
                                          }}
                                          className="p-card-menu-btn"
                                        >
                                          <ListItemIcon>
                                            <FontAwesomeIcon
                                              icon={faCircleCheck}
                                              size="lg"
                                              style={{ color: "#ffc813" }}
                                            />{" "}
                                          </ListItemIcon>
                                          <ListItemText primary="Acknowledge" />
                                        </ListItem>
                                      )}
                                      {res?.status_id === 24 && (
                                        <ListItem
                                          button
                                          onClick={() => {
                                            setRejectModal(true);
                                          }}
                                          className="p-card-menu-btn"
                                        >
                                          <ListItemIcon>
                                            <FontAwesomeIcon
                                              icon={faCircleXmark}
                                              size="lg"
                                              style={{ color: "#ffc813" }}
                                            />{" "}
                                          </ListItemIcon>
                                          <ListItemText primary="Cancel" />
                                        </ListItem>
                                      )}
                                    </List>
                                  </OverlayPanel>
                                </>
                              )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </table>
              {orders?.length === 0 && <NoData />}
            </div>
            <div>
              {total > 10 && (
                <Paginator
                  first={currentPage * rows - rows}
                  rows={rows}
                  totalRecords={totalPages * rows} // Assuming each page has 'rows' number of items
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
      <RejectTaskModal
        id={eid}
        getList={getList}
        getTabs={getTabs}
        setType={setTypeTab}
        rejectModal={rejectModal}
        handleRejectClose={handleRejectClose}
      />
      <AcknowledgeModal
        id={eid}
        getList={getList}
        getTabs={getTabs}
        setType={setTypeTab}
        ackModal={ackModal}
        handleAckClose={handleAckClose}
      />
    </div>
  );
};

export default TasksBuyer;
