export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAIL = "NOTIFICATION_FAIL";

export const NOTIFICATION_COUNT_REQUEST = "NOTIFICATION_COUNT_REQUEST";
export const NOTIFICATION_COUNT_SUCCESS = "NOTIFICATION_COUNT_SUCCESS";
export const NOTIFICATION_COUNT_FAIL = "NOTIFICATION_COUNT_FAIL";

export const NOTIFICATION_UNREAD_COUNT_REQUEST = "NOTIFICATION_UNREAD_COUNT_REQUEST";
export const NOTIFICATION_UNREAD_COUNT_SUCCESS = "NOTIFICATION_UNREAD_COUNT_SUCCESS";
export const NOTIFICATION_UNREAD_COUNT_FAIL = "NOTIFICATION_UNREAD_COUNT_FAIL";

export const NOTIFICATION_CHECK_REQUEST = "NOTIFICATION_CHECK_REQUEST";
export const NOTIFICATION_CHECK_SUCCESS = "NOTIFICATION_CHECK_SUCCESS";
export const NOTIFICATION_CHECK_FAIL = "NOTIFICATION_CHECK_FAIL";