import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { TextField, CircularProgress } from "@mui/material";
import { Dropdown } from "primereact/dropdown"; // Import the Dropdown component from PrimeReact
import API from "../../../api/ClientApi";
import Swal from "sweetalert2";

const stripePromise = loadStripe(
  "pk_test_51Ir885FpPc0MZ8BuHc0VkB93Kfu4T6EMllOl9Qy1rpH7JHeVPoOiKEdIhqMScGyxgmqojvECwsNtvVoEWF4mJzPs00gaJr5ktA"
);

const AddressDetails = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [rError, setRError] = useState(false);
  const [acError, setACError] = useState(false);
  const [arError, setARError] = useState(false);
  const [bankAccountToken, setBankAccountToken] = useState(null);
  const [bankAccountDetails, setBankAccountDetails] = useState({
    country: "US", // Initially empty
    currency: "USD", // Initially empty
    routing_number: "",
    account_number: "",
    account_holder_name: "",
    account_holder_type: "individual", // Initially empty
  });

  // Define options for dropdowns
  const countryOptions = [
    { label: "United States", value: "US" },
    { label: "United Kingdom", value: "UK" },
    { label: "United Arab Emirates", value: "UAE" },
  ];

  const currencyOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "AED", value: "AED" },
  ];

  const accountHolderTypeOptions = [
    { label: "Individual", value: "individual" },
    { label: "Company", value: "company" },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (bankAccountDetails?.routing_number === "") {
      setRError(true);
    }
    if (bankAccountDetails?.account_holder_name === "") {
      setACError(true);
    }
    if (bankAccountDetails?.account_number === "") {
      setARError(true);
    }

    if (
      bankAccountDetails?.routing_number !== "" &&
      bankAccountDetails?.account_holder_name !== "" &&
      bankAccountDetails?.account_number !== ""
    ) {
      setLoading(true);
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        return;
      }

      // Create a bank account token
      const { token, error } = await stripe.createToken("bank_account", {
        bank_account: {
          country: bankAccountDetails.country,
          currency: bankAccountDetails.currency,
          routing_number: bankAccountDetails.routing_number,
          account_number: bankAccountDetails.account_number,
          account_holder_name: bankAccountDetails.account_holder_name,
          account_holder_type: bankAccountDetails.account_holder_type,
        },
      });

      if (error) {
        // Handle the error
        setLoading(false);
        Swal.fire({
          title: "Error!",
          html: error.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
          allowOutsideClick: false,
        });
      } else {
        const bankAccountToken = token.id;
        setBankAccountToken(bankAccountToken);

        try {
          const response = await API.get(
            `/api/link-bank-account-details/${token.id}`
          );
        } catch (error) {
          Swal.fire({
            title: "Error!",
            html: error.response.data.message,
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
            allowOutsideClick: false,
          });
        }
        setLoading(false);
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "account_holder_name") {
      setACError(false);
    }
    if (name === "account_number") {
      setARError(false);
    }
    if (name === "routing_number") {
      setRError(false);
    }
    setBankAccountDetails({
      ...bankAccountDetails,
      [name]: value,
    });
  };

  return (
    <div className="billing-info-main info-main-acc">
      <form onSubmit={handleSubmit}>
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container">
            <div className="w-100">
              <label>Country</label>
            </div>
            {/* Render the Country dropdown */}
            <Dropdown
              name="country"
              value={bankAccountDetails.country}
              options={countryOptions}
              className="w-100 custom-input-billing"
              onChange={handleInputChange}
              placeholder="Select Country"
            />
          </div>
          <div className="info-billing-container second-input">
            <div className="w-100">
              <label>Currency</label>
            </div>
            {/* Render the Currency dropdown */}
            <Dropdown
              name="currency"
              value={bankAccountDetails.currency}
              options={currencyOptions}
              className="w-100 custom-input-billing"
              onChange={handleInputChange}
              placeholder="Select Currency"
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container second-input">
            <div className="w-100">
              <label>Routing Number</label>
            </div>
            <TextField
              className="w-100 signup-email-field"
              name="routing_number"
              type="number"
              variant="outlined"
              value={bankAccountDetails.routing_number}
              onChange={handleInputChange}
              InputProps={{
                classes: {
                  root: `custom-input-billing ${
                    rError ? "err-acc-outline" : ""
                  }`,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
          <div className="info-billing-container second-input">
            <div className="w-100">
              <label>Account Number</label>
            </div>
            <TextField
              className="w-100 signup-email-field"
              name="account_number"
              type="number"
              variant="outlined"
              value={bankAccountDetails.account_number}
              onChange={handleInputChange}
              InputProps={{
                classes: {
                  root: `custom-input-billing ${
                    arError ? "err-acc-outline" : ""
                  }`,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between multi-input-section">
          <div className="info-billing-container">
            <div className="w-100">
              <label>Account Holder Name</label>
            </div>
            <TextField
              className="w-100 signup-email-field"
              name="account_holder_name"
              type="text"
              variant="outlined"
              value={bankAccountDetails.account_holder_name}
              onChange={handleInputChange}
              InputProps={{
                classes: {
                  root: `custom-input-billing ${
                    acError ? "err-acc-outline" : ""
                  }`,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "custom-label",
                },
              }}
            />
          </div>
          <div className="info-billing-container second-input">
            <div className="w-100">
              <label>Account Holder Type</label>
            </div>
            {/* Render the Account Holder Type dropdown */}
            <Dropdown
              name="account_holder_type"
              value={bankAccountDetails.account_holder_type}
              options={accountHolderTypeOptions}
              className="w-100 custom-input-billing"
              onChange={handleInputChange}
              placeholder="Select Account Holder Type"
            />
          </div>
        </div>
        {(arError || acError || rError) && (
          <div className="mt-2 d-flex justify-content-end">
            <p style={{ color: "red" }}>Please fill all fields</p>
          </div>
        )}

        <div className="w-100 d-flex justify-content-end account-setting-update">
          <button type="submit" className="billing-btn">
            {loading ? (
              <CircularProgress className="text-black" />
            ) : (
              "SAVE DETAILS"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddressDetails;
