import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  USER_ROLE_SELECT_SUCCESS,
  USER_ROLE_SELECT_REQUEST,
  USER_ROLE_SELECT_FAIL,
  USER_PHONE_SELECT_SUCCESS,
  USER_PHONE_SELECT_REQUEST,
  USER_PHONE_SELECT_FAIL,
  USER_OTP_SELECT_SUCCESS,
  USER_OTP_SELECT_REQUEST,
  USER_OTP_SELECT_FAIL,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAIL,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_RESET,
  UPDATE_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAIL,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  CLEAR_ERRORS,
  LOAD_USER_PROFILE_FAIL,
  LOAD_USER_PROFILE_REQUEST,
  LOAD_USER_PROFILE_SUCCESS,
} from "../constants/userConstants";

// Login, Register and Load user (Auth)
export const authReducers = (state = { user: {} }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case REGISTER_USER_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
      };

    case LOGIN_SUCCESS:
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        userAuth: action.payload,
      };

    case LOGOUT_USER_SUCCESS:
      return {
        loading: false,
        isAuthenticated: false,
        userAuth: null,
      };

    case LOGOUT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case LOGIN_FAIL:
    case REGISTER_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userAuth: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// verify user
export const verifyUserReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };

    case VERIFY_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// User Role
export const userRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ROLE_SELECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ROLE_SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };

    case USER_ROLE_SELECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// User phone
export const userPhoneReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PHONE_SELECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_PHONE_SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };

    case USER_PHONE_SELECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// OTP verification
export const userOTPReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_OTP_SELECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_OTP_SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };

    case USER_OTP_SELECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// User reducers
export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_PROFILE_REQUEST:
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        updateLoading: true,
      };

    case UPDATE_USER_PROFILE_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        isUpdated: action.payload,
      };

    case UPDATE_USER_PROFILE_FAIL:
    case UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        updateLoading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// User PROFILE reducers
export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case LOAD_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// User PROFILE PAGE reducers
export const userProfilePageReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case LOAD_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Forgot password reducers
export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
    case NEW_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case FORGOT_PASSWORD_FAIL:
    case NEW_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
