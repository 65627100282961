import axios from "axios";
import Swal from "sweetalert2";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,

  headers: {
    responseType: "json", // default
    Accept: "application/json",
  },
});

// Interceptor for request headers
API.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

// Interceptor for successful responses
API.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
     return response;
    }
    return response;
  },
  function (error) {
    const statusCode = error.response ? error.response.status : null;
    if (statusCode === 404) {
      // window.location.href = '/error-pages/error-404';
    }
    if (statusCode === 401) {
      // localStorage.removeItem("access_token");
    } else if (statusCode === 500) {
      // window.location.href = '/error-pages/error-500';
    } else if (statusCode === 422 || statusCode === 409) {
      Swal.fire({
        title: "Error!",
        html: error.response.data.message,
        icon: "error",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
    } else if (statusCode === 401) {
      Swal.fire({
        text: "Please Check your Email and Password",
        icon: "error",
      });
      window.location.href = "/login";
    } else if (statusCode === 403) {
      window.location.href = "/error-pages/error-403";
    }
    else if(statusCode === 500){
      Swal.fire({
        title: "Error!",
        html: error.response.error.message,
        icon: "error",
        timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
        showConfirmButton: false, // Hide the "OK" button
        allowOutsideClick: false, // Prevent closing by clicking outside
      });
    }
    if(error?.response?.data?.message === "Unauthenticated."
    ){
      localStorage.removeItem("access_token");
      localStorage.removeItem("selectedSubRoute");
      localStorage.removeItem("role");
      localStorage.removeItem("url");
      localStorage.removeItem("storedUrl");
      localStorage.removeItem("setSubRoute");
    }
    return Promise.reject(error);
  }
);

export default API;
