import React, { useState, useRef } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import API from "../../../api/ClientApi";
import Swal from "sweetalert2";

const ContactForm = () => {
  const captchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
  });

  const setClear = () => {
    setContact({ ...contact, name: "", email: "", message: "" });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setContact((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const token = captchaRef.current.getValue();
    if (token) {
      setLoading(true);
      try {
        await API.post("/api/contact-us-by-email", contact);
        captchaRef.current.reset();
        Swal.fire({
          title: "Success!",
          html: "Contact information submitted successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
      } catch (error) {
      } finally {
        setClear();
        setLoading(false);
      }
    } else {
      setError("Please verify that you are not a robot");
    }
  };

  return (
    <div className="contact-form-main">
      <div className="contact-form-heading">
        <h2>
          HAVE ANY<span> QUESTIONS?</span>
        </h2>
      </div>
      <div className="signup-container">
        <div className="contact-form-top-text">
          <p>Drop us a line</p>
        </div>
        <form
          onSubmit={handleSubmit}
          className="w-100 d-flex flex-column align-items-end form-main-sec"
        >
          <TextField
            type="text"
            className="mb-3 w-100 signup-email-field"
            placeholder="Your Name*"
            name="name"
            variant="outlined"
            required
            value={contact.name}
            onChange={handleChange}
            InputProps={{
              classes: {
                root: "custom-input custom-border-color",
                input: "custom-input",
              },
            }}
            InputLabelProps={{
              classes: {
                root: "custom-label",
              },
            }}
          />
          <TextField
            type="email"
            className="mb-3 w-100 signup-email-field"
            placeholder="Your email*"
            name="email"
            variant="outlined"
            required
            value={contact.email}
            onChange={handleChange}
            InputProps={{
              classes: {
                root: "custom-input custom-border-color",
                input: "custom-input",
              },
            }}
            InputLabelProps={{
              classes: {
                root: "custom-label",
              },
            }}
          />
          <TextField
            multiline
            rows={2}
            type="text"
            className="w-100 signup-email-field"
            placeholder="Your message*"
            name="message"
            variant="outlined"
            required
            value={contact.message}
            onChange={handleChange}
            InputProps={{
              classes: {
                root: "custom-input-area custom-border-color",
                input: "custom-input-area",
              },
            }}
            InputLabelProps={{
              classes: {
                root: "custom-label",
              },
            }}
          />
          <div className="w-100 d-flex flex-column justify-content-center align-items-center contact-captcha-section">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_SITE}
              ref={captchaRef}
            />
            {error && <p>{error}</p>}
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="w-100 signup-btn"
          >
            {loading ? <CircularProgress className="text-white" /> : "SUBMIT"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
