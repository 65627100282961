import React, { useEffect } from "react";

const TawkTo = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://embed.tawk.to/654557fdf2439e1631eb801c/1hebdve9e";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <script
      src="https://embed.tawk.to/654557fdf2439e1631eb801c/1hebdve9e"
      async
    />
  );
};

export default TawkTo;
