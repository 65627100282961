import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import AddOneWebiste from "./AddOneWebiste";
import AddMultiple from "./AddMultiple";
import WebsiteSetup from "./WebsiteSetup";
import API from "../../../api/ClientApi";
import { useNavigate } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import axios from "axios";
import Swal from "sweetalert2";
import "../styles/myPaltform.css";

const AddWebsiteSteps = (props) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [progress, setProgress] = useState(33.3333);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);
  const [webload, setWebLoad] = useState(false);
  const [category, setCategory] = useState(1);
  const items = [
    {
      label:
        active === 0
          ? "Terms and conditions"
          : active === 1
          ? "Add website(s)"
          : "Add website info",
    },
  ];
  const home = { label: "Home", url: "/dashboard/platform" };

  const [websiteData, setWebsiteData] = useState({
    url: localStorage.getItem("url"),
    catagories: 1,
    language: "",
    links: "",
    sponsored: "No",
    service_type: "",
    special_requirements: "",
    consider_free_posts: false,
    free_article_requirement: "",
    best_article_link: "",
    content_placement_price: 0,
    mini_post: "0",
    long_article: "0",
  });

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, type, checked, value } = event.target;
    // Update the mini_post value based on the checkbox state
    if (type === "checkbox") {
      if (name === "mini_post" || name === "long_article") {
        setWebsiteData((prevData) => ({
          ...prevData,
          [name]: checked ? "123.00" : "0",
        }));
      }
    }
    setWebsiteData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNext = async (e) => {
    setLoad(true);
    e.preventDefault();
    localStorage.setItem("url", url);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-type": "multipart/form-data", // Since we are also submitting images
      },
    };
    try {
      const isValid = await axios.post(
        "https://app.webylinks.com/webylinks/public/api/checkurl-by-publisher",
        { url: url },
        config
      );
      if (isValid) {
        setActive(3);
      }
    } catch (error) {
      setLoad(false);
      setError(error.response.data.error || error.response.data.message);
      return error;
    }
    setActive(2);
    setProgress(100);
    setLoad(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "buyer" || props?.publish===0) {
      navigate("/dashboard/website-list");
    }
    const getCategory = async () => {
      try {
        const data = await API.get("/api/get-product-category");

        if (data) {
          setCategory(data.data.data.getProducts);
        }
      } catch (error) {
        return error;
      }
    };
    getCategory();
  }, [localStorage.getItem("role")]);

  return (
    <div className="w-100 account-settings-main">
      <BreadCrumb model={items} home={home} />
      <div className="w-100 as-header">
        <h2>Add Website(s)</h2>
      </div>
      <div className="progress-main-wrap">
        <div className="progress" style={{ height: "8px" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      {active === 0 && (
        <div className="w-100">
          <div className="add-website-wraper term-conditions">
            <h3>TERMS AND CONDITIONS</h3>
            <p>
              Two primary roles provided by Webylink is as a owner and
              contributor.Publishers will be working for the sites associated to
              site owners only.And is you want to proceed your work as a
              contributor than by default you have to Pass Moderation.
            </p>
            <ul className="terms-condition-list">
              <li>Add a website</li>
              <li>Read Webylink's term and conditions briefly</li>
              <li>Briefly overview your website and its status</li>
              <li>
                Go through every minor details of your website and make
                necessary updates
              </li>
              <li>Activate/deactivate your website</li>
            </ul>
            <h3>ADD WEBSITE</h3>
            <p>
              Looking to monetize your site with unique and relevant
              content/Simply just add your website to our inventory, receive
              tasks from buyers and EARN PROFIT! NOTE: Try to improve your works
              more and more and this can increase the chances of receiving more
              and more tasks from buyers.
            </p>
            <h3>Services Type</h3>
            <ul className="terms-condition-list">
              <li>
                Place content to your website provided by the buyers.Set price
                according to your choice in exchange of content placement.
              </li>
              <li>
                Create different types of content like Articles, Blogs and
                mini-articles, post it on your website at your customized rates
                and enjoy daily profit.
              </li>
            </ul>
            <h3>Results</h3>
            <ul className="terms-condition-list">
              <li>100% Reliable Payments</li>
              <li>Monetize Your Website</li>
              <li>Boost your Google Ranking with high quality content.</li>
              <li>
                You have complete control over the content featured on your
                site.
              </li>
              <li>
                Maintain your readers' trust by providing clear sponsorship
                disclosures.
              </li>
            </ul>
            <div className="w-100 justify-content-end terms-btn-wrapper">
              <button
                className="billing-btn"
                onClick={() => {
                  setActive(1);
                  setProgress(66.6666);
                }}
                style={{ border: "none", color: "white" }}
              >
                Agree and continue
              </button>
            </div>
          </div>
        </div>
      )}
      {active === 1 && (
        <div className="w-100">
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            variant="scrollable"
          >
            <Tab
              className={`${
                currentTabIndex === 0 ? "tab-active" : "account-list-tabs"
              }`}
              label="One Website"
            />
            <Tab
              className={`${
                currentTabIndex === 1 ? "tab-active" : "account-list-tabs"
              }`}
              label="List of websites"
            />
          </Tabs>

          {/* TAB 1 Contents */}
          {currentTabIndex === 0 && (
            <Box>
              <AddOneWebiste
                error={error}
                setError={setError}
                handleSubmit={handleNext}
                url={url}
                setUrl={setUrl}
                load={load}
              />
            </Box>
          )}

          {/* TAB 2 Contents */}
          {currentTabIndex === 1 && (
            <Box>
              <AddMultiple />
            </Box>
          )}
        </div>
      )}
      {active === 2 && (
        <WebsiteSetup
          data={websiteData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setActive={setActive}
          sponsored={websiteData.sponsored}
          //   setChanged={setChanged}
          category={category}
        />
      )}
    </div>
  );
};

export default AddWebsiteSteps;
