import React, { useEffect, useState, useRef } from "react";
import InfoIcon from "../../../assets/images/infoIcon.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import API from "../../../api/ClientApi";
import { CircularProgress, Button } from "@mui/material";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

const WebsiteSetup = (props) => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [long, setLong] = useState(false);
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);
  const [placement, setPlacement] = useState(false);
  const [creation, setCreation] = useState(false);
  const [article, setArticle] = useState(false);
  const [longA, setALong] = useState(false);
  const [mini, setMini] = useState(false);
  const [catError, setCatError] = useState(false);
  const [category, setCategory] = useState([]);
  const [errors, setErrors] = useState(false);
  const [service, setService] = useState([]);
  const [websiteData, setWebsiteData] = useState({
    product_category_id: 1,
    url: localStorage.getItem("url"),
    language: { name: "English", code: "English" },
    link_type: 1,
    disclosure_type: 0,
    service_type: { name: "Link insertion", code: 1 },
    number_of_links: { name: "No Limit", code: 0 },
    special_requirements: "",
    free_requirements: "",
    best_article_link: "",
    content_placement_price: 0,
    mini_post_price: 0,
    long_article: 0,
    article_price: 0,
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  // const category = [
  //   { name: "Bussiness", code: 1 },
  //   { name: "Traveling", code: 3 },
  //   { name: "Sports", code: 2 },
  // ];
  const languages = [
    { name: "English", code: "English" },
    { name: "Urdu", code: "Urdu" },
    { name: "Chinese", code: "Chinese" },
    { name: "French", code: "French" },
    { name: "German", code: "German" },
    { name: "Hindi", code: "Hindi" },
    { name: "Spanish", code: "Spanish" },
    { name: "Arabic", code: "Arabic" },
    { name: "Bulgarian", code: "Bulgarian" },
    { name: "Dutch", code: "Dutch" },
    { name: "Greek", code: "Greek" },
    { name: "Hrvatski", code: "Hrvatski" },
    { name: "Indonesian", code: "Indonesian" },
    { name: "Italian", code: "Italian" },
    { name: "Japanese", code: "Japanese" },
    { name: "Korean", code: "Korean" },
    { name: "Norwegian", code: "Norwegian" },
    { name: "Polish", code: "Polish" },
    { name: "Portuguese", code: "Portuguese" },
    { name: "Romanian", code: "Romanian" },
    { name: "Russian", code: "Russian" },
    { name: "Spanish", code: "Spanish" },
    { name: "Swedish", code: "Swedish" },
    { name: "Turkish", code: "Turkish" },
    { name: "Ukranian", code: "Ukranian" },
  ];

  const nType = [
    { name: "No Limit", code: 0 },
    { name: "1", code: 1 },
    { name: "2", code: 2 },
    { name: "3", code: 3 },
  ];

  // Footer temp. for multiselect
  const handleOkButtonClick = () => {
    // Close the multiselect dropdown
    if (multiSelectRef.current) {
      multiSelectRef.current.hide();
    }
  };

  const panelFooterTemplate = () => {
    return (
      <div className="mb-2 w-100 d-flex justify-content-center">
        <button
          className="active-offer-btn offer-accept-pub-btn"
          onClick={handleOkButtonClick}
        >
          OK
        </button>
      </div>
    );
  };

  const multiSelectRef = useRef(null);

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    // Update the mini_post value based on the checkbox state
    if (type === "checkbox") {
      if (name === "mini_post_price") {
        const newVal = checked ? "123.00" : "0";
        setWebsiteData((prevData) => ({
          ...prevData,
          [name]: newVal,
        }));
      }
      if (name === "article_price") {
        const newVal = checked ? "123.00" : "0";
        setWebsiteData((prevData) => ({
          ...prevData,
          [name]: newVal,
        }));
      }
      if (name === "content_placement_price") {
        const newVal = checked ? "123.00" : "0";
        setWebsiteData((prevData) => ({
          ...prevData,
          [name]: newVal,
        }));
      }
      if (name === "long_article") {
        setLong(!long);
        const newVal = checked ? "123.00" : "0";
        setWebsiteData((prevData) => ({
          ...prevData,
          [name]: newVal,
        }));
      }
      //   if (name === "sponsored_no") {
      //     setLong(!long);
      //     const newVal = checked ? "Yes" : "No";
      //     setWebsiteData((prevData) => ({
      //       ...prevData,
      //       ["sponsor"]: newVal,
      //     }));
      //   }
    } else if (type === "radio") {
      if (name === "disclosure_typeYes") {
        const newName = "disclosure_type";
        setWebsiteData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
      if (name === "disclosure_typeNo") {
        const newName = "disclosure_type";
        setWebsiteData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
      if (name === "linksYes") {
        const newName = "link_type";
        setWebsiteData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
      if (name === "linksNo") {
        const newName = "link_type";
        setWebsiteData((prevData) => ({
          ...prevData,
          [newName]: parseInt(value),
        }));
      }
    } else {
      if (name === "service_type") {
        setWebsiteData((prevData) => ({
          ...prevData,
          [name]: value?.code,
        }));
      }
      if (name === "number_of_links") {
        setWebsiteData((prevData) => ({
          ...prevData,
          [name]: value?.code,
        }));
      }
      if (name === "best_article_link") {
        setWebsiteData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
      setWebsiteData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedCategories.length === 0) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
      setCatError(true);
    }
    if (
      websiteData.content_placement_price === 0 &&
      websiteData.long_article === 0 &&
      websiteData.article_price === 0 &&
      websiteData.mini_post_price === 0
    ) {
      setError("Please choose at least one service type");
    } else {
      if (
        (placement === true && websiteData.content_placement_price <= 0) ||
        (longA === true && websiteData.long_article <= 0) ||
        (article === true && websiteData.article_price <= 0) ||
        (mini === true && websiteData.mini_post_price <= 0)
      ) {
        setError("Amount should be greater than 0");
      } else {
        if (selectedCategories?.length !== 0) {
          if (
            websiteData?.best_article_link !== "" &&
            !areAllUrlsValid(websiteData?.best_article_link)
          ) {
            setErrors(true);
          } else {
            if (
              websiteData.content_placement_price > 0 &&
              (websiteData.long_article > 0 ||
                websiteData.article_price > 0 ||
                websiteData.mini_post_price > 0)
            ) {
              if (
                (longA &&
                  parseInt(websiteData.content_placement_price) >
                    parseInt(websiteData.long_article)) ||
                (mini &&
                  parseInt(websiteData.content_placement_price) >
                    parseInt(websiteData.mini_post_price)) ||
                (article &&
                  parseInt(websiteData?.content_placement_price) >
                    parseInt(websiteData?.article_price))
              ) {
                setError(
                  "Placement price should not be greater than article, long-article, or mini-post price."
                );
              } else {
                setLoad(true);
                let modifiedData = {
                  ...websiteData,
                  language: websiteData?.language?.name,
                  service_type: websiteData?.service_type?.code,
                  number_of_links: websiteData?.number_of_links?.code,
                  best_article_link:
                    websiteData?.best_article_link?.split(/,|\n/),
                  product_category_id:
                    selectedCategories === null
                      ? [1]
                      : selectedCategories.map((category) => category.code),
                };

                const payload = {
                  ...modifiedData,
                  best_article_link: JSON.stringify(
                    modifiedData?.best_article_link
                  ),
                  product_category_id: `[${modifiedData.product_category_id.join(
                    ", "
                  )}]`,
                };
                try {
                  const config = {
                    headers: {
                      "Content-type": "multipart/form-data",
                    },
                  };
                  const data = await API.post(
                    "/api/publisher?user_type=2",
                    payload,
                    config
                  );
                  if (data) {
                    setLoad(false);
                    Swal.fire({
                      title: "Success!",
                      html: "Website saved successfully",
                      icon: "success",
                      timer: 1500,
                      showConfirmButton: false,
                      allowOutsideClick: false,
                    });
                    navigate("/dashboard/platform");
                  }
                } catch (error) {
                  setLoad(false);
                  Swal.fire({
                    title: "Error!",
                    html: error.response.data.message,
                    icon: "error",
                    timer: 1500,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                  });
                }
              }
            } else {
              setLoad(true);
              let modifiedData = {
                ...websiteData,
                language: websiteData?.language?.name,
                service_type: websiteData?.service_type?.code,
                number_of_links: websiteData?.number_of_links?.code,
                best_article_link:
                  websiteData?.best_article_link?.split(/,|\n/),
                product_category_id:
                  selectedCategories === null
                    ? [1]
                    : selectedCategories.map((category) => category.code),
              };

              const payload = {
                ...modifiedData,
                best_article_link: JSON.stringify(
                  modifiedData?.best_article_link
                ),
                product_category_id: `[${modifiedData.product_category_id.join(
                  ", "
                )}]`,
              };

              try {
                const config = {
                  headers: {
                    "Content-type": "multipart/form-data",
                  },
                };
                const data = await API.post(
                  "/api/publisher?user_type=2",
                  payload,
                  config
                );
                if (data) {
                  setLoad(false);
                  Swal.fire({
                    title: "Success!",
                    html: "Website saved successfully",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                  });
                  navigate("/dashboard/platform");
                }
              } catch (error) {
                setLoad(false);
                Swal.fire({
                  title: "Error!",
                  html: error.response.data.message,
                  icon: "error",
                  timer: 1500,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                });
              }
            }
          }
        }
      }
    }
  };

  // Reference Url Functionality
  const handleUrlChange = (e) => {
    setErrors(false);
    const inputValue = e.target.value;
    const urls = areAllUrlsValid(inputValue);
    setWebsiteData({ ...websiteData, best_article_link: inputValue });
  };

  const areAllUrlsValid = (text) => {
    const lines = text.split(/,|\n/);

    for (let line of lines) {
      const trimmedLine = line.trim();
      if (!isValidUrl(trimmedLine)) {
        return false; // If any URL is invalid, return false immediately
      }
    }

    return true; // If all URLs are valid, return true
  };

  const isValidUrl = (url) => {
    // You can use a regular expression to validate URLs.
    // This is a simple example; you can use a more comprehensive regex.
    const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return regex.test(url);
  };

  useEffect(() => {
    const fetchCategory = async () => {
      const data = await API.get("/api/get-product-category");
      const dataService = await API.get("/api/get-service-type?user_type=2");
      const modifiedArray = data?.data?.data?.productcategory?.map((item) => ({
        code: item.id,
        name: item.title,
      }));
      const modifiedService = dataService?.data?.data?.map((item) => ({
        code: item.id,
        name: item.title,
      }));
      setService(modifiedService);
      setCategory(modifiedArray);
    };
    fetchCategory();
  }, []);

  return (
    <div className="add-website-wraper">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-md-6">
          <div className="left-form-section-wrap">
            <div className="form-group">
              <label> URL * </label>
              <input
                type="url"
                name="url"
                value={websiteData?.url}
                onChange={handleChange}
                className="form-control"
                placeholder="https://www.yoursite.com"
                disabled
              />
            </div>
            <div className="form-group">
              <label> Language * </label>
              <Dropdown
                name="language"
                value={websiteData?.language}
                onChange={handleChange}
                options={languages}
                optionLabel="name"
                placeholder="Select a language"
                filter
                className="w-100 prime-mu-select"
              />
            </div>
            <div className="form-group">
              <label> Content has to be marked as sponsored * </label>
              <p>
                {" "}
                Disclosure is used to label sponsored content, e.g.: “Promoted
                by”, “Sponsored by”, “Presented by”, etc.{" "}
              </p>
              <div className="radio-button-wrap">
                <div className="form-check-inline form-after-prime">
                  <label className="customradio">
                    <span className="radiotextsty">Yes</span>
                    <input
                      type="radio"
                      name="disclosure_typeYes"
                      value={1}
                      checked={websiteData?.disclosure_type === 1}
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="customradio">
                    <span className="radiotextsty">No</span>
                    <input
                      type="radio"
                      name="disclosure_typeNo"
                      value={0}
                      checked={websiteData?.disclosure_type === 0}
                      onChange={handleChange}
                    />

                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="textarea-group">
              <label> Special requirements </label>
              <textarea
                className="form-control"
                rows="6"
                name="special_requirements"
                value={websiteData?.special_requirements}
                onChange={handleChange}
              ></textarea>
            </div>
            {/* <div className="control-group">
              <label className="control control--checkbox">
                Besides paid posts, I may consider placing buyer's article for
                free if it's high quality and meets all my requirements.
                <input type="checkbox" />
                <div className="control__indicator"></div>
              </label>
            </div> */}
            <div className="textarea-group">
              <label> Requirements for submitting free articles </label>
              <textarea
                className="form-control"
                rows="6"
                name="free_requirements"
                value={websiteData?.free_requirements}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="textarea-group">
              <label>
                {" "}
                Links to your best articles to show buyers examples of your work
                (the domain of works should match the site's URL) . Separate url
                by commas or separate them one line at a time.
              </label>
              <textarea
                type="text"
                rows="6"
                className={`form-control ${errors ? "ref-err-outline" : ""}`}
                placeholder="http(s)://"
                name="best_article_link"
                value={websiteData?.best_article_link}
                onChange={handleUrlChange}
              />
            </div>
            {errors && (
              <p style={{ color: "red" }}>
                Please make sure all urls are valid
              </p>
            )}
            <div className="info-group">
              {" "}
              <span>
                <img src={InfoIcon} alt="Info" />{" "}
              </span>{" "}
              <strong>
                If you provide examples of work, your offer will be ranked
                higher
              </strong>{" "}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="right-form-section-wrap">
            <div className="form-group" ref={formRef}>
              <label>
                {" "}
                Categories* ( You can select maximum 3 categories )
              </label>
              <MultiSelect
                value={selectedCategories}
                onChange={(e) => {
                  if (e.value.length <= 3) {
                    setCatError(false);
                    setSelectedCategories(e.value);
                  }
                }}
                options={category}
                optionLabel="name"
                filter
                placeholder="Select categories"
                className={`w-100 ${
                  !catError ? "prime-mu-select" : "err-outline-select"
                }`}
                panelFooterTemplate={panelFooterTemplate}
                ref={multiSelectRef}
              />
              {catError && (
                <p style={{ color: "red" }}>Please select a category</p>
              )}
            </div>
            <div className="form-group">
              <label> Links *</label>
              <div className="radio-button-wrap">
                <div className="form-check-inline form-after-prime">
                  <label className="customradio">
                    <span className="radiotextsty">NoFollow</span>
                    <input
                      type="radio"
                      name="linksYes"
                      value={1}
                      checked={websiteData?.link_type === 1}
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>{" "}
                  </label>
                  <label className="customradio">
                    <span className="radiotextsty">DoFollow</span>
                    <input
                      type="radio"
                      name="linksNo"
                      value={0}
                      checked={websiteData?.link_type === 0}
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>{" "}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label> Service type * </label>
              <p> What service type you want to choose? </p>
              <div className="form-group radio-button-right">
                <Dropdown
                  name="service_type"
                  value={websiteData?.service_type}
                  onChange={handleChange}
                  options={service}
                  optionLabel="name"
                  placeholder="Select a service"
                  className="w-100 prime-mu-select"
                />
              </div>
            </div>
            <div className="form-group">
              <label> Number of links * </label>
              <p> How many links can be placed in one article? </p>
              <div className="form-group radio-button-right">
                <Dropdown
                  name="number_of_links"
                  value={websiteData?.number_of_links}
                  onChange={handleChange}
                  options={nType}
                  optionLabel="name"
                  placeholder="Select a type"
                  className="w-100 prime-mu-select"
                />
              </div>
            </div>
            <div className="check-box-new-right">
              <ul>
                <li>
                  <div className="w-100 d-flex">
                    <div className="check-type">
                      <div className="control-group">
                        <label className="control control--checkbox">
                          <input
                            type="checkbox"
                            onChange={() => {
                              setPlacement(!placement);
                              if (placement) {
                                setWebsiteData((prevData) => ({
                                  ...prevData,
                                  content_placement_price: 0,
                                }));
                              }
                            }}
                            checked={placement}
                          />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                    </div>
                    <div className="w-100 d-flex text-price-check-wrap">
                      <div className="check-wrap-type">
                        <div className="text-check-wrap">
                          <h5> Content placement </h5>
                          <p>
                            {" "}
                            By choosing this service type you agree to place
                            buyer’s content on your website permanently.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="check-price">
                        <div className="form-group">
                          {" "}
                          <span> $</span>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0"
                            disabled={!placement}
                            name="content_placement_price"
                            // value={
                            //  websiteData?.content_placement_price
                            // }
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="w-100 d-flex">
                    <div className="check-type">
                      <div className="control-group">
                        <label className="control control--checkbox">
                          <input
                            type="checkbox"
                            onChange={() => {
                              if (creation === false) {
                                setCreation(true);
                                setMini(true);
                                setArticle(true);
                                setALong(true);
                              }
                              if (creation === true) {
                                setCreation(false);
                                setMini(false);
                                setArticle(false);
                                setALong(false);
                                setWebsiteData((prevData) => ({
                                  ...prevData,
                                  article_price: 0,
                                  mini_post_price: 0,
                                  long_article: 0,
                                }));
                              }
                            }}
                            checked={creation}
                          />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                    </div>
                    <div className="check-wrap-placementprice">
                      <div className="text-check-wrap">
                        <h5> Content creation and placement </h5>
                        <p>
                          {" "}
                          By choosing this service type you agree to place
                          buyer’s content on your website permanently.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="add-website-content-type-check">
                  <div className="w-100 d-flex">
                    <div className="check-type c-type-margin">
                      <div className="control-group">
                        <label className="control control--checkbox">
                          <input
                            type="checkbox"
                            onChange={() => {
                              setMini(!mini);
                              if (mini) {
                                setWebsiteData((prevData) => ({
                                  ...prevData,
                                  mini_post_price: 0,
                                }));
                              }
                            }}
                            checked={mini}
                          />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                    </div>
                    <div className="w-100 d-flex text-price-check-wrap">
                      <div className="check-wrap-type">
                        <div className="text-check-wrap">
                          <p>
                            {" "}
                            <strong>Mini-post</strong> up to 300 words, 1
                            hyperlink allowed{" "}
                          </p>
                        </div>
                      </div>
                      <div className="check-price">
                        <div className="form-group">
                          {" "}
                          <span> $</span>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0"
                            disabled={!mini}
                            name="mini_post_price"
                            // value={websiteData?.mini_post_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="add-website-content-type-check">
                  <div className="w-100 d-flex">
                    <div className="check-type c-type-margin">
                      <div className="control-group">
                        <label className="control control--checkbox">
                          <input
                            type="checkbox"
                            onChange={() => {
                              setArticle(!article);
                              if (article) {
                                setWebsiteData((prevData) => ({
                                  ...prevData,
                                  article_price: 0,
                                }));
                              }
                            }}
                            checked={article}
                          />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                    </div>
                    <div className="w-100 d-flex text-price-check-wrap">
                      <div className="check-wrap-type">
                        <div className="text-check-wrap">
                          <p>
                            {" "}
                            <strong>Article</strong> up to 300 words, 1
                            hyperlink allowed{" "}
                          </p>
                        </div>
                      </div>
                      <div className="check-price">
                        <div className="form-group">
                          {" "}
                          <span> $</span>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0"
                            name="article_price"
                            disabled={!article}
                            // value={websiteData?.article_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="add-website-content-type-check">
                  <div className="w-100 d-flex">
                    <div className="check-type c-type-margin">
                      <div className="control-group">
                        <label className="control control--checkbox">
                          <input
                            type="checkbox"
                            name="longA"
                            onChange={async () => {
                              setALong(!longA);
                              if (longA) {
                                setWebsiteData((prevData) => ({
                                  ...prevData,
                                  long_article: 0,
                                }));
                              }
                            }}
                            checked={longA}
                          />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                    </div>
                    <div className="w-100 d-flex text-price-check-wrap">
                      <div className="check-wrap-type">
                        <div className="text-check-wrap">
                          <p>
                            {" "}
                            <strong>Long-article</strong> up to 300 words, 1
                            hyperlink allowed{" "}
                          </p>
                        </div>
                      </div>
                      <div className="check-price">
                        <div className="form-group">
                          {" "}
                          <span> $</span>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0"
                            name="long_article"
                            disabled={!longA}
                            // value={websiteData?.long_article}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <p style={{ color: "red" }}>{error ? error : ""}</p>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="buttons-add-website-wrap">
            <Button
              className="update-button"
              type="submit"
              style={{ backgroundColor: "#4880f0" }}
            >
              {load ? <CircularProgress className="text-white" /> : "ADD"}
            </Button>{" "}
          </div>
        </div>
      </form>
    </div>
  );
};

export default WebsiteSetup;
