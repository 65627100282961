import React from "react";
import Error from "../../../assets/images/error-icon.svg"
import "../styles/createOpenOffer.css";

const CreateOpenOffer = () => {
  return (
    <div className="buyer-form-main-wraper">
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-12">
            <h2> Create An Open Offer </h2>
            <p>
              {" "}
              Pellentesque at dolor consequat, dictum nisl ut, pellentesque
              nisl. Etiam a neque sed ante scelerisque pellentesque. Nulla enim
              leo, lobortis eget suscipit in, iaculis eu metus. Aliquam egestas
              ultricies est facilisis congue.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="info-tooltip-wrap">
        {" "}
        <span>
          {" "}
          <img src={Error} alt="error" />{" "}
        </span>{" "}
        <strong>
          {" "}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce magna
          orci, accumsan ac imperdiet pulvinar, pretium in justo. Praesent
          maximus enim dui,{" "}
        </strong>
        <div className="clearfix"></div>
      </div>
      <div className="create-offer-form-wrap">
        <div className="row">
          <div className="col-md-6">
            <div className="create-form-left">
              <div className="form-field-group">
                <div className="form-field-label">
                  {" "}
                  <span> Make offer available to</span>
                  <div className="detail-field-label">
                    {" "}
                    Create an open offer for all publishers or specify the
                    important criteria using the advanced settings.{" "}
                  </div>
                </div>
                <div className="form-check-inline">
                  <label className="customradio">
                    <span className="radiotextsty">All publishers</span>
                    <input type="radio" checked="checked" name="radio" />
                    <span className="checkmark"></span>{" "}
                  </label>
                  <label className="customradio">
                    <span className="radiotextsty">Specified publishers</span>
                    <input type="radio" name="radio" />
                    <span className="checkmark"></span>{" "}
                  </label>
                </div>
              </div>
              <div className="form-field-group">
                <div className="form-field-label">
                  {" "}
                  <span> Make offer available to</span>
                  <div className="detail-field-label">
                    {" "}
                    Create an open offer for all publishers or specify the
                    important criteria using the advanced settings.{" "}
                  </div>
                </div>
                <div className="form-check-inline">
                  <label className="customradio">
                    <span className="radiotextsty">All publishers</span>
                    <input type="radio" checked="checked" name="radio" />
                    <span className="checkmark"></span>{" "}
                  </label>
                  <label className="customradio">
                    <span className="radiotextsty">Specified publishers</span>
                    <input type="radio" name="radio" />
                    <span className="checkmark"></span>{" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOpenOffer;
