import React, { useState } from "react";
import API from "../../../api/ClientApi";
import DeleteIcon from "../../../assets/images/ssss.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../../Dashboard/styles/deldecModal.css";
import Swal from "sweetalert2";
import { userBalance } from "../../../actions/walletActions";

const TaskAcknowledgeModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);

  const handleAccept = async (e) => {
    e.preventDefault();
    setLoad(true);
    const modifiedData = {
      user_type: 1,
      acknowledgement: 1,
    };
    await API.post(`/api/completed-order-by-buyer/${props.id}`, modifiedData)
      .then(async (response) => {
        setLoad(false);
        // Handle success or failure
        // Close the confirmation modal
        await props.handleAcknowledgeClose();
        Swal.fire({
          title: "Success!",
          html: "Task completed successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
        await props.getTask();
        await dispatch(userBalance(localStorage.getItem("role")));
        // navigate("/dashboard/platform")

        // window.location.reload();
      })
      .catch((error) => {
        setLoad(false);
        // Handle error
      });
  };

  return (
    <Modal
      open={props.acknowledgeModal}
      onClose={props.c}
      aria-labelledby="Complete Task"
    >
      <Box className="modal-container">
        <FontAwesomeIcon
          icon={faCircleCheck}
          size="xl"
          className="font-style"
        />
        <Typography variant="h6" id="modal-title" gutterBottom>
          Complete Task
        </Typography>
        <Typography className="detail-modal-text">
          Do you want to mark this task as complete?
        </Typography>

        <hr />
        <div className="buttons-container">
          {" "}
          {/* Added a container for the buttons */}
          <Button
            onClick={props.handleAcknowledgeClose}
            className="cancel-btn-modal"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="delete-button"
            onClick={handleAccept}
            disabled={load} // Disable the button when loading
          >
            {load ? (
              <CircularProgress className="text-white circle-delete" />
            ) : (
              "Complete"
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default TaskAcknowledgeModal;
