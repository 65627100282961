import React from "react";
import { CircularProgress } from "@material-ui/core";
import "./fullLoader.css";

const FullLoader = () => {
  return (
    <div className="d-flex w-100 justify-content-center align-items-center full-loader">
      <CircularProgress />
    </div>
  );
};

export default FullLoader;
