import API from "../api/ClientApi";
import {
  NOTIFICATION_FAIL,
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_COUNT_REQUEST,
  NOTIFICATION_COUNT_SUCCESS,
  NOTIFICATION_COUNT_FAIL,
  NOTIFICATION_UNREAD_COUNT_FAIL,
  NOTIFICATION_UNREAD_COUNT_REQUEST,
  NOTIFICATION_UNREAD_COUNT_SUCCESS,
  NOTIFICATION_CHECK_FAIL,
  NOTIFICATION_CHECK_SUCCESS,
  NOTIFICATION_CHECK_REQUEST,
} from "../constants/NotificationConstants";

// Get notification
export const userNotifications = (read) => async (dispatch) => {
  try {
    dispatch({ type: NOTIFICATION_REQUEST });

    const { data } = await API.get(`/api/get-notifications?read=${read}`);
    dispatch({ type: NOTIFICATION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_FAIL,
      payload: error,
    });
  }
};

// Get count
export const userCountNotifications = () => async (dispatch) => {
  try {
    dispatch({ type: NOTIFICATION_COUNT_REQUEST });

    const { data } = await API.get(
      `/api/get-notifications?read=0&notification_type=Order`
    );
    dispatch({ type: NOTIFICATION_COUNT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_COUNT_FAIL,
      payload: error,
    });
  }
};

// Get unread count
export const userUnreadCountNotifications = () => async (dispatch) => {
  try {
    dispatch({ type: NOTIFICATION_UNREAD_COUNT_REQUEST });

    const { data } = await API.get(
      `/api/get-notifications?read=0&notification_type=Order&user_type=${
        localStorage.getItem("role") === "buyer" ? 1 : 2
      }`
    );
    dispatch({ type: NOTIFICATION_UNREAD_COUNT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_UNREAD_COUNT_FAIL,
      payload: error,
    });
  }
};

// Get notificationcheck
export const userCheckNotifications = () => async (dispatch) => {
  try {
    dispatch({ type: NOTIFICATION_CHECK_REQUEST });

    const { data } = await API.get("/api/get-notification-status");
    dispatch({ type: NOTIFICATION_CHECK_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_CHECK_FAIL,
      payload: error,
    });
  }
};
