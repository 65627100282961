import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MyPlatforms from "./components/MyPlatforms";

const MyWebsites = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("role") === "buyer" || props?.publish===0) {
      navigate("/dashboard/website-list");
    }
  }, [localStorage.getItem("role")]);

  return (
    <div className="w-100">
      <MyPlatforms />
    </div>
  );
};

export default MyWebsites;
