import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";

import NotificationsMain from "./components/NotificationsMain";

const NotificationsBuyer = () => {
  const navigate = useNavigate();
  const items = [{ label: "Notifications" }];
  const home = { label: "Home", url: "/dashboard/website-list" };

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
  }, [localStorage.getItem("role")]);
  return (
    <>
      <BreadCrumb model={items} home={home} />
      <NotificationsMain />
    </>
  );
};

export default NotificationsBuyer;
