import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import NotificationsMain from "./components/NotificationsMain";

const NotificationsPublisher = (props) => {
  const navigate = useNavigate();
  const items = [{ label: "Notifications" }];
  const home = { label: "Home", url: "/dashboard/platform" };
  useEffect(() => {
    if (localStorage.getItem("role") === "buyer" || props.publish === 0) {
      navigate("/dashboard/website-list");
    }
  }, [localStorage.getItem("role")]);
  return (
    <>
      <BreadCrumb model={items} home={home} />
      <NotificationsMain />
    </>
  );
};

export default NotificationsPublisher;
