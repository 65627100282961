import axios from "axios";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  USER_ROLE_SELECT_SUCCESS,
  USER_ROLE_SELECT_REQUEST,
  USER_ROLE_SELECT_FAIL,
  USER_PHONE_SELECT_SUCCESS,
  USER_PHONE_SELECT_REQUEST,
  USER_PHONE_SELECT_FAIL,
  USER_OTP_SELECT_SUCCESS,
  USER_OTP_SELECT_REQUEST,
  USER_OTP_SELECT_FAIL,
  LOGOUT_USER_FAIL,
  LOGOUT_USER_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAIL,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_REQUEST,
  CLEAR_ERRORS,
  LOAD_USER_PROFILE_FAIL,
  LOAD_USER_PROFILE_REQUEST,
  LOAD_USER_PROFILE_SUCCESS,
} from "../constants/userConstants";
import API from "../api/ClientApi";
import Swal from "sweetalert2";
import { userBalance } from "./walletActions";
import {
  userCheckNotifications,
  userCountNotifications,
  userNotifications,
  userUnreadCountNotifications,
} from "./notificationActions";
import { userModeration } from "./moderationActions";

// Login action
export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/api/login`,
      { email, password },
      config
    );
    localStorage.setItem("access_token", data.token);
    dispatch({ type: LOGIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error,
    });
  }
};

// Register action
export const register = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const config = {
      headers: {
        "Content-type": "multipart/form-data", // Since we now submiting images as well
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/api/register`,
      userData,
      config
    );
    localStorage.setItem("access_token", data?.data?.token);
    dispatch({ type: REGISTER_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error,
    });
  }
};

// User profile
export const userProfile = (role) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });
    const { data } = await API.get("/api/profile");
    dispatch({ type: LOAD_USER_SUCCESS, payload: data?.data?.getUserProfile });
  } catch (error) {
    dispatch({
      type: LOAD_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// User profile PAGE
export const userProfilePage = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_PROFILE_REQUEST });
    const { data } = await API.get("/api/profile");
    dispatch({
      type: LOAD_USER_PROFILE_SUCCESS,
      payload: data?.data?.getUserProfile,
    });
  } catch (error) {
    dispatch({
      type: LOAD_USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Third part registeration
export const registerThirdParty = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const config = {
      headers: {
        "Content-type": "multipart/form-data", // Since we now submiting images as well
      },
    };

    const { data } = await API.post("/api/register-3rdpary", userData, config);
    localStorage.setItem("access_token", data?.token);
    dispatch({ type: REGISTER_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error,
    });
  }
};

// Third part registeration for profile
export const registerSocialMedia = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const config = {
      headers: {
        "Content-type": "multipart/form-data", // Since we now submiting images as well
      },
    };

    const { data } = await API.post("/api/register-3rdpary", userData, config);
    dispatch({ type: REGISTER_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error,
    });
  }
};

// Register action
export const verifyUserEmail = (id) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_USER_REQUEST });

    const { data } = await API.get(`/api/verify-email/${id}`);
    dispatch({ type: VERIFY_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VERIFY_USER_FAIL,
      payload: error,
    });
  }
};

// Select Role
export const selectRole = (userData) => async (dispatch) => {
  try {
    dispatch({ type: USER_ROLE_SELECT_REQUEST });

    const config = {
      headers: {
        "Content-type": "multipart/form-data", // Since we now submiting images as well
      },
    };

    const { data } = await API.post("/api/select-role", userData, config);
    dispatch({ type: USER_ROLE_SELECT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_ROLE_SELECT_FAIL,
      payload: error,
    });
  }
};

// Phone number registeration
export const selectPhoneNumber = (userData) => async (dispatch) => {
  try {
    dispatch({ type: USER_PHONE_SELECT_REQUEST });

    const config = {
      headers: {
        "Content-type": "multipart/form-data", // Since we now submiting images as well
      },
    };

    const { data } = await API.post("/api/phone-verify", userData, config);
    dispatch({ type: USER_PHONE_SELECT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_PHONE_SELECT_FAIL,
      payload: error,
    });
  }
};

// Phone number registeration
export const otpVerification = (userData) => async (dispatch) => {
  const accessToken = localStorage.getItem("access_token");
  try {
    dispatch({ type: USER_OTP_SELECT_REQUEST });
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`, // Since we now submiting images as well
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/api/verify-otp`,
      userData,
      config
    );
    dispatch({ type: USER_OTP_SELECT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_OTP_SELECT_FAIL,
      payload: error,
    });
  }
};

// Update user action
export const updateUser = (userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_PROFILE_REQUEST });

    const config = {
      headers: {
        "Content-type": "multipart/form-data", // Since we now submiting images as well
      },
    };

    const { data } = await API.post("/api/profile", userData, config);

    dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: data.success });
    return data;
  } catch (error) {
    dispatch({
      type: UPDATE_USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update user password action
export const updatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.put(
      "/api/v1/password/update",
      passwords, // Old and new password
      config
    );

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Forgot user password action
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post("/api/v1/password/forgot", email, config);

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.message });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Reset user password action
export const resetPassword = (token, passwords) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PASSWORD_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.put(
      `/api/v1/password/reset/${token}`,
      passwords,
      config
    );

    dispatch({ type: NEW_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: NEW_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Logout user action
export const logoutUser = () => async (dispatch) => {
  try {
    await axios.get("/api/v1/logout");

    dispatch({ type: LOGOUT_USER_SUCCESS });
  } catch (error) {
    dispatch({
      type: LOGOUT_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

//Clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
