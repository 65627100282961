import React, { useState } from "react";
import API from "../../../api/ClientApi";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextareaAutosize, // Import TextareaAutosize
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../../Dashboard/styles/deldecModal.css";
import "../../Tasks/style/taskReview.css";
import Swal from "sweetalert2";
import { Rating } from "primereact/rating";
import Filter from "bad-words"; // Import "bad-words"

const InviteModal = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [comments, setComments] = useState("");
  const [cError, setCError] = useState(false);
  const [rating, setRating] = useState(0);

  // Create a "Filter" instance from "bad-words"
  const filter = new Filter();

  // Test Email validation
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    if (comments !== "" && comments !== null && isValidEmail(comments)) {
      setLoad(true);

      const modifiedData = {
        email: comments,
      };

      await API.post(`/api/invitation-email`, modifiedData)
        .then(async (response) => {
          setLoad(false);
          // Handle success or failure
          // Close the confirmation modal
          setComments("");
          await props.handleInviteClose();
          Swal.fire({
            title: "Success!",
            html: "Invitation sent successfully",
            icon: "success",
            timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
            showConfirmButton: false, // Hide the "OK" button
            allowOutsideClick: false, // Prevent closing by clicking outside
          });
        })
        .catch((error) => {
          setLoad(false);
          // Handle error
        });
    } else {
      setCError(true);
    }
  };

  return (
    <Modal
      open={props.inviteModal}
      onClose={props.handleInviteClose}
      aria-labelledby="Accept Task"
    >
      <Box className="modal-container modal-feedback-box">
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          {/* <div className="w-100 d-flex justify-content-center review-star-container">
            <Rating
              value={rating}
              onChange={(e) => setRating(e.value)}
              cancel={false}
              className="custom-rating"
            />
          </div> */}
          <div className="w-100 d-flex justify-content-center review-star-container">
            <Typography variant="h6" id="modal-title" gutterBottom>
              Invite User
            </Typography>
            {/* Add a Textarea with label "Your Feedback" */}
          </div>
          <div className="d-flex flex-column justify-content-center feedback-textarea">
            {/* <label>Email</label> */}
            <input
              //   rows={6}
              type="email"
              value={comments}
              className={`review-area ${cError ? "feedback-red" : ""}`}
              onChange={(e) => {
                setCError(false);
                setComments(e.target.value);
              }}
              placeholder="Enter Email"
            />
            {cError && (
              <p style={{ color: "red" }}>Please enter a valid email</p>
            )}
          </div>
        </div>
        <div className="buttons-container">
          <Button
            onClick={props.handleInviteClose}
            className="cancel-btn-modal"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="delete-button"
            onClick={handleInvite}
            disabled={load}
          >
            {load ? (
              <CircularProgress className="text-white circle-delete" />
            ) : (
              "Invite"
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default InviteModal;
