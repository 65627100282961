import React from "react";
import { Avatar } from "primereact/avatar";

const ReviewContainer = (props) => {
  // Times ago
  function getTimeAgo(created_at) {
    const currentDate = new Date();
    const createdAtDate = new Date(created_at);
    const timeDifference = currentDate - createdAtDate;

    // Convert milliseconds to seconds
    const secondsAgo = Math.floor(timeDifference / 1000);

    if (secondsAgo < 60) {
      return "just now";
    } else if (secondsAgo < 3600) {
      const minutesAgo = Math.floor(secondsAgo / 60);
      return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
    } else if (secondsAgo < 86400) {
      const hoursAgo = Math.floor(secondsAgo / 3600);
      return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
    } else if (secondsAgo < 2592000) {
      const daysAgo = Math.floor(secondsAgo / 86400);
      return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
    } else if (secondsAgo < 31536000) {
      const monthsAgo = Math.floor(secondsAgo / 2592000);
      return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;
    } else {
      const yearsAgo = Math.floor(secondsAgo / 31536000);
      return `${yearsAgo} year${yearsAgo > 1 ? "s" : ""} ago`;
    }
  }

  return (
    <div className="review-main-container">
      <div className="review-main-wrapper">
        <div className="ava-rev-wrapper">
          <Avatar
            icon="pi pi-user"
            className="mr-2 avatar-pub-profile"
            size="xlarge"
            shape="circle"
          />
          <div className="buyer-review-info">
            <div className="d-flex justify-content-between buyer-review-date">
              <h4>{props?.user?.buyer?.wl_users_header?.name}</h4>
              <p>{getTimeAgo(props?.user?.created_at)}</p>
            </div>
            <p>{props?.user?.comments} </p>
          </div>
        </div>
        {/* <div className="buyer-review-date">
          <p>15 hours ago</p>
        </div> */}
      </div>
    </div>
  );
};

export default ReviewContainer;
