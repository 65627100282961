import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  CircularProgress,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from "@mui/material";
import InfoIcon from "../../../assets/images/info-icon-new.png";
import RemoveIcon from "@mui/icons-material/Remove";
import { BreadCrumb } from "primereact/breadcrumb";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import "../styles/buyerForm.css";
import API from "../../../api/ClientApi";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import validUrl from "valid-url";
import { userBalance } from "../../../actions/walletActions";
import AddFundsModal from "../../../components/AddFundsModal/AddFundsModal";
import ConsultationModal from "../../../components/ConsultationModal/ConsultationModal";

const DATrafficForm = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const form2Ref = useRef(null);
  const form4Ref = useRef(null);
  const { user } = useSelector((state) => state.profile);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [cError, setCError] = useState(false);
  const [uError, setUError] = useState(false);
  const [kError, setKError] = useState(false);
  const [packageCount, setPackageCount] = useState(0);
  const [websiteData, setWebsiteData] = useState(null);
  const [wload, setWload] = useState(false);
  const [wordActive, setWordActive] = useState(1);
  const [load, setLoad] = useState(false);
  const [active, setActive] = useState(0);
  const [serviceActive, setServiceActive] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [publisherActive, setPublisherActive] = useState(0);
  const [steps, setSteps] = useState([]);
  const [trafficSteps, setTrafficSteps] = useState([]);
  const [postAmount, setPostAmount] = useState(0);
  const [wordAmount, setWordAmount] = useState(0);
  const [daAmount, setDaAmount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [trafficAmount, setTrafficAmount] = useState(0);
  const [invalidUrlIndexes, setInvalidUrlIndexes] = useState([]);
  const [words, setWords] = useState([]);
  const [wordId, setWordId] = useState(false);
  const items = [{ label: "Packages based on DA / traffic" }];
  const home = { label: "Home", url: "/dashboard/website-list" };
  const [buyData, setBuyData] = useState({
    product_id: id,
    url: [""],
    anchor_text: [""],
    special_requirements: "",
    total_amount: 0,
    category_id: 0,
    suggestion: "",
    keywords: "",
    number_of_posts: { name: "5", code: "5" },
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [noOfPosts, setPosts] = useState([]);
  const category = [
    { name: "Bussiness", code: 1 },
    { name: "Traveling", code: 3 },
    { name: "Sports", code: 2 },
  ];

  // Footer temp. for multiselect
  const handleOkButtonClick = () => {
    // Close the multiselect dropdown
    if (multiSelectRef.current) {
      multiSelectRef.current.hide();
    }
  };

  const panelFooterTemplate = () => {
    return (
      <div className="mb-2 w-100 d-flex justify-content-center">
        <button
          className="active-offer-btn offer-accept-pub-btn"
          onClick={handleOkButtonClick}
        >
          OK
        </button>
      </div>
    );
  };

  const multiSelectRef = useRef(null);

  const fresh = () => {
    setBuyData((prevData) => ({
      ...prevData,
      url: [""],
      anchor_text: [""],
      special_requirements: "",
      total_amount: 0,
      category_id: [1],
      suggestion: "",
      keywords: "",
      number_of_posts: { name: "5", code: "5" },
    }));
  };
  const isMobile = useMediaQuery("(max-width: 767px)");

  const [activeStep, setActiveStep] = useState(1); // Initialize as -1
  const [daValue, setDaValue] = useState(1);
  const handleStepClick = async (value, amount) => {
    if (value === activeStep) {
      return; // Don't change the active step if it's already selected
    }
    setActiveStep(value === activeStep ? -1 : value);
    setDaValue(value);
    if (serviceActive === 0) {
      setDaAmount(amount);
    } else {
      setTrafficAmount(amount);
    }
  };

  //-----------------------------------------

  // Url
  const addUrlInput = () => {
    if (buyData.url.length < 3) {
      setBuyData((prevData) => ({
        ...prevData,
        url: [...prevData.url, ""],
      }));
    }
  };

  // Remove url
  const removeUrlInput = (index) => {
    setBuyData((prevData) => ({
      ...prevData,
      url: prevData.url.filter((_, i) => i !== index),
    }));
  };

  const handleUrlChange = (e, index) => {
    setUError(false);
    const newUrls = [...buyData.url];
    newUrls[index] = e.target.value;
    // Check if the index is in the invalidUrlIndexes array and remove it
    if (invalidUrlIndexes.includes(index)) {
      const updatedInvalidUrlIndexes = invalidUrlIndexes.filter(
        (i) => i !== index
      );
      setInvalidUrlIndexes(updatedInvalidUrlIndexes);
    }
    setBuyData((prevData) => ({
      ...prevData,
      url: newUrls,
    }));
  };
  //-------------------------------------------------

  // Anchor text
  const addAnchorInput = () => {
    if (buyData.anchor_text.length < 3) {
      setBuyData((prevData) => ({
        ...prevData,
        anchor_text: [...prevData.anchor_text, ""],
      }));
    }
  };

  const isCatMobile = useMediaQuery("(max-width: 1000px)");

  // Remove anchor
  const removeAnchorInput = (index) => {
    setBuyData((prevData) => ({
      ...prevData,
      anchor_text: prevData.anchor_text.filter((_, i) => i !== index),
    }));
  };

  const handleAnchorChange = (e, index) => {
    const newAnchors = [...buyData.anchor_text];
    newAnchors[index] = e.target.value;
    setBuyData((prevData) => ({
      ...prevData,
      anchor_text: newAnchors,
    }));
  };

  //-------------------------------------------------------

  // Returning values based on amount
  const findNumberOfPostsByAmount = (amountToFind, noOfPosts) => {
    for (const item of noOfPosts) {
      if (item.amount === amountToFind) {
        return item.id;
      }
    }
    // If no match is found, return a default value or handle the case as needed
    // return null;
  };

  //-------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedCategories?.length === 0) {
      form4Ref.current.scrollIntoView({ behavior: "smooth" });
      setCError(true);
    }

    if (buyData?.keywords === "") {
      formRef.current.scrollIntoView({ behavior: "smooth" });
      setKError(true);
    }

    if (
      buyData?.url?.some((url, index) => {
        if (url === "" || !isValidUrl(url)) {
          if (index > 0 && url === "") {
            return false;
          } else {
            form2Ref.current.scrollIntoView({ behavior: "smooth" });
            setInvalidUrlIndexes((prevIndexes) => [...prevIndexes, index]); // Add the index to the array
          }
        }
      })
    ) {
      form2Ref.current.scrollIntoView({ behavior: "smooth" });
      setUError(true);
    }

    // Proceed with submission only if validation conditions are met
    if (
      buyData?.keywords !== "" &&
      selectedCategories?.length !== 0 &&
      !buyData?.url?.every((url) => url === "") &&
      buyData?.url[0] !== "" &&
      buyData?.url?.every((url) => isValidUrl(url))
    ) {
      try {
        setLoad(true);
        const config = {
          headers: {
            "Content-type": "multipart/form-data", // Since we now submit images as well
          },
        };

        const modifiedData = {
          ...buyData,
          website_priorities: serviceActive === 0 ? "DA 40" : "Traffic",
          anchor_text: JSON.stringify(buyData.anchor_text),
          url: JSON.stringify(buyData.url),
          order_type: "custom",
          content_creator_type:
            publisherActive === 0 ? "publisher" : "webylinks",
          content_type: 0,
          words: wordId,
          total_amount: setPrice(),
          da: serviceActive === 0 ? daValue : null,
          traffic: serviceActive === 1 ? daValue : null,
          number_of_posts: findNumberOfPostsByAmount(
            buyData?.number_of_posts,
            noOfPosts
          ),
          category_id:
            selectedCategories === null
              ? [1]
              : selectedCategories.map((category) => category.code),
        };

        const payload = {
          ...modifiedData,
          category_id: `[${modifiedData.category_id.join(", ")}]`,
        };

        // Make the POST request using Axios
        const response = await API.post(
          `${process.env.REACT_APP_BACKEND_API}/api/customize-task-order?user_type=1`,
          payload,
          config
        );
        fresh();
        // Handle the response if needed
        Swal.fire({
          title: "Success!",
          html: "Order submitted successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
        navigate("/dashboard/buyer/tasks");
        dispatch(userBalance(localStorage.getItem("role")));
      } catch (error) {
        // Handle errors
        Swal.fire({
          title: "Add Funds",
          html: `
            <p style="font-size: 13px;">You have insufficient balance to perform this action, please add funds</p>
          `,
          confirmButtonText: "Add Funds",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          customClass: {
            title: "modal-title",
            content: "modal-content",
            confirmButton: "modal-confirm-button",
            cancelButton: "modal-cancel-button-swal",
            actions: "modal-actions",
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            openModal();
            // if (localStorage.getItem("role") === "buyer") {
            //   navigate("/dashboard/balance"); // Navigate to the add funds page
            // } else {
            //   navigate("/dashboard/publisher/balance");
            // }
          }
        });
      } finally {
        setLoad(false);
      }
    }
  };

  const handleChange = async (e) => {
    setKError("");
    e.preventDefault();

    if (e.target.name === "number_of_posts") {
      setPostAmount(e.target.value);
      setBuyData((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value,
      }));
    } else {
      setBuyData((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleService = (id) => {
    setServiceActive(id);
    if (id === 0) {
      setTrafficAmount(0);
      setDaAmount(steps[0]?.amount);
    }
    if (id === 1) {
      setDaAmount(0);
      setTrafficAmount(trafficSteps[0]?.amount);
    }
    setActiveStep(1);
    setDaValue(1);
  };

  const handleWords = (id, amount) => {
    setWordActive(id);
    setWordId(id);
    setWordAmount(amount);
  };

  const setPrice = () => {
    return postAmount + wordAmount + daAmount + trafficAmount;
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "publisher") {
      navigate("/dashboard/platform");
    }
    const fetchPackages = async () => {
      setWload(true);
      try {
        const response = await API.get("/api/custom-order-field-count");
        const responseP = await API.get("/api/get-packages-count?user_type=1");
        setPosts(response?.data?.custom_package_post);
        setSteps(response?.data?.custom_package_da);
        setTrafficSteps(response?.data?.custom_package_traffic);
        setWords(response?.data?.custom_package_word_count);
        setWordId(response?.data?.custom_package_word_count[0]?.id);
        setBuyData({
          ...buyData,
          number_of_posts: response?.data?.custom_package_post[0]?.amount,
        });
        setPostAmount(response?.data?.custom_package_post[0]?.amount);
        setDaAmount(response?.data?.custom_package_da[0]?.amount);
        // setTrafficAmount(response?.data?.custom_package_traffic[0]?.amount);
        setWordAmount(response?.data?.custom_package_word_count[0]?.amount);
        setPackageCount(responseP?.data?.packages_count);
      } catch (error) {
        return error;
      } finally {
        setWload(false);
      }
    };

    // fetchData();
    fetchPackages();
  }, [id, localStorage.getItem("role")]);

  const isValidUrl = (url) => {
    // Regular expression to match a URL pattern with "https://"
    if (url === "") {
      return true;
    } else {
      return validUrl.isWebUri(url);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="buyer-form-main-wraper">
      <BreadCrumb model={items} home={home} />
      <div className="heading-info-wrap">
        <div className="row">
          <div className="col-md-7">
            <h2> Based on DA / traffic</h2>
          </div>
        </div>
      </div>
      <Accordion className="accordion-filter accordion-global">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-summery"
        >
          <div className="w-100 d-flex justify-content-between filter-header">
            <h2>HOW DO PACKAGES WORK?</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          <div className="accordion-row-col">
            Looking for high quality backlinks and want to avoid wasting time on
            searching and interacting with publishers.Then these packages
            ared-flex flex-column align-items-start specifically designed to fit
            you need.Following results will be given to you with traffic/ DA
            distribution packages
          </div>
          <div className="accordion-row">
            <div className="d-flex flex-column align-items-start accordion-row-col">
              {" "}
              <h6 className="order-article-faq-q ">
                Sites of relevance with superior metrics/statistics
              </h6>
              We will carefully list the guest post sites to ensure that they
              will align with all your requirements.
            </div>
            <div className="d-flex flex-column align-items-start accordion-row-col">
              <h6 className="order-article-faq-q ">Top Quality Publishers</h6>
              Your assigned manager will pick publishers based on highest
              parameters high completion rate, TAT etc
            </div>
            <div className="d-flex flex-column align-items-start accordion-row-col">
              {" "}
              <h6 className="order-article-faq-q ">Policy for Refund</h6>
              If the quality of provided service will be disliked by you than we
              will consider a refund according to terms and conditions.
            </div>
            <div className="d-flex flex-column align-items-start accordion-row-col">
              {" "}
              <h6 className="order-article-faq-q ">Delivery Time</h6>
              Due to our robust partnerships with publishers, we will deliver
              this service within 30 business days.
            </div>
          </div>
          <h6 className="faq-qa-da">
            What is the procedure for placing an order?
          </h6>
          <div className="accordion-row">
            <div className="accordion-row-col">
              <strong className="accordion-col-strong">1.</strong>
              Select the package according to your requirements DA priority and
              set metrics.
            </div>
            <div className="accordion-row-col">
              <strong className="accordion-col-strong">2.</strong>
              Briefly review your credentials and select CHECKOUT button to
              place your order.
            </div>
            <div className="accordion-row-col">
              <strong className="accordion-col-strong">3.</strong>A letter will
              be received to you that will confirm your order placement.
            </div>
            <div className="accordion-row-col">
              <strong className="accordion-col-strong">4.</strong>
              Your manager will reach out for you with one or two working days
              to clarify all your order details.
            </div>
            <div className="accordion-row-col">
              <strong className="accordion-col-strong">5.</strong>
              Based on your specified criteria, manager will choose the sites
              and a list will be sent, asking your approval.
            </div>
            <div className="accordion-row-col">
              <strong className="accordion-col-strong">6.</strong>
              Once everything is finalized, we will proceed to publish the guest
              posts containing your link.
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="cta-add-website-wrap">
        <div className="row">
          <div className="col-md-9 question-text">
            <h2> Any questions you'd like to ask? us! </h2>
          </div>
          <div className="col-md-3 d-flex align-items-center justify-content-end banner-order-container">
            <Button
              onClick={() => setVisible(true)}
              className="website-add-banner-btn order-banner-btn consultation-btn"
            >
              Get a Consultation
            </Button>
          </div>
        </div>
      </div>
      <div className="w-100 mb-4">
        <div className="w-100 d-flex align-items-center package-count-wrapper">
          <h1>{packageCount}</h1>
          <p className="mx-2">packages available</p>
        </div>
        <div className="w-100">
          <p>
            Our managers carefully curate each site and publisher on your
            behalf. To ensure top-tier service quality, we maintain a limited
            number of available packages.
          </p>
        </div>
      </div>
      {wload ? (
        <div className="d-flex w-100 justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="buyer-form-wraper">
          <h4> * Fields are required </h4>
          <form>
            <div className="single-word-group da-opt">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex align-items-center single-select-word">
                    <label className="d-flex align-items-center">
                      Select you options{" "}
                      <Tooltip
                        title="Set your desired length for the article. Keep in mind
            that it will directly impact the final price"
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>

                    <ul className="word-count-list wc-list">
                      <li
                        onClick={() => handleService(0)}
                        className={`word-select ${
                          serviceActive === 0 ? "service-type-s1" : ""
                        }`}
                      >
                        {" "}
                        DA priority{" "}
                      </li>

                      <li
                        onClick={() => handleService(1)}
                        className={`word-select ${
                          serviceActive === 1 ? "service-type-s2" : ""
                        }`}
                      >
                        {" "}
                        Traffic priority{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-word-group da-opt">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex align-items-center single-select-word">
                    <label className="d-flex align-items-center">
                      {" "}
                      Word Count*{" "}
                      <Tooltip
                        title="Set your desired length for the article. Keep in mind
                        that it will directly impact the final price"
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <ul className="word-count-list wc-list">
                      {words?.map((res, id) => (
                        <li
                          key={id}
                          className={`word-select ${
                            wordActive === res?.id
                              ? "single-select-active-0"
                              : ""
                          }`}
                          onClick={() => handleWords(res?.id, res?.amount)}
                        >
                          {" "}
                          {`${res?.words_count} words`}{" "}
                        </li>
                      ))}
                      {/* <li
                        className={`word-select ${
                          wordActive === 0 ? "single-select-active-0" : ""
                        }`}
                        onClick={() => handleWords(0)}
                      >
                        {" "}
                        100 words{" "}
                      </li>
                      <li
                        className={`word-select ${
                          wordActive === 1 ? "single-select-active-1" : ""
                        }`}
                        onClick={() => handleWords(1)}
                      >
                        {" "}
                        1000 words{" "}
                      </li>
                      <li
                        className={`word-select ${
                          wordActive === 2 ? "single-select-active-2" : ""
                        }`}
                        onClick={() => handleWords(2)}
                      >
                        {" "}
                        1500 words{" "}
                      </li>
                      <li
                        className={`word-select ${
                          wordActive === 3 ? "single-select-active-3" : ""
                        }`}
                        onClick={() => handleWords(3)}
                      >
                        {" "}
                        2000 words{" "}
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {serviceActive === 0 && (
              <div className="form-group da-opt">
                <div className="d-flex align-items-center da-opt-wrapper">
                  <label className="z-1 d-flex align-items-center">
                    {" "}
                    DA strength{" "}
                    <Tooltip
                      title="If you'd like to get sites with a specific DR instead of DA, please select a value here and let us know in the Special requirements field below that you are looking
                    for the DR metric."
                      placement="top-start"
                      className="mui-tooltip"
                    >
                      <IconButton size="small" className="info-icon">
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className="info-icon-image"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <div className="stepper-group">
                    <div className="range-selector">
                      <Stepper
                        activeStep={-1}
                        alternativeLabel
                        className="selector-range"
                      >
                        {steps.map((step, index) => (
                          <Step key={step.label}>
                            <StepLabel
                              onClick={() =>
                                handleStepClick(step.id, step?.amount)
                              }
                              className={`step-label
                              ${activeStep === step.id ? "active" : ""}
                            `}
                            >
                              {`DA ${step.da}`}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {serviceActive === 1 && (
              <div className="form-group da-opt">
                <div className="d-flex align-items-center">
                  <div className="w-100 d-flex align-items-center da-opt-wrapper">
                    <label className="z-1 d-flex align-items-center">
                      {" "}
                      Traffic strength{" "}
                      <Tooltip
                        title="If you'd like to get sites with a specific DR instead of DA, please select a value here and let us know in the Special requirements field below that you are looking
                  for the DR metric."
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <div className="stepper-group">
                      <div className="range-selector">
                        <Stepper
                          activeStep={-1}
                          alternativeLabel
                          className="selector-range"
                        >
                          {trafficSteps.map((step, index) => (
                            <Step key={step.id}>
                              <StepLabel
                                onClick={() =>
                                  handleStepClick(step.id, step?.amount)
                                }
                                className={`step-label
                              ${activeStep === step.id ? "active" : ""}
                            `}
                              >
                                {step.monthly_traffic}
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="form-group">
              <div className="row">
                <div className={!isCatMobile ? "col-md-6" : "col-md-12"}>
                  <div className="d-flex filed-form-new-wrap">
                    <label className="d-flex align-items-center">
                      {" "}
                      Number of posts *{" "}
                      <Tooltip
                        title="If you choose, e.g., 5 guest posts, we will pick 5
                    unique sites based on the package metrics you set."
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton
                          size="small"
                          className="info-icon"
                          pTooltip="Enter your username"
                          tooltipPosition="top"
                        >
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <Dropdown
                      name="number_of_posts"
                      value={buyData?.number_of_posts}
                      onChange={handleChange}
                      options={noOfPosts?.map((item) => ({
                        name: `${item.nunmber_of_post}`,
                        value: item.amount,
                      }))}
                      optionLabel="name"
                      placeholder="Select number of posts"
                      className="prime-mu-select"
                    />
                  </div>
                </div>

                <div
                  className={`cat-wrapper ${
                    !isCatMobile ? "col-md-6" : "col-md-12"
                  } ${cError ? "cat-err-wrapper" : ""}`}
                >
                  <div className="d-flex filed-form-new-wrap" ref={form4Ref}>
                    <label className="d-flex align-items-center lang-label">
                      {" "}
                      Category *{" "}
                      <Tooltip
                        title="In which business field do you require an article? We
                        will contact a copywriter with the most professional
                        expertise in that field to compose your article."
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <MultiSelect
                      value={selectedCategories}
                      onChange={(e) => {
                        setCError(false);
                        setSelectedCategories(e.value);
                      }}
                      options={category}
                      optionLabel="name"
                      filter
                      placeholder="Select categories"
                      className={`${
                        cError ? "err-outline-select" : "prime-mu-select"
                      }`}
                      panelFooterTemplate={panelFooterTemplate}
                      ref={multiSelectRef}
                    />
                  </div>
                  {cError && (
                    <p className="cat-err">Please select a category</p>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex filed-form-new-wrap" ref={formRef}>
                    <label className="d-flex align-items-center keyword-label">
                      {" "}
                      Keywords*{" "}
                      <Tooltip
                        title="You can write a headline or allow our copywriters to
                        create one for you, following to the best practices for
                        attention-grabbing titles."
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>

                    <input
                      type="text"
                      className={`${
                        kError ? "err-outline" : "text-area-control"
                      }`}
                      fullWidth
                      name="keywords"
                      placeholder="Separate each keyword with comma"
                      value={buyData.keywords}
                      onChange={handleChange}
                    />
                    {kError && (
                      <p className="key-error key-da-err">The field required</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-12 url-wrapper-form">
                  <div
                    className="d-flex filed-form-new-wrap url-wrapper-form"
                    ref={form2Ref}
                  >
                    <label className="d-flex align-items-center url-label">
                      {" "}
                      URL*{" "}
                      <Tooltip
                        title="Enter the URL that you have included in your content above.
                    It will be used to check the performed task for compliance
                    with your requirements. Maximum of 3 urls can be added."
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>

                    <input
                      type="text"
                      className={`${
                        invalidUrlIndexes.includes(0)
                          ? "err-url url-text-main"
                          : "text-area-control"
                      }`}
                      placeholder="http(s)://"
                      value={buyData.url[0]} // Use the first element from the array
                      onChange={(e) => handleUrlChange(e, 0)} // Pass index 0
                    />
                    {invalidUrlIndexes.includes(0) && (
                      <p className="err-msg err-da-msg">
                        A valid url is required
                      </p>
                    )}
                  </div>
                  {buyData.url.slice(1).map((url, index) => (
                    <div className="form-group" key={index}>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="filed-form-wrap additional-arc-fields"
                            ref={form2Ref}
                          >
                            <input
                              type="text"
                              className={`${
                                invalidUrlIndexes.includes(index + 1)
                                  ? "err-url"
                                  : "text-area-control"
                              }`}
                              placeholder="http(s)://"
                              value={url}
                              onChange={(e) => handleUrlChange(e, index + 1)} // Increment the index by 1
                            />
                            {invalidUrlIndexes.includes(index + 1) && (
                              <p style={{ color: "red" }}>
                                A valid url is required
                              </p>
                            )}
                          </div>
                          <Button
                            className="remove-btn-buy-form remove-array-btn"
                            type="button"
                            onClick={() => removeUrlInput(index + 1)}
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <Button
                    className="add-btn-buy-form"
                    type="button"
                    onClick={addUrlInput}
                  >
                    <AddIcon />
                  </Button>
                  {uError && (
                    <p style={{ color: "red" }}>A valid url is required</p>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                {/* ... (other columns and components) */}

                {/* Anchor text */}
                <div className="col-md-12 url-wrapper-form">
                  <div className="d-flex filed-form-new-wrap url-wrapper-form">
                    <label className="d-flex align-items-center anch-label">
                      {" "}
                      Anchor text{" "}
                      <Tooltip
                        title="Enter the anchor text that you have included in your content
                    above. It will be used to check the performed task for
                    compliance with your requirements. Maximum of 3 anchor text
                    can be added."
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>

                    <input
                      type="text"
                      className="text-area-control"
                      placeholder="Provide your anchor text here"
                      value={buyData.anchor_text[0]}
                      onChange={(e) => handleAnchorChange(e, 0)}
                    />
                  </div>
                  {buyData.anchor_text.slice(1).map((anchor, index) => (
                    <div className="form-group" key={index}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="filed-form-wrap additional-arc-fields">
                            <input
                              type="text"
                              className="text-area-control"
                              placeholder="Provide your anchor text here"
                              value={anchor}
                              onChange={(e) => handleAnchorChange(e, index + 1)}
                            />
                          </div>
                          <Button
                            className="remove-btn-buy-form remove-array-btn"
                            type="button"
                            onClick={() => removeAnchorInput(index + 1)}
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                        {/* ... (info-tool-tip column) */}
                      </div>
                    </div>
                  ))}
                  <Button
                    className="add-btn-buy-form"
                    type="button"
                    onClick={addAnchorInput}
                  >
                    <AddIcon />
                  </Button>
                </div>
              </div>
            </div>
            {/* ... (other components and form structure) */}

            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex filed-form-area-wrap">
                    <label className="d-flex align-items-center sr-label">
                      {" "}
                      Special Requirements{" "}
                      <Tooltip
                        title="Detail any specific criteria you have in mind for the
                    article, such as its outline, format, preferred tone of
                    voice etc"
                        placement="top-start"
                        className="mui-tooltip"
                      >
                        <IconButton size="small" className="info-icon">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className="info-icon-image"
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <textarea
                      className="text-area-control requirement-text-area"
                      rows="6"
                      name="special_requirements"
                      placeholder="Please specify any other requirements you have here"
                      value={buyData.special_requirements}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <button className="d-flex price-info-wrap">
              {" "}
              <p>
                Task price
                <span className="price-info-price">${setPrice()}</span>
              </p>
            </button>

            <div className="btn-form-buyer">
              <Button
                className="send-btn-form"
                type="submit"
                onClick={handleSubmit}
              >
                {" "}
                {load ? (
                  <CircularProgress className="text-white" />
                ) : (
                  "CHECKOUT"
                )}{" "}
              </Button>
              {/* <Button className="draft-btn"> SAVE AS DRAFT </Button> */}
            </div>
          </form>
        </div>
      )}
      <ConsultationModal visible={visible} setVisible={setVisible} />
      <AddFundsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        user={user}
        transaction={() => {
          return;
        }}
      />
    </div>
  );
};

export default DATrafficForm;
