import React, { useState } from "react";
import API from "../../api/ClientApi";
import DeleteIcon from "../../assets/images/ssss.svg";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Dashboard/styles/deldecModal.css";
import Swal from "sweetalert2";

const AcknowledgeModal = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);

  const handleAcknowledge = async (e) => {
    e.preventDefault();
    setLoad(true);
    const modifiedData = {
      user_type: 1,
      acknowledgement: 1,
    };
    await API.post(`/api/completed-order-by-buyer/${props.id}`, modifiedData)
      .then(async (response) => {
        setLoad(false);
        // Handle success or failure
        // Close the confirmation modal
        await props.handleAckClose();
        Swal.fire({
          title: "Success!",
          html: "Task acknowledged successfully",
          icon: "success",
          timer: 1500, // Set the duration in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the "OK" button
          allowOutsideClick: false, // Prevent closing by clicking outside
        });
        await props.getTabs();
        await props.getList();
        props.setType(29);
        // navigate("/dashboard/platform")
        // window.location.reload();
      })
      .catch((error) => {
        setLoad(false);
        // Handle error
      });
  };

  return (
    <Modal
      open={props.ackModal}
      onClose={props.handleAckClose}
      aria-labelledby="Acknowledge Task"
    >
      <Box className="modal-container">
        <FontAwesomeIcon
          icon={faCircleXmark}
          size="xl"
          className="font-style"
        />
        <Typography variant="h6" id="modal-title" gutterBottom>
          Acknowledge Task
        </Typography>
        <Typography className="detail-modal-text">
          Are you sure you want to acknowledge this task?
        </Typography>

        <hr />
        <div className="buttons-container">
          {" "}
          {/* Added a container for the buttons */}
          <Button
            onClick={props.handleAckClose}
            className="cancel-btn-modal"
            color="error"
          >
            Close
          </Button>
          <Button
            variant="contained"
            className="delete-button"
            onClick={handleAcknowledge}
            disabled={load} // Disable the button when loading
          >
            {load ? (
              <CircularProgress className="text-white circle-delete" />
            ) : (
              "Confirm"
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AcknowledgeModal;
