import React from 'react'
import "./styles/contact.css"
import ContactForm from './components/ContactForm'

const Contact = () => {
  return (
    <ContactForm/>
  )
}

export default Contact